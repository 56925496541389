import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ListCustomersForAssociationRequest } from 'src/app/shared/api-structures/admin/customer';
import { SetPlangoramAssociationsRequest } from 'src/app/shared/api-structures/misc/planogram';
import { AssociationLogicComponent } from 'src/app/shared/components/associations/association-logic/association-logic.component';

@Component({
  selector: 'planogram-associations',
  templateUrl: './planogram-associations.component.html',
  styleUrls: ['./planogram-associations.component.scss']
})
export class PlanogramAssociationsComponent {
  @ViewChild('clustersGen') clustersGen: AssociationLogicComponent
  @ViewChild('customersGen') customersGen: AssociationLogicComponent
  @ViewChild('usersGen') usersGen: AssociationLogicComponent

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,
    // private customersService: CustomerService,
    // private usersService: UserManagementService,
  ) {
  }

  async getAssociatedClusters(currentPage: number, pageSize: number, query: string) {
    return await this.data.clusterService.listClusters({ currentPage, pageSize, query, planogramId: this.data.id })
  }

  async getClustersOptions(currentPage: number, pageSize: number, query: string) {
    return await this.data.clusterService.listClusters({ currentPage, pageSize, query })
  }

  async getAssociatedCustomers(currentPage: number, pageSize: number, query: any) {
    return await this.data.dal.listAssociatedCustomersToPlanogram({ currentPage, pageSize, filter: '', planogramId: this.data.id })
  }

  async getCustomersOptions(currentPage: number, pageSize: number, query: ListCustomersForAssociationRequest | null) {
    if (!query) {
      return await this.data.dal.listCustomersForAssociation({ currentPage, pageSize })
    } else {
      return await this.data.dal.listCustomersForAssociation({ ...query, currentPage, pageSize })
    }
  }

  async getAssociatedUsers(currentPage: number, pageSize: number, query: string) {
    return await this.data.dal.listAssociatedUsersToPlanogram({ currentPage, pageSize, filter: query, planogramId: this.data.id })
  }

  async getUsersOptions(currentPage: number, pageSize: number, query: string) {
    return await this.data.usersService.loadUsers({ currentPage, pageSize, query })
  }

  async onConfirm() {
    const req: SetPlangoramAssociationsRequest = {
      planogramId: this.data.id,
      clusterIds: Object.keys(this.clustersGen.selected),
      customerIds: Object.keys(this.customersGen.selected),
      userIds: Object.keys(this.usersGen.selected)
    }

    await this.data.dal.setPlanogramAssociations(req)
    this.dialogRef.close()
  }

  async onCancel() {
    this.dialogRef.close()
  }

}