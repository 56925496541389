import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitiesService } from '../../../services/utilities.service';
import { ListCategoriesWithIds } from 'src/app/shared/api-structures/misc/catalog';
import { ListBrandsWithIds } from 'src/app/shared/api-structures/misc/catalog';
import { Dal } from 'src/app/dal/dal';
import { AddProductRequest } from 'src/app/shared/api-structures/misc/product';
import { Category } from 'src/app/shared/api-structures/misc/category';
import { Brand } from 'src/app/shared/api-structures/misc/brand';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import validbarcode from "barcode-validator";
import { LanguageService } from 'src/app/shared/services/language.service';


@Component({
  selector: 'app-product-new-dialog',
  templateUrl: './product-new-dialog.component.html',
  styleUrls: ['./product-new-dialog.component.css']
})
export class ProductNewDialogComponent {

  productForm: FormGroup
  product: AddProductRequest

  showAddButton: boolean = false

  // Categories
  categories: ListCategoriesWithIds.Category[] = []
  filteredCategoryItems: Observable<any[]>
  isShowAddCategoryDialog: boolean
  isLoadingCategories: boolean
  // Brands
  brands: ListBrandsWithIds.Brand[] = []
  filteredBrandItems: Observable<any[]>
  isShowAddBrandDialog: boolean
  isLoadingBrands: boolean

  constructor(public dialogRef: MatDialogRef<ProductNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private utilitiesService: UtilitiesService,
    private dal: Dal,
    private snackbarService: SnackbarService,
    private languageService: LanguageService,
  ) {
    this.productForm = this.formBuilder.group({
      nameCtrl: new FormControl({ value: '', disabled: false }),
      eanCtrl: new FormControl({ value: '', disabled: false }, [this.validateBarcode]),
      isCompetitorsCtrl: new FormControl(),
      categoryCtrl: new FormControl(),
      brandCtrl: new FormControl(),
    })

    this.getCategories()
    this.filteredCategoryItems = this.productForm.get('categoryCtrl').valueChanges
      .pipe(
        startWith(''),
        map(item => this.filterCategoryItems(item || ''))
      )

    this.getBrands()
    this.filteredBrandItems = this.productForm.get('brandCtrl').valueChanges
      .pipe(
        startWith(''),
        map(item => this.filterBrandItems(item || ''))
      )

    // this.product = {
    //   name: '',
    //   isCompetitors: false,
    //   ean: '',
    //   category: {
    //     id: null,
    //     name: null
    //   },
    //   brand: {
    //     id: null,
    //     name: null
    //   }
    // }
  }

  validateBarcode(control: FormControl) {
    if (!validbarcode(control.value)) {
      return { validateBarcode: true }
    }
    return null
  }

  filterCategoryItems(item: string | Category) {
    const name = typeof item === 'string' ? item : item.name
    let results = this.categories.filter(item =>
      item.name.toLowerCase().indexOf(name.toLowerCase()) === 0)
    this.showAddButton = this.categories.filter(i => i.name == name).length === 0
    return results
  }

  filterBrandItems(item: string | Brand) {
    const name = typeof item === 'string' ? item : item.name
    let results = this.brands.filter(item =>
      item.name.toLowerCase().indexOf(name.toLowerCase()) === 0)
    this.showAddButton = this.brands.filter(i => i.name == name).length === 0
    return results
  }

  async getCategories() {
    this.isLoadingCategories = true
    const categoriesRes = await this.dal.getCategoriesWithIds()
    this.categories = categoriesRes
    this.isLoadingCategories = false
  }

  async getBrands() {
    this.isLoadingBrands = true
    const brandsRes = await this.dal.getBrandsWithIds()
    this.brands = brandsRes
    this.isLoadingBrands = false
  }

  dropDownCategoryOptions() {
    const value = this.productForm.get('categoryCtrl').value
    this.productForm.get('categoryCtrl').setValue(value)
  }

  dropDownBrandOptions() {
    const value = this.productForm.get('brandCtrl').value
    this.productForm.get('brandCtrl').setValue(value)
  }

  categoryOptionSelected(option) {
    if ('name' in option.value) {
      this.product.category.id = option.value.id
      this.product.category.name = option.value.name
    }
  }

  brandOptionSelected(option) {
    if ('name' in option.value) {
      this.product.brand.id = option.value.id
      this.product.brand.name = option.value.name
    }
  }

  async addNewCategory() {
    this.isShowAddCategoryDialog = false
    const option = this.productForm.get('categoryCtrl').value
    if (!this.categories.some(entry => entry.name === option)) {
      const categoryRes = await this.dal.addCategory({ name: option })
      const index = this.categories.push(categoryRes) - 1
      this.productForm.get('categoryCtrl').setValue(this.categories[index])
      this.product.category.id = categoryRes.id
      this.product.category.name = categoryRes.name
    }
  }

  async addNewBrand() {
    this.isShowAddBrandDialog = false
    const option = this.productForm.get('brandCtrl').value
    if (!this.brands.some(entry => entry.name === option)) {
      const brandRes = await this.dal.addBrand({ name: option })
      const index = this.brands.push(brandRes) - 1
      this.productForm.get('brandCtrl').setValue(this.brands[index])
      this.product.brand.id = brandRes.id
      this.product.brand.name = brandRes.name
    }
  }

  displayFn(category: Category): string {
    return category && category.name ? category.name : ''
  }

  displayFn2(brand: Brand): string {
    return brand && brand.name ? brand.name : ''
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  save() {
    if (!this.productForm.valid) {
      this.utilitiesService.validateAllFormFields(this.productForm)
      return
    }
    if (!this.product.category.id) {
      this.snackbarService.openSnackBar(4000, `${this.languageService.translateSync('PleaseSaveTheCategoryFirstBeforeSaveProduct')}`)
      return
    }
    this.dialogRef.close(this.product);
  }
}
