<div class="main">
    <div class="request-container">
      <div class="logo-container">
        <img src="/assets/images/login_wtm_ire_logo.png" alt="logo" />
      </div>
      <h3 class="text-center">{{'ImageRecognitionStudio'|translate}}</h3>
      <div class="login-form-container">
        <mat-card *ngIf="!showSuccessMessage">
          <mat-card-title>{{'RequestAccountDeletion'|translate}}</mat-card-title>
          <mat-card-content>
            <div class="card-txt">
              {{'PleaseEnterYourEmailAndPasswordBelowAndWeWillProccessYourRequest'|translate}}
            </div>
          </mat-card-content>

          <mat-card-content class="warning-card">
              <mat-icon>info</mat-icon> 
              <div>
                <b style="color: #B84545">
                  {{'Warning'|translate}}:
                </b>
                {{'ThisIsNotALoginScreenWhenYouFillInTheFieldsBelowYourDataAndYourAccountWillBeDeleted'|translate}}
              </div>
          </mat-card-content>

          <firebase-ui 
            (signInSuccessWithAuthResult)="successCallback($event)" 
            (signInFailure)="errorCallback($event)"
            (uiShown)="uiShownCallback()">
          </firebase-ui>
        </mat-card>

        <mat-card *ngIf="showSuccessMessage">
          <mat-card-content>
            <div class="card-txt">
              {{'YourRequestHasBeenSentSuccesfully'|translate}}
            </div>
            
            <mat-card-actions 
              [style]="{'paddingTop': '15px'}"
              [align]="'end'"
            >
              <p (click)="logout()"><a>{{'GoBackToLoginPage'|translate}}</a></p>
            </mat-card-actions>
          </mat-card-content>
        </mat-card>
      </div>      
    </div>
</div>
