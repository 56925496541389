import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SortDirection } from 'src/app/shared/api-structures/common';
import { ListProductsWithImagesToApproveFilters, SortBy } from 'src/app/shared/api-structures/misc/toApprove';

@Component({
  selector: 'app-unapproved-list-filters',
  template: `
  <h2>{{'FilterOptions'|translate}}</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill">
      <mat-label>{{'SortBy'|translate}}</mat-label>
      <mat-select formControlName="sortBy">
        <mat-option value="productId">{{'ProductId'|translate}}</mat-option>
        <mat-option value="productName">{{'ProductName'|translate}}</mat-option>
        <mat-option value="accuracy">{{'Accuracy'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{'SortDirection'|translate}}</mat-label>
      <mat-select formControlName="sortDirection">
        <mat-option value="asc">{{'Ascending'|translate}}</mat-option>
        <mat-option value="desc">{{'Descending'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="btn-div">
      <button mat-button type="reset">{{'ClearFilters'|translate}}</button>
      <button mat-button type="submit" [disabled]="!form.valid">{{'Submit'|translate}}</button>
    </div>
  </form>
  `,
  styles: [`
  .slider-div {
    width: 100%;
  }
  .btn-div {
    display: flex;
    justify-content: space-between;
  }
`]
})
export class UnapprovedListFiltersComponent {
  constructor(public dialogRef: MatDialogRef<UnapprovedListFiltersComponent>, @Inject(MAT_DIALOG_DATA) private data: { filters: ListProductsWithImagesToApproveFilters, sortBy: SortBy, sortDirection: SortDirection }) { }
  form = new FormGroup({
    minimumImages: new FormControl(this.data.filters.minimumImages),
    maximumImages: new FormControl(this.data.filters.maximumImages),
    sortBy: new FormControl(this.data.sortBy),
    sortDirection: new FormControl(this.data.sortDirection),
  });

  onSubmit() {
    const sortBy = this.form.value.sortBy;
    const sortDirection = this.form.value.sortDirection;
    delete this.form.value.sortBy;
    delete this.form.value.sortDirection;
    const filters = this.form.value;
    this.dialogRef.close({ sortBy, sortDirection, filters });
  }
}
