import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';

@Component({
  selector: 'organization-admin-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends AutoDestroy {
  isShowNav = false

  constructor(private router: Router) {
    super()
    this.subscriptions.push(this.router.events.subscribe(v => this.isShowNav = false))
  }
}