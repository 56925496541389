<p-sidebar #sidebar [(visible)]="isShowNav">
    <mat-nav-list>
        <a mat-list-item [routerLink]="['/']">
            <mat-icon>home</mat-icon>Home
        </a>
        <a mat-list-item [routerLink]="['companies']">
            <mat-icon>business</mat-icon>Companies
        </a>
    </mat-nav-list>
</p-sidebar>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="isShowNav=true">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>Organization Admin</span>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>