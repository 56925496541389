import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ExportResponse, ImportRequest } from "src/app/shared/api-structures/admin/user";
import { ByIdRequest } from "src/app/shared/api-structures/common";
import { AddPriceTargetRequest, BasePriceTarget, ListPriceTargetsRequest, ListPriceTargetsResponse, PriceTargetFull, UpdatePriceTargetRequest } from "src/app/shared/api-structures/misc/priceTarget";
import { ApiService } from "src/app/shared/services/api.service";
import { LanguageService } from "src/app/shared/services/language.service";

@Injectable()
export class PriceTargetService {
  private priceTargetsSubject = new Subject<ListPriceTargetsResponse>()
  priceTargets$ = this.priceTargetsSubject.asObservable()

  constructor(private api: ApiService, private languageService: LanguageService) { }

  async fetchPriceTarget(query: ListPriceTargetsRequest) {
    const result = await this.api.adminRequest<ListPriceTargetsRequest, ListPriceTargetsResponse>({
      endpoint: '/listPriceTargets',
      body: query,
      errorMessage: this.languageService.translateSync('ErrorAddingPriceTargets')
    })
    return result
  }

  async listPriceTargets(query: ListPriceTargetsRequest) {
    const result = await this.fetchPriceTarget(query)
    this.priceTargetsSubject.next(result)
  }

  async addPriceTarget(priceTarget: AddPriceTargetRequest) {
    const result = await this.api.adminRequest<AddPriceTargetRequest, BasePriceTarget>({
      endpoint: '/addPriceTarget',
      body: priceTarget,
      errorMessage: this.languageService.translateSync('ErrorAddingPriceTarget')
    })
    return result
  }

  async updatePriceTarget(updateRequest: UpdatePriceTargetRequest) {
    const result = await this.api.adminRequest<UpdatePriceTargetRequest, BasePriceTarget>({
      endpoint: '/updatePriceTarget',
      body: updateRequest,
      errorMessage: this.languageService.translateSync('ErrorAddingPriceTarget')
    })
    return result
  }

  async deletePriceTarget(id: string) {
    const result = await this.api.adminRequest<ByIdRequest, null>({
      endpoint: '/deletePriceTarget',
      body: { id },
      errorMessage: this.languageService.translateSync('ErrorAddingPriceTarget')
    })
  }

  // Price Target
  async exportPriceTargets() {
    const result = await this.api.adminRequest({
      endpoint: '/exportPriceTargets',
      responseType: ExportResponse,
      errorMessage: this.languageService.translateSync('ErrorExportPriceTargets')
    })

    return result
  }

  async importPriceTargets(url: string) {
    const result = await this.api.adminRequest<ImportRequest>({
      endpoint: '/importPriceTargets',
      body: { url },
      errorMessage: this.languageService.translateSync('ErrorImportPriceTargets')
    })

    return result
  }

}