<div class="settings-table-main">
    <div class="table-content-container">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" animationDuration="0ms" *ngIf="companySettings">
            <mat-tab label="General">
                <div class="col-action-settings">
                  <div>
                    <label class="margin-top">{{'Logo'|translate}}</label>
                    <mat-card class="img-card mat-elevation-z0" *ngIf="_logoUrl">
                        <button mat-mini-fab (click)="_logoUrl=undefined"
                            class="img-icon"><mat-icon>add_a_photo</mat-icon></button>
                        <img class="img-logo" mat-card-image src="{{_logoUrl}}" alt="LOGO" />
                    </mat-card>
                    <drag-and-drop-files isMultipleFiles="false" accept="image/*" (onFileSelected)="saveLogoFileToStorga($event)"
                        *ngIf="!_logoUrl && !isLoadingSettings"></drag-and-drop-files>
                    <div *ngIf="uploadProgress">
                        {{uploadProgress.name}}
                        <mat-progress-bar mode="determinate" value="{{uploadProgress.progress}}"></mat-progress-bar>
                    </div>
                  </div>
                  <br>
                  <mat-form-field appearance="fill">
                    <mat-label>{{'Language'|translate}}</mat-label>
                    <mat-select [(ngModel)]="_language" ngDefaultControl>
                        <mat-option value="pt">Portuguese</mat-option>
                        <mat-option value="en">English</mat-option>
                        <mat-option value="es">Spanish</mat-option>
                        <mat-option value="ar">Arabic</mat-option>
                        <mat-option value="el">Greek</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <br>
                  <mat-form-field appearance="fill" *ngIf="countries.length > 0">
                    <mat-label>{{'Country'|translate}}</mat-label>
                    <mat-select [(ngModel)]="_country" ngDefaultControl>
                      <mat-option></mat-option>
                      <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div style="align-self: self-end; text-align-last: center;">
            <button mat-button (click)="saveSettings()" cdkFocusInitial matTooltip="{{'SaveChanges'|translate}}">
                <mat-icon color="primary">save</mat-icon>
            </button>
        </div>
    </div>
</div>