<div class="container">
    <div class="header">
        <h2>{{"ProductList" | translate}}</h2>
        <span>{{data.issue | translate}}</span>
    </div>
    <div *ngIf="data.issue === 'DuplicateProducts'; else normalList">
        <table style="margin-bottom: -46px;">
            <thead>
                <tr>
                    <th style="padding: 16px;" class="ean">{{"EAN" | translate}}</th>
                    <th style="padding: 16px;">{{"ProductInfo" | translate}}</th>
                    <th style="padding: 16px;">&nbsp;</th>
                </tr>
            </thead>
        </table>
        <div *ngFor="let group of Products; let gIndex = index" class="product-group scrollbar">
            <table class="group-table">
                <tbody>
                    <tr *ngFor="let product of group; let i = index">
                        <td style="padding: 16px;">{{ product.ean }}</td>
                        <td style="padding: 16px;">
                            <div class="productInfo">
                                <span>{{product.name}}</span>
                                <span>{{product.productId}}</span>
                            </div>
                        </td>
                        <td [routerLink]="['/manage-products']" [queryParams]="{ product_id: getProductId(product.productId), mode: 'edit' }" (click)="close()" style="padding: 16px;">
                            <mat-icon style="cursor: pointer;">open_in_new</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <ng-template #normalList>
        <table>
            <thead>
                <tr>
                    <th style="padding: 16px;" class="ean">{{"EAN" | translate}}</th>
                    <th style="padding: 16px;">{{"ProductInfo" | translate}}</th>
                    <th style="padding: 16px;">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let product of Products; let i = index">
                    <td style="padding: 16px;">{{ product.ean }}</td>
                    <td style="padding: 16px;">
                        <div class="productInfo">
                            <span style="font-family: Roboto; font-size: 15px; font-weight: 500; line-height: 22.5px; letter-spacing: 0.024em; text-align: left;">
                                {{product.name}}
                            </span>
                            <span>
                                {{product.productId}}
                            </span>
                        </div>
                    </td>
                    <td routerLink="/manage-products" [queryParams]="{ product_id : getProductId(i), mode: 'edit' }"
                        (click)="close()" style="padding: 16px;">
                        <mat-icon style="cursor: pointer;">open_in_new</mat-icon>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-template>
    <div class="footer">
        <mat-paginator (page)="handlePageEvent($event)" [length]="totalProducts" [pageSize]="pageSize"></mat-paginator>
    </div>
</div>
<div class="button-row">
    <button (click)="close()" class="btn">{{ 'Close' | translate | capitalizeFirst }}</button>
</div>
