import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Dal } from 'src/app/dal/dal';
import { AppService } from 'src/app/services/app.service';
import { PlanogramImportFromImageService } from 'src/app/services/planogram-import-from-image.service';
import { EquipmentWebStructure, EquipmentWithId } from 'src/app/shared/api-structures/misc/equipment';
import { CreateSignedUrlRequest } from 'src/app/shared/api-structures/misc/misc';
import { SetPlanogramFromImageResponse } from 'src/app/shared/api-structures/misc/planogram';
import { Structure } from 'src/app/shared/api-structures/misc/planogramStructure';
import { ProductWithCatalog } from 'src/app/shared/api-structures/misc/product';
import { DragAndDropFilesComponent } from 'src/app/shared/components/drag-and-drop-files/drag-and-drop-files.component';

type Step = 'UPLOAD_IMAGE' | 'PREVIEW_IMAGE' | 'SELECT_EQUIPMENT' | 'CREATE_EQUIPMENT'
@Component({
  selector: 'app-import-from-image-dialog',
  templateUrl: './import-from-image-dialog.component.html',
  styleUrls: ['./import-from-image-dialog.component.scss']
})
export class ImportFromImageDialogComponent implements OnInit {
  @ViewChild('fileDrop') fileDrop: DragAndDropFilesComponent
  imageUrl: string
  step: Step = 'UPLOAD_IMAGE'
  equipmentName: string = ''
  imageLoading: boolean = true
  planogramProducts: ProductWithCatalog[]
  imageData: SetPlanogramFromImageResponse
  disableImportButton: boolean
  structure: Structure
  tempEquipment: EquipmentWebStructure
  matchingEquipment: EquipmentWithId
  selectedEquipment: EquipmentWithId
  error: string
  fileType: string
  file: string


  constructor(
    public dialogRef: MatDialogRef<void>,
    private appService: AppService,
    private dal: Dal,
    private planogramImportFromImageService: PlanogramImportFromImageService,
    @Inject(MAT_DIALOG_DATA) public data: { planogramId: string, equipments: EquipmentWithId[] }
  ) { }

  ngOnInit(): void {
  }

  async uploadFile(files: File[]) {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']
    if (!allowedTypes.includes(files[0].type)) {
      this.error = 'InvalidFileType'
      return
    }
    if (files[0].size > 5 * 1024 * 1024) {
      this.error = 'FileSizeIsTooLarge'
      return
    }
    if (files[0].type === 'application/pdf') {
      this.file = URL.createObjectURL(files[0])
      this.fileType = 'pdf'
      this.imageLoading = false
    }

    const urlRequest: CreateSignedUrlRequest = {
      ext: files[0].name.split('.').pop(),
      contentType: files[0].type,
      isMobileReport: false,
      isPricetag: false,
      source: 'import_planogram',
      isPlanogramUpload: true,
      planogramId: this.data.planogramId
    }

    this.imageUrl = await this.appService.uploadFileToStorageFull(files[0], urlRequest, progress => {
      if (progress === 100) {
        this.fileDrop.progress[0].status = true
      }
      this.fileDrop.progress[0].percent = progress
    })
  }

  async detecPlanogramDetails() {
    this.disableImportButton = true
    if (!this.imageData) {
      try {
        this.imageData = await this.dal.detectPlanogrmFromImage(this.data.planogramId, this.imageUrl)
      } catch (e) {
        console.error(e)
        this.error = e.error.errors[0]
        return
      }
    }

    const { equipment, structure } = this.planogramImportFromImageService.convertPlanogramImageData(this.imageData)
    this.tempEquipment = equipment
    this.matchingEquipment = this.planogramImportFromImageService.matchEquipmentToImageData(equipment, this.data.equipments)
    this.structure = structure
    if (!this.structure.areas?.some(a => a.items?.length > 0)) {
      this.error = 'NoProductsDetected'
      return
    }
    this.continueToEquipment()
  }


  continueToPreview() {
    this.step = 'PREVIEW_IMAGE'
  }

  async continueToEquipment() {
    this.step = 'SELECT_EQUIPMENT'
  }

  continueToCreateEquipment() {
    this.step = 'CREATE_EQUIPMENT'
  }

  async confirmCreateEquipment() {
    if (!this.equipmentName) {
      return
    }
    this.tempEquipment.name = this.equipmentName
    this.selectedEquipment = await this.dal.addEquipment({
      ...this.tempEquipment,
    })
    this.confirmAndClose()
    // this.dialogRef.close()
  }

  async selectExistingEquipment() {
    this.selectedEquipment = this.matchingEquipment
    this.confirmAndClose()
  }

  confirmAndClose() {
    if (!this.selectedEquipment || !this.structure) {
      return
    }
    this.dialogRef.close({
      equipment: this.selectedEquipment, structure: this.structure, catalogs: this.imageData.products.map(p => {
        return p.product
      }), imageImportedFromUrl: this.imageUrl
    });
  }

  // async test() {
  //   this.imageData = {} as any as SetPlanogramFromImageResponse
  //   // return
  //   this.imageData.products.forEach(p => {
  //     if (!p.label) {
  //       p.label = "invasor_invasor"
  //     }
  //   })
  //   // this.imageData = this.planogramImportFromImageService.convertSizeToCm(this.imageData,this.catalogs, 3)
  //   const imageData = this.planogramImportFromImageService.fixImageData(this.imageData)
  //   this.planogramImportFromImageService.drawDebug(imageData)
  //   // this.equipmentName = "test_image_equipment"
  //   this.detecPlanogramDetails()
  // }

}
