import { Position } from "src/app/shared/api-structures/common";

export class Rect {
    constructor(public x: number, public y: number, public width: number, public height: number) {
        this.x = Number(x.toFixed(2));
        this.y = Number(y.toFixed(2));
        this.width = Number(width.toFixed(2));
        this.height = Number(height.toFixed(2));
    }

    static fromRect(rect: Rect) {
        return new Rect(rect.x, rect.y, rect.width, rect.height);
    }

    static fromPosition(position: Position) {
        return new Rect(position.left, position.top, position.right - position.left, position.bottom - position.top);
    }
}
