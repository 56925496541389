<div class="table-main" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50"
  [infiniteScrollDisabled]="isInfiniteScrollDisabled">
  <div class="table-content-container">
      <div class="title">
        {{'ImageCatalogsList'|translate}}
      </div>

    <div class="content">
      <button mat-button routerLink="/unapproved">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="pre-table-header">
        <mat-form-field class="aligned">
          <mat-chip-list #filterChipList>
            <mat-chip *ngFor="let filter of filters" [selectable]="selectable" [removable]="true"
              (removed)="removeFilter(filter)">
              {{filter}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="{{'SearchImageCatalog'|translate}}" [matChipInputFor]="filterChipList"
              [matChipInputSeparatorKeyCodes]="filterSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addFilter($event)">
          </mat-chip-list>
          <mat-icon matSuffix *ngIf="filterShowSpinner">
            <i class="fa fa-spinner fa-spin"></i>
          </mat-icon>
        </mat-form-field>
      </div>
      <br />
      <ul>
        <li *ngFor="let image of filteredImageCatalogs let i=index">
          <mat-card class="mat-elevation-z0">
            <label [for]="'id'+i">
              <img class="show-product-icon" [src]="image.thumbnail" routerLink="/image-review"
                [queryParams]="{ product_id : productId,product_name: productName, imageCatalog_id: image.imageCatalogId, product_thumbnail: image.thumbnail, is_have_more_image_catalogs: true}" />
            </label>
            <mat-card-content>
              <span matTooltip="{{image.imageCatalogName}}" class="crop-long-text"
                #toolTip1>{{image.imageCatalogName}}</span>
              <br />
              <!-- <div style="color:#919191;" class="crop-long-text" matTooltip="{{image.productId}}"
                [matTooltipDisabled]="isMatTooltipDisabled(toolTip2)" #toolTip2>
                {{ image.imageCatalogId}}
              </div> -->
            </mat-card-content>
          </mat-card>
        </li>
      </ul>
      <br />
    </div>
  </div>