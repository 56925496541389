<button
  [hidden]="hidden || isRound"
  mat-flat-button
  matTooltip="{{ 'Import' | translate }}"
  (click)="openDialog()"
  color="primary"
  class="dialog-button-container"
  class="button-desc"
>
  <mat-icon>import_export</mat-icon>{{ "Import" | translate }}
</button>

<button
  [hidden]="hidden || !isRound"
  mat-mini-fab
  matTooltip="{{ 'Import' | translate }}"
  color="primary"
  (click)="openDialog()"
>
  <mat-icon>cloud_upload</mat-icon>
</button>
