import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export type customInputValue = string | number | boolean | Date

@Component({
  selector: 'custom-field-input',
  templateUrl: './custom-field-input.component.html',
  styleUrls: ['./custom-field-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => CustomFieldInputComponent)
  }]
})
export class CustomFieldInputComponent implements ControlValueAccessor {

  onChange = (value: customInputValue) => { }
  onTouched: any = () => { };
  disabled = false;
  ctrl = new FormControl()

  @Input() fieldType: 'string' | 'number' | 'date' | 'boolean' | 'select' = 'string'
  @Input() options: string[] = []
  @Input() required: boolean
  @Input() placeholder: string = ''

  constructor() {
    this.ctrl.valueChanges.subscribe((value) => {
      this.onChange(value)
    })
  }

  setValue(e) {
    this.onChange(e.target.value)
  }

  registerOnChange(fn: (value: customInputValue) => void): void {
    this.onChange = (value) => {
      fn(value)
    };
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: customInputValue): void {
    this.ctrl.setValue(value)
  }

}
