// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsNumber, IsOptional, IsString } from 'class-validator'
import { DateType } from '../customDecorators'

export class EquipmentWebStructure {
    @Expose() @IsString() @IsOptional() id?: string
    @Expose() @IsString() name!: string
    @Expose() @IsBoolean() isVertical!: boolean
    @Expose() @IsNumber() @IsOptional() numberOfShelves?: number
    @Expose() @IsNumber() @IsOptional() shelfHeight?: number
    @Expose() @IsNumber() width!: number
    @Expose() @IsNumber() @IsOptional() depth?: number
    @Expose() @IsString() backgroundColor!: string
    @Expose() @IsNumber() @IsOptional() shelfSeparatorHeight?: number
    @Expose() @Type(() => ShelfWebStructure) @IsArray() shelves!: ShelfWebStructure[]
    @Expose() @IsString() status!: string
    @Expose() @IsBoolean() @IsOptional() isUsed?: boolean
    @Expose() @DateType() @IsDate() @IsOptional() lastUpdate?: Date
    @Expose() @IsBoolean() @IsOptional() isUpdating?: boolean
}

export class ShelfWebStructure {
    @Expose() @IsNumber() shelfHeight!: number
    @Expose() @IsNumber() @IsOptional() depth?: number
    @Expose() @IsNumber() @IsOptional() shelfSeparatorHeight?: number
}

export class EquipmentWithId extends EquipmentWebStructure {
    @Expose() @IsString() id!: string
}

export class AddEquipmentRequest extends EquipmentWebStructure { }

export class AddEquipmentResponse extends EquipmentWithId { }

export class SearchEquipmentsRequestFilters {
    @Expose() @IsString() status?: string
    @Expose() @IsNumber() minimumOfShelves?: number
    @Expose() @IsNumber() maximumOfShelves?: number
    @Expose() @IsString() sortBy?: string
    @Expose() @IsString() sortDirection?: string
}

export class ListEquipmentsRequest {
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) @IsOptional() filters?: string[]
    @Expose() @Type(() => SearchEquipmentsRequestFilters) @IsOptional() modalFilters?: SearchEquipmentsRequestFilters
}
export class ListEquipmentResponse {
    @Expose() @Type(() => EquipmentWithId) @IsArray() arr!: EquipmentWithId[]
}