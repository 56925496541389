import { Injectable } from "@angular/core"
import { SetPlanogramFromImageProduct, SetPlanogramFromImageResponse, SetPlanogramFromImageShelves } from "../shared/api-structures/misc/planogram"
import { ProductWithCatalog } from "../shared/api-structures/misc/product"
import { Area, Structure } from "../shared/api-structures/misc/planogramStructure"
import { EquipmentWebStructure, EquipmentWithId } from "../shared/api-structures/misc/equipment"
import { Rect } from "../views/planograms/planogram-planning/planogram-builder/Rect"
import { ExtendedProduct } from "../shared/interfaces"

type ProductWithSize = {
    productFromImage: SetPlanogramFromImageProduct,
    product: ProductWithCatalog,
    rect: Rect
}

type Ratio = {
    heightRatio: number,
    widthRatio: number
}

@Injectable({ providedIn: 'root' })
export class PlanogramImportFromImageService {
    calculateEquipmentSizeRatio(imageData: SetPlanogramFromImageResponse) {
        let ratios: { ratio: Ratio, confidnece: number, ratioDiff: number, productName: string }[] = []

        imageData.products.forEach(p => {
            const label = p.label
            if (p.product && p.product.id !== 'invasor' && p.product.imageCatalogs[0].width && p.product.imageCatalogs[0].height) {
                const widthRatio = p.product.imageCatalogs[0].width / (p.position.right - p.position.left)
                const heightRatio = p.product.imageCatalogs[0].height / (p.position.bottom - p.position.top)
                const ratioDiff = Math.abs(widthRatio - heightRatio)
                ratios.push({ ratio: { widthRatio, heightRatio }, confidnece: p.classificationConfidence, ratioDiff, productName: p.product.name + ' ' + p.product.imageCatalogs[0].name })
            }
        })

        ratios = ratios.sort((a, b) => b.confidnece - a.confidnece).slice(0, 2)

        let ratio = 0
        let ratioDiff = 0
        ratios.forEach(r => {
            ratioDiff += r.ratioDiff
            ratio += r.ratio.widthRatio * r.ratioDiff
        })

        if (!ratio) {
            ratio = 1
        } else {
            ratio = ratio / ratioDiff
        }

        return ratio
    }

    matchEquipmentToImageData(equipToMatch: EquipmentWebStructure, exitings: EquipmentWithId[]) {
        const WIDTH_ERROR_MARGIN = 3
        const SHELF_HEIGHT_ERROR_MARGIN = 3
        const existing = exitings.find(equip => {
            const widthDiff = equip.width - equipToMatch.width
            if (widthDiff < WIDTH_ERROR_MARGIN && widthDiff >= 0) {
                const matching = equip.shelves?.every((s, i) => {
                    if (!equipToMatch.shelves[i]) {
                        return false
                    }
                    const heightDiff = s.shelfHeight - equipToMatch.shelves[i].shelfHeight
                    return heightDiff < SHELF_HEIGHT_ERROR_MARGIN && heightDiff >= 0
                })
                return matching
            }
        })
        return existing
    }

    convertPlanogramImageData(imageData: SetPlanogramFromImageResponse) {
        // currently only horizontal shelves are supported
        imageData = this.fixImageData(imageData)
        const equipment: EquipmentWebStructure = this.createEquipmentFromImageData(imageData)
        const structure: Structure = this.createStructureFromImageData(imageData, equipment)
        return { equipment, structure }
    }

    fixImageData(imageData: SetPlanogramFromImageResponse) {
        let fixedImageData = JSON.parse(JSON.stringify(imageData)) as SetPlanogramFromImageResponse
        fixedImageData = this.convertSizeToCm(fixedImageData)
        fixedImageData = this.alignShelvesToLowestProduct(fixedImageData)
        fixedImageData = this.mergeSameHeightShelves(fixedImageData)
        // remove space between shelves
        fixedImageData.shelves.forEach((s, i) => {
            if (i === 0) {
                s.position.top = 0
                return
            }
            const prevShelf = fixedImageData.shelves[i - 1]
            s.position.top = prevShelf.position.bottom
        })
        fixedImageData = this.resizeShelvesWithSimilarHeight(fixedImageData)
        return fixedImageData
    }

    createEquipmentFromImageData(imageData: SetPlanogramFromImageResponse) {
        const newEquipment: EquipmentWebStructure = {
            backgroundColor: '#ffffff',
            depth: 0,
            isUsed: false,
            isVertical: false,
            lastUpdate: new Date(),
            name: '',
            numberOfShelves: imageData.shelves.length,
            shelves: imageData.shelves.map(s => {
                const shelfHeight = s.position.bottom - s.position.top
                return {
                    depth: 0,
                    shelfHeight: Math.round(shelfHeight * 100) / 100,
                    shelfSeparatorHeight: 1
                }
            }),
            status: '',
            shelfHeight: 0,
            width: Math.round(imageData.originalWidth * 100) / 100
        }
        return newEquipment
    }

    createStructureFromImageData(imageData: SetPlanogramFromImageResponse, equipment: EquipmentWebStructure) {
        const structure: Structure = {
            height: 0,
            width: equipment.width,
            areas: []
        }

        equipment.shelves.forEach((s, i) => {
            const shelfTop = structure.areas.reduce((acc, curr) => acc + Number(curr.rect.height), 0)
            const area: Area = {
                areaType: 'horizontal',
                rect: new Rect(0, shelfTop, structure.width, s.shelfHeight),
                items: []
            }
            structure.areas.push(area)

            if (i < equipment.shelves.length - 1) {
                const seperatorTop = structure.areas.reduce((acc, curr) => acc + Number(curr.rect.height), 0)
                const seperator: Area = {
                    areaType: 'seperator',
                    rect: new Rect(0, seperatorTop, structure.width, s.shelfSeparatorHeight || 1),
                }
                structure.areas.push(seperator)
            }
        })

        structure.areas.sort((a, b) => a.rect.y - b.rect.y)
        structure.height = structure.areas.reduce((acc, curr) => acc + curr.rect.height, 0)

        const outOfPlanogram: SetPlanogramFromImageProduct[] = []
        imageData.products.forEach(p => {
            const area = structure.areas.find(a => {
                const rect = Rect.fromPosition(p.position)
                const pMiddle = rect.y + rect.height / 2
                return pMiddle >= a.rect.y && pMiddle <= a.rect.y + a.rect.height
            })

            if (area) {
                area.items = area.items || []
                const rect = Rect.fromPosition(p.position)
                area.items.push({
                    itemType: 'product',
                    rect: new Rect(rect.x, area.rect.height - rect.height, rect.width, rect.height),
                    absolutePoint: rect,
                    productId: p.product.id,
                    imagesCatalogId: p.product.imageCatalogs[0].id,
                    imgUrl: p.product.imageCatalogs[0].thumbnailUrl,
                    name: p.product.name,
                })

            } else {
                outOfPlanogram.push(p)
            }
        })

        structure.areas.forEach(area => {
            let productsWidth = area?.items?.reduce((acc, curr) => acc + curr.rect.width, 0)
            if (productsWidth && productsWidth >= area.rect.width) {
                area.rect.width = productsWidth
                equipment.width = Math.max(equipment.width, productsWidth)
            }
            // align items to the left
            let lastLeft = area.rect.x
            area.items?.sort((a, b) => a.rect.x - b.rect.x).forEach(item => {
                const prevX = item.rect.x
                item.rect.x = lastLeft
                item.absolutePoint.x += (item.rect.x - prevX)
                lastLeft += item.rect.width
            })
        })
        structure.areas.forEach(area => {
            area.rect.width = equipment.width
        })
        return structure
    }

    private convertSizeToCm(imageData: SetPlanogramFromImageResponse) {
        const ratio = this.calculateEquipmentSizeRatio(imageData)
        const fixedImageData = JSON.parse(JSON.stringify(imageData)) as SetPlanogramFromImageResponse
        fixedImageData.shelves = fixedImageData.shelves.map(s => {
            return {
                position: {
                    top: s.position.top * ratio,
                    left: s.position.left * ratio,
                    right: s.position.right * ratio,
                    bottom: s.position.bottom * ratio
                }
            }
        })
        fixedImageData.products = fixedImageData.products.map(p => {
            let top = p.position.top * ratio
            let right = p.position.right * ratio
            if (p.product.id !== 'invasor') {
                right = p.position.left * ratio + p.product.imageCatalogs[0].width
                top = p.position.bottom * ratio - p.product.imageCatalogs[0].height
            }
            return {
                position: {
                    top: top,
                    left: p.position.left * ratio,
                    right: right,
                    bottom: p.position.bottom * ratio
                },
                product: p.product,
                label: p.label,
                classificationConfidence: p.classificationConfidence
            }
        })
        fixedImageData.originalWidth = fixedImageData.originalWidth * ratio
        fixedImageData.originalHeight = fixedImageData.originalHeight * ratio
        return fixedImageData
    }

    private alignShelvesToLowestProduct(imageData: SetPlanogramFromImageResponse) {
        imageData.shelves.forEach(s => {
            const shelfProducts = imageData.products.filter(p => {
                const productHeight = p.position.bottom - p.position.top
                const productWidth = p.position.right - p.position.left
                const productMiddle = [p.position.top + productHeight / 2, p.position.left + productWidth / 2]
                return productMiddle[0] > s.position.top &&
                    productMiddle[0] < s.position.bottom &&
                    productMiddle[1] > s.position.left &&
                    productMiddle[1] < s.position.right
            })
            const lowestProduct = shelfProducts.sort((a, b) => a.position.bottom - b.position.bottom).pop()
            if (lowestProduct) {
                s.position.bottom = lowestProduct.position.bottom
            }
        })
        return imageData
    }

    private mergeSameHeightShelves(imageData: SetPlanogramFromImageResponse) {
        // find shelves with vertical overlap
        const twinShelfIndexes: number[][] = []
        imageData.shelves.forEach((s, index) => {
            imageData.shelves.forEach((s2, index2) => {
                if (twinShelfIndexes.find(t => t.includes(index) || t.includes(index2))) return
                if (index === index2) return
                const overlapLength = Math.min(s.position.bottom, s2.position.bottom) - Math.max(s.position.top, s2.position.top)
                const lowestShelf = s.position.bottom > s2.position.bottom ? s : s2
                const overlapRatio = overlapLength / (lowestShelf.position.bottom - lowestShelf.position.top)
                if (overlapRatio > 0.5) {
                    twinShelfIndexes.push([index, index2])
                }
            })
        })

        const shelves = twinShelfIndexes.map(t => {
            const s1 = imageData.shelves[t[0]]
            const s2 = imageData.shelves[t[1]]
            const newShelf = {
                position: {
                    top: Math.min(s1.position.top, s2.position.top),
                    left: Math.min(s1.position.left, s2.position.left),
                    right: Math.max(s1.position.right, s2.position.right),
                    bottom: Math.max(s1.position.bottom, s2.position.bottom)
                }
            }
            return newShelf
        })
        // add shelves with no twin
        imageData.shelves.forEach((s, index) => {
            if (!twinShelfIndexes.find(t => t.includes(index))) {
                shelves.push(s)
            }
        })

        imageData.shelves = shelves.sort((a, b) => a.position.top - b.position.top)
        return imageData
    }

    private resizeShelvesWithSimilarHeight(imageData: SetPlanogramFromImageResponse) {
        const HEIGHT_ALLOWED_DIFFERENCE = 0.1
        let minShelfHeight = 0
        let maxShelfHeight = 0

        // let avgShefWidth = 0
        imageData.shelves.sort((a, b) => a.position.top - b.position.top).forEach((s, i) => {
            // ignore first shelf becuse its height cant be calculated
            if (i === 0) {
                return
            }
            const shelfHeight = s.position.bottom - s.position.top
            if (minShelfHeight === 0) {
                minShelfHeight = shelfHeight
            }
            minShelfHeight = Math.min(shelfHeight, minShelfHeight)
            maxShelfHeight = Math.max(shelfHeight, maxShelfHeight)
        })

        // resize shelves to match the highest product in shelf
        imageData.products.forEach(p => {
            const shelf = this.findShelf(imageData, p)
            if (!shelf) return // @todo: create shelf if product is not in any shelf
            const shelfHeight = shelf.position.bottom - shelf.position.top
            const productHeight = p.position.bottom - p.position.top
            if (productHeight > shelfHeight) {
                const diff = productHeight - shelfHeight
                imageData.products.forEach(product => {
                    if (product.position.top >= shelf.position.top) {
                        product.position.top += diff
                        product.position.bottom += diff
                    }
                })
                imageData.shelves.forEach(s => {
                    if (s.position.top === shelf.position.top) {
                        s.position.bottom += diff
                    } else if (s.position.top > shelf.position.top) {
                        s.position.top += diff
                        s.position.bottom += diff
                    }
                })
            }
        })

        const avgShelfHeigt = ((minShelfHeight + maxShelfHeight) / 2)
        const heighstProduct = imageData.products.reduce((acc, curr) => {
            const currHeight = curr.position.bottom - curr.position.top
            return currHeight > acc ? currHeight : acc
        }, 0)

        if (Math.abs(maxShelfHeight - minShelfHeight) / avgShelfHeigt < HEIGHT_ALLOWED_DIFFERENCE && heighstProduct < avgShelfHeigt) {
            // adjust shelf height to be the same
            imageData.shelves.forEach(s => {
                const shelfHeight = s.position.bottom - s.position.top
                const diff = avgShelfHeigt - shelfHeight
                imageData.products.forEach(product => {
                    if (product.position.top >= s.position.top) {
                        product.position.bottom += diff
                        product.position.top += diff
                    }
                })
                imageData.shelves.forEach(shelf => {
                    if (shelf.position.top === s.position.top) {
                        shelf.position.bottom += diff
                    } else if (shelf.position.top > s.position.top) {
                        shelf.position.bottom += diff
                        shelf.position.top += diff
                    }
                })
            })
        }
        return imageData
    }

    findShelf(imageData: SetPlanogramFromImageResponse, product: SetPlanogramFromImageProduct) {
        return imageData.shelves.find(s => {
            const heightMiddle = product.position.top + (product.position.bottom - product.position.top) / 2
            const widthMiddle = product.position.left + (product.position.right - product.position.left) / 2
            return s.position.top <= heightMiddle
                && s.position.bottom >= heightMiddle
                && s.position.left <= widthMiddle
                && s.position.right >= widthMiddle
        })
    }
    // dont remove for dubugging
    drawDebug(planogramDetails: SetPlanogramFromImageResponse) {
        const newDiv = window.document.createElement('div')
        newDiv.style.position = 'absolute'
        newDiv.style.top = '0'
        newDiv.style.left = '0'
        newDiv.style.width = planogramDetails.originalWidth + 'px'
        newDiv.style.height = planogramDetails.originalHeight + 'px'
        newDiv.style.background = '#fff'
        newDiv.style.zIndex = '1000'
        newDiv.id = 'aaa'

        planogramDetails.shelves.forEach(s => {
            const div = window.document.createElement('div')
            div.style.position = 'absolute'
            div.style.top = s.position.top + 'px'
            div.style.left = s.position.left + 'px'
            div.style.width = (s.position.right - s.position.left) + 'px'
            div.style.height = (s.position.bottom - s.position.top) + 'px'
            div.style.border = '1px solid blue'
            newDiv.appendChild(div)
        })

        planogramDetails.products.forEach(p => {
            const product = p.product
            const div = window.document.createElement('div')
            div.style.position = 'absolute'
            div.style.top = p.position.top + 'px'
            div.style.left = p.position.left + 'px'
            div.style.width = (p.position.right - p.position.left) + 'px'
            div.style.height = (p.position.bottom - p.position.top) + 'px'
            div.style.border = '1px solid red'
            div.setAttribute('title', (product?.name || 'unrecognized') + ' ' + p.label + ' ' + p.classificationConfidence)
            newDiv.appendChild(div)
        })

        const button = window.document.createElement('button')
        button.textContent = 'Close'
        button.onclick = () => {
            window.document.getElementById('aaa').remove()
        }
        button.style.position = 'absolute'
        button.style.top = '0'
        button.style.right = '0'
        newDiv.appendChild(button)

        window.document.body.appendChild(newDiv)
    }
}