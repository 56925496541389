import { Injectable } from "@angular/core";
import { CustomFieldSettingsWithId, ListCustomFieldSettingsRequest, ListCustomFieldSettingsResponse } from "src/app/shared/api-structures/admin/customFieldSettings";
import { ByIdRequest } from "src/app/shared/api-structures/common";
import { ApiService } from "src/app/shared/services/api.service";

@Injectable()
export class CustomFieldSettingsService {

  constructor(private api: ApiService) { }

  async add(request: CustomFieldSettingsWithId) {
    const data = await this.api.adminRequest<CustomFieldSettingsWithId>({
      endpoint: '/addCustomFieldSettings',
      body: request
    })
  }

  async update(request: CustomFieldSettingsWithId) {
    const data = await this.api.adminRequest<CustomFieldSettingsWithId>({
      endpoint: '/updateCustomFieldSettings',
      body: request
    })
  }

  async delete(id: string) {
    const data = await this.api.adminRequest<ByIdRequest>({
      endpoint: '/deleteCustomFieldSettings',
      body: { id }
    })
  }

  async list(request: ListCustomFieldSettingsRequest) {
    const data = await this.api.adminRequest<ListCustomFieldSettingsRequest, ListCustomFieldSettingsResponse>({
      endpoint: '/listCustomFieldsSettings',
      body: request
    })
    return data
  }

  async listCustomFieldsByEntity(request: ByIdRequest){
    const data = await this.api.miscRequest<ByIdRequest, ListCustomFieldSettingsResponse>({
      endpoint: '/listCustomFieldsByEntity',
      body: request
    })
    return data
  }
}