<div class="wrapper">
  <div class="main_image_wrap" [ngStyle]="{top: top + 'px',left:left + 'px', transform: 'rotate('+imageRotation+'deg)'}"
    *ngIf="!imgError">
    <img class="main_image" [id]="image?.imagePath"
      [ngStyle]="{'max-height': maxHeight+'px', 'max-width': maxWidth+'px','height': height+'px' }" alt="Image Preview"
      [src]="image?.imagePath" (error)="onImgError()" />

    <markers-container [image]="image" [markers]="markers" (markSelected)="markSelected($event)"
      [tagSelected]="tagSelected" [selectedMarker]="selectedMarker" (markChange)='markChange.emit($event)'
      [imageRotation]="imageRotation"></markers-container>
    <draw-annotations (onDrawEnded)="onDrawAnnotation($event)" *ngIf="mode === 'add'"></draw-annotations>

  </div>
  <div class="main_image_error_wrap">
    <div *ngIf="imgError" class="image_placeholder">
      <div class="col">
        <div>{{'ImageBroken'|translate|uppercase }}</div>
      </div>
    </div>
  </div>
</div>