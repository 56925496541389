import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TrainingsService } from 'src/app/admin/services/trainings.service';
import { ListTrainings } from 'src/app/shared/api-structures/admin/training';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'admin-trainings-list',
  templateUrl: './trainings-list.component.html',
  styleUrls: ['./trainings-list.component.scss'],
  animations: [
    trigger('detailsExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TrainingsListComponent extends AutoDestroy implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  dataSource = new MatTableDataSource<ListTrainings.Item>();
  columnsToDisplay = [
    'id',
    'name',
    'isActive',
    'status',
    'progress',
    'totalProducts',
    'createTime',
    'lastUpdate',
    'runningHours',
    'actions',
  ];
  expandedElement: ListTrainings.Item | null = null

  constructor(private trainingsService: TrainingsService, private helpers: HelpersService) { super() }

  ngOnInit() {
    this.subscriptions.push(this.trainingsService.trainings$.subscribe(ti => {
      ti.forEach(v => v.history = v.history.sort((a, b) => b.lastUpdate.getTime() - a.lastUpdate.getTime()))
      this.dataSource.data = ti
    }))

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  getCreateTime(history: ListTrainings.History[]) {
    return history.slice(-1)[0].lastUpdate
  }

  getRunningHours(history: ListTrainings.History[]) {
    if (history.length === 0) {
      return
    }
    const last = history[0]
    let runningTime
    if (last.state === 'trash') {
      const lastUpdate = new Date(last.lastUpdate)
      runningTime = Math.round((lastUpdate.getTime() - this.getCreateTime(history).getTime()) / 1000)
    } else {
      const now = new Date()
      runningTime = Math.round((now.getTime() - this.getCreateTime(history).getTime()) / 1000)
    }
    return this.formatSecondsToHours(runningTime)
  }

  private formatSecondsToHours(seconds: number) {
    const hours = Math.floor(seconds / 60 / 60)
    const minutesLeft = Math.floor(seconds / 60 % 60)
    const secondsLeft = Math.floor(seconds % 60)
    return `${('0' + hours).slice(-2)}:${('0' + minutesLeft).slice(-2)}:${('0' + secondsLeft).slice(-2)}`
  }

  async makeActive(id: string) {
    this.persistExpandedElement()
    await this.trainingsService.setActiveModel(id)
  }

  private persistExpandedElement() {
    // When clicking on the star to select active model, the row also expands (Not desired behavior)
    // This logic will restore after a tick the older state
    // This way, no effect will be shown
    const currentExpandedElement = this.expandedElement
    this.helpers.runNextTick(() => this.expandedElement = currentExpandedElement)
  }
}
