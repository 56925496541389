import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayAdjustmentMode } from 'src/app/shared/interfaces';

@Component({
  selector: 'image-buttons',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule],
  templateUrl: './image-buttons.component.html',
  styleUrls: ['./image-buttons.component.scss']
})
export class ImageButtonsComponent implements OnInit {
  @Input() mode: OverlayAdjustmentMode = 'view'
  @Output() modeChange = new EventEmitter<OverlayAdjustmentMode>();
  @Output() reset = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  onButtonPressed(mode: OverlayAdjustmentMode) {
    this.modeChange.emit(mode);
  }

  onReset() {
    this.reset.emit();
  }

}
