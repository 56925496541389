import { EventEmitter, Injectable } from '@angular/core'
import { UtilitiesService } from 'src/app/services/utilities.service'
import { Dal } from '../../dal/dal'
import { AppService } from '../../services/app.service'
import { ImagesCatalog } from '../interfaces'
import { SlowPromises } from '../slowPromises'

export interface ImageFileToUpload {
  toUpload?: boolean
  catalogId?: string
  files: File[]
  isCatalogExist?: boolean
  filesUrl?: string[]
  size: Size
}

interface Size {
  width: number,
  height: number,
}

export interface UploadStatus {
  [productId: string]: {
    countUplaoded: number,
    countImages: number,
    catalogs: {
      [collectionName: string]:
      {
        countUplaoded: number,
        countImages: number, images: { [image: string]: { progress: number, isUplaoded?: boolean } }
      }
    }
  }
}

@Injectable({ providedIn: 'root' })
export class ProductsImageCatalogsBatchUplaodService {

  private countToUpload = 0
  private imageFoldersToUpload: { [productId: string]: { [collectionName: string]: ImageFileToUpload } }

  private imageFolderUploadStatus: UploadStatus = {}
  private slowPromises = new SlowPromises(4)

  onProgressChanged = new EventEmitter<{ productId: string, catalogName: string, image: string, progress: number }>()
  onImageUplaodComplete = new EventEmitter<{ productId: string, catalogName: string, image: string }>()
  onUplaodingStatusChanged = new EventEmitter<boolean>()

  constructor(private dal: Dal, private appService: AppService, private utilService: UtilitiesService) { }

  get getImageFolderUploadStatus() { return this.imageFolderUploadStatus }
  get getCountToUpload() { return this.countToUpload }

  setImageFoldersToUpload(dict: { [productId: string]: { [collectionName: string]: ImageFileToUpload } }) {
    this.imageFoldersToUpload = dict
  }

  async uploadFolders() {
    this.initImageFolderUploadStatus()
    await this.uploadToStoreg()
    await this.whenAll()
  }

  async initImageFolderUploadStatus() {
    this.countToUpload = 0
    for (const [product, catalog] of Object.entries(this.imageFoldersToUpload)) {
      this.imageFolderUploadStatus[product] = { countUplaoded: 0, countImages: 0, catalogs: {} }
      for (const [name, details] of Object.entries(catalog)) {
        this.imageFolderUploadStatus[product].catalogs[name] = { countUplaoded: 0, countImages: 0, images: {} }
        for (const file of details.files) {
          this.imageFolderUploadStatus[product].catalogs[name].images[file.name] = { progress: 0 }
          this.imageFolderUploadStatus[product].countImages++
          this.imageFolderUploadStatus[product].catalogs[name].countImages++
          this.countToUpload++
        }
      }
    }
    this.onUplaodingStatusChanged.emit(true)
  }

  async uploadToStoreg() {
    for (const [product, catalogs] of Object.entries(this.imageFoldersToUpload)) {
      for (const [name, details] of Object.entries(catalogs)) {

        if (!details.isCatalogExist && details.files.length) {
          const primaryFile = details.files.shift()
          if (details.size === undefined || details.size?.width === 0 || details.size?.height === 0) {
            await this.uploadPrimaryImage(product, name, primaryFile, 0, 0, 0)
          } else {
            await this.uploadPrimaryImage(product, name, primaryFile, details.size.width, details.size.height, 0)
          }
        }

        for (let i = 0; i < details.files.length; i++) {
          const file = details.files[i]

          const uploadFunc = async () => {

            const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(details.files[i], progress => this.onProgressChanged.emit({ productId: product, catalogName: name, image: file.name, progress: progress }), true)
            await this.dal.uploadImageCatalog(product, details.catalogId, fileUrl)
            this.onImageUplaodComplete.emit({ productId: product, catalogName: name, image: file.name })
          }

          this.slowPromises.add(uploadFunc)
        }
      }
    }
  }

  async whenAll() {
    try {
      await this.slowPromises.whenAll()
    } finally {
      this.imageFoldersToUpload = {}
      this.onUplaodingStatusChanged.emit(false)
    }
  }

  async uploadPrimaryImage(product: string, catalogName: string, file: File, width: number, height: number, depth: number) {
    // const uniqueKey = await this.utilService.createImageUniqueKey(file)
    const fileName = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, progress => this.onProgressChanged.emit({ productId: product, catalogName: catalogName, image: file.name, progress: progress }), true)
    const catalogRes: ImagesCatalog = await this.dal.addImagesCatalog(product, catalogName, height, width, fileName, depth, true)
    this.imageFoldersToUpload[catalogRes.mc1ProductId][catalogRes.name].catalogId = catalogRes.id
    this.imageFoldersToUpload[catalogRes.mc1ProductId][catalogRes.name].isCatalogExist = true
    this.onImageUplaodComplete.emit({ productId: product, catalogName: catalogName, image: file.name })
  }
}
