<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Id'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'IsActive'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.isActive">grade</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Status'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon matTooltip="{{row.error}}" class="error" *ngIf="row.status=='error'">error</mat-icon>
        {{row.status | titlecase}}
      </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Progress'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-progress-bar [(value)]="row.progress" style="width:50px;"></mat-progress-bar>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalProducts">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TotalProducts'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.totalProducts}}</td>
    </ng-container>

    <ng-container matColumnDef="createTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CreateTime'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{(row.history.length > 0 ? getCreateTime(row.history) : '') | date:'short'}}</td>
    </ng-container>

    <ng-container matColumnDef="lastUpdate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'LastUpdate'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.lastUpdate | date:'short'}}</td>
    </ng-container>

    <ng-container matColumnDef="runningHours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RunningHours'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{getRunningHours(row.history)}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="row.canActivate && !row.isActive" mat-icon-button color="primary"
          (click)="makeActive(row.modelId)">
          <mat-icon color="primary" matTooltip="Make Active">grade</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetails">
      <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length">
        <div class="element-details-container" [@detailsExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
          <div class="element-details">
            <ng-container *ngIf="row.history && row.history.length > 0">
              <mat-card *ngFor="let history of row.history">
                <div mat-card-subtitle>{{history.state | splitUpper | titlecase}}</div>
                <div class="error" mat-card-content *ngIf="history.state==='error'">{{row.error}}</div>
                <div mat-card-footer>{{history.lastUpdate | date:'short'}} </div>
              </mat-card>
            </ng-container>
            <ng-container *ngIf="!row.history || row.history.length == 0">
              <div mat-card-subtitle>{{'NoHistoryYet'|translate}}</div>
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="element-row"
      (click)="expandedElement = expandedElement === row ? null : row">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetails']" class="details-row"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
    [pageSizeOptions]="[2, 5, 10, 20, 50]">
  </mat-paginator>
</div>