// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace
import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsInt, IsNumber, IsOptional, IsString, Max, Min, ValidateNested } from 'class-validator'
export namespace CompanySettings {
    export class Misc {
        @Expose() @IsBoolean() @IsOptional() isNoiseCatalogCreated?: boolean
        @Expose() @IsBoolean() @IsOptional() isDefaultStaticModelCreated?: boolean
        @Expose() @IsBoolean() @IsOptional() isMc1DataPathCreated?: boolean
    }
    export class SharingProject {
        @Expose() @IsString() @IsOptional() company?: string
        @Expose() @IsBoolean() @IsOptional() enabled?: boolean
        @Expose() @IsString() @IsOptional() environment?: string
        @Expose() @IsString() @IsOptional() organization?: string
    }
    export class MobileTabs {
        @Expose() @IsArray() @IsOptional() fullUser?: string[]
        @Expose() @IsArray() @IsOptional() simpleUser?: string[]
    }
    export class ContractTrainingResponse {
        @Expose() @IsNumber() @IsOptional() currentMonth?: number
        @Expose() @IsNumber() @IsOptional() currentYear?: number
        @Expose() @IsNumber() @IsOptional() @IsInt() @Min(0) @Max(1000) trainingCount?: number
        @Expose() @IsNumber() @IsOptional() @IsInt() @Min(0) @Max(1000) trainingQuota?: number
        @Expose() @IsNumber() @IsOptional() @IsInt() @Min(0) @Max(1000) singleTrainingSold?: number
    }
    export class DataStudioStructure {
        @Expose() @IsString() url!: string
        @Expose() @IsString() title!: string
        @Expose() @IsNumber() @IsOptional() order?: number
    }
    export class DataStudio {
        @Expose() @Type(() => DataStudioStructure) @IsOptional() @ValidateNested() @IsArray() screens?: DataStudioStructure[]
    }
    export class MetabaseStructure {
        @Expose() @IsNumber() id!: number
        @Expose() @IsString() title!: string
        @Expose() @IsNumber() @IsOptional() order?: number
    }
    export class Metabase {
        @Expose() @IsString() @IsOptional() siteUrl?: string
        @Expose() @IsString() @IsOptional() secretKey?: string
        @Expose() @Type(() => MetabaseStructure) @IsOptional() @ValidateNested() @IsArray() screens?: MetabaseStructure[]
    }
    export class Internal {
        @Expose() @IsString() apiUrl!: string
        @Expose() @IsString() iframeUrl!: string
        @Expose() @IsString() dashboardGcpProject!: string
    }
    export class Dashboard {
        @Expose() @Type(() => Metabase) @IsOptional() @ValidateNested() metabase?: Metabase
        @Expose() @Type(() => DataStudio) @IsOptional() @ValidateNested() dataStudio?: DataStudio
        @Expose() @Type(() => Internal) @IsOptional() @ValidateNested() internal?: Internal
    }
    export class Item {
        @Expose() @IsString() @IsOptional() companyType?: string
        @Expose() @IsString() @IsOptional() companyName?: string
        @Expose() @IsString() @IsOptional() currentModelId?: string
        @Expose() @IsBoolean() @IsOptional() isNoiseEnabled?: boolean
        @Expose() @IsString() @IsOptional() language?: string
        @Expose() @IsString() @IsOptional() country?: string
        @Expose() @IsString() @IsOptional() currency?: string
        @Expose() @IsString() @IsOptional() logoUrl?: string
        @Expose() @IsNumber() @IsOptional() blurDetectionThreshold?: number
        @Expose() @IsNumber() @IsOptional() blurDetectionCaptureTries?: number
        @Expose() @IsNumber() @IsOptional() imagesQualityAndroid?: number
        @Expose() @IsNumber() @IsOptional() imagesQualityIOS?: number
        @Expose() @IsBoolean() @IsOptional() singlePicturePriceOcr?: boolean
        @Expose() @IsBoolean() @IsOptional() multiPicturePriceOcr?: boolean
        @Expose() @IsNumber() @IsOptional() overlayBounds?: number
        @Expose() @IsBoolean() @IsOptional() galeryPhotoEnabled?: boolean
        @Expose() @IsString() @IsOptional() mc1DataSourceUrl?: string
        @Expose() @IsBoolean() @IsOptional() showCatalogDimensions?: boolean
        @Expose() @IsString() @IsOptional() ocrReadMethod?: string
        @Expose() @IsNumber() @IsOptional() threshold?: number
        @Expose() @IsNumber() @IsOptional() maxNumberImagesInCatalog?: number
        @Expose() @IsNumber() @IsOptional() maxNumberOfCatalogs?: number
        @Expose() @IsString() @IsOptional() syncOnlyEditedImages?: string
        @Expose() @Type(() => Misc) @ValidateNested() @IsOptional() misc?: Misc
        @Expose() @Type(() => Dashboard) @ValidateNested() @IsOptional() dashboards?: Dashboard
        @Expose() @Type(() => SharingProject) @ValidateNested() @IsOptional() sharingProject?: SharingProject
        @Expose() @Type(() => ContractTrainingResponse) @ValidateNested() @IsOptional() trainingSettings?: ContractTrainingResponse
        @Expose() @Type(() => MobileTabs) @ValidateNested() @IsOptional() mobileTabs?: MobileTabs
        @Expose() @IsBoolean() @IsOptional() wtmKeyExists?: boolean
    }
}

export class CustomFieldsRequest {
    @Expose() @IsString() collection!: string
}
