import { Pipe, PipeTransform } from '@angular/core';
import { Notification } from '../views/health-check/notification.model';

@Pipe({
  name: 'FilterBySeverity'
})
export class FilterBySeverityPipe implements PipeTransform {
  transform(notifications: Notification[], severity: string): Notification[] {
    if (severity === 'All') return notifications;
    return notifications.filter(notification => notification.severity === severity);
  }
}