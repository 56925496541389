<div class="terr-form">
  <h4>
    <span *ngIf="mode === 'add'">{{'AddArea' | translate}}</span>
    <span *ngIf="mode === 'edit'">{{'EditArea' | translate}}</span>
    <span *ngIf="mode === 'edit'">
      <button mat-flat-button color="primary" (click)="onDelete()" style="float:right; background-color: #A2333329; color: #A23333;">
        <mat-icon>delete</mat-icon> {{'DeleteArea' | translate}}</button>&nbsp;
    </span>
  </h4>
  <form [formGroup]="areaForm">
    <mat-form-field>
      <input matInput placeholder="{{'Name' | translate}}" formControlName="name" required>
    </mat-form-field>
    <mat-form-field>
      <mat-chip-list #chipListRegion>
        <mat-chip *ngFor="let region of areaForm.value.regions" (removed)="removeRegion(region)">{{region}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input matInput placeholder="{{'Region' | translate}} ({{'PressEnterToInclude' | translate}})" [formControl]="regionsInput" [matChipInputFor]="chipListRegion"
          (matChipInputTokenEnd)="addRegion($event)">
      </mat-chip-list>
    </mat-form-field>
    <mat-form-field>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let tag of areaForm.value.tags" (removed)="removeTag(tag)">{{tag}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input matInput placeholder="{{'Tags' | translate}} ({{'PressEnterToInclude' | translate}})" [formControl]="tagsInput" [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="addTag($event)">
      </mat-chip-list>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'Color' | translate}}" type="color" formControlName="color" >
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="{{'PolygonPath' | translate}}" [(ngModel)]="polygonPathStr"
        [ngModelOptions]="{standalone: true}"></textarea>
      <button mat-button type="button" (click)="onPolygonChange()">{{'Set' | translate}}</button>
    </mat-form-field>

    <div class="row" style="gap: 6px">
      <button mat-stroked-button style="width: 48%;" type="button" color="primary" (click)="onCancel()">{{'Cancel' | translate}}</button>
      <button mat-flat-button style="width: 48%;" type="button" color="primary" cdkFocusInitial (click)="onSave()">{{'Save' | translate}}</button>&nbsp;
    </div>
  </form>