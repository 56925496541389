import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AreaService } from 'src/app/admin/services/area.service';
import { CustomFieldSettingsService } from 'src/app/admin/services/customFieldSettings.service';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { CustomFields, LatLng } from 'src/app/shared/api-structures/common';
import { Area, ListAreasResponse } from 'src/app/shared/api-structures/misc/area';

@Component({
  selector: 'app-manage-customer',
  templateUrl: './manage-customer.component.html',
  styleUrls: ['./manage-customer.component.scss']
})

export class ManageCustomerComponent implements OnInit {

  formCustomer: FormGroup;
  get addressControls() {
    this.initialAddress = this.formCustomer.value.addresses[0]
    return (<FormArray>this.formCustomer.get('addresses')).controls
  }
  get territoryControls() {
    return (<FormArray>this.formCustomer.get('territories')).controls
  }
  customerId
  taxId
  latLng = []
  areas = []
  customerTypes = ['LegalEntity', 'Individual', 'SellerCustomer']
  initialAddress: string
  customFields
  customFieldsValue

  constructor(private route: ActivatedRoute,
    private customerService: CustomerService,
    private areaService: AreaService,
    private customFieldSettingsService: CustomFieldSettingsService) { }

  ngOnInit(): void {
    this.fetchCustomFields()
    this.formCustomer = new FormGroup({
      name: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      customerCode: new FormControl(null, Validators.required),
      externalId: new FormControl(null),
      chain: new FormControl(null),
      addresses: new FormArray([]),
      areas: new FormArray([]),
      territories: new FormArray([]),
      customFields: new FormControl(null)
    })
    this.customerId = this.route.snapshot.queryParams['customer_id']
    if (this.customerId) {
      this.customerService.loadCustomers({ currentPage: 0, pageSize: 10, query: this.customerId }).then(customerResponse => {
        const { items } = customerResponse
        const [customer] = items

        this.formCustomer.patchValue({
          name: customer.name ?? '',
          category: customer.category ?? '',
          type: customer.type ?? '',
          externalId: customer.externalId,
          customerCode: customer.customerCode ?? '',
          chain: customer.chain,
          customFields: customer.customFields
        });

        customer.locations?.length > 0 ? null : this.latLng.push(customer.location)
        customer.locations?.map(l => { this.latLng.push(l) })
        this.customFieldsValue = customer.customFields
        customer.addresses && customer.addresses?.length > 0 ? null : (<FormArray>this.formCustomer.get('addresses')).push(new FormControl(customer.address));
        customer.area ? (<FormArray>this.formCustomer.get('areas')).push(new FormControl(customer.area)) : null;

        customer.addresses?.map(ad => {
          (<FormArray>this.formCustomer.get('addresses')).push(new FormControl(ad));
        })

        if (customer?.territories?.length > 0) {
          const { territories } = customer;
          for (const territory of territories) {
            const territoryControl = new FormControl(territory);
            (<FormArray>this.formCustomer.get('territories')).push(territoryControl);
          }
        } else if (customer?.territory) {
          const { territory } = customer;
          const territoryControl = new FormControl(territory);
          (<FormArray>this.formCustomer.get('territories')).push(territoryControl);
        }

        customer.areas?.map(ar => {
          (<FormArray>this.formCustomer.get('areas')).push(new FormControl(ar));
        })
        this.getAreaByLocations(this.latLng).then(areas => {
          areas.map(area => {
            this.areas.push({ id: area.id, name: area.name })
          })
        })
        this.taxId = customer.taxId
      })
    }
  }

  async onPlaceChanged(place: any, index: number) {
    return new Promise<void>(async (resolve) => {
      (<FormArray>this.formCustomer.get('addresses')).controls[index].setValue(place.formatted_address)
      if (this.latLng[index]) {
        this.latLng[index] = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }
        const areas: Area[] = await this.getAreaByLocations(this.latLng)
        this.areas = []
        areas.map(area => {
          this.areas.push({ id: area.id, name: area.name });
          (<FormArray>this.formCustomer.get('areas')).push(new FormControl(area.id))
        })
      } else {
        this.latLng.push({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        })
        const areas: Area[] = await this.getAreaByLocations([{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }])
        areas.map(area => {
          if (!this.areas.includes(area.id)) {
            this.areas.push({ id: area.id, name: area.name });
            (<FormArray>this.formCustomer.get('areas')).push(new FormControl(area.id))
          }
        })
      }
      resolve()
    })
  }

  async getAreaByLocations(latLngs: LatLng[]): Promise<Area[]> {
    let responseAreas: Area[] = []
    for (let latLng of latLngs) {
      const areas: ListAreasResponse = await this.areaService.listAreasByLocation(latLng)
      areas.items.map(area => {
        responseAreas.push(area)
      })
    }
    return responseAreas
  }

  onAddAdress() {
    const control = new FormControl(null);
    (<FormArray>this.formCustomer.get('addresses')).push(control)
  }

  onAddTerritory() {
    const control = new FormControl(null);
    (<FormArray>this.formCustomer.get('territories')).push(control)
  }

  deleteTerritory(index: number) {
    (<FormArray>this.formCustomer.get('territories')).removeAt(index)
  }

  customFieldsChanged(data: CustomFields) {
    this.customFieldsValue = data
  }

  async fetchCustomFields() {
    const res = await this.customFieldSettingsService.listCustomFieldsByEntity({ id: 'customers' })
    this.customFields = res.items
  }

  submitForm() {
    this.formCustomer.value.addresses = this.formCustomer.value.addresses.filter(a => a !== null)
    this.formCustomer.value.territories = this.formCustomer.value.territories.filter(t => t !== null)
    let payload = this.formCustomer.value
    payload.taxId = this.taxId
    payload.areas = this.areas
    payload.customFields = this.customFieldsValue
    payload.locations = this.latLng
    if (this.customerId) {
      payload.id = this.customerId
      this.customerService.updateCustomer(payload)
      return
    }
    this.customerService.addCustomer(payload)
  }
}
