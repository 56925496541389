import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Inject, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CompanySettings } from 'src/app/shared/api-structures/admin/companySettings';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CompaniesService } from 'src/app/organization-admin/services/org-admin-companies.service';

@Component({
  selector: 'company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
})
export class CompanySettingsComponent implements OnInit, AfterViewInit {

  uploadProgress: {
    name: string,
    progress: number
  }
  _metabaseAddTitle: string
  _metabaseAddId: string
  _dataStudioAddTitle: string
  _dataStudioAddUrl: string
  _isNoiseEnabled: boolean
  _threshold: number
  _syncOnlyEditedImages: boolean
  _sharingProjectCompany: string
  _sharingProjectEnabled: boolean
  _sharingProjectEnvironment: string
  _sharingProjectOrganization: string
  _trainingSettingsTrainingQuota: number
  _trainingSettingsSingleTrainingSold: number
  _metabaseSecretKey: string
  _metabaseSiteUrl: string
  _dataSourceMetabase
  _dataSourceDataStudio
  _language: string
  _country: string
  _logoUrl: string
  _blurDetectionThreshold: number
  _blurDetectionCaptureTries: number
  _imagesQualityAndroid: number
  _imagesQualityIOS: number
  _singlePicturePriceOcr: boolean
  _multiPicturePriceOcr: boolean
  _overlayBounds: number
  _galeryPhotoEnabled: boolean
  _ocrReadMethod: string
  _maxNumberImagesInCatalog: number
  _maxNumberOfCatalogs: number

  companyId: string
  environmentId: string
  organizationId: string
  companySettings: CompanySettings.Item
  isLoadingSettings: boolean
  countries = []

  constructor(private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<CompanySettingsComponent>,
    private companiesService: CompaniesService,
    private languageService: LanguageService,
    private _focusMonitor: FocusMonitor,
    private utilService: UtilitiesService,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== undefined) {
      this.companyId = data.companyId
      this.environmentId = data.environmentId
      this.organizationId = data.organizationId
    }
  }

  ngOnInit() {
    this.getCompanySettings(this.organizationId, this.environmentId, this.companyId)
    this.getCountries()
  }

  ngAfterViewInit() {
    this._focusMonitor.stopMonitoring(document.getElementById('matSlider'));
  }

  async saveLogoFileToStorga(event: FileList) {
    const file: File = event[0]
    if (this.utilService.validateImageType(file) == false) {
      return
    }
    this.uploadProgress = { name: file.name, progress: 0 }
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadProgress.progress = progress, true, this.organizationId)
    await this.utilService.isFileUrlExist(fileUrl)
    this._logoUrl = fileUrl
    this.uploadProgress = undefined
  }

  async getCountries() {
    (await this.utilService.getCountries()).subscribe((res: any) => {
      res.forEach(c => {
        this.countries.push(c.nome['abreviado-EN'])
      })
      this.countries = this.countries.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      })
      this.countries.sort()
    })
  }  

  async getCompanySettings(organizationId: string, environmentId: string, companyId: string) {
    this.isLoadingSettings = true
    
    this.companySettings = await this.companiesService.getCompanySettings({ organization: organizationId, environment: environmentId, company: companyId })
    this._logoUrl = this.companySettings.logoUrl
    this._language = this.companySettings.language
    this._country = this.companySettings.country
    
    this.isLoadingSettings = false
  }

  async saveSettings() {
    if (!this._country || !this._language) {
      this.snackbarService.openSnackBar(4000, `${this.languageService.translateSync('CountryAndLanguageIsMandatory')}`)
      return
    }
    const request = {
      logoUrl: this._logoUrl,      
      language: this._language,
      country: this._country,
    }

    await this.companiesService.saveCompanySettings({ organization: this.organizationId, environment: this.environmentId, company: this.companyId }, request)
    this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('companySuccessfullyUpdated')}`)
    
  }  
}