<div class="body-flex">
    <div class="body-flex-c1">
        <div class="search-header">
            <div class="search-header-title">
                <div>{{ 'AllCustomers' | translate }} - ({{data?.length}} {{'Total' | translate}})</div>
            </div>
            <div class="search-header-input">
                <mat-form-field>
                    <input matInput [placeholder]="'Search' | translate"
                        (keyup.enter)="emitSearchFilter($event.target.value)" #MatAutocompleteTrigger>
                </mat-form-field>
            </div>
            <div class="search-header-select">
                <mat-checkbox color="primary" type="checkbox" [checked]="allSelected"
                    (change)="emitSelectAll()"></mat-checkbox> <span class="text1">{{'SelectAll' | translate}}
                    {{data?.length}} {{'Customers' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="body-flex-c2">
        <div class="cards">
            <associated-users-card *ngFor="let customer of data" (onToggleSelect)="onToggleSelect.emit($event)"
                [id]="customer.id" [selected]="selected[customer.id]" [data]="customer"></associated-users-card>
        </div>
    </div>
</div>