import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';
import { AddCustomerRequest } from 'src/app/shared/api-structures/admin/customer';
import { CustomFields, LatLng } from 'src/app/shared/api-structures/common';

@Component({
  selector: 'create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})

export class CreateCustomerComponent implements OnInit {

  externalId: string
  name: string
  category: string
  address: string
  territory: string
  chain: string
  latLng: LatLng
  taxId: string
  customerCode: string

  listCategories: string[] = []
  listChains: string[] = []
  listTerritories: string[] = []
  isLoadingCategories: boolean
  isLoadingAttributes: boolean
  customFields: CustomFields


  categoryCtrl = new FormControl('')
  filteredCategories: Observable<string[]>

  terrCtrl = new FormControl('')
  filteredTerritories: Observable<string[]>

  chainsCtrl = new FormControl('')
  filteredChains: Observable<string[]>


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { customFields: CustomFieldSettingsWithId[] },
    public dialogRef: MatDialogRef<CreateCustomerComponent>,
    private customerService: CustomerService,
  ) {
  }

  ngOnInit() {
    this.getCustomerAttributes()

    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(this.listCategories, value || '')),
    )

    this.filteredTerritories = this.terrCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(this.listTerritories, value || '')),
    )

    this.filteredChains = this.chainsCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(this.listChains, value || '')),
    )
  }

  private _filter(arr: string[], value: string): string[] {
    const filterValue = value.toLowerCase()
    return arr.filter(option => option.toLowerCase().includes(filterValue))
  }

  async getCustomerAttributes() {
    this.isLoadingAttributes = true
    try {
      const attr = await this.customerService.listCustomersAttributes()
      this.listCategories = attr.categories
      this.listChains = attr.chains
      this.listTerritories = attr.territories
    } finally {
      this.isLoadingAttributes = false
    }
  }

  dropDownCategoryOptions() {
    const value = this.categoryCtrl.value
    this.categoryCtrl.setValue(value)
  }

  onPlaceChanged(place) {
    this.address = place.formatted_address
    this.latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
  }

  async save() {
    const customer = new AddCustomerRequest()
    customer.externalId = this.externalId
    customer.name = this.name
    customer.category = this.category
    customer.address = this.address
    customer.location = this.latLng
    customer.territory = this.territory
    customer.chain = this.chain
    customer.taxId = this.taxId
    customer.customerCode = this.customerCode
    customer.customFields = this.customFields

    await this.customerService.addCustomer(customer)
    this.dialogRef.close({ isCustomerCreated: true })
  }

  customFieldsChanged(data: CustomFields) {
    this.customFields = data
  }
}