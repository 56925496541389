import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[insideViewport]'
})
export class InsideViewportDirective implements OnInit {
    @Output() insideViewport = new EventEmitter()
    isInsideViewPort = false
    constructor(
        private elementRef: ElementRef
    ) { }

    ngOnInit() {
        this.scrollEvent()
    }


    @HostListener('window:resize', ['$event'])
    @HostListener('window:scroll', ['$event'])
    scrollEvent() {
        const windowHeight = window.innerHeight;
        const boundedRect = this.elementRef.nativeElement.getBoundingClientRect();
        const isInsideViewPort = boundedRect.top >= 0 && boundedRect.top < windowHeight
        if (isInsideViewPort !== this.isInsideViewPort) {
            this.isInsideViewPort = isInsideViewPort
            this.insideViewport.emit(isInsideViewPort)
        }
    }
}