<div mat-dialog-content>
	<div class="form-headline">
		{{'ProductDetails'|translate}}
	</div>
	<form [formGroup]="productForm">
		<div class="row">
			<div class="col-sm-6">
				<mat-form-field>
					<input matInput placeholder="{{'ProductamName'|translate}}" [(ngModel)]="product.name"
						formControlName="nameCtrl" required>
				</mat-form-field>
			</div>
			<div class="col-sm-2">
				<mat-form-field>
					<input matInput placeholder="{{'EAN'|translate}}" [(ngModel)]="product.ean"
						formControlName="eanCtrl" required>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-4">
				<mat-form-field>
					<input matInput placeholder="{{'Category'|translate}}" [matAutocomplete]="auto"
						formControlName="categoryCtrl" #exposure (click)="dropDownCategoryOptions()" required>
					<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
						(optionSelected)="categoryOptionSelected($event.option)" [displayWith]="displayFn">
						<mat-option *ngFor="let category of filteredCategoryItems | async" [value]="category">
							{{category.name}}</mat-option>
					</mat-autocomplete>
					<button *ngIf="showAddButton && exposure.value" matSuffix mat-button mat-icon-button
						(click)="isShowAddCategoryDialog=true"><mat-icon
							matTooltip='Add {{exposure.value}}'>add</mat-icon></button>
					<mat-icon matSuffix *ngIf="isLoadingCategories">
						<i class="fa fa-spinner fa-spin"></i>
					</mat-icon>
				</mat-form-field>
			</div>
			<div class="col-sm-4">
				<mat-form-field>
					<input matInput placeholder="{{'Brand'|translate}}" [matAutocomplete]="auto2"
						formControlName="brandCtrl" #exposure2 (click)="dropDownBrandOptions()" required>
					<mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete"
						(optionSelected)="brandOptionSelected($event.option)" [displayWith]="displayFn2">
						<mat-option *ngFor="let brand of filteredBrandItems | async" [value]="brand">
							{{brand.name}}</mat-option>
					</mat-autocomplete>
					<button *ngIf="showAddButton && exposure2.value" matSuffix mat-button mat-icon-button
						(click)="isShowAddBrandDialog=true"><mat-icon
							matTooltip='Add {{exposure2.value}}'>add</mat-icon></button>
					<mat-icon matSuffix *ngIf="isLoadingBrands">
						<i class="fa fa-spinner fa-spin"></i>
					</mat-icon>
				</mat-form-field>
			</div>
		</div>
		<div><label>{{'IsCompetitors'|translate}}</label></div>
		<mat-radio-group aria-label="Is competitors" [(ngModel)]="product.isCompetitors"
			formControlName="isCompetitorsCtrl">
			<mat-radio-button color="primary" [value]="true">{{'Yes'}}</mat-radio-button>
			<mat-radio-button color="primary" [value]="false">{{'No'}}</mat-radio-button>
		</mat-radio-group>
	</form>

</div>

<div mat-dialog-actions>
	<button mat-stroked-button color="primary" (click)="onCancelClick()">{{'CANCEL'|translate}}</button>
	<button mat-stroked-button color="primary" cdkFocusInitial (click)="save()">{{'saveCONTINUE'|translate}}</button>
</div>
<p-dialog [(visible)]="isShowAddCategoryDialog">
	<p-header>{{ 'Warning' | translate }}</p-header>
	<p>{{'AddCategoryCaution'|translate}}</p>
	<div class="dialog-button-container">
		<button mat-flat-button color="primary" (click)="isShowAddCategoryDialog=false">{{'Cancel' |translate}}</button>
		<button mat-flat-button (click)="addNewCategory()">{{'AddCategory' | translate}}</button>
	</div>
</p-dialog>
<p-dialog [(visible)]="isShowAddBrandDialog">
	<p-header>{{ 'Warning' | translate }}</p-header>
	<p>{{'AddBrandCaution'|translate}}</p>
	<div class="dialog-button-container">
		<button mat-flat-button color="primary" (click)="isShowAddBrandDialog=false">{{'Cancel' |translate}}</button>
		<button mat-flat-button (click)="addNewBrand()">{{'ADD' | translate}}</button>
	</div>
</p-dialog>