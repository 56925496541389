import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import moment from 'moment';
import { SortDirection } from 'src/app/shared/api-structures/common';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'association-logic',
  templateUrl: './association-logic.component.html',
})
export class AssociationLogicComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @Input() fetch: (currentPage: number, pageSize: number, filter: string) => Promise<any>
  @Input() fetchAddOptions: (currentPage: number, pageSize: number, query: any) => Promise<any>
  dataSource: Record<string, unknown>[] = []
  selected: Record<string, boolean> = {}
  selectedCount: number = 0

  sortBy: string;
  sortDirection: SortDirection
  filter: string = ''
  currentPage: number = 0
  pageSize: number = 600
  moment = moment

  customerName: string
  selectedId: string
  isShowAddUserField: boolean = false
  isShowConfirmDeleteDialog = false

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helpersService: HelpersService,
  ) {
  }

  ngOnInit() {
    this.getResults('', true)
  }

  sortData(sort: Sort) {
    this.sortBy = sort.active;
    this.sortDirection = (sort.direction as SortDirection)
    this.applyFilter()
    this.sortBy = ""
    this.sortDirection = null
  }

  onOptionSelected(filter: string) {
    this.filter = filter
    this.applyFilter()
  }

  applyFilter() {
    this.currentPage = 0
    this.getResults()
  }

  async getResults(searchFilter?: any, setExistingSelect = false) {
    const options = await this.fetchAddOptions(this.currentPage, this.pageSize, searchFilter)
    let existing = []
    if (!searchFilter) {
      const res = await this.fetch(this.currentPage, 10000, null)
      existing = res.items
      if (setExistingSelect) {
        existing.forEach(o => this.selected[o.id as string] = true)
      }
    }
    this.dataSource = [...existing, ...options.items.filter(o => !existing.find(o2 => o2.id === o.id))]
    this.selectedCount = Object.keys(this.selected).length
  }

  closeAdd() {
    this.filter = ''
    this.getResults()
  }

  toggleSelect(id: string) {
    if (this.selected[id]) {
      delete this.selected[id]
    } else {
      this.selected[id] = true
    }
    this.selected = { ...this.selected }
    this.selectedCount = Object.keys(this.selected).length
  }

  toggleSelectAll() {
    if (this.dataSource.every(o => this.selected[o.id as string])) {
      this.dataSource.forEach(o => delete this.selected[o.id as string])
    } else {
      this.dataSource.forEach(o => this.selected[o.id as string] = true)
    }
    this.selected = { ...this.selected }
    this.selectedCount = Object.keys(this.selected).length
  }
}


