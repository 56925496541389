/* eslint-disable @typescript-eslint/ban-types */
import { Transform } from 'class-transformer'
import { ValidationArguments, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, registerDecorator } from 'class-validator'
import { isString } from 'lodash'

export const DateType = () => {
    return Transform(v => {
        // TODO: Remove this transformer after fixing the problem so sometimes the dal returns a Firestore Timestamp instead of a Date
        const oVal = v.obj[v.key]

        if (typeof oVal === 'string' || oVal instanceof String) {
            const result = new Date(oVal as string)
            return result
        } else if (oVal?._seconds !== undefined && oVal?._nanoseconds !== undefined) {
            const newDate = new Date(oVal._seconds * 1000 + oVal._nanoseconds / 1000000)
            return newDate
        }

        return v.value
    })
}

export const CustomFieldsType = () => {
    return Transform(v => v.obj[v.key])
}


/**
 * Validates that property value matches expected context (ctx) format
 * Supports strings like 'wtm.mdlz.br/C0/R2' or 'hineken/prod/hineken mexico'
 * Allows spaces in company names for backward compatibility
 */
export function IsCtx(_property?: string, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'isCtx',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value: any, _args: ValidationArguments) {
                    const result = isString(value) && /^[a-z\d]+([\.-][a-z\d]+)*\/[a-z\d]+\/[a-z\d ]+$/i.test(value)
                    return result
                },
                defaultMessage(_validationArguments?: ValidationArguments) {
                    return "Ctx '$value' not valid"
                }
            }
        })
    }
}

/**
 * Validates as IsCtx without allowing spaces in company names
 */
export function IsStrictCtx(_property?: string, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'isCtx',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value: any, _args: ValidationArguments) {
                    const result = isString(value) && /^[a-z\d]+([\.-][a-z\d]+)*\/[a-z\d]+\/[a-z\d]+$/i.test(value)
                    return result
                },
                defaultMessage(_validationArguments?: ValidationArguments) {
                    return "Ctx '$value' not valid"
                }
            }
        })
    }
}
