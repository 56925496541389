import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { ListBrandsWithIds, ListCategoriesWithIds } from '../shared/api-structures/misc/catalog';
import { EquipmentWithId } from '../shared/api-structures/misc/equipment';
import { ImagesCatalog, Product } from '../shared/interfaces';
import { SetPlanogramFromImageResponse } from '../shared/api-structures/misc/planogram';

@Injectable({ providedIn: 'root' })
export class DataService {
  public categoriesWithIds: ListCategoriesWithIds.Category[] = []
  public brandsWithIds: ListBrandsWithIds.Brand[] = []
  public equipments: EquipmentWithId[] = [];
  // x = import * as _ from 'underscore';
  getDefaultImagesCatalog(productId, imagesCatalogs: ImagesCatalog[]) {
    var catalogs = _.filter(imagesCatalogs, i => i.mc1ProductId == productId)
    return catalogs[0]
  }

  prepareProduct(productDB: Product, imagesCatalogs: ImagesCatalog[]): Product {
    productDB.defaultImagesCatalog = this.getDefaultImagesCatalog(productDB.mc1Id, imagesCatalogs);
    productDB.quantity = 1;
    if (productDB.defaultImagesCatalog) {
      let imageFound = productDB.defaultImagesCatalog?.thumbnailUrl //_.find(productDB.defaultImagesCatalog.images, (i: ImageCatalog) => i.isPrimary == true)
      if (imageFound) {
        productDB.image = imageFound
      }
    } else {
      productDB.image = undefined
    }

    return productDB
  }
  

}
