<div class="settings-table-main">
    <div class="table-content-container">
        <div class="table-title-container">
            <div class="table-title">
                {{'Settings'|translate}}
            </div>
        </div>
        <div class="mat-elevation-z4 mat-dialog-content content">
            <mat-tab-group animationDuration="0ms" mat-stretch-tabs dynamicHeight>
                <mat-tab label="{{'General'|translate}}">
                    <label class="margin-top">{{'Language'|translate}}</label>
                    <br>
                    <div class="fit-content">
                        <mat-form-field class="field">
                            <mat-select [(ngModel)]="language">
                                <mat-option value="pt">Portuguese</mat-option>
                                <mat-option value="en">English</mat-option>
                                <mat-option value="es">Spanish</mat-option>
                                <mat-option value="ar">Arabic</mat-option>
                                <mat-option value="el">Greek</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-tab>

                <mat-tab label="{{'Theme'|translate}}">
                    <label class="margin-top">{{'Logo'|translate}}</label>
                    <mat-card class="img-card mat-elevation-z0" *ngIf="logoUrl">
                        <button mat-mini-fab (click)="logoUrl=undefined"
                            class="img-icon"><mat-icon>add_a_photo</mat-icon></button>
                        <img class="img-logo" mat-card-image src="{{logoUrl}}" alt="LOGO" />
                    </mat-card>
                    <drag-and-drop-files isMultipleFiles="false" accept="image/*" (onFileSelected)="saveLogoFileToStorga($event)"
                        *ngIf="!logoUrl && !isLoadingSettings"></drag-and-drop-files>
                    <div *ngIf="uploadProgress">
                        {{uploadProgress.name}}
                        <mat-progress-bar mode="determinate" value="{{uploadProgress.progress}}"></mat-progress-bar>
                    </div>

                    <div class="row">
                        <div class="col fit-content">
                            <label class="margin-top">{{'PrimaryColor'|translate}}</label>
                        </div>
                        <div class="col">
                            <mat-card class="img-card mat-elevation-z0">
                                <input type="color" [(ngModel)]="primaryColor"/>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col fit-content">
                            <label class="margin-top">{{'SecondaryColor'|translate}}</label>
                        </div>
                        <div class="col">
                            <mat-card class="img-card mat-elevation-z0">
                                <input type="color" [(ngModel)]="secondaryColor"/>
                            </mat-card>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="{{'Training'|translate}}">
                    <label class="margin-top">{{'ImageClassification'|translate}}</label>
                    <div class="row">
                        <div class="col fit-content">
                            <label class="margin-top">{{'SamplesPerCall'|translate}}</label>
                            <mat-form-field class="mat-elevation-z0">
                                <input type="number" matInput [(ngModel)]="samplesPerCall" placeholder="Min 1 - Max 1000"/>
                            </mat-form-field>
                        </div>
                        <div class="col fit-content">
                            <label class="margin-top">{{'SamplesPerProducts'|translate}}</label>
                            <mat-form-field class="mat-elevation-z0">
                                <input type="number" style="width: 15%;" matInput [(ngModel)]="samplesPerProducts" placeholder="Min 1 - Max 1000">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col fit-content">
                            <label class="margin-top">{{'TrainBudget'|translate}}</label>
                            <mat-form-field class="mat-elevation-z0">
                                <input type="number" matInput [(ngModel)]="trainBudget"/>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>



        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close matTooltip="{{'CANCEL'|translate}}">
                <mat-icon color="primary">cancel</mat-icon>
            </button>
            <button mat-button (click)="saveSettings()" matTooltip="{{'SaveChanges'|translate}}">
                <mat-icon color="primary">save</mat-icon>
            </button>
        </mat-dialog-actions>
    </div>
</div>