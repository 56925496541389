<div class="row setting-row">
  <div class="col-1 text-center">
    <mat-slide-toggle [ngModel]="toggleValue" [disabled]="disabled"></mat-slide-toggle>
  </div>
  <div class="col-5">
    <div class="text1">{{ title | translate }}</div>
    <div class="text2">{{ description | translate }}</div>
  </div>
  <div class="col-5" *ngIf="hasControl">
    <ng-content></ng-content>
  </div>
</div>
