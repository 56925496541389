import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _ from 'underscore';
export function regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }
    const invalid = regex.test(control.value);
    return invalid ? error : null;
  };
}
export function uniqueValidator(key, keyId, isNew, id, array) {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    if (_.isUndefined(group.value) || group.value == "" || group.value == null) {
      return null;
    }
    var arrayFilteredByValue = _.filter(array, i => i[key] == group.value)
    if (isNew) {
      if (arrayFilteredByValue.length > 0) {
        return { unique: true };
      }
      return null
    }
    else {
      var itemFound = _.find(arrayFilteredByValue, i => i[keyId] != id && i[key] == group.value)
      if (!_.isUndefined(itemFound)) {
        return { unique: true };
      }
      return null;
    }
  }
}