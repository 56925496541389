<div *ngFor="let notification of notifications" class="notification-card">
    <div class="card-header">
        <div class="date-position">{{ notification.createdAt | date:'dd/MM/yyyy - HH:mm' }}</div>
    </div>
    <div class="card-content">
        <div class="icon-container"
            [ngStyle]="{'background-color': getBackgroundColor(notification.severity), 'color': getIconColor(notification.severity)}">
            <i class="pi" [ngClass]="getIcon(notification.severity)"></i>
        </div>
        <div class="text-container">
            <div class="first-text" [style.color]="getTextColor(notification.severity)">{{ notification.issue | translate }}</div>
            <div class="second-text">{{ "YouHave" | translate }}&nbsp;<b>{{ notification.amount | translate }}</b>&nbsp;{{ notification.description | translate }}</div>
        </div>
    </div>
    <div class="card-actions">
        <button class="notification-button" mat-button routerLink="health-check">{{ 'Review' | translate }}</button>
    </div>
</div>