// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsIn, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ByPageRequest, SortDirection, SortDirectionArray } from '../common'

export type SourceType = 'user_feedback' | 'machine_learning'
const SourceTypesArray: SourceType[] = ['user_feedback', 'machine_learning']

export type SortBy = 'productId' | 'productName' | 'totalImages' | 'lastUpdate'

export class CountImagesToApproveRequest {
    @Expose() @IsOptional() @IsIn(SourceTypesArray) filterBy?: SourceType
}

export class CountImagesToApproveResponse {
    @Expose() @IsNumber() count!: number
    @Expose() @IsBoolean() isHaveMore!: boolean
}

export namespace ListProductsWithImagesToApprove {
    export class ResponseStructre {
        @Expose() @IsString() productId!: string
        @Expose() @IsString() @IsOptional() externalId?: string
        @Expose() @IsString() imageCatalogId!: string
        @Expose() @IsString() imageCatalogName!: string
        @Expose() @IsNumber() imageCount!: number
        @Expose() @IsString() productName!: string
        @Expose() @IsString() @IsOptional() thumbnail?: string
        @Expose() @IsBoolean() isCompetitors!: boolean
        @Expose() @IsString() category!: string
        @Expose() @IsNumber() @IsOptional() successRate?: number
        @Expose() @IsNumber() @IsOptional() amount?: number
        @Expose() @IsNumber() @IsOptional() percentOfAmount?: number
        @Expose() @IsNumber() @IsOptional() areEnoughImages?: boolean
    }

    export class Response {
        @Expose() @IsNumber() totalItemsCount!: number
        @Expose() @Type(() => ResponseStructre) @IsArray() items!: ResponseStructre[]
    }
}

export namespace ListImageCatalogsWithImagesToApprove {
    export class ResponseStructre {
        @Expose() @IsString() imageCatalogId!: string
        @Expose() @IsString() imageCatalogName!: string
        @Expose() @IsString() @IsOptional() thumbnail?: string
    }

    export class Response {
        @Expose() @Type(() => ResponseStructre) @IsArray() items!: ResponseStructre[]
    }

    export class Request {
        @Expose() @IsString() productId!: string
    }
}

export enum EnumSearchToApproveSortBy { productId = 'productId', productName = 'productName', isCompetitors = 'type', category = 'category', imageCount = 'imageCount' }

export class ListProductsWithImagesToApproveFilters {
    @Expose() @IsString() @IsOptional() minimumImages?: number
    @Expose() @IsString() @IsOptional() maximumImages?: number
    @Expose() @IsString() @IsIn(['productId', 'productName', 'lastUpdate', 'accuracy']) @IsOptional() sortBy?: 'productId' | 'productName' | 'lastUpdate' | 'accuracy'
    @Expose() @IsString() @IsIn(SortDirectionArray) @IsOptional() sortDirection?: SortDirection
}

export class ListProductsWithImagesToApproveRequest extends ByPageRequest {
    @Expose() @IsIn(SourceTypesArray) @IsOptional() filterBy?: SourceType
    @Expose() @IsString() @IsOptional() sortBy?: SortBy
    @Expose() @IsIn(SortDirectionArray) @IsOptional() sortDirection?: SortDirection
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) @IsOptional() filters?: string[]
    @Expose() @ValidateNested() @IsOptional() newFilters?: ListProductsWithImagesToApproveFilters
}

export class ListImagesToApproveRequest {
    @Expose() @IsNumber() pageSize!: number
    @Expose() @IsString() @IsOptional() productId?: string
    @Expose() @IsString() @IsOptional() imageCatalogId?: string
    @Expose() @IsString() @IsOptional() syncOnlyEditedImages?: string
    @Expose() @IsString() @IsOptional() filterBy?: string
    @Expose() @Type(() => ListImagestoApproveFilter) @IsOptional() newFilters?: ListImagestoApproveFilter
    @Expose() @IsIn(SortDirectionArray) @IsOptional() sortDirection?: SortDirection
    @Expose() @IsOptional() sortBy?: string
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) @IsOptional() filters?: string[]
    @Expose() @IsOptional() @IsString() pageToken?: string
}

export namespace ListImagesToApprove {
    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsString() thumbnailUrl!: string
        @Expose() @IsIn(SourceTypesArray) source!: SourceType
        @Expose() @IsString() userName!: string
        @Expose() @IsString() userId!: string
        @Expose() @IsString() planogramId!: string
        @Expose() @IsDate() lastUpdate!: Date
    }

    export class Response {
        @Expose() @IsString() currentPageToken!: string
        @Expose() @IsString() @IsOptional() prevPageToken?: string
        @Expose() @IsString() @IsOptional() nextPageToken?: string
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
        @Expose() @IsString() @IsOptional() thumbnail?: string
        @Expose() @IsNumber() @IsOptional() totalItemsCount?: number
    }
}

export namespace ApproveImages {
    export class Item {
        @Expose() @IsString() imageId!: string
        @Expose() @IsString() catalogId!: string
    }

    export class Request {
        @Expose() @Type(() => Item) @IsArray() items!: Item[]
    }
}

export class ListProductsAccuraciesRequest {
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) @IsOptional() productsName?: string[]
}

export namespace ListProductsAccuracies {
    export class Item {
        @Expose() @IsNumber() accuracy!: number
        @Expose() @IsDate() date1!: Date
        @Expose() @IsString() productId!: string
        @Expose() @IsString() productName!: string
    }

    export class Response {
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

export class CountProductCatalogImagesRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() catalogId!: string
}

export namespace CountProductCatalogImagesResponse {
    export class Response {
        @Expose() @IsNumber() countImages!: number
    }
}

export class ListImagestoApproveFilter {
    @Expose() @IsString() dateStart?: string
    @Expose() @IsString() dateEnd?: string
    @Expose() @IsString() userIds?: string[]
    @Expose() @IsString() origin?: string
    @Expose() @IsString() methodRecognition?: string
}