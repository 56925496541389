import { Component, OnInit } from '@angular/core';
import { TrainingsService } from 'src/app/admin/services/trainings.service';

@Component({
  selector: 'admin-trainings',
  templateUrl: './trainings.component.html',
})
export class TrainingsComponent implements OnInit {

  constructor(private trainingsService: TrainingsService) { }

  ngOnInit() {
    this.trainingsService.loadTrainings()
  }

  refresh() {
    this.trainingsService.loadTrainings()
  }
}
