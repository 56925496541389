import { Component, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { ROUTER_LINK } from 'src/app/shared/const';
import { AutoDestroy } from '../../../shared/base-directives/auto-destroy';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import { TrainingsService } from '../../services/trainings.service';
import { ContractTrainingResponse } from 'src/app/shared/api-structures/admin/training';
import { StandaloneNavbarService } from 'src/app/shared/services/standalone.navbar.service';

@Component({
  selector: 'admin-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends AutoDestroy {
  isShowNav = false
  shouldShowHome: boolean = true;
  companyType = getCompanyType()
  remainingTrainig: number;
  reloadSubscription
  contractTraining: ContractTrainingResponse = new ContractTrainingResponse()

  constructor(private router: Router, private memoryStorageService: MemoryStorageService, private trainingsService: TrainingsService, private standaloneNavbarService: StandaloneNavbarService) {
    super()
    this.subscriptions.push(router.events.subscribe(v => this.isShowNav = false))
    this.getContractTraining()
    if (this.companyType === 'MC1') {
      this.shouldShowHome = this.memoryStorageService.routerLink === ROUTER_LINK.allTabs ? true : false
    }
    this.reloadSubscription = this.memoryStorageService.isReloadTrainingPageObservable.subscribe(res => {
      if (res) {
        this.getContractTraining()
      }
    })
  }

  goHome() {
    this.router.navigateByUrl('/home')
    this.standaloneNavbarService.validateNavbar()
  }

  getContractTraining(){
    this.memoryStorageService.contractTrainingObs.subscribe(v => {
      if (v) {
        this.contractTraining = v
        this.remainingTrainig = Math.abs(v.trainingCount - v.trainingQuota + v.singleTrainingSold);
      }
    })
  }

  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe()
  }
}
