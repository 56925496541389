import { Injectable } from '@angular/core';
import { GetDashboardUrl, GetInternalDashboardUrlResponse } from 'src/app/shared/api-structures/admin/dashboard';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private api: ApiService) { }

  async getDashboardUrl() {
    const response = await this.api.adminRequest({ endpoint: '/getDashboardUrl', responseType: GetDashboardUrl.Response })
    return response
  }

  async getInternalDashboardUrl() {
    const response = await this.api.adminRequest({ endpoint: '/getDashboardInternalUrl', responseType: GetInternalDashboardUrlResponse })
    return response
  }
}
