// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace
import { Expose, Type } from 'class-transformer'
import { IsArray, IsOptional, IsString, ValidateNested } from 'class-validator'
import { BulkAddResponseBase } from '../bulk'

export class BaseBrand {
    @Expose() @IsString() name!: string
}

export class Brand extends BaseBrand {
    @Expose() @IsString() id!: string
}

export class AddBrandRequest extends BaseBrand { }

export class BulkAddBrandsRequest {
    @Expose() @IsString() @IsOptional() entity?: string
    @Expose() @ValidateNested() @Type(() => BaseBrand) @IsArray() data!: BaseBrand[]
}

export class BulkAddBrandsReport {
    @Expose() @IsString() error!: string
    @Expose() @ValidateNested() @Type(() => BaseBrand) item!: BaseBrand
}

export class BulkAddBrandsResponse extends BulkAddResponseBase {
    @Expose() @ValidateNested() @Type(() => BulkAddBrandsReport) @IsArray() report!: BulkAddBrandsReport[]
}

export class AddBrandResponse extends Brand { }

export class ListBrandsResponse {
    @Expose() @Type(() => Brand) @IsArray() items!: Brand[]
}