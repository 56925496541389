import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { ByIdRequest } from 'src/app/shared/api-structures/common';
import { CreateSignedUrlRequest, CreateSignedUrlRsponse } from 'src/app/shared/api-structures/misc/misc';
import { ListOrganizationsResponse, CreateOrganizationResponse, UpdateOrganizationRequestAndResponse, ListOrganizationsRequest, GetOrganizationSettingsResponse, UpdateOrganizationSettingsRequest, CreateOrganizationRequest } from 'src/app/shared/api-structures/super-admin/organization';
import { ApiService } from 'src/app/shared/services/api.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private listOrganizations = new Subject<ListOrganizationsResponse>()
  listOrganizations$ = this.listOrganizations.asObservable()

  constructor(private api: ApiService, private http: HttpClient, private languageService: LanguageService,) { }

  async createOrganization(body: CreateOrganizationRequest) {
    const response = await this.api.superRequest({ endpoint: '/createOrganization', body: body, responseType: CreateOrganizationResponse })
    return response
  }

  async updateOrganization(body: UpdateOrganizationRequestAndResponse) {
    const response = await this.api.superRequest({ endpoint: '/updateOrganization', body, responseType: UpdateOrganizationRequestAndResponse })
    return response
  }

  async deleteOrganization(body: ByIdRequest) {
    await this.api.superRequest({ endpoint: '/deleteOrganization', body })
  }

  async loadOrganizations(body: ListOrganizationsRequest) {
    const data = await this.api.superRequest({ endpoint: '/listOrganizations', body: body, responseType: ListOrganizationsResponse })
    this.listOrganizations.next(data)
    return data
  }

  async getOrganizationSettings(body: ByIdRequest) {
    const data = await this.api.superRequest({ endpoint: '/getOrganizationSettings', body, responseType: GetOrganizationSettingsResponse })
    return data
  }

  async updateOrganizationSettings(body: UpdateOrganizationSettingsRequest) {
    const data = await this.api.superRequest<UpdateOrganizationSettingsRequest>({ endpoint: '/updateOrganizationSettings', body, responseType: ListOrganizationsResponse })
    return data
  }

  async createSignedUrl(ext: string, contentType: string, organizationId: string, isHideProgress: boolean = false) {
    const result = await this.api.superRequest<CreateSignedUrlRequest, CreateSignedUrlRsponse>({
      endpoint: '/createSignedUrl',
      body: { isMobileReport: false, ext, contentType, organizationId },
      responseType: CreateSignedUrlRsponse,
      errorMessage: this.languageService.translateSync('ErrorUploadingImage'),
    }, { isHideProgress })

    return result
  }
}
