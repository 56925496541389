import { Component, Injectable, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'underscore';
@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) { }
  openSnackBar(duration, message) {
    this.snackbar.openFromComponent(SnackbarComponent, {horizontalPosition: 'center', verticalPosition: 'top', duration: duration, data: message});
  }
}
@Component({
  selector: 'snack-bar-component',
  template: `<span style="text-align:center">{{message}}</span>`
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public message: any) {

  }
}
