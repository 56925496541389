import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/authGuard';
import { UserManagementComponent } from '../shared/components/user-management/user-management.component';
import { RoleGuard } from '../shared/role.guard';
import { AdminComponent } from './components/admin.component';
import { CustomerManagementComponent } from './components/customer-management/customer-management.component';
import { PriceTargetComponent } from './components/price-target/price-target.component';
import { StaticModelsComponent } from './components/static-models/static-models.component';
import { AreasComponent } from './components/areas/areas.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { ClustersComponent } from './components/clusters/clusters.component';
import { AddEditClusterComponent } from './components/clusters/add-edit-cluster/add-edit-cluster.component';
import { CustomFieldsSettingsComponent } from './components/custom-fields-settings/custom-fields-settings.component';
import { ManagePriceTargetComponent } from './components/price-target/manage-price-target/manage-price-target.component';
import { ManageCustomerComponent } from './components/customer-management/manage-customer/manage-customer.component';
import { RunInOrderGuard } from '../shared/guards/run-in-order.guard';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'admin',
    component: AdminComponent,
    children: [
      { canActivate: [RunInOrderGuard], path: 'trainings', component: TrainingsComponent, data: { accessRole: 'Train' } },
      { canActivate: [RunInOrderGuard], path: 'static-models', component: StaticModelsComponent, data: { accessRole: 'Models' } },
      { canActivate: [RunInOrderGuard], path: 'user-management', component: UserManagementComponent, data: { accessRole: 'Users' } },
      { canActivate: [RunInOrderGuard], path: 'customer', component: CustomerManagementComponent, data: { accessRole: 'Customer' } },
      { canActivate: [RunInOrderGuard], path: 'manage-customer', component: ManageCustomerComponent, data: { accessRole: 'Customer' } },
      { canActivate: [RunInOrderGuard], path: 'areas', component: AreasComponent, data: { accessRole: 'Area' } },
      { canActivate: [RunInOrderGuard], path: 'price-target', component: PriceTargetComponent, data: { accessRole: 'PriceTarget' } },
      { canActivate: [RunInOrderGuard], path: 'manage-price-target', component: ManagePriceTargetComponent, data: { accessRole: 'PriceTarget' } },
      { canActivate: [RunInOrderGuard], path: 'clusters', component: ClustersComponent, data: { accessRole: 'Clusters' } },
      { canActivate: [RunInOrderGuard], path: 'clusters/:id', component: AddEditClusterComponent, data: { accessRole: 'Clusters' } },
      { canActivate: [RunInOrderGuard], path: 'custom-fields', component: CustomFieldsSettingsComponent, data: { accessRole: 'CustomFields' } },
      { path: '', redirectTo: 'trainings', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
