import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LanguageService } from 'src/app/shared/services/language.service';
import _ from 'underscore';

export type AreaFields = {
  polygon?: google.maps.LatLngLiteral[],
  name?: string,
  regions?: string[],
  tags?: string[],
  color?: string
}

@Component({
  selector: 'app-add-edit-area',
  templateUrl: './add-edit-area.component.html',
  styleUrls: ['./add-edit-area.component.scss']
})
export class AddEditAreaComponent implements OnInit {
  @Output() submit = new EventEmitter<AreaFields | void>();
  @Output() delete = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() polygonChange = new EventEmitter<google.maps.LatLngLiteral[]>();
  @Input() mode: 'add' | 'edit' = 'add'
  @Input() selectedArea: AreaFields | null = null
  polygonPathStr: string = ''
  @Input() set editedPolygonPath(path: google.maps.LatLngLiteral[]) {
    this.polygonPathStr = JSON.stringify(path)
  }
  get editedPolygonPath() {
    return JSON.parse(this.polygonPathStr)
  }

  tagsInput = new FormControl('')
  regionsInput = new FormControl('')
  areaForm = new FormGroup({
    name: new FormControl(''),
    regions: new FormControl([]),
    tags: new FormControl([]),
    color: new FormControl('')
  })

  constructor(private dialog: MatDialog, private languageService: LanguageService) { }

  ngOnInit(): void {
    this.tagsInput = new FormControl('')
    this.regionsInput = new FormControl('')
    if (this.selectedArea) {
      this.polygonPathStr = JSON.stringify(this.selectedArea?.polygon)
      this.areaForm = new FormGroup({
        name: new FormControl(this.selectedArea?.name),
        regions: new FormControl(this.selectedArea?.regions || []),
        tags: new FormControl(this.selectedArea?.tags || []),
        color: new FormControl(this.selectedArea?.color)
      })
    }
  }

  addRegion(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (value) {
      this.areaForm.get('regions').value.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.regionsInput.setValue(null);
  }

  removeRegion(region: string): void {
    const regions = this.areaForm.get('regions').value
    const index = regions.indexOf(region);

    if (index >= 0) {
      regions.splice(index, 1);
      this.areaForm.get('regions').setValue(regions)
    }
  }

  addTag(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (value) {
      this.areaForm.get('tags').value.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.tagsInput.setValue(null);
  }

  removeTag(tag: string): void {
    const tags = this.areaForm.get('tags').value
    const index = tags.indexOf(tag);

    if (index >= 0) {
      tags.splice(index, 1);
      this.areaForm.get('tags').setValue(tags)
    }
  }

  onSave() {
    this.areaForm.markAllAsTouched()
    if (this.areaForm.invalid) return
    this.submit.emit(this.areaForm.value)
  }

  onCancel() {
    this.cancel.emit()
  }

  onDelete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: {
        title: `${this.languageService.translateSync('ConfirmDeletionTitle')}`,
        message: `${this.languageService.translateSync('confirmAreaDeleteMessage')}`
      }
    })
    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }
      this.delete.emit()
    })
  }

  onPolygonChange() {
    this.polygonChange.emit(this.editedPolygonPath)
  }
}
