import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { ListStaticModel } from 'src/app/shared/api-structures/admin/staticModel';
import { PlanogramParametersToggle, PlanogramWithId } from 'src/app/shared/api-structures/misc/planogram';
import * as _ from 'underscore';
import { UtilitiesService } from '../../../services/utilities.service';
import { PLANOGRAM_STATUS } from '../../../shared/const';
import { MatTabGroup } from '@angular/material/tabs';
import { PlanogramSettingsGettingResponse } from 'src/app/shared/api-structures/misc/planogramSettings';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import { CompanyType } from 'src/app/shared/api-structures/common';
import { Dal } from 'src/app/dal/dal';
import { AppService } from 'src/app/services/app.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-planogram-new-dialog',
  templateUrl: './planogram-new-dialog.component.html',
  styleUrls: ['./planogram-new-dialog.component.css']
})
export class PlanogramNewDialogComponent implements OnInit, OnDestroy {
  staticModels: ListStaticModel.Item[];
  shareOfShelfType: string;
  planogram: PlanogramWithId;
  planogramForm: UntypedFormGroup;
  filterPlanogramsIds: any[] = [];
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  private companyType: CompanyType;
  hideParameters: boolean = false;
  PLANOGRAM_STATUS = PLANOGRAM_STATUS

  public parametersToggle: PlanogramParametersToggle;
  public kpisFullOptions: { key: string, description: string }[] = [];
  public kpisSimpleOptions: { key: string, description: string }[] = [];
  public detectionZonesOptions: { key: string, description: string }[] = [];
  public annotationsOptions: { key: string, description: string }[] = [];
  public priceOcrOptions: { key: string, description: string }[] = [];
  public commonDataResponse: PlanogramSettingsGettingResponse
  public userInfo: any // TODO DELETE
  uploadingFiles: {
    name: string,
    progress: number
  }[] = []

  imageUrlExternal: string | SafeUrl = '';
  tmpUrl?: string

  private file?: File
  private removeImg: boolean = false

  constructor(
    private dal: Dal,
    private memoryStorageService: MemoryStorageService,
    public dialogRef: MatDialogRef<PlanogramNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private utilitiesService: UtilitiesService,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
    private sanitizer: DomSanitizer
  ) {
    const { commonData, planogram, staticModels } = data;


    this.planogramForm = this.formBuilder.group({
      codeCtrl: new UntypedFormControl({ value: '', disabled: true }),
      nameCtrl: new UntypedFormControl({ value: '', disabled: planogram?.state === PLANOGRAM_STATUS.PUBLISHED }),
      descriptionCtrl: new UntypedFormControl(),
      commentsCtrl: new UntypedFormControl(),
      recomendedCtrl: new UntypedFormControl(),
      invasorKPICtrl: new UntypedFormControl({ value: false }),
      classificationThresholdToggle: new UntypedFormControl({ value: false }),
      autoCropToggle: new UntypedFormControl({ value: false }),
      kpisFullToggle: new UntypedFormControl({ value: false }),
      kpisSimpleToggle: new UntypedFormControl({ value: false }),
      detectionZonesToggle: new UntypedFormControl({ value: false }),
      annotationsToggle: new UntypedFormControl({ value: false }),
      priceOcrToggle: new UntypedFormControl({ value: false }),
      syncOnlyEditedResults: new UntypedFormControl({ value: false }),
    })

    this.commonDataResponse = commonData

    this.staticModels = staticModels

    const defaultFullKpis = ['Compliance', 'Presence'];
    const defaultDetectionZones = ['goldZone', 'hotZone'];
    const defaultAnnotations = ['noise', 'invasor', 'emptySpace', 'outOfPlanogram'];

    const parameters = {
      classificationThreshold: planogram?.parameters?.classificationThreshold ?? 25,
      autoCrop: planogram?.parameters?.autoCrop ?? false,
      kpisFull: planogram?.parameters?.kpisFull ?? defaultFullKpis,
      kpisSimple: planogram?.parameters?.kpisSimple,
      detectionZones: planogram?.parameters?.detectionZones ?? defaultDetectionZones,
      annotations: planogram?.parameters?.annotations ?? defaultAnnotations,
      priceOcrPicture: planogram?.parameters?.priceOcrPicture ?? 'multi',
      syncOnlyEditedResults: planogram?.parameters?.syncOnlyEditedResults ?? false
    };

    this.planogram = {
      id: planogram?.id ?? null,
      name: planogram?.name ?? this.memoryStorageService.mc1PlanogramName,
      mc1PlanogramId: planogram?.mc1PlanogramId ?? this.memoryStorageService.mc1PlanogramId,
      validity: planogram?.validity ?? new Date(),
      staticModelsIds: planogram?.staticModelsIds ?? this.staticModels.map(v => v.id),
      state: planogram?.state ?? PLANOGRAM_STATUS.DRAFT,
      lastUpdate: planogram?.lastUpdate ?? new Date(),
      description: planogram?.description ?? null,
      comment: planogram?.comment ?? null,
      recomendMPV: planogram?.recomendMPV ?? null,
      planogramType: planogram?.planogramType ?? 'free',
      shareOfShelfType: planogram?.shareOfShelfType ?? 'linear',
      invasorKPI: planogram?.invasorKPI ?? false,
      parameters,
      equipmentId: planogram?.equipmentId,
      imageUrl: planogram?.imageUrl,
      imagePath: planogram?.imagePath,
      imageUrlExternal: planogram?.imageUrlExternal,
      extra: planogram?.extra,
      structure: planogram?.structure,
      products: planogram?.products,
      imageImportedFromUrl: planogram?.imageImportedFromUrl,
    }

    this.parametersToggle = {
      classificationThresholdToggle: !!planogram?.parameters?.classificationThreshold,
      autoCropToggle: !!this.planogram?.parameters?.autoCrop,
      kpisFullToggle: !!this.planogram?.parameters?.kpisFull && this.planogram?.parameters?.kpisFull.length > 0,
      kpisSimpleToggle: !!this.planogram?.parameters?.kpisSimple && this.planogram?.parameters?.kpisSimple.length > 0,
      detectionZonesToggle: !!this.planogram?.parameters?.detectionZones && this.planogram?.parameters?.detectionZones.length > 0,
      annotationsToggle: !!this.planogram?.parameters?.annotations && this.planogram?.parameters?.annotations.length > 0,
      priceOcrToggle: !!planogram?.parameters?.priceOcrPicture
    };

    if (planogram) {
      this.imageUrlExternal = planogram?.imageUrlExternal && planogram.imageUrlExternal?.trim() !== '' ? planogram.imageUrlExternal : planogram.imageUrl
    }

    this.fillUpCommonData();
  }

  public fillUpCommonData() {
    const { kpisFull, kpisSimple, annotations, zones } = this.commonDataResponse;

    const { planogramType } = this.planogram;

    const isFixed = planogramType?.toLocaleLowerCase() === 'planogram'

    const kpisFullOptionsToShow = isFixed
      ? kpisFull
      : kpisFull?.filter(({ key }) => key.toLocaleLowerCase() !== 'misplaced');

    if (!isFixed && this.planogram.parameters.kpisFull) {
      this.planogram.parameters.kpisFull = this.planogram.parameters.kpisFull.filter(x => x.toLocaleLowerCase() !== 'misplaced')
    }

    this.kpisFullOptions = kpisFullOptionsToShow;
    this.kpisSimpleOptions = kpisSimple;
    this.detectionZonesOptions = zones;
    this.annotationsOptions = annotations;
    this.priceOcrOptions = [
      {
        key: 'multiPictureOcr',
        description: 'Multi Picture OCR'
      },
      {
        key: 'singlePictureOcr',
        description: 'Single Picture OCR'
      }
    ]
  }

  async ngOnInit() {
    this.companyType = getCompanyType()
    if (this.companyType === 'MC1') {
      this.hideParameters = true
    }
    await this.dal.getUserInfo().then(userInfo => this.userInfo = userInfo)
  }

  ngOnDestroy(): void {
    URL.revokeObjectURL(this.tmpUrl)
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  async save() {
    if (!this.planogramForm.valid) {
      this.utilitiesService.validateAllFormFields(this.planogramForm);
      return;
    }

    this.planogram.parameters.classificationThreshold = this.parametersToggle.classificationThresholdToggle ? this.planogram.parameters.classificationThreshold : null;
    this.planogram.parameters.autoCrop = this.parametersToggle.autoCropToggle;
    this.planogram.parameters.kpisFull = this.parametersToggle.kpisFullToggle ? this.planogram.parameters.kpisFull : [];
    this.planogram.parameters.kpisSimple = this.parametersToggle.kpisSimpleToggle ? this.planogram.parameters.kpisSimple : [];
    this.planogram.parameters.detectionZones = this.parametersToggle.detectionZonesToggle ? this.planogram.parameters.detectionZones : [];
    this.planogram.parameters.annotations = this.parametersToggle.annotationsToggle ? this.planogram.parameters.annotations : [];
    this.planogram.parameters.priceOcrPicture = this.parametersToggle.priceOcrToggle ? this.planogram.parameters.priceOcrPicture : null;
    this.dialogRef.close({planogram: this.planogram, imageFile: this.file, removeImage: this.removeImg, imageUrlExternal: this.planogram?.imageUrlExternal});
  }

  changeTab(index) {
    this.tabGroup.selectedIndex = index
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async handleImageFile(file) {
    if (this.utilitiesService.validateImageType(file) == false || !this.utilitiesService.validateSize(file)) {
      return
    }
    this.file = file
    this.tmpUrl = URL.createObjectURL(file)
    this.imageUrlExternal = this.sanitizer.bypassSecurityTrustUrl(this.tmpUrl)
    this.removeImg = false
  }

  removeImage() {
    if (this.file) {
      this.file = undefined
    }
    if (this.planogram?.imageUrlExternal && this.imageUrlExternal && this.imageUrlExternal !== this.planogram.imageUrl) {
      this.removeImg = true
    }
    this.imageUrlExternal = this.planogram.imageUrl
  }
}
