import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ByIdRequest, LatLng, NumberResponse, StringResponse } from "src/app/shared/api-structures/common";
import { AddAreaRequest, BaseArea, ListAreasRequest, ListAreasResponse, Area, UpdateAreaRequest } from "src/app/shared/api-structures/misc/area";
import { PolyData } from "src/app/shared/components/map/map.component";
import { ApiService } from "src/app/shared/services/api.service";
import { LanguageService } from "src/app/shared/services/language.service";

@Injectable()
export class AreaService {
  private areasSubject = new Subject<Area[]>()
  areas$ = this.areasSubject.asObservable()

  constructor(private api: ApiService, private languageService: LanguageService) { }

  async listAreas(query: ListAreasRequest) {
    const result = await this.api.adminRequest<ListAreasRequest, ListAreasResponse>({
      endpoint: '/listAreas',
      body: query,
      errorMessage: this.languageService.translateSync('ErrorListingAreas')
    })
    this.areasSubject.next(result.items)
    return result
  }

  async countCustomersInArea(id: string) {
    const result = await this.api.adminRequest<ByIdRequest, NumberResponse>({
      endpoint: '/countCustomersInArea',
      body: { id },
      errorMessage: this.languageService.translateSync('ErrorListingAreaCustomers')
    })
    return result.response
  }

  async addArea(area: AddAreaRequest) {
    const result = await this.api.adminRequest<AddAreaRequest, BaseArea>({
      endpoint: '/addArea',
      body: area,
      errorMessage: this.languageService.translateSync('ErrorAddingArea')
    })
    return result
  }

  async updateArea(area: UpdateAreaRequest) {
    const result = await this.api.adminRequest<UpdateAreaRequest, BaseArea>({
      endpoint: '/updateArea',
      body: area,
      errorMessage: this.languageService.translateSync('ErrorUpdatingArea')
    })
    return result
  }

  async deleteArea(id: string) {
    const result = await this.api.adminRequest<ByIdRequest, null>({
      endpoint: '/deleteArea',
      body: { id },
      errorMessage: this.languageService.translateSync('ErrorDeletingArea')
    })
    return result
  }

  async listAreasByLocation(latLng: LatLng) {
    const result = await this.api.adminRequest<LatLng, ListAreasResponse>({
      endpoint: '/listAreasByLocation',
      body: latLng,
      responseType: ListAreasResponse,
      errorMessage: this.languageService.translateSync('ErrorDeletingArea')
    })
    return result
  }

  createPolygonData(area, onPolygonClick: (polygon: PolyData) => void, color = 'lightblue') {

    return {
      id: area.id,
      options: {
        paths: area.polygon,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.35,
        clickable: true,
      },
      polygonClicked: onPolygonClick
    }

  }

  // async editAreaCustomers(data: EditTerritoryCustomersRequest) {
  //   const result = await this.api.adminRequest<EditTerritoryCustomersRequest, StringResponse>({
  //     endpoint: '/editCustomersInArea',
  //     body: data,
  //     errorMessage: this.languageService.translateSync('ErrorEditingAreaCustomers')
  //   })
  //   return result
  // }

}