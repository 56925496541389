import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { MemoryStorageService } from '../services/memory-storage.service'
import { InitSessionRequest, InitSessionResponse } from '../shared/api-structures/misc/misc'
import { ROUTER_LINK } from '../shared/const'
import { ApiService } from '../shared/services/api.service'
import { CommunicatorService } from '../shared/services/communicator.service'
import { HelpersService } from '../shared/services/helpers.service'
import * as jwt_decode from "jwt-decode"
import { CompanySettings } from '../shared/api-structures/admin/companySettings'
import { LanguageService } from '../shared/services/language.service'
import { ListMc1Companies } from '../shared/api-structures/admin/mc1'

@Component({
  selector: 'communicator',
  templateUrl: './communicator.component.html',
})
export class CommunicatorComponent {
  status = 'Starting...'
  isError = false

  constructor(private router: Router, private memoryStorageService: MemoryStorageService,
    private apiService: ApiService, private spinner: NgxSpinnerService,
    private helpers: HelpersService, private communicatorService: CommunicatorService,
    private languageService: LanguageService) {
    spinner.show()
    this.helpers.runNextTick(() => this.start())
  }

  async start() {
    try {
      const data = await this.communicatorService.loadDataAndInit()
      const companySettings = await this.apiService.adminRequest({ endpoint: '/getSettings', responseType: CompanySettings.Item})
      const decryptToken: any = jwt_decode.default(data.token)
      if ((companySettings.sharingProject && companySettings.sharingProject.company !== decryptToken.cIDCompany) ||
        (decryptToken.cIDMainCompany && decryptToken.cIDMainCompany !== decryptToken.cIDCompany)) {
        const err = {
          error: {
            message: this.languageService.translateSync('UnauthorizedError')
          }
        }
        this.showError(err)
        return
      }

      if (data?.sessions?.menuIRE) {
        switch (data.sessions.menuIRE) {
          case ROUTER_LINK.productCatalogs:
              this.router.navigateByUrl('/product-catalogs-without-tabs')
              break
          case ROUTER_LINK.unapproved:
            this.router.navigateByUrl('/unapproved-without-tabs')
            break
          case ROUTER_LINK.training:
            this.router.navigateByUrl('/admin')
            break
          case ROUTER_LINK.dashboard:
            this.router.navigateByUrl('/dashboard-without-tabs')
            break
          default:
            this.status = 'Server sync'
            const res = await this.apiService.miscRequest<InitSessionRequest, InitSessionResponse>({ endpoint: '/initSession', body: data, responseType: InitSessionResponse })
            this.memoryStorageService.mc1PlanogramId = res.mc1PlanogramId
            this.memoryStorageService.mc1PlanogramName = res.mc1PlanogramName
            this.memoryStorageService.syncOnlyEditedImages = res.syncOnlyEditedImages
            this.status = 'Done. Redirecting...'
            this.router.navigateByUrl('/')
            return
        }
      }else {
        this.status = 'Server sync'
        const res = await this.apiService.miscRequest<InitSessionRequest, InitSessionResponse>({ endpoint: '/initSession', body: data, responseType: InitSessionResponse })
        this.memoryStorageService.mc1PlanogramId = res.mc1PlanogramId
        this.memoryStorageService.mc1PlanogramName = res.mc1PlanogramName
        this.memoryStorageService.syncOnlyEditedImages = res.syncOnlyEditedImages
        this.status = 'Done. Redirecting...'
        this.router.navigateByUrl('/')
        return
      }
      
      this.memoryStorageService.routerLink = data.sessions.menuIRE
    } catch (err: any) {
      this.showError(err)
    }
  }

  private showError(err: any) {
    console.error(err)
    this.status = err.error?.message ?? err.message
    this.isError = true
    this.spinner.show(undefined, { color: 'rgba(0, 0, 0, 0)' })
  }
}
