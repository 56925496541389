import { Injectable } from "@angular/core"
import { CreateApiKeyRequest, CreateApiKeyResponse, ListApiKeyResponse } from "src/app/shared/api-structures/superAdmin"
import { ApiService } from "src/app/shared/services/api.service"


@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {

  constructor(private api: ApiService) { }

  async createApiKey(obj: CreateApiKeyRequest) {
    const response = await this.api.superRequest<CreateApiKeyRequest, CreateApiKeyResponse>({
      endpoint: '/createApiKey',
      body: obj,
    })
    return response
  }

  async deleteApiKey(id: string) {
    const response = await this.api.superRequest({
      endpoint: '/deleteApiKey',
      body: { id }
    })
    return response
  }

  async listApiKeys() {
    const response = await this.api.superRequest({
      endpoint: '/listApiKeys',
      responseType: ListApiKeyResponse
    })
    return response
  }
}
