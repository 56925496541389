<div>
  <div class="import-header">
    <h1 mat-dialog-title>
      {{ "Importing" | translate }} {{ data.title | translate }}
    </h1>
    <div>
      <mat-icon
        mat-dialog-close
        aria-hidden="false"
        fontIcon="close"
      ></mat-icon>
    </div>
  </div>

  <div mat-dialog-content class="import-container">
    <div *ngIf="data.template" class="template-wrapper">
      <div class="template-wrapper">
        <mat-icon aria-hidden="false" fontIcon="error"></mat-icon>
        <span class="template-span">
          <span>{{ "Warning" | translate }}:</span>
          {{ "WarmingImport" | translate }}
        </span>
      </div>
      <button
        class="download-template"
        tabindex="-1"
        mat-flat-button
        color="secondary"
        (click)="download(data.template)"
      >
        {{ "DownloadTemplate" | translate }}
      </button>
    </div>

    <div class="drag-drop-wrapper">
      <drag-and-drop-files
        accept=".csv"
        isMultipleFiles="false"
        (onFileSelected)="onFileSelected($event)"
      ></drag-and-drop-files>
    </div>

    <div align="end">
      <button
        style="margin-right: 16px"
        mat-flat-button
        mat-button
        mat-dialog-close
        class="import-button primary"
      >
        {{ "Cancel" | translate }}
      </button>
      <button
        [disabled]="fileData.length == 0"
        mat-flat-button
        mat-button
        mat-dialog-close
        class="import-button secondary"
        color="primary"
        (click)="emitImportEvent()"
      >
        {{ "Import" | translate }}
      </button>
    </div>
  </div>
</div>
