import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PriceTargetService } from 'src/app/admin/services/price-target.service';
import { PriceTargetFull } from 'src/app/shared/api-structures/misc/priceTarget';
import { SelectTableFetchFunction } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-price-target-dialog',
  templateUrl: './price-target-dialog.component.html',
  styleUrls: ['./price-target-dialog.component.scss']
})
export class PriceTargetDialogComponent implements OnInit {

  constructor(private priceTargetService: PriceTargetService, @Inject(MAT_DIALOG_DATA) public data: { selectPriceTarget: (data: { id: string, name: string }) => void }) { }

  ngOnInit(): void {
  }

  fetchPriceTargets: SelectTableFetchFunction<PriceTargetFull> = async ({ page, pageSize, textFilter, sortDirection, sortBy }) => {
    const sortByPT = sortBy as keyof PriceTargetFull
    const res = await this.priceTargetService.fetchPriceTarget({
      currentPage: page, pageSize, textFilter, sort: sortDirection || 'desc', sortBy: sortByPT
    })
    return res
  }

}
