import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { CustomFields, CustomFieldValue } from '../../api-structures/common';
import { CustomFieldSettingsWithId } from '../../api-structures/admin/customFieldSettings';

export type CustomField = {
  fieldName: string,
  value: CustomFieldValue
}

@Component({
  selector: 'custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})

export class CustomFieldsComponent implements OnInit {

  @Input() fieldsSettings: CustomFieldSettingsWithId[]
  @Input() customFieldsValues: CustomFields = {}
  @Input() setDefaultValue: boolean = false
  @Output() formValue = new EventEmitter<CustomFields>()

  form = new FormGroup({})
  formSubscribe = null

  constructor() {
  }

  ngOnInit(): void {
    this.setupFields()
  }

  setupFields() {
    this.fieldsSettings.forEach(field => {
      let val: CustomFieldValue

      if (this.setDefaultValue) {
        val = field.defaultValue
      }

      const csValue = this.customFieldsValues[field.name]
      if (csValue !== undefined || csValue !== null) {
        val = csValue
      }

      this.form.addControl(field.name, new FormControl(val))

    })
    this.formSubscribe = this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(v => {
        this.onChange()
      })

  }

  ngOnDestory() {
    this.formSubscribe.unsubscribe()
  }

  onChange() {
    const res = Object.entries(this.form.value).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {})
    this.formValue.emit(res as CustomFields)
  }

}
