// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace
import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsIn, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { BulkAddResponseBase, BulkChangeBase, BulkChangeResponseBase } from '../bulk'
import { SortDirectionArray, SortDirection } from '../common'
import { DateType } from '../customDecorators'
import { ProductImageCatalog } from './catalogStructure'
import { Brand } from './productBrand'
import { CatalogWithId } from './catalog'

export class ProductCategory {
    @Expose() @IsString() id!: string
    @Expose() @IsString() @IsOptional() name?: string
}

export class SimilarityGroup {
    @Expose() @IsString() id!: string
    @Expose() @IsString() name!: string
}

export class Product {
    @Expose() @IsString() mc1Id!: string
    @Expose() @IsBoolean() isCompetitors!: boolean
    @Expose() @IsBoolean() @IsOptional() inactive?: boolean
    @Expose() @IsString() name!: string
    @Expose() @IsString() @IsOptional() ean?: string
    @Expose() @IsNumber() totalImageCatalogs!: number
    @Expose() @IsNumber() totalImages!: number
    @Expose() @DateType() @IsDate() updatedAt!: Date
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) categoriesNames!: string[]
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) brandNames!: string[]
    @Expose() @IsString() brandId?: string
    @Expose() @Type(() => ProductCategory) @ValidateNested() category!: ProductCategory
    @Expose() @IsNumber() qtUsedInPlanograms!: number
    @Expose() @IsNumber() width?: number
    @Expose() @IsNumber() height?: number
    @Expose() @IsString() thumbnailUrl?: string
}

export class BaseProduct {
    @Expose() @IsString() name!: string
    @Expose() @IsString() @IsOptional() externalId?: string
    @Expose() @IsString() @IsOptional() ean?: string
    @Expose() @IsBoolean() isCompetitors!: boolean
    @Expose() @IsBoolean() @IsOptional() inactive?: boolean
    @Expose() @Type(() => ProductCategory) @ValidateNested() category!: ProductCategory
    @Expose() @Type(() => ProductCategory) @ValidateNested() @IsOptional() brand?: Brand
    @Expose() @Type(() => SimilarityGroup) @ValidateNested() @IsOptional() similarityGroup?: SimilarityGroup | null
    @Expose() @IsString() @IsOptional() source?: string
    @Expose() @IsNumber() @IsOptional() width?: number
    @Expose() @IsNumber() @IsOptional() height?: number
}

export class EditProductRequest {
    @Expose() @IsString() id!: string
    @Expose() @IsString() @IsOptional() name?: string
    @Expose() @IsBoolean() @IsOptional() isCompetitors?: boolean
    @Expose() @IsString() @IsOptional() externalId?: string
    @Expose() @IsString() @IsOptional() ean?: string
    @Expose() @IsBoolean() @IsOptional() inactive?: boolean
    @Expose() @Type(() => ProductCategory) @ValidateNested() @IsOptional() category?: ProductCategory
    @Expose() @Type(() => ProductCategory) @ValidateNested() @IsOptional() brand?: Brand
    @Expose() @Type(() => SimilarityGroup) @ValidateNested() @IsOptional() similarityGroup?: SimilarityGroup
    @Expose() @IsString() @IsOptional() source?: string
    @Expose() @IsNumber() @IsOptional() width?: number
    @Expose() @IsNumber() @IsOptional() height?: number
}

export class BaseProductWithId extends BaseProduct {
    @Expose() @IsString() id!: string
}
export class ProductWithCatalog extends BaseProductWithId {
    @Expose() @Type(() => CatalogWithId) @ValidateNested() @IsArray() imageCatalogs!: CatalogWithId[]
}

export class AddProductRequest extends BaseProduct { }

export class BulkAddProductsRequest {
    @Expose() @IsString() entity!: string
    @Expose() @ValidateNested() @Type(() => BaseProduct) @IsArray() data!: BaseProduct[]
}

export class BulkAddProductReport {
    @Expose() @IsString() error!: string
    @Expose() @ValidateNested() @Type(() => BaseProduct) item!: BaseProduct
}

export class ChangeProductEntity extends BulkChangeBase {
    @Expose() @ValidateNested() @Type(() => BaseProduct) data?: BaseProduct
}

export class BulkAddProductsResponse extends BulkAddResponseBase {
    @Expose() @ValidateNested() @Type(() => BulkAddProductReport) @IsArray() report!: BulkAddProductReport[]
}

export class BulkChangeProductReport {
    @Expose() @IsString() error!: string
    @Expose() @ValidateNested() @Type(() => ChangeProductEntity) item!: ChangeProductEntity
}

export class BulkChangeProductsRequest {
    @Expose() @ValidateNested() @Type(() => ChangeProductEntity) @IsArray() data!: ChangeProductEntity[]
}

export class BulkChangeProductsResponse extends BulkChangeResponseBase {
    @Expose() @ValidateNested() @Type(() => BulkChangeProductReport) @IsArray() report!: BulkChangeProductReport[]
}

export class GetProductResponse {
    @Expose() @IsNumber() totalProducts!: number
    @Expose() @Type(() => Product) @ValidateNested() @IsArray() @IsOptional() products?: Product[]
    @Expose() @Type(() => ProductImageCatalog) @ValidateNested() @IsArray() @IsOptional() imageCatalogs?: ProductImageCatalog[]
}

export namespace SearchProducts {

    export class Item {
        @Expose() @IsString() productId!: string
        @Expose() @IsString() @IsOptional() thumbnailUrl?: string
        @Expose() @IsString() name!: string
        @Expose() @IsString() isCompetitors!: boolean
        @Expose() @IsBoolean() @IsOptional() inactive?: boolean
        @Expose() @IsString() @IsOptional() ean?: string
        @Expose() @IsString() @IsOptional() category?: string
        @Expose() @IsString() @IsOptional() brand?: string
        @Expose() @IsNumber() totalImageCatalogs!: number
        @Expose() @IsNumber() totalImages!: number
        @Expose() @DateType() @IsDate() updatedAt!: Date
        @Expose() @IsString() @IsOptional() family?: string
        @Expose() @Type(() => ProductImageCatalog) @IsOptional() @ValidateNested() @IsArray() imageCatalogs?: ProductImageCatalog[]
        @Expose() @IsNumber() qtUsedInPlanograms!: number
    }

    export class Response {
        @Expose() @IsNumber() totalProducts!: number
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

export namespace SearchProductsForPlanogramPlanning {

    export class ImageCatalog {
        @Expose() @IsString() id!: string
        @Expose() @IsString() thumbnailUrl!: string
        @Expose() @IsString() name!: string
        @Expose() @IsOptional() @IsNumber() width?: number
        @Expose() @IsOptional() @IsNumber() height?: number
        @Expose() @IsBoolean() @IsOptional() isTrained?: boolean
        @Expose() @IsBoolean() @IsOptional() filledHeightWidth?: boolean
        @Expose() @DateType() @IsDate() lastUpdate!: Date
    }

    export class Item {
        @Expose() @IsString() productId!: string
        @Expose() @IsString() name!: string
        @Expose() @Type(() => ImageCatalog) @ValidateNested() @IsArray() imageCatalogs!: ImageCatalog[]
        @Expose() @IsBoolean() isCompetitors!: boolean
        @Expose() @IsString() category!: string
        @Expose() @IsString() @IsOptional() brand?: string
        @Expose() @IsString() @IsOptional() ean?: string
    }

    export class Response {
        @Expose() @IsNumber() totalProducts!: number
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

export class SearchProductsRequestFilters {
    @Expose() @IsString() minimumImages?: string
    @Expose() @IsString() maximumImages?: string
    @Expose() @IsString() minimumCatalogs?: string
    @Expose() @IsString() maximumCatalogs?: string
    @Expose() @IsString() updatedAtStart?: string
    @Expose() @IsString() updatedAtEnd?: string
    @Expose() @IsString() category?: string
    @Expose() @IsString() brand?: string
    @Expose() @IsBoolean() isUsedInPlanogram?: boolean
    @Expose() @IsBoolean() productStatus?: boolean
    @Expose() @IsBoolean() isCompetitors?: boolean
    @Expose() @IsBoolean() othersProducts?: boolean
}

export class SearchProductsRequestFiltersPlanogram {
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) @IsOptional() categories?: string[]
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) @IsOptional() brands?: string[]
    @Expose() @IsBoolean() @IsString() isCompetitor?: string
}

export class SearchProductsRequest {
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) @IsOptional() filters?: string[]
    @Expose() @Type(() => SearchProductsRequestFilters) @IsOptional() newFilters?: SearchProductsRequestFilters
    @Expose() @Type(() => SearchProductsRequestFiltersPlanogram) @IsOptional() modalFilters?: SearchProductsRequestFiltersPlanogram
    @Expose() @IsNumber() currentPage!: number
    @Expose() @IsNumber() pageSize!: number
    @Expose() @IsIn(['', 'none', 'ean', 'name', 'type', 'categories', 'brands', 'qtUsedInPlanograms', 'updatedAt', 'totalImageCatalogs', 'totalImages']) @IsOptional() sortBy?: '' | 'none' | 'ean' | 'name' | 'type' | 'categories' | 'qtUsedInPlanograms' | 'updatedAt' | 'totalImageCatalogs' | 'totalImages'
    @Expose() @IsIn(SortDirectionArray) @IsOptional() sortDirection?: SortDirection
    @Expose() @IsBoolean() @IsOptional() onlyWithImageCatalog?: boolean
    @Expose() @IsString() @IsOptional() productQuery?: string
    @Expose() @IsString() @IsOptional() categoriesQuery?: string
    @Expose() @IsBoolean() @IsOptional() includeImageCatalogs?: boolean
}

export class SearchProductsResponse extends GetProductResponse { }

export class ProductsByLabelsResponse {
    @Expose() @IsNumber() totalProducts!: number
    @Expose() @Type(() => ProductWithCatalog) @ValidateNested() @IsArray() items!: ProductWithCatalog[]
}

export class ExportResponse {
    @Expose() @IsString() url!: string
}

export class ImportRequest {
    @Expose() @IsString() url!: string
}


export class CountAllProductsResponse {
    @Expose() @IsNumber() totalProducts!: number
    @Expose() @IsNumber() diff!: number
}

export class RecalculateAmountOfImagesAndCatalogsResponse {
    @Expose() @Type(() => RecalculateAmountOfImagesAndCatalogsItem) @ValidateNested() @IsArray() items!: RecalculateAmountOfImagesAndCatalogsItem[]
}
export class RecalculateAmountOfImagesAndCatalogsItem {
    @Expose() @IsNumber() productId!: string
    @Expose() @IsNumber() totalImages!: number
    @Expose() @IsNumber() totalImageCatalogs!: number
}
