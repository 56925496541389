import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ListClusterCustomersRequest, ListClusterCustomersResponse } from 'src/app/shared/api-structures/admin/cluster';
import { AddCustomerRequest, AddCustomerResponse, ListCustomersAttributesResponse, ListCustomersByAreaRequest, ListCustomersCategoriesResponse, ListCustomersRequest, ListCustomersResponse, UpdateCustomerRequest, UpdateCustomerResponse } from 'src/app/shared/api-structures/admin/customer';
import { ExportResponse, ImportRequest } from 'src/app/shared/api-structures/admin/user';
import { ByIdRequest } from 'src/app/shared/api-structures/common';
import { ListAreasResponse } from 'src/app/shared/api-structures/misc/area';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private listCustomers = new Subject<ListCustomersResponse>()
  private listCustomersRequest = new Subject<ListCustomersRequest>()

  listCustomers$ = this.listCustomers.asObservable()
  listCustomersRequest$ = this.listCustomersRequest.asObservable()

  constructor(private api: ApiService, private auth: AuthService, private languageService: LanguageService) {
  }

  async addCustomer(customer: AddCustomerRequest) {
    const response = await this.api.adminRequest({
      endpoint: '/addCustomer',
      body: customer,
      context: this.auth.context,
      responseType: AddCustomerResponse
    })
    return response
  }

  async deleteCustomer(id: ByIdRequest) {
    await this.api.adminRequest({
      endpoint: '/deleteCustomer',
      body: id,
      context: this.auth.context
    })
  }

  async updateCustomer(body: UpdateCustomerRequest) {
    const response = await this.api.adminRequest({
      endpoint: '/updateCustomer',
      body,
      context: this.auth.context,
      responseType: UpdateCustomerResponse
    })
    return response
  }

  async importCustomers(url: string) {
    const result = await this.api.adminRequest<ImportRequest>({
      endpoint: '/importCustomers',
      body: { url },
      errorMessage: this.languageService.translateSync('CorruptFilePleaseCheck')
    })

    return result
  }
  
  async exportCustomers() {
    const result = await this.api.adminRequest({
      endpoint: '/exportCustomers',
      responseType: ExportResponse,
      errorMessage: this.languageService.translateSync('ErrorOnExport')
    })

    return result
  }

  async loadCustomers(request: ListCustomersRequest, isHideProgress: boolean = false) {
    const data = await this.api.adminRequest({
      endpoint: '/listCustomers',
      body: request,
      context: this.auth.context,
      responseType: ListCustomersResponse
    }, { isHideProgress })
    this.listCustomers.next(data)
    return data
  }

  async loadAreaCustomers(request: ListCustomersByAreaRequest, isHideProgress: boolean = false) {
    const data = await this.api.adminRequest({
      endpoint: '/listAreaCustomers',
      body: request,
      context: this.auth.context,
      responseType: ListCustomersResponse
    }, { isHideProgress })
    this.listCustomers.next(data)
    return data
  }

  async listPolygonsInCustomer(customerId: ByIdRequest) {
    const data = await this.api.adminRequest({
      endpoint: '/listPolygonsInCustomer',
      body: customerId,
      responseType: ListAreasResponse
    })
    return data
  }

  async listCustomersCategories(isHideProgress: boolean = true) {
    const data = await this.api.adminRequest({
      endpoint: '/listCustomersCategories',
      context: this.auth.context,
      responseType: ListCustomersCategoriesResponse
    }, { isHideProgress })
    return data
  }

  async listCustomersAttributes(isHideProgress: boolean = true) {
    const data = await this.api.adminRequest({
      endpoint: '/listCustomersAttributes',
      context: this.auth.context,
      responseType: ListCustomersAttributesResponse
    }, { isHideProgress })
    return data
  }

  async listClusterCustomers(data: ListClusterCustomersRequest) {
    const result = await this.api.adminRequest<ListClusterCustomersRequest, ListClusterCustomersResponse>({
      endpoint: '/listClusterCustomers',
      body: data
    })

    return result
  }

}