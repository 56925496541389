import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClusterWithId } from 'src/app/shared/api-structures/admin/cluster';

@Component({
  selector: 'associated-clusters-card',
  templateUrl: './associated-clusters-card.component.html',
  styleUrls: ['./associated-clusters-card.component.scss']
})
export class AssociatedClustersCardComponent implements OnInit {

  // @Input() data:
  @Input() selected = false
  @Input() id: string
  @Input() data: ClusterWithId
  @Input() areas: { name: string, id: string }[] = []

  @Output() onToggleSelect = new EventEmitter<string>()
  areaNames: string[] = []

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.areas.length) {
      this.areaNames = this.areas.filter(area => this.data.regions.includes(area.id)).map(area => area.name)
    }
  }

  emitToggleSelect() {
    this.onToggleSelect.emit(this.id)
  }

}
