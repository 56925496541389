import {
  AfterViewInit, Component, EventEmitter, Output, ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ListUsers } from 'src/app/shared/api-structures/admin/user';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SuperUserManagementService } from 'src/app/super-admin/services/super-user-management.service';
@Component({
  selector: 'super-users-list',
  templateUrl: './super-users-list.component.html',
  styleUrls: ['./super-users-list.component.scss'],
})
export class SuperUsersListComponent extends AutoDestroy implements AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @Output() getUsersRequest = new EventEmitter();

  dataSource = new MatTableDataSource<ListUsers.Item>();
  columnsToDisplay = ['name', 'email', 'context', 'phone', 'role', 'deleteIcon'];

  constructor(private superUserManagementService: SuperUserManagementService, private dialog: MatDialog,
    private languageService: LanguageService,) {
    super();
    this.subscriptions.push(this.superUserManagementService.listUsers$.subscribe(d => {
      this.dataSource.data = d;
      this.dataSource.sort = this.sort;
    }));
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  searchUsers(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  setupFilter() {
    this.dataSource.filterPredicate = (data: ListUsers.Item, filter: string) => {
      return data.name.toLowerCase().includes(filter) || data.email.toLowerCase().includes(filter) ||
        data.phone.toLowerCase().includes(filter) || data.role.toLowerCase().includes(filter);
    };
  }

  @Output() search = new EventEmitter<string>();
  users: ListUsers.Item[]

  userToDelete: ListUsers.Item = {
    name: '', email: '',
    id: '',
    role: 'mobile',
  }

  ondeleteClick(id: string, name: string, email: string, phone: string) {
    this.userToDelete.id = id
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `${this.languageService.translateSync('ConfirmDeletionTitle')}`, message: `"${email ?? phone ?? id}" ${this.languageService.translateSync('ConfirmDeleteMessage')}`
      }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) { return } this.deleteUser()
    })
  }

  async deleteUser() {
    await this.superUserManagementService.deleteUser({ id: this.userToDelete.id })
    this.getUsersRequest.emit()
  }

  getCountryCode(phone: string): string {
    const countryCodes: { [key: string]: string } = {
      '1': 'us',
      '44': 'gb',
      '61': 'au',
      '64': 'nz',
      '27': 'za',
      '52': 'mx',
      '57': 'co',
      '55': 'br',
      '54': 'ar',
      '56': 'cl',
      '51': 'pe',
      '20': 'eg',
      '966': 'sa',
      '971': 'ae',
      '212': 'ma',
      '963': 'sy',
      '30': 'gr',
      '91': 'in',
    };

    const numericPhone = phone.replace(/\D/g, '');

    for (const code in countryCodes) {
      if (numericPhone.startsWith(code)) { return countryCodes[code]; }
    }
    return 'unknown';
  }


}
