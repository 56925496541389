import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CountImagesToApproveResponse, ListImageCatalogsWithImagesToApprove } from 'src/app/shared/api-structures/misc/toApprove';
import { Dal } from '../../../dal/dal';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-unapproved-catalogs',
  templateUrl: './unapproved-catalogs.component.html',
  styleUrls: ['./unapproved-catalogs.component.scss']
})
export class UnapprovedCatalogsComponent implements OnInit {

  @Output() onImageCountChange = new EventEmitter<CountImagesToApproveResponse>()

  imageCatalogs: ListImageCatalogsWithImagesToApprove.ResponseStructre[] = []
  filteredImageCatalogs: ListImageCatalogsWithImagesToApprove.ResponseStructre[] = []
  productName: string
  productId: string
  readonly filterSeparatorKeysCodes: number[] = [ENTER, COMMA]
  filters: string[] = []
  filterShowSpinner: false
  isInfiniteScrollDisabled: boolean = false
  areEnoughImages: boolean

  constructor(private utilitiesService: UtilitiesService, private snackbarService: SnackbarService, private router: Router, private dal: Dal, private languageService: LanguageService) { }

  ngOnInit() {
    let urlParams = this.utilitiesService.getUrlParams(window.location.href)
    if (!urlParams['product_id']) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('InvalidUrl'))
      this.router.navigateByUrl('/unapproved')
    }

    this.productId = urlParams['product_id']
    this.productName = decodeURIComponent(urlParams['product_name']) || ''
    this.areEnoughImages = urlParams['are_enough_images']
    const checkCatalogs = urlParams['check_catalogs']
    this.getCatalogs(checkCatalogs)
  }

  async getCatalogs(checkCatalogs?: boolean) {
    const response = await this.dal.listImageCatalogsWithImagesToApprove({ productId: this.productId })
    if (checkCatalogs && response.items.length === 0) {
      this.router.navigateByUrl('unapproved')
      return
    }
    this.imageCatalogs = response.items
    this.filteredImageCatalogs = this.filters.length === 0 ? this.imageCatalogs : this.imageCatalogs.filter(catalog => {
      return this.filters.some(filter => catalog.imageCatalogName.includes(filter) || catalog.imageCatalogId.includes(filter))
    })
  }

  addFilter(event: MatChipInputEvent) {
    const input = event.input
    const value = event.value

    if ((value || '').trim()) {
      this.filters.push(value.trim())
      this.getCatalogs()
    }

    // Reset the input value
    if (input) {
      input.value = ''
    }
  }

  removeFilter(filter: string) {
    const index = this.filters.indexOf(filter)

    if (index >= 0) {
      this.filters.splice(index, 1)
      this.getCatalogs()
    }
  }

  isMatTooltipDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }
}
