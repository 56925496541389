<page-layout title="{{'PriceTargets' | translate}}" description="{{'PriceTargetDescription' | translate}}">
  <page-layout-header>
    <generic-import [data]="importDialogData" (onImport)="listPriceTargets()"
      style="margin-right: 8px;"></generic-import>
    <button mat-button class="button-desc" routerLink="/admin/manage-price-target"
      [queryParams]="{mode: 'new'}"><mat-icon>add</mat-icon> {{'AddNew' | translate}}
    </button>
  </page-layout-header>

  <table-layout>
    <table-layout-caption>
      <div class="py-2">
        <span class="title-square">{{'ProductsList' | translate}}</span>
      </div>
      <div>
        <mat-form-field class="aligned">
          <input matInput placeholder="{{'Search'|translate}}" (keyup.enter)="onFilter($event)">
        </mat-form-field>
      </div>
    </table-layout-caption>

    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> {{'Image' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <div style="margin: 8px 16px 0px 0px; text-align: -webkit-center;">
            <img style="height: 32px;" src="{{element.thumbnailUrl}}" alt="">
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="productInfo">
        <th mat-header-cell *matHeaderCellDef> {{'ProductInfo' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex; flex-direction: column;">
            <span>{{element.productName}}</span>
            <span class="productId-info">{{element.productId}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="minPrice">
        <th mat-header-cell *matHeaderCellDef> {{'MinPrice' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.minPriceLabel}} </td>
      </ng-container>

      <ng-container matColumnDef="basePrice">
        <th mat-header-cell *matHeaderCellDef> {{'Price' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.priceLabel}} </td>
      </ng-container>

      <ng-container matColumnDef="maxPrice">
        <th mat-header-cell *matHeaderCellDef> {{'MaxPrice' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.maxPriceLabel}} </td>
      </ng-container>

      <ng-container matColumnDef="customerArea">
        <th mat-header-cell *matHeaderCellDef> {{'CustomerArea' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.customerArea}} </td>
      </ng-container>

      <ng-container matColumnDef="customerTerritory">
        <th mat-header-cell *matHeaderCellDef> {{'CustomerTerritory' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.customerTerritory}} </td>
      </ng-container>

      <ng-container matColumnDef="customerCategory">
        <th mat-header-cell *matHeaderCellDef> {{'CustomerCategory' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.customerCategory}} </td>
      </ng-container>

      <ng-container matColumnDef="customerChain">
        <th mat-header-cell *matHeaderCellDef> {{'CustomerChain' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.customerChain}} </td>
      </ng-container>

      <ng-container matColumnDef="dateFrom">
        <th mat-header-cell *matHeaderCellDef> {{'DateFrom' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element?.dateFrom? moment(element.dateFrom).format('L'): '' }} </td>
      </ng-container>

      <ng-container matColumnDef="dateTo">
        <th mat-header-cell *matHeaderCellDef> {{'DateTo' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element?.dateTo ? moment(element.dateTo).format('L') : ''}} </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdate">
        <th mat-header-cell *matHeaderCellDef> {{'LastUpdate' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{moment(element.lastUpdate).format('L') }} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> {{'CreatedAt' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{moment(element.createdAt).format('L') }} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-button routerLink="/admin/manage-price-target" [queryParams]="{mode: 'edit', id: row.id}">
            <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="copy">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-button routerLink="/admin/manage-price-target" [queryParams]="{mode: 'new', id: row.id}">
            <mat-icon fontSet="fa" fontIcon="fa-copy" style="color:#919191"></mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-button (click)="onDelete(row.id)">
            <mat-icon fontSet="fa" fontIcon="fa-trash" style="color:#919191"></mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsCustomer"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsCustomer;"></tr>
    </table>

    <table-layout-footer>
      <div class="flex justify-content-between align-content-center">
        <button mat-stroked-button class="button-export" (click)="exportPriceTargets()">{{'Export' | translate}}
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="totalItems" [pageSize]="query.pageSize"
          style="background-color: transparent;" [pageIndex]="query.currentPage" (page)="onPaginate($event)">
        </mat-paginator>
      </div>

    </table-layout-footer>
  </table-layout>
</page-layout>