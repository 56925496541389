<app-dialog title="SelectProduct" (onClose)="onDialogClose()" [width]="45" style="width: 65vw; min-height: 65vh;">
    <div class="flex flex-column justify-content-center align-items-center" style="margin-top: 15%;"
        *ngIf="!state.loading && paginatedData?.items.length <= 0;" Î>
        <img src="../../../../../assets/images/empty-box.png" style="width: 20%;" alt="">
        <span class="mt-3">{{'productNotFound' | translate}}</span>
        <button pButton label="{{'SeeAllProducts' | translate}}" class="p-button-text p-button-text-plain"
            (click)="clearFilters()"></button>
    </div>

    <div *ngIf="!state.loading && paginatedData?.items.length > 0; ">
        <div class="">
            <div class="flex flex-column">
                <div class="p-input-icon-left">
                    <i class="pi pi-search text-primary"></i>
                    <input type="text" pInputText placeholder="{{'Search' | translate}}"
                        class="p-inputtext-sm filled b-0" [(ngModel)]="filters.srcValue"
                        (keyup.enter)="getProducts({filters: [filters.srcValue]})" />

                    <i class="pi pi-times clear-input text-muted" *ngIf="filters.srcValue !== ''"
                        (click)="filters.srcValue = '' "></i>
                </div>

                <div class=" flex align-items-center py-3">
                    <small class="text-muted mr-2">{{ 'Filters' | translate |uppercase }}</small>
                    <p-dropdown styleClass="ml-2 p-dropdown-xs" optionLabel="name"
                        placeholder="{{'Category' | translate}}" [options]="categoryList" [showClear]="true"
                        [filter]="true" filterBy="name" [(ngModel)]="filters.category"
                        (onChange)="getProducts({filters: [$event?.value?.name]})">
                    </p-dropdown>

                    <p-dropdown optionLabel="name" styleClass="ml-2 p-dropdown-xs" placeholder="{{'Brand' | translate}}"
                        [showClear]="true" [filter]="true" filterBy="name" [(ngModel)]="filters.brand"
                        [options]="brandList" (onChange)="getProducts({filters: [$event?.value?.name]})" Ï>
                    </p-dropdown>
                </div>
            </div>

            <div class="row flex align-items-center px-2">
                <ng-container *ngFor="let product of paginatedData.items;  index as i">
                    <div *ngIf="!!product?.name" class="col-sm-3 p-1" (click)="onSelectProduct(product)">
                        <div class="product-card"
                            [ngClass]="{'selected': product?.productId === selectedProduct?.productId}">

                            <div class="image" *ngIf="product.thumbnailUrl as image">
                                <img [src]="image" alt="" />
                            </div>
                            <div class="text-box ml-2 flex flex-grow-1 flex-column">
                                <span class="product-title" [pTooltip]="product?.name"
                                    tooltipPosition="bottom">{{product?.name.truncate(18)}}
                                </span>
                                <small class="text-muted mb-2" [pTooltip]="!!product?.ean ? product?.ean : product?.productId"
                                    tooltipPosition="bottom">{{!!product?.ean ? product?.ean : product?.productId}}
                                </small>
                                <div>
                                    <button pButton label="My Product"
                                        class="p-button-rounded p-button-text filled text-small"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-template #footer>
            <div class="row flex align-items-center">
                <div class="col-sm-7 flex justify-content-start">
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="paginatedData.totalProducts"
                        [pageSize]="state.pageSize" [pageIndex]="state.currentPage" (page)="onPaginate($event)"
                        style="background-color: transparent;">
                    </mat-paginator>
                </div>

                <div class="col-sm-5">
                    <button pButton class="p-button-outlined" [label]="'CancelLower' | translate"
                        (click)="onDialogClose()"></button>
                    <button pButton class="p-button-sm" label="{{'Confirm' | translate}}"
                        (click)="onSelectProduct(selectedProduct)"></button>
                </div>
            </div>

        </ng-template>
    </div>

</app-dialog>