import { EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import jwt_decode from 'jwt-decode';
import { MemoryStorageService } from "src/app/services/memory-storage.service";
import arLanguage from '../../../assets/i18n/ar.json';
import enLanguage from '../../../assets/i18n/en.json';
import esLanguage from '../../../assets/i18n/es.json';
import ptLanguage from '../../../assets/i18n/pt.json';
import elLanguage from '../../../assets/i18n/el.json';
import { unique } from "underscore";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LanguageService {
    private languageChangedSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);

    onLanguageChanged: Observable<void> = this.languageChangedSubject.asObservable();

    constructor(private translateService: TranslateService, private memoryStorageService: MemoryStorageService) { }

    translateSync(key: string) {
        const result = this.translateService.instant(key)
        return result
    }

    getLanguageFromToken(token: string) {
        const { cIDLanguage } = jwt_decode<{ cIDLanguage: string }>(token)
        return cIDLanguage
    }

    setLanguage(languageId?: string, languageDesc?: string) {

        const translations = this.getTranslations()
        const forceLanguage = this.memoryStorageService.language
        let language = 'en'
        if (forceLanguage && translations.find(t => t.language === forceLanguage)) {
            language = forceLanguage
        } else if (languageId) {
            language = translations.find(v => v.id === languageId).language
        } else if (languageDesc) {
            language = languageDesc
        }

        const translation = translations.find(v => v.language === language)
        this.translateService.setTranslation(translation.language, translation.translation)
        this.translateService.setDefaultLang(translation.language)

        this.languageChangedSubject.next();
    }

    getLanguage() {
        const lang = this.translateService.getDefaultLang()
        return lang
    }

    checkMissingTranslations() {
        const langs = [
            { key: 'en', file: enLanguage, missing: {} },
            { key: 'pt', file: ptLanguage, missing: {} },
            { key: 'es', file: esLanguage, missing: {} },
            { key: 'ar', file: arLanguage, missing: {} },
            { key: 'el', file: elLanguage, missing: {} }
        ]

        const keys = unique(langs.reduce((acc, curr) => [...acc, ...Object.keys(curr.file)], []))

        langs.forEach(lang => {
            keys.forEach(key => {
                if (!lang.file[key]) {
                    lang.missing[key] = langs[0].file[key] || ''
                }
            })

            if (Object.keys(lang.missing).length) {
                console.warn("missing translates ", lang.key)
                let str = ''
                Object.entries(lang.missing).forEach(([key, value]) => {
                    str += `"${key}": "${value}",\n`
                })
                console.log(str)
            }
        })
    }

    getTranslations() {
        const result: { id: string, language: string, translation: any }[] = [
            {
                id: '1',
                language: 'pt',
                translation: ptLanguage,
            },
            {
                id: '2',
                language: 'es',
                translation: esLanguage,
            },
            {
                id: '3',
                language: 'en',
                translation: enLanguage,
            },
            {
                id: '4',
                language: 'ar',
                translation: arLanguage,
            },
            {
                id: '5',
                language: 'el',
                translation: elLanguage,
            },
        ]

        return result
    }
}