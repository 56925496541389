<div style="padding:20px">

	<h2 mat-dialog-title>{{'Update'|translate}}
		<br />
	</h2>

	<mat-form-field>
		<input matInput placeholder="{{'externalId'|translate}}" [(ngModel)]="externalId">
	</mat-form-field>

	<mat-form-field>
		<input matInput placeholder="{{'Name'|translate}}" [(ngModel)]="name" required>
	</mat-form-field>

	<mat-form-field class="example-full-width">
		<mat-label>Category</mat-label>
		<input type="text" placeholder="Select category" aria-label="Select category" matInput [formControl]="categoryCtrl"
			[matAutocomplete]="autoCategory" [(ngModel)]="category" (click)="dropDownOptions('category')" required>
		<mat-autocomplete autoActiveFirstOption #autoCategory="matAutocomplete">
			<mat-option *ngFor="let option of filteredCategories  | async" [value]="option">
				{{option}}
			</mat-option>
		</mat-autocomplete>
		<mat-icon matSuffix *ngIf="isLoadingAttributes">
			<i class="fa fa-spinner fa-spin"></i>
		</mat-icon>
	</mat-form-field>

	<mat-form-field>
		<input matInput placeholder="{{'TaxId'|translate}}" [(ngModel)]="taxId" required>
	</mat-form-field>

	<mat-form-field>
		<input matInput placeholder="{{'CustomerCode'|translate}}" [(ngModel)]="customerCode" required>
	</mat-form-field>

	<address-input (setAddress)="onPlaceChanged($event)" [initialAddress]="address" required></address-input>

	<mat-form-field class="example-full-width">
		<mat-label>{{'Territory' | translate }}</mat-label>
		<input type="text" placeholder="Select Territory" aria-label="Select Territory" matInput
			(click)="dropDownOptions('territory')" [matAutocomplete]="autoTerritory" [formControl]="terrCtrl"
			[(ngModel)]="territory">
		<mat-autocomplete autoActiveFirstOption #autoTerritory="matAutocomplete">
			<mat-option *ngFor="let option of filteredTerritories | async" [value]="option">
				{{option}}
			</mat-option>
		</mat-autocomplete>
		<mat-icon matSuffix *ngIf="isLoadingAttributes">
			<i class="fa fa-spinner fa-spin"></i>
		</mat-icon>
	</mat-form-field>

	<mat-form-field class="example-full-width">
		<mat-label>Chain</mat-label>
		<input type="text" placeholder="Select Chain" aria-label="Select Chain" matInput (click)="dropDownOptions('chain')"
			[formControl]="chainCtrl" [matAutocomplete]="auto" [(ngModel)]="chain">
		<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
			<mat-option *ngFor="let option of filteredChains  | async" [value]="option">
				{{option}}
			</mat-option>
		</mat-autocomplete>
		<mat-icon matSuffix *ngIf="isLoadingAttributes">
			<i class="fa fa-spinner fa-spin"></i>
		</mat-icon>
	</mat-form-field>

	<custom-fields [customFieldsValues]="this.data.customFields" (formValue)="customFieldsChanged($event)"
		[fieldsSettings]="this.data.customFields"></custom-fields>

	<div mat-dialog-actions>
		<button mat-button color="primary" (click)="onCancelClick()" matTooltip="{{'CANCEL'|translate}}"><mat-icon
				color="primary">cancel</mat-icon></button>
		<button mat-button color="primary" cdkFocusInitial (click)="save()"
			matTooltip="{{'SaveChanges'|translate}}"><mat-icon color="primary">save</mat-icon></button>
	</div>
</div>