<div (click)="emitToggleSelect()" class="card" [ngClass]="{'selected': selected}">
    <div class="small-circle">
        <mat-icon>check</mat-icon>
    </div>
    <div class="title-field">{{data.name}}</div>
    <div class="field field-1">
        <span class="field-value" [title]="data.customerCode">{{data.customerCode || '- No Code -'}}</span>
    </div>
    <div class="field">
        <span class="field-value" [title]="data.address">{{data.address || '- No Address -'}}</span>
    </div>
    <div class="field">
        <span class="field-name">{{'Territory' | translate}}: </span><span class="field-value"
            [title]="data.territory">{{data.territory || '- None -'}}</span>
    </div>
    <div class="field">
        <span class="field-name">{{'Category' | translate}}: </span><span class="field-value"
            [title]="data.category">{{data.category || '- None -'}}</span>
    </div>
</div>