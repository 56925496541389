<div>
  <div *ngFor="let row of cardsValues">      
    <mat-card>
      <mat-card-content>
          <p>  
            <button *ngIf="row.id=='default'" disabled color="primary">
              <mat-icon>delete</mat-icon>
            </button> 
            <button *ngIf="row.id!=='default'" color="primary" (click)="delete(row.id)">
              <mat-icon matTooltip="Delete Item">delete</mat-icon>
            </button>
            <button *ngIf="row.isActive" color="primary">
              <mat-icon matTooltip="Make Active" color="primary">grade</mat-icon>
            </button>
            <button *ngIf="!row.isActive" (click)="makeActive(row.id)">
              <mat-icon>grade</mat-icon>
            </button>              
          </p>
          <p><b>{{'Id'|translate}}</b>: {{row.id}}</p>
          <p><b>{{'Name'|translate}}</b>: {{row.name}}</p>
          <p><b>{{'IsActive'|translate}}</b>: {{row.isActive}}</p>          
          <p><b>{{'ModelUrl'|translate}}</b>: {{row.modelUrl}}</p>
          <p><b>{{'LabelsUrl'|translate}}</b>: {{row.labelsUrl}}</p>
          <p *ngIf="row.priceModelUrl"><b>{{'PriceModelUrl'|translate}}</b>: {{row.priceModelUrl}}</p>
          <p *ngIf="row.priceLabelUrl"><b>{{'PriceLabelUrl'|translate}}</b>: {{row.priceLabelUrl}}</p>
          <p *ngIf="row.shelfModelUrl"><b>{{'ShelfModelUrl'|translate}}</b>: {{row.shelfModelUrl}}</p>
          <p *ngIf="row.shelfLabelUrl"><b>{{'ShelfLabelUrl'|translate}}</b>: {{row.shelfLabelUrl}}</p>
          <p *ngIf="row.shelfLabelUrl"><b>{{'OCRModelUrl'|translate}}</b>: {{row.ocrModelUrl}}</p>
          <p *ngIf="row.popModelUrl"><b>{{'PopModelUrl'|translate}}</b>: {{row.popModelUrl}}</p>
          <p *ngIf="row.popLabelUrl"><b>{{'PopLabelUrl'|translate}}</b>: {{row.popLabelUrl}}</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<!--
<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Id'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'IsActive'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.isActive">grade</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="modelUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ModelUrl'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.modelUrl}}</td>
    </ng-container>

    <ng-container matColumnDef="labelsUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'LabelsUrl'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.labelsUrl}}</td>
    </ng-container>

    

    <ng-container matColumnDef="priceModelUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PriceModelUrl'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.priceModelUrl}}</td>
    </ng-container>

    <ng-container matColumnDef="priceLabelUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PriceLabelUrl'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.priceLabelUrl}}</td>
    </ng-container>

    <ng-container matColumnDef="shelfModelUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ShelfModelUrl'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.shelfModelUrl}}</td>
    </ng-container>

    <ng-container matColumnDef="shelfLabelUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ShelfLabelUrl'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.shelfLabelUrl}}</td>
    </ng-container>

    

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="row.id!=='default'" mat-icon-button color="primary" (click)="delete(row.id)">
          <mat-icon>delete</mat-icon>
        </button>
        <button *ngIf="!row.isActive" mat-icon-button color="primary" (click)="makeActive(row.id)">
          <mat-icon color="primary" matTooltip="Make Active">grade</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
  </table>

  <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
    [pageSizeOptions]="[2, 5, 10, 20, 50]">
  </mat-paginator>
</div>

-->