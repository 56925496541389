interface String {
    truncate(maxLenght: number): string;
    capitalize(str: string): string
}

String.prototype.truncate = function (maxLenght: number): string {
    const value: string = this
    return value.length > maxLenght ? `${value.slice(0, maxLenght)}...` : value
}

String.prototype.capitalize = function (): string {
   
    const strArr = this.split(" ");

    const capitalize = (str: string) => {
        str = str.toLowerCase();
        if (str.length > 1) {
            return `${str.charAt(0).toUpperCase() + str.slice(1)} `;
        }
        return str;
    };

    const newStr = strArr.reduce((accu, cur) => {
        accu = capitalize(accu);
        accu += capitalize(cur);
        return accu;
    });

    return newStr;
}
