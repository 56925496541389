/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsIn, IsNumber, IsObject, IsOptional, IsString } from 'class-validator'
import ValidatorJS from 'validator'
import { DateType } from './customDecorators'
import { CollectionsType } from './collections'

export type CompanyType = 'Standalone' | 'MC1'
export const CompanyTypeArray: CompanyType[] = ['Standalone', 'MC1']

export type DataSplit = 'train' | 'val' | 'test' | 'unsorted'
export const DataSplitArray: DataSplit[] = ['train', 'val', 'test', 'unsorted']

export type SortDirection = 'asc' | 'desc'
export const SortDirectionArray: SortDirection[] = ['asc', 'desc']

export type SortField<T> = {
    field: keyof T
    dir?: SortDirection
}

export type Operator = '==' | '<=' | '>=' | 'in'

export type Collections = CollectionsType

export type Ctx = string

export class QueryFilter {
    @Expose() @IsString() field!: string
    @Expose() @IsIn(['==', '<=', '>=', 'in']) operator!: Operator
    @Expose() @IsString() value!: string
}

export const UrlOptions: ValidatorJS.IsURLOptions = {
    require_tld: false,
    require_protocol: true,
    allow_underscores: true,
}

export class ByActiveRequest {
    @Expose() @IsOptional() @IsBoolean() active?: boolean
}

export class ByIdRequest {
    @Expose() @IsString() id!: string
}

export class ByIdsRequest {
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) ids!: string[]
}

export class ByPageRequest {
    @Expose() @IsNumber() currentPage!: number
    @Expose() @IsNumber() pageSize!: number
}

export class StringResponse {
    @Expose() @IsString() response!: string
}

export class NumberResponse {
    @Expose() @IsNumber() response!: number
}

export class BaseStructure {
    @Expose() @IsString() id!: string
    @Expose() @IsObject() customFields?: CustomFields
    @Expose() @DateType() @IsDate() lastUpdate!: Date
    @Expose() @DateType() @IsDate() createdAt?: Date
}

export class LatLng {
    @Expose() @IsNumber() lat!: number
    @Expose() @IsNumber() lng!: number
}

export class Position {
    @Expose() @IsNumber() top!: number
    @Expose() @IsNumber() left!: number
    @Expose() @IsNumber() bottom!: number
    @Expose() @IsNumber() right!: number
}

export type CustomFieldValue = string | number | boolean | string[] | number[] | Date

export type CustomFields = Record<string, CustomFieldValue>

export class CountResponse {
    @Expose() @IsNumber() count!: number
}

