import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/shared/services/settings.service';

@Component({
  selector: 'chat-cpg',
  template: `
    <iframe class="full_iframe" [src]="url | safe" *ngIf="url"></iframe>
  `,
  styles: []
})
export class ChatCpgComponent implements OnInit {

  url: string

  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
    this.getUrl()
  }

  async getUrl() {
    const settings = await this.settingsService.getSettings()
    this.url = settings.chatCpgUrl
  }
}
