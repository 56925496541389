import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { UserCustomerService } from 'src/app/admin/services/user-customer.service';
import { Dal } from 'src/app/dal/dal';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CustomerWithId } from 'src/app/shared/api-structures/admin/customer';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { AddAssociatedUserToCustomerComponent } from '../../associated-users2/add-associated-user/add-associated-user.component';

type CustomerAssociationType = 'user' | 'planogram'

@Component({
  selector: 'add-associated-customer2',
  templateUrl: './add-associated-customer.component.html',
  styleUrls: ['./add-associated-customer.component.scss']
})
export class AddAssociatedCustomrComponent2 extends AutoDestroy {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger
  @Input() filter: string = ''
  @Input() id: string
  @Input() idType: CustomerAssociationType
  @Output() close = new EventEmitter()
  @Output() onCustomerSelected = new EventEmitter<string>()

  customerId: string
  customers: CustomerWithId[] = []
  totalUsers: number
  isLoadingCustomers: boolean = false


  constructor(
    private snackbarService: SnackbarService,
    private userCustomerService: UserCustomerService,
    private customerService: CustomerService,
    private dal: Dal,
  ) {
    super()
  }



  async getCustomers(pageSize: number, query: string) {
    this.isLoadingCustomers = true
    try {
      const usersRes = await this.customerService.loadCustomers({ currentPage: 0, pageSize, query }, true)
      this.customers = usersRes.items
    } finally {
      this.isLoadingCustomers = false
    }
  }

  async listCustomersForAutocomplete(value: string) {
    if (value.length > 2) {
      this.getCustomers(50, value.toLowerCase())
    } else {
      this.autocomplete.closePanel()
    }
  }

  async optionSelected(option) {
    this.customerId = option.value.id
    this.onCustomerSelected.emit(this.customerId)
  }

  emitFilter(filter: string) {
    this.onCustomerSelected.emit(filter)
    this.autocomplete.closePanel()
  }

  displayFn(customer: CustomerWithId): string {
    const result = customer.name ? (customer.externalId ?? '') + '  ' + customer.name + '  ' + customer.category : this.filter
    return result
  }

  async addAssociatedUsersToCustomer() {
    switch (this.idType) {
      case 'user': await this.userCustomerService.addUserCustomer({ customerId: this.customerId, userId: this.id })
        this.snackbarService.openSnackBar(2000, "User-Customer association was successfully added")
        break
      case 'planogram': await this.dal.addAssociatedCustomersToPlanogram({ planogramId: this.id, customerId: this.customerId })
        this.snackbarService.openSnackBar(2000, "Customer-Planogram association was successfully added")
    }
    this.filter = ''
    this.close.emit()
  }

  onCancelClick() {
    this.close.emit()
  }
}