import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StaticModelsService } from 'src/app/admin/services/static-models.service';
import { ListStaticModel } from 'src/app/shared/api-structures/admin/staticModel';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';

@Component({
  selector: 'admin-static-models-list',
  templateUrl: './static-models-list.component.html',
  styleUrls: ['./static-models-list.component.scss'],
})
export class StaticModelsListComponent extends AutoDestroy implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  cardsValues: any[]
  dataSource = new MatTableDataSource<ListStaticModel.Item>();
  columnsToDisplay = [
    'id',
    'name',
    'isActive',
    'modelUrl',
    'labelsUrl',
    'priceModelUrl',
    'priceLabelUrl',
    'shelfModelUrl',
    'shelfLabelUrl',
    'ocrModelUrl',
    'popModelUrl',
    'popLabelUrl',
    'actions'
  ]

  constructor(private staticModelsService: StaticModelsService) { super() }

  ngOnInit() {
    this.subscriptions.push(this.staticModelsService.staticModels$.subscribe(d => {
      
      this.dataSource.data = d
      this.cardsValues = d
      
    }))

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }  

  delete(id: string) {
    this.staticModelsService.deleteStaticModel(id)
  }

  makeActive(id: string) {
    this.staticModelsService.setActiveStaticModel(id)
  }
}
