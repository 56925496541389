import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ComplianceReportImage, IPosition } from 'src/app/shared/api-structures/mobile';
import { MobileReportMarker, RectPosition, RectSize } from 'src/app/shared/interfaces';
import { DrawAnnotationsComponent } from './draw-annotations.component';
import { MarkersContainerComponent } from './markers/markers-container.component';

@Component({
  selector: 'main-image',
  standalone: true,
  imports: [CommonModule, MarkersContainerComponent, MatIconModule, TranslateModule, DrawAnnotationsComponent],
  templateUrl: './main-image.component.html',
  styleUrls: ['./main-image.component.scss']
})

export class MainImageComponent implements OnInit {
  @Input() markers: MobileReportMarker[] = []
  @Input() tagSelected = ''
  @Input() selectedMarker = ''
  @Input() maxHeight: number
  @Input() maxWidth: number
  @Input() height: number
  @Input() top = 0
  @Input() left = 0
  @Input() mode: 'add' | 'replace' | 'delete' | 'view' = 'view'
  @Input() imageRotation = 0
  @Output() onAnnotationDrawn = new EventEmitter<IPosition>();
  @Output() markChange = new EventEmitter<MobileReportMarker>();

  imgError: boolean = false
  _image: ComplianceReportImage | null

  @Input() set image(image: ComplianceReportImage | null) {
    this._image = image
    this.imgError = false
  }

  get image() {
    return this._image
  }

  @Output() onMarkSelected = new EventEmitter<MobileReportMarker>();

  constructor(private utils: UtilitiesService) { }

  ngOnInit(): void { }

  markSelected(event: MobileReportMarker) {
    this.onMarkSelected.emit(event);
  }

  calcPosition(pos: RectPosition, size: RectSize) {
    const topP = this.utils.percentOf(pos.top, size.height)
    const leftP = this.utils.percentOf(pos.left, size.width)
    const heightP = this.utils.percentOf(pos.height, size.height)
    const widthP = this.utils.percentOf(pos.width, size.width)

    const top = this.image.height * (topP / 100)
    const left = this.image.width * (leftP / 100)
    const bottom = top + this.image.height * (heightP / 100)
    const right = left + this.image.width * (widthP / 100)

    return { top, left, bottom, right }
  }

  onDrawAnnotation(event: { pos: RectPosition, canvasSize: RectSize }) {
    const position = this.calcPosition(event.pos, event.canvasSize)
    this.onAnnotationDrawn.emit(position)
  }

  onImgError() {
    this.imgError = true
  }

  showImage() {
    if (this.image) {
      return true
    }
    return false
  }
}
