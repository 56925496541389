<div class="container">
  <div class="title-row">
    <div>
      <h2 class="title">{{'HealthCheck' | translate}}</h2>
      <span class="sub-title">{{'HealthCheckDescription' | translate}}</span>
    </div>
    <button (click)="checkNotifications()" class="btn btn-primary">
      <i class="pi pi-refresh" style="margin-right: 8px;"></i> {{"Refresh" | translate}}
    </button>
  </div>
  <div class="bottom-wrapper">
    <div>
      <div *ngFor="let severity of severities" [ngClass]="{'warning-card': severity === 'warning', 
                       'critical-card': severity === 'critical', 
                       'info-card': severity === 'notification',
                       'd-none': getSeverityAmount(severity) < 1}" class="notification-card">
        <div class="content">
          <div class="text-content">
            <span class="severity">{{ severity | translate }}</span>
            <span class="amount">{{ getSeverityAmount(severity) }}</span>
          </div>
          <span class="icon">
            <mat-icon *ngIf="severity !== 'notification'">{{getIcon(severity)}}</mat-icon>
            <i *ngIf="severity === 'notification'" [class]="getNotificationClass(severity)"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="tabs-wrapper">
      <mat-tab-group animationDuration="0ms" mat-stretch-tabs="true" dynamicHeight selectedIndex="0">
        <mat-tab *ngFor="let severity of ['All', 'critical', 'warning', 'notification']"
          [label]="(severity | translate) + ' (' + (notifications | FilterBySeverity: severity).length + ')'">
          <ng-container *ngIf="severity === 'All'; else filteredContent">
            <notification-card *ngFor="let notification of notifications"
              [notification]="notification"></notification-card>
          </ng-container>
          <ng-template #filteredContent>
            <notification-card *ngFor="let notification of notifications | FilterBySeverity: severity"
              [notification]="notification"></notification-card>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>