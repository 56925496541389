

<div *ngIf="data.key; else elseBlock" style="max-width: 650px;">
    <h2 style="margin-bottom: 0;" mat-dialog-title>{{ 'CreateApiKey' | translate }}</h2>
    <span>{{ 'CreateApiKeyDetails' | translate }}</span>
    <mat-dialog-content>
        <div style="padding-bottom: 16px;">
            <span class="note">{{ 'ApiKeyNote' | translate }}</span>
        </div>
        <div style="border: 2px solid #E3E4EB;">
            <div style="padding: 8px 16px;">
                <span class="apiKeyText">{{ 'ApiKey' | translate }}</span>
            </div>
            <div class="apiKey">
                
                <div style="display: flex; justify-content: center; align-content: center;">
                    {{ data.message }} 
                </div>
                
                <div class="tooltip_" *ngIf="showTooltip" [@fadeInOut]>
                    Successfully copied!
                </div>

                <div *ngIf="!showTooltip" style="display: flex; justify-content: center; align-content: center;">
                    <mat-icon (click)="copyMessage(data.message)" class="copy">content_copy</mat-icon>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</div>

<ng-template #elseBlock>
    <div style="max-width: 650px;">
        <mat-dialog-content>
            <span>{{ data.message | translate }}</span>
        </mat-dialog-content>
    </div>
</ng-template>

<mat-dialog-actions align="end" style="margin-top: 24px; background-color: #F4F4F8; width: 108%; margin-left: -4.5%;">
    <button (click)="close(false)" class="cancel" mat-button mat-dialog-close
        matTooltip="{{ 'CancelLower' | translate }}">
        {{ 'CancelLower' | translate }}
    </button>
    <button (click)="close(true)" class="confirm" mat-button matTooltip="{{ 'SaveChanges' | translate }}">
        {{ 'Confirm' | translate }}
    </button>
</mat-dialog-actions>