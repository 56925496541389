<div>
    <div class="pre-table-header">
        <mat-form-field class="aligned">
            <input matInput placeholder="{{'Search'|translate}}" (keyup.enter)="onSearch($event)">
        </mat-form-field>
    </div>
    <table mat-table [dataSource]="data" matSort class="width-100" (matSortChange)="onSort($event)">
        <ng-container *ngFor="let fieldName of fields" [matColumnDef]="fieldName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="disableSort">
                {{capitalize(fieldName) | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                <!-- Dates -->
                <span *ngIf="fieldsOptions[fieldName] === 'date'">
                    {{row[fieldName] ?moment(row[fieldName]).format('L') : ''}}
                </span>
                <!-- default -->
                <span *ngIf="(!fieldsOptions[fieldName] || fieldsOptions[fieldName] === 'text') && fieldName !== 'thumbnailUrl' ">
                    {{row[fieldName]}}
                </span>
                <div *ngIf="fieldName === 'thumbnailUrl'" style="text-align: center;">
                    <img style="height: 32px;" src="{{row[fieldName]}}" alt="">
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="fields"></tr>
        <tr mat-row *matRowDef="let row; columns: fields;" class="clickableRow" (click)="selectRow(row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="totalItems" (page)="fetch()">
    </mat-paginator>
</div>