<div class="user-photo-container" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)" [class.dragover]="isDragOver">

    <h6>{{ title | translate}}</h6>

    <div class="user-photo">
        <img *ngIf="!!imageUrl && imageUrl !== 'default'" [src]="imageUrl">
        <div *ngIf="!imageUrl || imageUrl === 'default'" class="default-image-container">
            <mat-icon>imagesmode</mat-icon>
        </div>
    </div>

    <div style="display: flex; place-content: space-between; margin-top: 20px; margin-bottom: 20px; gap: 8px;">
        <button [disabled]="!imageUrl || imageUrl === 'default'"
            style="color: rgba(107, 107, 107, 1); width: 100%;" mat-stroked-button (click)="onRemoveImage()">
            {{'REMOVE' | translate | uppercase}}
        </button>

        <input type="file" accept=".gif, .jpg, .png" id="singleFile" (change)="onFileChange($event)" [hidden]="true">

        <button style="background-color: rgba(51, 100, 162, 1); color: white; width: 100%;" mat-button
            (click)="onUploadButtonClick()">
            {{'Upload' | translate | uppercase}}
        </button>
    </div>
</div>