import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { BasePriceTarget } from 'src/app/shared/api-structures/misc/priceTarget';
import { SelectProductDialogComponent } from '../select-product-dialog/select-product-dialog.component';

@Component({
  selector: 'app-price-target-form',
  templateUrl: './price-target-form.component.html',
  styleUrls: ['./price-target-form.component.scss']
})
export class PriceTargetFormComponent implements OnInit {
  productDialogRef: MatDialogRef<SelectProductDialogComponent, any>
  @Input() mode: 'add' | 'edit' = 'add'
  _selectedPriceTarget: BasePriceTarget | null = null
  @Input() set selectedPriceTarget(selected) {
    this._selectedPriceTarget = selected
    this.priceTargetForm = new FormGroup({
      productId: new FormControl(this.selectedPriceTarget?.productId),
      productName: new FormControl(this.selectedPriceTarget?.productName),
      price: new FormControl(this.selectedPriceTarget?.price),
      customerSalesTerritory: new FormControl(this.selectedPriceTarget?.customerTerritory),
      customerCategory: new FormControl(this.selectedPriceTarget?.customerCategory),
      customerChain: new FormControl(this.selectedPriceTarget?.customerChain),
      customerArea: new FormControl(this.selectedPriceTarget?.customerArea),
      dateFrom: new FormControl(this.selectedPriceTarget?.dateFrom),
      dateTo: new FormControl(this.selectedPriceTarget?.dateTo),
    })
  }

  get selectedPriceTarget() {
    return this._selectedPriceTarget
  }
  // tagsInput = new FormControl('')
  priceTargetForm = new FormGroup({
    productId: new FormControl(null),
    productName: new FormControl(null),
    price: new FormControl(null),
    customerSalesTerritory: new FormControl(null),
    customerCategory: new FormControl(null),
    customerChain: new FormControl(null),
    customerArea: new FormControl(null),
    dateFrom: new FormControl(null),
    dateTo: new FormControl(null),
  })

  listCategories: string[] = []
  listChains: string[] = []
  listTerritories: string[] = []
  isLoadingAttributes: boolean

  categoryCtrl = this.priceTargetForm.controls.customerCategory
  filteredCategories: Observable<string[]>

  terrCtrl = this.priceTargetForm.controls.customerSalesTerritory
  filteredTerritories: Observable<string[]>

  chainsCtrl = this.priceTargetForm.controls.customerChain
  filteredChains: Observable<string[]>

  constructor(public dialogRef: MatDialogRef<PriceTargetFormComponent>, private customerService: CustomerService, private dialog: MatDialog) { }

  ngOnInit() {
    this.getCustomerAttributes()
  }

  async getCustomerAttributes() {
    function _filter(arr: string[], value: string): string[] {
      const filterValue = value.toLowerCase()
      return arr.filter(option => option.toLowerCase().includes(filterValue))
    }
    this.isLoadingAttributes = true
    try {
      const attr = await this.customerService.listCustomersAttributes()
      this.listCategories = attr.categories
      this.listChains = attr.chains
      this.listTerritories = attr.territories
    } finally {
      this.isLoadingAttributes = false
      this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
        startWith(''),
        map(value => _filter(this.listCategories, value || '')),
      )

      this.filteredTerritories = this.terrCtrl.valueChanges.pipe(
        startWith(''),
        map(value => _filter(this.listTerritories, value || '')),
      )

      this.filteredChains = this.chainsCtrl.valueChanges.pipe(
        startWith(''),
        map(value => _filter(this.listChains, value || '')),
      )
    }
  }

  dropDownOptions(name: 'category' | 'territory' | 'chain') {
    switch (name) {
      case 'category':
        this.categoryCtrl.setValue(this.categoryCtrl.value)
        break
      case 'territory':
        this.terrCtrl.setValue(this.terrCtrl.value)
        break
      case 'chain':
        this.chainsCtrl.setValue(this.chainsCtrl.value)
        break
    }
  }

  selectProduct(row: { id: string, name: string }) {
    this.productDialogRef.close()
    this.priceTargetForm.controls.productId.setValue(row.id)
    this.priceTargetForm.controls.productName.setValue(row.name)
  }

  openPriceTargetDialog() {
    this.productDialogRef = this.dialog.open(SelectProductDialogComponent, {
      data: {
        onSelect: this.selectProduct.bind(this)
      }
    })
  }

  onSave() {
    this.priceTargetForm.markAllAsTouched()
    if (this.priceTargetForm.valid) {
      this.dialogRef.close(this.priceTargetForm.value)
    }
  }

  onCancel() {
    this.dialogRef.close()
  }
}
