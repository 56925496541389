import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';

export type OptionForSelect = {
  key: string,
  displayName?: string
}

@Component({
  selector: 'add-associate',
  templateUrl: './add-associate.component.html',
  styleUrls: ['./add-associate.component.scss']
})

export class AddAssociateComponent extends AutoDestroy {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger
  @Input() filter: string = ''
  @Input() fetchOptions: (currentPage: number, pageSize: number, query: string) => Promise<OptionForSelect[]>

  @Output() close = new EventEmitter()
  @Output() onOptionSelected = new EventEmitter<string>()
  @Output() onAdd = new EventEmitter<string>()

  key: string
  options = []
  isLoadingOptions: boolean = false
  loadOptionsTimeout


  constructor() {
    super()
  }

  async loadOptions(query: string) {
    this.isLoadingOptions = true
    try {
      const result = await this.fetchOptions(0, 30, query)
      this.options = result
    } finally {
      this.isLoadingOptions = false
    }
  }

  async listOptionsForAutocomplete(value: string) {
    clearTimeout(this.loadOptionsTimeout)
    this.loadOptionsTimeout = setTimeout(() => {
      if (value.length > 2) {
        this.loadOptions(value.toLowerCase())
      } else {
        this.autocomplete.closePanel()
      }
    }, 500)
  }

  displayFn(option: OptionForSelect): string {
    return option.displayName || option.key
  }

  async optionSelected(option) {
    this.key = option.value.key
    this.onOptionSelected.emit(this.key)
  }

  emitFilter(filter: string) {
    this.onOptionSelected.emit(filter)
    this.autocomplete.closePanel()
  }

  async addAndClose() {
    if(!this.key) return
    this.onAdd.emit(this.key)
  }

  onCancelClick() {
    this.close.emit()
  }

}