import { AfterViewChecked, ChangeDetectorRef, Component } from "@angular/core";
import { SnackbarService } from "src/app/services/snackbar.service";
import { AutoDestroy } from "../base-directives/auto-destroy";
import { ApiTrackerService } from "../services/api.tracker.service";


@Component({
    selector: 'network-status2',
    templateUrl: './network-status2.html',
})
export class NetworkStatus2 extends AutoDestroy implements AfterViewChecked {
    showSpinner: boolean = false

    constructor(private apiTrackerService: ApiTrackerService, private snackbar: SnackbarService, private cdr: ChangeDetectorRef) {
        super()
    }

    private changeSpinner(value: boolean) {
        this.showSpinner = value
        this.cdr.detectChanges()
    }

    ngAfterViewChecked(): void {
        this.changeSpinner(this.apiTrackerService.isSpinnerNeeded)
        
        this.subscriptions.push(this.apiTrackerService.onSpinnerNeededChanged$.subscribe(d => this.changeSpinner(d.isShow)))
        this.subscriptions.push(this.apiTrackerService.onError$.subscribe(d => this.snackbar.openSnackBar(2000, d.error)))
    }
}
