import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getCompanyType } from 'src/app/shared/services/companyType.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  message = 'Loading profile...'
  companyType = getCompanyType()

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.redirectToScreen()
  }

  goHome() {
    this.router.navigateByUrl('/home')
  }

  async redirectToScreen() {
    try {
      let returnUrl: string = this.route.snapshot.queryParams['returnUrl'] || '/home'
      if ( getCompanyType() === 'MC1') {
        returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/planograms'
      }
      this.navigate(returnUrl)
    } catch {
      this.message = 'Something went wrong...'
    }
  }

  async navigate(returnUrl: string) {
    const IsAuthorized = await this.router.navigateByUrl(returnUrl)

    if (IsAuthorized === false) {
      this.router.navigate(['/home'])
      this.message = 'You are not authorized.'
    }
  }
}
