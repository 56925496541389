<div class="settings-table-main">
  <div class="table-content-container">
    <div class="header-wrapper">
      <div class="title">
        <h4 style="margin-bottom: 8px; color: #0A131F;">{{'Settings'|translate}}</h4>
        <small>{{'Please enter the name and parameters of your new company.' | translate}}</small>
      </div>
    </div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" animationDuration="0ms" *ngIf="companySettings"
      style="overflow: auto;">
      <mat-tab label="General">
        <div class="col-action-settings">
          <div>
            <div style="max-width: 365px;">
              <label class="margin-top">{{'Logo'|translate}}</label>
              <drag-and-drop-files isMultipleFiles="false" accept="image/*"
                (onFileSelected)="saveLogoFileToStorga($event)"
                *ngIf="!_logoUrl && !isLoadingSettings"></drag-and-drop-files>
            </div>
            <mat-card class="img-card mat-elevation-z0" *ngIf="_logoUrl">
              <button mat-mini-fab (click)="_logoUrl=undefined"
                class="img-icon"><mat-icon>add_a_photo</mat-icon></button>
              <img class="img-logo" mat-card-image src="{{_logoUrl}}" alt="LOGO" />
            </mat-card>
            <div *ngIf="uploadProgress">
              {{uploadProgress.name}}
              <mat-progress-bar mode="determinate" value="{{uploadProgress.progress}}"></mat-progress-bar>
            </div>
          </div>
          <br>
          <mat-slide-toggle class="custom-slide-toggle" [(ngModel)]="_isNoiseEnabled">
            {{'NoiseEnabled'|translate}}
          </mat-slide-toggle>
          <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 16px; margin-top: 16px;">

            <mat-form-field>
              <mat-label>{{'Language'|translate}}</mat-label>
              <mat-select [(ngModel)]="_language">
                <mat-option value="pt">Portuguese</mat-option>
                <mat-option value="en">English</mat-option>
                <mat-option value="es">Spanish</mat-option>
                <mat-option value="ar">Arabic</mat-option>
                <mat-option value="el">Greek</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="countries.length > 0">
              <mat-label>{{'Country'|translate}}</mat-label>
              <mat-select [(ngModel)]="_country" (selectionChange)="changeCountry($event)">
                <mat-option></mat-option>
                <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'Currency'|translate}}</mat-label>
              <input [disabled]="true" type="string" matInput [value]="_currency">
            </mat-form-field>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Mobile">
        <div class="mobile-table">
          <div class="table-header">
            <div class="col-1">Enable</div>
            <div class="col-5">Parameter</div>
            <div class="col-6"></div>
          </div>
  
          <app-settings-row
            *ngFor="let setting of mobileSettings"
            [title]="setting.title"
            [description]="setting.description"
            [toggleValue]="setting.toggleValue"
            [disabled]="setting.disabled"
            [hasControl]="setting.hasControl"
          >
            <ng-container *ngIf="setting.type === 'slider'">
              <div class="slider-text">
                {{ setting.controlText | translate }} ({{ this[setting.controlRef] }}{{setting?.isNotPercent ? '' : '%'}})
              </div>
              <mat-slider
                [max]="setting?.sliderMax ?? 100"
                min="0"
                step="1"
                thumbLabel
                [(ngModel)]="this[setting.controlRef]"
              ></mat-slider>
            </ng-container>
  
            <ng-container *ngIf="setting.type === 'select'">
              <mat-form-field>
                <mat-label>{{ setting.controlText | translate }}</mat-label>
                <mat-select [(ngModel)]="this[setting.controlRef]">
                  <mat-option *ngFor="let option of setting.options" [value]="option.key">
                    {{ option.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="setting.type === 'toggle'">
              <mat-button-toggle-group [name]="setting.controlText" [aria-label]="setting.controlText | translate" [(ngModel)]="this[setting.controlRef]">
                <mat-button-toggle *ngFor="let option of setting.options" [value]="option.key">
                  {{ option.description }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </ng-container>
          </app-settings-row>
        </div>
      </mat-tab>
      <mat-tab label="{{'Integration'|translate}}" [disabled]="companySettings.companyType !== 'MC1'">
        <mat-form-field>
          <mat-label>{{'Mc1 DataSource'|translate}}</mat-label>
          <input type="string" matInput [(ngModel)]="_mc1DataSourceUrl">
        </mat-form-field>
      </mat-tab>

      <mat-tab label="Training">
        <div class="col-action-settings">
          <div>
            <mat-form-field>
              <mat-label>{{'SingleTrainingSold'|translate}}</mat-label>
              <input type="number" matInput [(ngModel)]="_trainingSettingsSingleTrainingSold" placeholder="Ex. 1">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'TrainingQuota'|translate}}</mat-label>
              <input type="number" matInput [(ngModel)]="_trainingSettingsTrainingQuota" placeholder="Ex. 1">
            </mat-form-field>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'Catalog'|translate}}">
        <div class="col-action-settings">
          <mat-slide-toggle [(ngModel)]="_showCatalogDimensions">
            {{'ShowCatalogDimensions'|translate}}
          </mat-slide-toggle>
        </div>
      </mat-tab>


      <mat-tab label="Images Review">


        <div class="slider-row-wrapper">
          <div class="slider-wrapper">
            <p>{{ "Amount" | translate }}</p>
            <mat-slider class="my-slider-2" [min]="minimumImages" [max]=" 1500" step="1" [(value)]="maximumImages">
            </mat-slider>
          </div>
          <div class="center">
            <aside id="info-block">
              <section class="file-marker">
                <div class="text-center">
                  <div class="box-title">min</div>
                  <div class="box-contents">
                    <div id="audit-trail">
                      <div class="input-wrapper">
                        <input type="number" pattern="[0-9]*" class="no-border w100" [(ngModel)]="minimumImages" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </aside>
          </div>
          <div class="center">
            <div class="separator"></div>
          </div>
          <div class="center">
            <aside id="info-block">
              <section class="file-marker">
                <div class="text-center">
                  <div class="box-title">max</div>
                  <div class="box-contents">
                    <div id="audit-trail">
                      <div class="input-wrapper">
                        <input type="number" pattern="[0-9]*" class="no-border w100" [(ngModel)]="maximumImages" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </aside>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>
  </div>
  <mat-dialog-actions align="end" style="margin-bottom: 1px; background-color: #F4F4F8; width: 100%;">
    <button class="cancel" mat-button mat-dialog-close matTooltip="{{'CancelLower'|translate}}">
      {{'CancelLower'|translate}}
    </button>
    <button class="confirm" mat-button (click)="saveSettings()" matTooltip="{{'SaveChanges'|translate}}">
      {{'Confirm'|translate}}
    </button>
  </mat-dialog-actions>
</div>
