import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'chat-cpg-float-button',
  templateUrl: './chat-cpg-float-button.component.html',
  styleUrls: ['./chat-cpg-float-button.component.scss'],
})
export class ChatCpgFloatButtonComponent implements OnInit {

  chatCpgActivated: boolean = false;
  alreadyInChatCpgScreen: boolean = false;

  constructor(private settingsService: SettingsService, private router: Router) { }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.alreadyInChatCpgScreen = event.url === '/chatcpg'
      })
    this.getUrl()
  }

  navigateToChatCpg() {
    this.router.navigate(['/chatcpg'])
  }

  async getUrl() {
    const settings = await this.settingsService.getSettings()
    this.chatCpgActivated = !!settings?.chatCpgUrl
  }

}
