import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dal } from '../dal/dal';
import { ApiService } from '../shared/services/api.service';
import { OrganizationsService } from '../super-admin/services/organizations.service';
import { UtilitiesService } from './utilities.service';
import { CreateSignedUrlRequest } from '../shared/api-structures/misc/misc';

@Injectable({ providedIn: 'root' })
export class AppService {

  constructor(private dal: Dal, private api: ApiService, private utilitiesService: UtilitiesService,
    private organizationsService: OrganizationsService) { }

  async uploadFileToStorageAndCompressIfNeedded(file: File, reportProgress: (progress: number) => void = undefined, isHideProgress?: boolean, organizationId?: string) {

    if (file.type.startsWith('image')) {
      file = await this.utilitiesService.compressImageFileIfNeeded(file)
    }

    let readUrl: string = undefined

    await this.api.callbackRequest(async httpClient => {
      const ext = file.name.split('.').pop()
      let url: { writeUrl: string, readUrl: string }
      if (organizationId !== undefined) {
        url = await this.organizationsService.createSignedUrl(ext, 'image/png', organizationId, isHideProgress)
      } else {
        url = await this.dal.createSignedUrl(ext, 'image/png', isHideProgress)
      }

      const req = httpClient.request<HttpEvent<any>>('PUT', url.writeUrl, {
        body: file,
        headers: {
          'Content-Type': 'image/png'
        },
        reportProgress: true,
        observe: 'events'
      })

      req.subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress && reportProgress !== undefined) {
            reportProgress(Math.round(100 * event.loaded / event.total))
          }
        })

      await req.toPromise()
      readUrl = url.readUrl

    }, true)

    return readUrl
  }

  async uploadFileToStorageFull(file: File, urlRequest: CreateSignedUrlRequest, reportProgress: (progress: number) => void = undefined, isHideProgress?: boolean) {

    let readUrl: string = undefined

    await this.api.callbackRequest(async httpClient => {
      const ext = file.name.split('.').pop()
      let url: { writeUrl: string, readUrl: string }

      url = await this.dal.createSignedUrlFull(urlRequest, isHideProgress)

      const req = httpClient.request<HttpEvent<any>>('PUT', url.writeUrl, {
        body: file,
        headers: {
          'Content-Type': file.type
        },
        reportProgress: true,
        observe: 'events'
      })

      req.subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress && reportProgress !== undefined) {
            reportProgress(Math.round(100 * event.loaded / event.total))
          }
        })

      await req.toPromise()
      readUrl = url.readUrl

    }, true)
    return readUrl

  }
}
