import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GetUserInfoResponse } from 'src/app/shared/api-structures/admin/user';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {

  @Input() imageUrl: string = '';
  @Input() title: string;
  @Output() imageUpload = new EventEmitter<File>();
  @Output() imageRemove = new EventEmitter<void>();

  isDragOver = false;

  onFileChange(event: { target: { files: File[] } }): void {
    const file: File = event.target.files[0];
    if (file) {
      this.imageUpload.emit(file);
    }
  }

  onUploadButtonClick(): void {
    document.getElementById('singleFile').click();
  }

  onRemoveImage(): void {
    this.imageRemove.emit();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
    
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.imageUpload.emit(file);
      }
    }
  }
}
