import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchProducts } from 'src/app/shared/api-structures/misc/product';
import { Dal } from '../../../../dal/dal';
import { MatDialogModule } from '@angular/material/dialog';
import { AppDialogComponent } from 'shared/ui-kit/dialog/dialog.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { LanguageService } from 'shared/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomPaginatorService } from 'shared/services/custom-paginator.service';
import { CapitalizeAll } from 'shared/pipes/capitalize.pipe';
import { FormsModule } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ListBrandsWithIds, ListCategoriesWithIds } from 'shared/api-structures/misc/catalog';
import { TooltipModule } from 'primeng/tooltip';

type SearchProductsParams = {
  filters?: string[]
}

interface IDialogData {
  onSelect: (data: { id: string, name: string, thumbnailUrl: string }) => void
  categories: any[],
  selectedProduct?: any
}

@Component({
  selector: 'app-select-product-dialog',
  standalone: true,
  templateUrl: './select-product-dialog.html',
  styleUrls: ['./select-product-dialog.scss'],
  imports: [
    CommonModule, FormsModule,
    MatDialogModule, DialogModule, AppDialogComponent, ButtonModule,
    InputTextModule, TranslateModule, DropdownModule, MatPaginatorModule,
    TooltipModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomPaginatorService }
  ]
})
export class SelectProductDialogComponent implements OnInit {
  paginatedData?: SearchProducts.Response
  selectedProduct?: SearchProducts.Item
  categoryList: Array<ListCategoriesWithIds.Category> = []
  brandList: Array<ListBrandsWithIds.Brand> = []

  state = {
    loading: true,
    isLoadingCategories: true,
    isLoadingBrands: true,
    showDialog: false,
    routeQuery: {} as any,
    currentPage: 0,
    pageSize: 25,
    searchValue: null
  }
  filters = {
    category: null,
    brand: null,
    srcValue: null
  }


  constructor(
    private dal: Dal,
    private langService: LanguageService,
    private matDialogRef: MatDialogRef<SelectProductDialogComponent, any>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData,
    public utilitiesService: UtilitiesService,
  ) {
    this.langService.setLanguage()
    // this.dialogData.categories = this.dialogData.categories.map((cat) => ({ name: cat }))
  }

  async ngOnInit(): Promise<void> {
    // Get Route params
    this.activatedRoute.queryParams.subscribe(data => this.state.routeQuery = data)
    this.selectedProduct = this.dialogData?.selectedProduct
    await this.getProducts();

    // Get selected product 
    const { prod: selectedProudctId, prodPage } = this.state.routeQuery
    await this.setSelectedProduct(selectedProudctId)

    this.state.loading = false
    this.state.showDialog = true

    // init non promise
    this.getCategories()
    this.getBrands()
  }

  onPaginate({ pageIndex, pageSize }) {
    this.state.currentPage = pageIndex
    this.state.pageSize = pageSize
    this.getProducts()
  }

  onDialogClose() {
    this.matDialogRef.close()
    if (!this.selectedProduct?.productId) {
      this.router.navigateByUrl('/admin/price-target')
    }
  }

  onSelectProduct(product: SearchProducts.Item) {
    this.router.navigate([], { queryParams: { ...this.state?.routeQuery, prod: product?.productId } })
    this.dialogData.onSelect({
      id: product?.productId,
      name: product?.name,
      thumbnailUrl: product?.thumbnailUrl
    })
  }

  async getCategories() {
    this.categoryList = await this.dal.getCategoriesWithIds()
    this.state.isLoadingCategories = false
  }

  async getBrands() {
    this.brandList = await this.dal.getBrandsWithIds()
    this.state.isLoadingBrands = false
  }

  async getProducts(params?: SearchProductsParams) {
    // Set filters
    let filters = [
      this.filters?.srcValue,
      this.filters?.category?.name,
      this.filters?.brand?.name,
    ]

    filters = filters.filter((item) => item)
    this.paginatedData = await this.dal.searchProducts(
      this.state.currentPage, this.state.pageSize, filters, {}, false
    )
  }

  async setSelectedProduct(productId?: string): Promise<void> {

    if (!this.selectedProduct?.productId) {
      return
    }

    if (productId) {
      const res = await this.dal.searchProducts(0, 10, [productId], {}, false)
      if (res.items.length <= 0) return null;
      this.selectedProduct = res.items[0]
    }
    //Remove the selected product from the list
    // And than add gain as the first element of the list
    // OBS: It is necessary to show the selected when all elements does not fit on the screen
    this.paginatedData.items = this.paginatedData.items.filter((item) => item.productId !== this.selectedProduct?.productId)
    this.paginatedData.items.splice(0, 0, this.selectedProduct)
  }

  async clearFilters() {
    this.filters = {} as any;
    await this.getProducts({ filters: [] });
  }


  // fetchProducts: SelectTableFetchFunction<SearchProductsItemWithId> = async ({ page, pageSize, textFilter, sortDirection, sortBy }) => {
  //   let sortByPT = sortBy
  //   if (sortByPT === 'id') sortByPT = 'none'
  //   const res = await this.dal.searchProducts(
  //     page, pageSize, [textFilter], {}, false, sortByPT as SearchProductsRequest['sortBy'], sortDirection || 'desc'
  //   )
  //   return { totalItems: res.totalProducts, items: res.items.map(item => ({ ...item, id: item.productId })) }
  // }
}