<div class="main" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50"
  [infiniteScrollDisabled]="isInfiniteScrollDisabled" (scrolled)="nextPage()">
  <div class="unapproved-header-fixed">
    <div class="table-header">
      <div class="table-title-container">
        <div class="title">
          {{'ImageReview'|translate}}
        </div>
        <div class="sub-title">
          {{'ImagesList'|translate}}
        </div>
      </div>
    </div>

    <div class="content title-container">
      <button mat-button (click)="returnUnapprovedCatalogs()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="product-image" *ngIf="PruductImageUrl!==undefined"
        [ngStyle]="{backgroundImage: 'url(' + PruductImageUrl + ')'}" (click)="expandCape(PruductImageUrl)"></div>
      <div style="display: flex; flex-direction: column;">
        <h5>{{productName}}</h5>
        <p style="color: #808080; margin-bottom: 0;">{{productId}}</p>
        <p style="color: #808080; margin-bottom: 0;">{{'Amount'|translate}}: {{amount}}</p>
      </div><br>

      <div style="margin-left: auto;">
        <button mat-stroked-button *ngIf="!isAllImagesSelected && !isSomeImagesSelected; else buttonsForSelected"
          color="primary" (click)="unapprove()" [disabled]="disableButton || images.length === 0">{{
          'RejectAll'|translate}}</button>
        <ng-template #buttonsForSelected>
          <button mat-stroked-button color="primary" (click)="unapprove()" [disabled]="disableButton">{{
            'RejectSelected'|translate}}</button>
          <button *ngIf="!areEnoughImages" mat-flat-button color="primary" [disabled]="disableButton"
            (click)="approve()" style="margin-left: 16px;">{{
            'ApproveSelected'|translate }}</button>
          <a *ngIf="areEnoughImages" matTooltip="{{'ImagesAreEnough'|translate}} ({{maxImagesInCatalog}})">
            <button mat-flat-button color="primary" [disabled]="areEnoughImages || disableButton" (click)="approve()"
              style="margin-left: 16px; cursor: not-allowed;">{{'ApproveSelected'|translate }}
            </button>
          </a>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="images-content-container content">
    <div class="images-options-container">

      <mat-checkbox class="example-margin" color='primary' [checked]="isAllImagesSelected"
        (change)="setAll($event.checked)" [disabled]="images.length === 0">
        {{'SelectAll'|translate}}
      </mat-checkbox>
      <div style="float: right">
        <span style="margin-left: 15px;color: #808080;">{{ 'SortBy' | translate }}:</span>
        <button tabindex="-1" style="text-decoration: underline; 
        text-decoration-color: #808080; margin-left: -10px;" mat-button [matMenuTriggerFor]="menuOrdering">{{
          textFilterOrderImages || 'MoreRecent' | translate }}</button>
        <mat-menu #menuOrdering="matMenu">
          <button mat-menu-item (click)="showLatestImages()">{{ 'MoreRecent' | translate }}</button>
          <button mat-menu-item (click)="showOlderImages()">{{ 'MoreOlder' | translate }}</button>
        </mat-menu>
        <button mat-stroked-button color="primary" (click)="openFilters()">
          {{'Filters'|translate}}
        </button>
      </div>
    </div>
    <ul>
      <li *ngFor="let image of images let i=index">
        <mat-card class="mat-elevation-z0 img-card" [ngClass]="{cardSelectedBlue: image.isChecked}"
          (click)="onCardClick(image)">
          <button mat-flat-button color="primary" class="custom-tooltip"
            (click)="openImage(image)">{{'ViewImage'|translate}}</button>
          <mat-checkbox color="primary" [hidden]="true" [(ngModel)]="image.isChecked" [id]="'id'+i">
          </mat-checkbox>
          <div>
            <label [for]="'id'+i" class="label-image"><img [src]="image.thumbnailUrl" /></label>
          </div>
          <mat-card-content class="img-data">
            <div class="select-catalog">
              <span style="align-self: center;">{{'Catalog'|translate}}:</span>
              <mat-select [(value)]="image.catalog" placeholder="{{'Catalog'|translate}}"
                *ngIf="isSelectCatalogDisabled === false; else catalog" panelClass="unapproved-images-panel-class">
                <mat-option *ngFor="let catalog of listCatalogs" [value]="catalog">{{catalog.name}}</mat-option>
              </mat-select>
              <ng-template #catalog><span class="crop-long-text" matTooltip="{{image.catalog?.name}}"
                  #toolTip2>{{image.catalog?.name}}</span>
              </ng-template>
            </div>
            <span class="info-card">
              <span>{{ 'Date' | translate }}</span>
              <span class="ellipsis-text">{{ image.lastUpdate | date : 'dd/MM/yyyy HH:mm' }}</span>
            </span>
            <span class="info-card">
              <span>{{ 'User' | translate }}:</span>
              <div class="ellipsis-text" [title]="image.userName">{{ image.userName}}
              </div>
            </span>
            <span class="info-card">
              <span>{{ 'Origin' | translate }}:</span>
              <span class="ellipsis-text" [title]="image.role">{{ image.role}}</span>
            </span>
            <span class="info-card">
              <span>{{ 'Recognition' | translate }}</span>
              <span *ngIf="image.source === 'user_feedback'">
                <mat-icon class="icon-recognition">person_outline</mat-icon>
                {{ 'Manual' | translate }}
              </span>
              <span *ngIf="image.source === 'machine_learning'">
                <mat-icon class="icon-recognition">phone_android</mat-icon>
                {{ 'Automatic' | translate }}
              </span>
            </span>
          </mat-card-content>
        </mat-card>
      </li>
    </ul>
  </div>
</div>