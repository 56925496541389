// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose } from 'class-transformer'
import { IsNumber, IsOptional, IsString } from 'class-validator'

export class CompanySettingsResponse {
    @Expose() @IsString() @IsOptional() isNoiseEnabled?: boolean
    @Expose() @IsString() @IsOptional() singlePicturePriceOcr?: boolean
    @Expose() @IsString() @IsOptional() multiPicturePriceOcr?: boolean
    @Expose() @IsString() @IsOptional() galeryPhotoEnabled?: boolean
    @Expose() @IsString() @IsOptional() mc1DataSourceUrl?: string
    @Expose() @IsString() @IsOptional() currentModelId?: string
    @Expose() @IsString() @IsOptional() syncOnlyEditedImages?: string
    @Expose() @IsString() @IsOptional() language?: string
    @Expose() @IsString() @IsOptional() country?: string
    @Expose() @IsString() @IsOptional() currency?: string
    @Expose() @IsString() @IsOptional() logoUrl?: string
    @Expose() @IsString() @IsOptional() ocrReadMethod?: string
    @Expose() @IsNumber() @IsOptional() blurDetectionThreshold?: number
    @Expose() @IsNumber() @IsOptional() maxNumberImagesInCatalog?: number
    @Expose() @IsNumber() @IsOptional() maxNumberOfCatalogs?: number
    @Expose() @IsString() @IsOptional() showCatalogDimensions?: boolean
    @Expose() @IsNumber() @IsOptional() blurDetectionCaptureTries?: number
    @Expose() @IsNumber() @IsOptional() imagesQualityAndroid?: number
    @Expose() @IsNumber() @IsOptional() imagesQualityIOS?: number
    @Expose() @IsNumber() @IsOptional() overlayBounds?: number
    @Expose() @IsNumber() @IsOptional() threshold?: number
}