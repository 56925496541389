<div class="main">
  <div class="container">
    <header class="header-dialog">
      <img
        [hidden]="!productInfo.thumbnailUrl"
        src="{{ productInfo.thumbnailUrl }}"
      />
      <span style="font-family: 'Roboto';
        font-weight: 600;
        font-size: 16px;
        line-height: 18.75px;
        color: rgba(51, 51, 51, 1);
        margin-left: 4px;">
        {{ productInfo.name }}
      </span>
    </header>
    <mat-dialog-content class="dialog-content">
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        class="custom-table planograms-table"
      >
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>{{ "PlanogramDetails" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; flex-direction: column;">
              <span style="font-family: 'Roboto';
                font-weight: 500;
                font-size: 15px;
                line-height: 22.5px;
                color: rgba(74, 75, 87, 1);">
                {{element.name}}
              </span>
              <span style="font-weight: 400;
                font-size: 12px;
                line-height: 14.4px;
                color: rgba(74, 75, 87, 1);">
                {{element.mc1PlanogramId || element.id}}
              </span>
            </div>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ "PlanogramName" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container> -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>{{ "Status" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.state }}</td>
        </ng-container>

        <ng-container matColumnDef="editIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" style="text-align: end">
            <button
              matTooltip="{{ 'Edit' | translate }}"
              mat-dialog-close="true"
              *ngIf="row.isEditable"
              (click)="onEditPlanogram(row)"
              mat-button
            >
            <mat-icon
              style="color: rgba(20, 38, 62, 1);
              font-size: larger;
              padding-top: 4px;">
              edit
            </mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onRowClicked(row)"
        ></tr>
      </table>
    </mat-dialog-content>
  </div>
</div>
