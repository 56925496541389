import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StaticModelsService } from '../../services/static-models.service';
import { AddEditComponent } from './add-edit/add-edit.component';

@Component({
  selector: 'admin-static-models',
  templateUrl: './static-models.component.html',
})
export class StaticModelsComponent implements OnInit {

  constructor(private staticModelsService: StaticModelsService, private dialog: MatDialog) { }

  ngOnInit() {
    this.staticModelsService.loadStaticModels()
  }

  refresh() {
    this.staticModelsService.loadStaticModels()
  }

  add() {
    this.dialog.open(AddEditComponent);
  }
}
