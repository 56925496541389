import { Component } from '@angular/core'
import { GetExistingProductsCatalogs } from 'src/app/shared/api-structures/misc/catalog'
import { Dal } from '../../../dal/dal'
import { ImageFileToUpload, ProductsImageCatalogsBatchUplaodService } from '../../../shared/services/products-image-catalogs-batch-uplaod.service'
import { Papa, ParseResult } from 'ngx-papaparse';
import { isNull, isUndefined } from 'underscore'


export enum DialogTypeMode {
  PRODUCTS = 'products',
  CATALOG = 'catalog',
  SHOW_FOLDER_STRUCTURE = 'showFolderStructure',
  CSV = 'csv',
  INCORRECT_PHOTOS_FORMAT = 'incorrectPhotosFormat'
}


@Component({
  selector: 'products-batch-upload',
  templateUrl: './products-batch-upload.component.html',
  styleUrls: ['./products-batch-upload.component.css']
})
export class ProductsBatchUploadComponent {

  isShowDialog: boolean = false
  isShowDifferentIcon: boolean = false
  dialogType: DialogTypeMode
  notExistingProducts: string[]
  notExistingProductsCsv: string[] = []
  incorrectPath: string
  imageFoldersToUpload: { [productId: string]: { [collectionName: string]: ImageFileToUpload } }

  sizeFileName: string = 'sizes.csv'
fileNamesToIgnore: string[] = ['sizes.csv', '.DS_Store']
  csvPattern: string = 'id; width; height'
  supportedFormats: string = '.gif, .jpg, .png'

  constructor(
    private dal: Dal,
    private papa: Papa,
    private batchUploadService: ProductsImageCatalogsBatchUplaodService) { }

  uploadImageFoldersBtnClick() {
    document.getElementById("multipleFfolders").click();
  }

  async onFolderSelected(eventfiles: FileList) {
    const files = eventfiles
    const res = this.addImagesToDict(files);
    const sizeFile = Object.keys(files).find(file => files[file].name.includes(this.sizeFileName))

    if (sizeFile !== undefined) {
      const file = files[sizeFile];
      await this.getSizes(file);

      if (this.notExistingProductsCsv.length > 0) {
        this.showCsvDialog()
        return
      }
    }

    if (res) {
      this.showFolderStructureDialog(res)
      return
    }

    this.prepareBatchImport()
  }

  private addImagesToDict(files: FileList) {
    this.imageFoldersToUpload = {}
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const path = file['webkitRelativePath'].split('/');
      if (path.length == 4 && file.type.includes('image')) {
        const productId = path[1]
        const catalogName = path[2]
        const size = { height: 0, width: 0 };
        if (this.imageFoldersToUpload[productId]) {
          if (this.imageFoldersToUpload[productId][catalogName]) {
            this.imageFoldersToUpload[productId][catalogName].files.push(file)
          } else {
            this.imageFoldersToUpload[productId][catalogName] = {
              files: [file], isCatalogExist: false, filesUrl: [], size
            }
          }
        } else {
          this.imageFoldersToUpload[productId] = { [catalogName]: { files: [file], isCatalogExist: false, filesUrl: [], size } }
        }
      } else if (!this.fileNamesToIgnore.includes(file.name)) {
        const validated = this.validatePhotoFormat(file)
        if (validated) {
          return validated
        }
      }
    }
    return null
  }

  private async getSizes(file: File) {
    const result = new Promise<void>((resolve, reject) => {
      this.papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        delimiter: ';',
        complete: (results: ParseResult) => {
          const data: [] = results.data;
          if (data !== null && data.length > 0) {
            for (const row of data) {
              const productId = row['id']
              // TODO: Notify the user so the size is required?
              const height = row['height'] ?? 0;
              const width = row['width'] ?? 0;

              if (this.validateProductCsv(productId) === false) {
                continue
              }

              const catalogs = this.imageFoldersToUpload[productId];

              for (const [, value] of Object.entries(catalogs)) {
                value.size = { width, height }
              }
            }
          }
          resolve()
        },
        error: err => reject(err)
      });
    })

    return result
  }

  showFolderStructureDialog(path: string) {
    this.incorrectPath = path
    this.dialogType = DialogTypeMode.SHOW_FOLDER_STRUCTURE
    this.isShowDialog = true
  }

  private async prepareBatchImport() {
    const res = await this.getExistingProductAndCollections()
    this.editImageFoldersToUpload(res)
    this.aprroveDialogBeforeUpload()
    this.notExistingProducts.forEach(v => (delete this.imageFoldersToUpload[v]))
  }

  private async getExistingProductAndCollections() {
    const prepareBatch = new GetExistingProductsCatalogs.Request()
    prepareBatch.folders = []
    for (const [key, value] of Object.entries(this.imageFoldersToUpload)) {
      prepareBatch.folders.push({ 'folder': key, 'subFolders': Object.keys(value) })
    }
    const res = await this.dal.getExistingProducts(prepareBatch)
    return res
  }

  private editImageFoldersToUpload(res: GetExistingProductsCatalogs.Response) {
    const existingProducts: string[] = []
    for (const folder of res.folders) {
      existingProducts.push(folder.folder)
      for (const catalog of folder.catalogs) {
        this.imageFoldersToUpload[folder.folder][catalog.name].catalogId = catalog['id']
        this.imageFoldersToUpload[folder.folder][catalog.name].isCatalogExist = true
      }
    }
    this.notExistingProducts = Object.keys(this.imageFoldersToUpload).filter(v => !existingProducts.includes(v))
  }

  private aprroveDialogBeforeUpload() {
    if (this.notExistingProducts.length > 0) {
      this.dialogType = DialogTypeMode.PRODUCTS
      this.isShowDialog = true
    } else { this.showCatalogDialog() }

  }

  showCatalogDialog() {
    this.isShowDialog = true
    this.dialogType = DialogTypeMode.CATALOG
  }

  uploadFoldersToSever() {
    this.isShowDialog = false
    this.batchUploadService.setImageFoldersToUpload(this.imageFoldersToUpload)
    this.batchUploadService.uploadFolders()
    this.imageFoldersToUpload = {}
  }

  showCsvDialog() {
    this.dialogType = DialogTypeMode.CSV
    this.isShowDialog = true
    this.notExistingProductsCsv.forEach(v => (delete this.imageFoldersToUpload[v]))
  }

  validateProductCsv(productId: string) {
    const product = this.imageFoldersToUpload[productId]

    if (isNull(product) || isUndefined(product)) {
      this.notExistingProductsCsv.push(productId)
      return false
    }

    return true
  }

  showInvalidFormatDialog(path: string) {
    this.incorrectPath = path
    this.dialogType = DialogTypeMode.INCORRECT_PHOTOS_FORMAT
    this.isShowDialog = true
  }

  validatePhotoFormat(file: File) {
    const sheetFormat = 'application/vnd.ms-excel'

    if (file.type === sheetFormat) {
      return file['webkitRelativePath']
    }

    return null
  }
}
