import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserWithPasswordRequest, CreateUserWithPasswordResponse, ListUsersForAdmin } from 'src/app/shared/api-structures/admin/user';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { UserManagementService } from '../../services/user-management.service';
import { CreateUserComponent } from './create-user/create-user.component';
import { saveAs } from "file-saver"
import * as XLSX from 'xlsx';
import { Context } from 'src/app/shared/api-structures/admin/user';
import { Dal } from 'src/app/dal/dal';
import { LanguageService } from '../../services/language.service';
import { mockCsvData } from './csvTemplateData'


@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent extends AutoDestroy implements OnInit {

  isShowModal =  false 
  usersToCreate: CreateUserWithPasswordRequest[]
  arrErrors: string[] = []
  
  importDialogData: any = {
    template: mockCsvData,
    title: "Users",
    id: "Users",
  };
  
  listUsersRequest: ListUsersForAdmin.Request
  context: Context = { company: '', environment: '', organization: '' }

  constructor(private userManagementService: UserManagementService, private dialog: MatDialog, private dal: Dal, private languageService: LanguageService) {
    super()
    this.listUsersRequest = { currentPage: 0, query: '', pageSize: 25 }
  }

  ngOnInit() {
    this.userManagementService.loadUsers(this.listUsersRequest)
  }

  refresh() {
    this.userManagementService.loadUsers(this.listUsersRequest)
  }

  add() {
    const dialogRef = this.dialog.open(CreateUserComponent)
    dialogRef.afterClosed().subscribe(v => {
      if (v.isUserCreated) {
        this.refresh()
      }
    })
  }

  selectExcel() {    
    document.getElementById('excelInput')!.click();
  }

  onInputClick(event: any) {
      event.target.value = ''
  }

  async importExcel(event: any) {

    const res = await this.userManagementService.getAvailableRoles(false, this.context)    
    
    const file = event[0];
    const reader = new FileReader();
    const self = this;
    
    const handleReaderLoad = async (e: any) => {      
      
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {type: 'array'});
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, {raw: true});

      const columnNames = Object.keys(excelData[0]);

      if (columnNames.findIndex(item2=> item2 === "FirstName") == -1 || columnNames.findIndex(item2=> item2 === "LastName") == -1 || columnNames.findIndex(item2=> item2 === "Email") == -1 || columnNames.findIndex(item2=> item2 === "Role") == -1 || columnNames.findIndex(item2=> item2 === "Password") == -1)
      {
        self.arrErrors.push(this.languageService.translateSync('InvalidUsersFile')) 
        this.isShowModal = true                
      }
      else {
      
        let skipItem: boolean = false
        for(let i = 0; i < excelData.length; i++)
        {
          skipItem = false          

          if (excelData[i]["FirstName"] == undefined || excelData[i]["FirstName"] == "")
          {
            skipItem = true
            self.arrErrors.push(this.languageService.translateSync('MissingName') + (i+2))                      
          }

          if (excelData[i]["LastName"] == undefined || excelData[i]["LastName"] == "")
          {
            skipItem = true
            self.arrErrors.push(this.languageService.translateSync('MissingLastName') + (i+2))                      
          }          

          if (excelData[i]["Email"] == undefined || excelData[i]["Email"] == "")
          {
            skipItem = true
            self.arrErrors.push(this.languageService.translateSync('MissingEmail') + (i+2))                     
          }          

          if (excelData[i]["Role"] == undefined|| excelData[i]["Role"] == "")
          {
            skipItem = true
            self.arrErrors.push(this.languageService.translateSync('MissingRole') + (i+2))
          }

          if (excelData[i]["Password"] == undefined|| excelData[i]["Password"] == "")
          {
            skipItem = true
            self.arrErrors.push(this.languageService.translateSync('MissingPassword') + (i+2))                 
          }

          if(res.roles.findIndex(item2=> item2.toLowerCase() === excelData[i]["Role"].toLowerCase()) == -1)            
          {
            skipItem = true
            self.arrErrors.push(this.languageService.translateSync('RoleNotSupported') + (i+2))        
          }          

          if(skipItem == false)
          {
            const user = new CreateUserWithPasswordRequest()
            user.name = excelData[i]["FirstName"] + " " + excelData[i]["LastName"]
            user.email = excelData[i]["Email"]
            user.role = excelData[i]["Role"]
            user.phone = excelData[i]["Phone"]
            user.password = excelData[i]["Password"]

            if (excelData[i]["Phone"] != undefined) {
              user.phone = excelData[i]["Phone"].toString().replace('+', '').replace('-', '').replace('(', '').replace(')', '').replace('*', '').replace('%', '').replace('=', '').replace(' ', '')
            }
            else {
              user.phone = ''
            }

            let result: CreateUserWithPasswordResponse
            try {
              result = await this.userManagementService.createUserWithPassword(user)
              
              if(result.message === '' && result.id != '' && result.id != undefined)
              { 
                               
                if(excelData[i]["PlanogramId"] != undefined)
                {
                  try{
                    await this.dal.addAssociatedUsersToPlanogram({ planogramId: excelData[i]["PlanogramId"], userId: result.id })
                    self.arrErrors.push(this.languageService.translateSync('UserCreatedAndPlanogramAssociated') + (i+2))
                  } catch (error) {
                    self.arrErrors.push(error.toString() + this.languageService.translateSync('AtLine') + (i+2))         
                  }

                  if(excelData[i]["PlanogramId2"] != undefined)
                  {
                    try{
                      await this.dal.addAssociatedUsersToPlanogram({ planogramId: excelData[i]["PlanogramId2"], userId: result.id })
                      self.arrErrors.push(this.languageService.translateSync('PlanogramAssociated').toString().replace("[REPLACE]", "2") + (i+2))
                    } catch (error) {
                      self.arrErrors.push(error.toString() + this.languageService.translateSync('AtLine') + (i+2))         
                    }
                  }
                  if(excelData[i]["PlanogramId3"] != undefined)
                  {
                    try{
                      await this.dal.addAssociatedUsersToPlanogram({ planogramId: excelData[i]["PlanogramId3"], userId: result.id })
                      self.arrErrors.push(this.languageService.translateSync('PlanogramAssociated').toString().replace("[REPLACE]", "3") + (i+2))
                    } catch (error) {
                      self.arrErrors.push(error.toString() + this.languageService.translateSync('AtLine') + (i+2))         
                    }
                  }

                }
                else{
                  self.arrErrors.push(this.languageService.translateSync('UserSaved')  + (i+2))
                }
                
              }
              else{
                if(result.message.toString() == "Error: The email address is already in use by another account.")
                  self.arrErrors.push(this.languageService.translateSync('EmailAddressAlreadyUsed') + this.languageService.translateSync('AtLine') + (i+2)) 
                if(result.message.toString() == "Error: The user with the provided phone number already exists.")
                  self.arrErrors.push(this.languageService.translateSync('PhoneAlreadyUsed') + this.languageService.translateSync('AtLine') + (i+2)) 
                else
                  self.arrErrors.push(result.message.toString()  + this.languageService.translateSync('AtLine') + (i+2)) 
              }
                
            } catch (error) {
              self.arrErrors.push(error.toString() + this.languageService.translateSync('AtLine') + (i+2))      
            }

            this.isShowModal = true
          } 
          
          this.isShowModal = true
          
        }
      }
    };    

    reader.onload = handleReaderLoad;
    reader.removeEventListener('load', handleReaderLoad);
    reader.readAsArrayBuffer(file);
  }

  afterConfirmModal() {
    this.arrErrors = []
    this.isShowModal = false
    this.userManagementService.loadUsers(this.listUsersRequest)    
  }

  async exportUsers() {
    const res = await this.userManagementService.exportUsers()
    saveAs(res.url, "users.csv")
  }
}
