import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterielModule } from '../shared/modules/materiel.module';
import { PrimengModule } from '../shared/modules/primeng.module';
import { SharedModule } from '../shared/shared.module';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { OrganizationAdminComponent } from './components/organization-admin.component';
import { SuperAdminRoutingModule } from './organization-admin-routing.module';
import { CompaniesListComponent } from './components/companies-management/companies-list/companies-list.component';
import { CustomersComponent } from './components/companies-management/customers/customers.component';
import { CompaniesManagementComponent } from './components/companies-management/companies-management.component';
import { CompanySettingsComponent } from './components/companies-management/company-settings/company-settings.component';

function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http) }

@NgModule({
  providers: [
  ],
  declarations: [
    SidenavComponent,
    OrganizationAdminComponent,
    CompaniesListComponent,
    CompaniesManagementComponent,
    CustomersComponent,
    CompanySettingsComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SuperAdminRoutingModule,
    MaterielModule,
    PrimengModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
})
export class OrganizationModule { }