import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListUsersForAdmin } from 'src/app/shared/api-structures/admin/user';
@Component({
  selector: 'associated-users',
  templateUrl: './associated-users.component.html',
  styleUrls: ['./associated-users.component.scss']
})
export class AssociatedUsersComponent implements OnInit {

  @Input() data: ListUsersForAdmin.Item[] = []
  @Input() selected: Record<string, boolean> = {}
  @Output() onSearch = new EventEmitter<string>()
  @Output() onToggleSelect = new EventEmitter<string>()
  @Output() onToggleSelectAll = new EventEmitter<void>()
  @Output() onConfirm = new EventEmitter<void>()
  @Output() onCancel = new EventEmitter<void>()
  searchFilter: string = ''
  allSelected: boolean = false

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.allSelected = this.data.length && this.data.every(o => this.selected[o.id])
  }

  emitSearchFilter(searchFilter: string) {
    this.searchFilter = searchFilter
    this.onSearch.emit(this.searchFilter)
  }

  emitSelectAll() {
    this.onToggleSelectAll.emit()
  }

  emitConfirm() {
    this.onConfirm.emit()
  }

  emitCancel() {
    this.onCancel.emit()
  }

}
