import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetDashboardUrl } from 'src/app/shared/api-structures/admin/dashboard';
import { DashboardService } from '../../admin/services/dashboard.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { getCompanyType } from 'src/app/shared/services/companyType.service';

class DashboardEx extends GetDashboardUrl.Item {
  index: number
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  index = 0
  isExternalDashboards = false
  refreshIntervalId: ReturnType<typeof setInterval>

  dashboards: DashboardEx[] = []
  internalDashboardsUrl: string

  constructor(
    private dashboardService: DashboardService,
    private utilitiesService: UtilitiesService,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    let urlParams = this.utilitiesService.getUrlParams(window.location.href)
    this.isExternalDashboards = urlParams['external'] === 'true'
    this.getDashboardUrl()
    this.refreshIntervalId = setInterval(() => this.getDashboardUrl(), 29 * 60 * 1000)
  }

  async getDashboardUrl() {
    if (this.isExternalDashboards === true) {
      this.getExternalDashboardUrl()
    } else {
      this.getInternalDashboardUrl()
    }
  }

  async getExternalDashboardUrl() {
    const urlRes = await this.dashboardService.getDashboardUrl()
    this.dashboards = []
    for (let i = 0; i < urlRes.items.length; i++) {
      const item = urlRes.items[i]
      this.dashboards.push({ index: i, title: item.title, url: item.url })
    }
  }

  async getInternalDashboardUrl() {
    if (getCompanyType() === 'MC1') {
      const urlRes = await this.dashboardService.getInternalDashboardUrl()
      const lang = this.languageService.getLanguage()
      const url = `${urlRes.url}&lang=${lang}`
      this.internalDashboardsUrl = url
    }
    else {

      const { url } = await this.dashboardService.getInternalDashboardUrl()

      const slashPosition = url.indexOf("/", 8)

      const urlParams = this.utilitiesService.getUrlParams(window.location.href)

      const pageIdentifier = urlParams['pageIdentifier']

      const internalFrameUrl = url.slice(0, slashPosition) + "/" + pageIdentifier + url.slice(slashPosition)

      const lang = this.languageService.getLanguage()

      const dashboardUrl = `${internalFrameUrl}&lang=${lang}`

      const [, queryString] = dashboardUrl.split('?')

      const parameters = new URLSearchParams(queryString);

      if(pageIdentifier)
        parameters.set('screens', pageIdentifier);

      const fullDashboardUrl = dashboardUrl.split('?')[0] + '?' + parameters.toString();

      this.internalDashboardsUrl = fullDashboardUrl
      
    }
  }

  ngOnDestroy() {
    clearInterval(this.refreshIntervalId)
  }
}
