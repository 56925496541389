<div [ngClass]="'association-table-main'">
  <div class="table-content-container">
    <div class="association-table-header">

      <div class="mat-elevation-z4">
        <add-associate (onOptionSelected)="onOptionSelected($event)" [fetchOptions]="fetchAddOptions"
          (onAdd)="addItem($event)" (close)="closeAdd()" class="associate-item">
        </add-associate>
      </div>
    </div>

    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="dataSource" matSort class="custom-table" (matSortChange)="sortData($event)">

        <ng-container *ngFor="let dc of displayedColumns" [matColumnDef]="dc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{dc==='edit' || dc==='delete' ? '' : dc | capitalize | translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- Dates -->
            <span *ngIf="['lastUpdate','createdAt','dateFrom','dateTo'].includes(dc) ">
              {{row[dc] ?moment(row[dc]).format('L') : ''}}
            </span>
            <!-- edit -->
            <a *ngIf="dc === 'edit'" matTooltip="{{'Edit'|translate}}" mat-button (click)="onEdit(row.id)">
              <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
            </a>
            <!-- delete -->
            <a *ngIf="dc === 'delete'" matTooltip="{{'Delete'|translate}}" mat-button
              (click)="showDeleteDialog(row.id)">
              <mat-icon fontSet="fa" fontIcon="fa-trash" style="color:#919191"></mat-icon>
            </a>
            <!-- default -->
            <span
              *ngIf="!['lastUpdate','createdAt','dateFrom','dateTo','edit','delete'].includes(dc)">{{row[dc]}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
      <!-- <div class="table_footer">
        <simple-paginator (page)="onPaginate($event)" [currentPage]="currentPage" [hasNext]="hasNext"></simple-paginator>
      </div> -->
    </div>
  </div>
  <p-dialog [(visible)]="isShowConfirmDeleteDialog">
    <p-header>{{ 'Warning' | translate }}</p-header>
    <p>{{'Delete'|translate}}{{'?'}}</p>
    <div class="dialog-button-container">
      <button mat-flat-button color="primary" (click)="isShowConfirmDeleteDialog=false">
        {{'Cancel'|translate}}
      </button>
      <button mat-flat-button (click)="onDelete()">{{'Delete' | translate}}</button>
    </div>
  </p-dialog>
</div>