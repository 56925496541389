import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FrontScreen } from '../api-structures/admin/user';

@Injectable({
  providedIn: 'root'
})
export class AvailableScreensService {

  private _availableScreens: FrontScreen[] = []
  isAvailableScreensInit
  $isAvailableScreensInit = new BehaviorSubject<boolean>(undefined)

  private availableScreensSubject = new Subject<FrontScreen[]>()
  availableScreens$ = this.availableScreensSubject.asObservable()

  constructor() { }

  set availableScreens(screens: FrontScreen[]) {
    if (!this.isListEquel(this._availableScreens, screens)) {
      this._availableScreens = screens
      this.availableScreensSubject.next(this._availableScreens)
    }
  }

  get availableScreens() {
    return this._availableScreens
  }

  setFromHeader(screensString: string | null) {
    if(!screensString) {
      return
    }
    const screens = (screensString.split(',') as FrontScreen[])
    this.availableScreens = screens
  }

  private isListEquel(l1: FrontScreen[], l2: FrontScreen[]) {
    const isEquel = l1.filter(i => !l2.includes(i))
    if (l1.length === l2.length && isEquel.length === 0) {
      return true
    }
    return false
  }

  activateAvailabeleScreens() {
    this.$isAvailableScreensInit.next(true)
  }

  isScreenAvailable(screen: FrontScreen) {
    return this.availableScreens.includes(screen)
  }
}