import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/authGuard';
import { ApiKeysComponent } from './components/api-keys/api-keys.component';
import { CompaniesManagementComponent } from './components/organizations/companies-management/companies-management.component';
import { CustomersComponent } from './components/organizations/companies-management/customers/customers.component';
import { OrganizationsComponent } from './components/organizations/organizations.component';
import { SuperSettingsComponent } from './components/settings/super-settings.component';
import { SuperAdminHomeComponent } from './components/super-admin-home/super-admin-home.component';
import { SuperAdminComponent } from './components/super-admin.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [
  {
    path: 'super', component: SuperAdminComponent,
    children: [
      { canActivate: [AuthGuard], path: 'organizations', component: OrganizationsComponent },
      { canActivate: [AuthGuard], path: 'organizations/companies', component: CompaniesManagementComponent },
      { canActivate: [AuthGuard], path: 'organizations/companies/customers', component: CustomersComponent },
      { canActivate: [AuthGuard], path: 'settings', component: SuperSettingsComponent },
      { canActivate: [AuthGuard], path: 'userManagement', component: UsersComponent },
      { canActivate: [AuthGuard], path: 'home', component: SuperAdminHomeComponent },
      { canActivate: [AuthGuard], path: 'dashboards', loadComponent: () => import('./components/super-dashboards/super-dashboards.component').then(m => m.SuperDashboardsComponent) },
      { canActivate: [AuthGuard], path: 'apiKeys', component: ApiKeysComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule { }
