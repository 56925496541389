import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { UserRole } from 'src/app/shared/api-structures/admin/user';
import { UserManagementService } from 'src/app/shared/services/user-management.service';


@Component({
  selector: 'update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  userId: string
  name: string
  role: string
  email: string
  phone: string
  roleOptions: string[]
  isSuperAdmin: boolean = false
  userForm: FormGroup
  isLoadingRoles: boolean = false
  displayUserRole: Function

  constructor(public dialogRef: MatDialogRef<UpdateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private utilitiesService: UtilitiesService,
    private userManagementService: UserManagementService
  ) {
    this.userId = data.id
    this.email = data.email
    this.name = data.name
    this.role = data.role
    this.phone = data.phone
    this.displayUserRole = data.displayUserRole    
    this.userForm = this.formBuilder.group({
      nameCtrl: new FormControl({ value: '', disabled: false }),
      roleCtrl: new FormControl(),
    })

  }

  ngOnInit(): void {
    this.getAvailableRoles()
  }

  async getAvailableRoles() {
    this.isLoadingRoles = true
    const res = await this.userManagementService.getAvailableRoles()
    this.roleOptions = res.roles
    this.isLoadingRoles = false
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  async save() {

    if(this.phone != undefined) {
      this.phone = this.phone.toString().replace('+','').replace('-','').replace('(','').replace(')','').replace('*','').replace('%','').replace('=','')
    }
    else {
      this.phone = ''
    }    
    await this.userManagementService.updateUser({ id: this.userId, name: this.name, role: this.role, phone: this.phone })
    this.dialogRef.close({ isUpdated: true });

  }

}
