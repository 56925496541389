import { AfterViewInit, Component } from '@angular/core';
import { CompaniesService } from 'src/app/organization-admin/services/org-admin-companies.service';
import { ListCompaniesRequest } from 'src/app/shared/api-structures/super-admin/company';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';

@Component({
  selector: 'companies-management',
  templateUrl: './companies-management.component.html',
  styleUrls: ['./companies-management.component.scss'],
})
export class CompaniesManagementComponent extends AutoDestroy implements AfterViewInit {
  listCompaniesRequest: ListCompaniesRequest
  organizationId: string

  constructor(private companiesService: CompaniesService) {
    super()

    this.listCompaniesRequest = { currentPage: 0, query: '', pageSize: 25, }
  }

  ngAfterViewInit() {
    this.companiesService.loadCompanies(this.listCompaniesRequest)
  }

  refresh() {
    this.companiesService.loadCompanies(this.listCompaniesRequest)
  }
}