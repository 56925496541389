<div class="mat-elevation-z8">
  <div class="pre-table-header">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup.enter)="applyFilter()" [(ngModel)]="filter" #input>
    </mat-form-field>
  </div>
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="companyId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CompanyId'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="environment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Environment'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.environmentId}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="customers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Customers'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.totalCustomers">{{row.totalCustomers}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">	
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'UpdatedAt'|translate}}</th>	
      <td mat-cell *matCellDef="let row">{{row.lastUpdate | date : 'dd/MM/yyyy HH:mm' }}</td>	
    </ng-container>

    <ng-container matColumnDef="customersIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Customers'|translate}}" mat-button [routerLink]="['customers']"
          [queryParams]="{ organization_id :organizationId, company_id:row.id, environment_id:row.environmentId }">
          <mat-icon style="color:#919191;transform: scale(0.8);">supervisor_account</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="settings">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Settings'|translate}}" mat-button (click)="popupSettings(row.organizationId, row.environmentId, row.id)">
          <mat-icon style="color:#919191;transform: scale(0.8);">settings</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="editIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Edit'|translate}}" mat-button (click)="updateCompany(row.id, row.name, row.environmentId)">
          <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
  </table>

  <mat-paginator [length]="totalCompanies" [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
  </mat-paginator>
</div>
