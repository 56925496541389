import { DatePipe } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { ImageCatalogImage, ListCatalogImagesResponse } from 'src/app/shared/api-structures/misc/catalog';
import { ROUTER_LINK } from 'src/app/shared/const';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import * as _ from 'underscore';
import { Dal } from '../../../dal/dal';
import { AppService } from '../../../services/app.service';
import { DataService } from '../../../services/data.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { uniqueValidator } from '../../../shared/custom-validations';
import { ImagesCatalog, Product } from '../../../shared/interfaces';
import { ConfirmDialogComponent } from '../../general/confirm-dialog/confirm-dialog.component';

export enum MODE {
  EDIT = "edit",
  INSERT = "insert",
  DELETE = "delete"
}

interface ImagesCatalogEx extends ImagesCatalog {
  images?: ImageCatalogImage[]
  selected?: boolean
}

@Component({
  selector: 'app-product-catalogs',
  templateUrl: './product-catalogs.component.html',
  styleUrls: ['./product-catalogs.component.css'],
})


export class ProductCatalogsComponent {
  productId: string
  routerLink: string
  product: Product
  imagesCatalogs: ImagesCatalog[] = []
  catalogSelected: ImagesCatalogEx
  currentMode
  pauseScrollAction = false
  lastScrollTop = 0
  MODE = MODE
  catalogForm: UntypedFormGroup
  catalogsImages: Record<string, ListCatalogImagesResponse> = {}
  editOpen: false
  searchCatalogsValue: string = ''
  uploadingFiles: {
    name: string,
    progress: number
  }[] = []
  constructor(
    private snackbarService: SnackbarService,
    private utilitiesService: UtilitiesService,
    public dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private dal: Dal,
    public dialog: MatDialog,
    private languageService: LanguageService,
    public datepipe: DatePipe,
    private appService: AppService,
    private memoryStorageService: MemoryStorageService,
    private translateService: TranslateService,
  ) {
    if (getCompanyType() === 'MC1') {
      switch (this.memoryStorageService.routerLink) {
        case ROUTER_LINK.allTabs:
          this.routerLink = '/products'
          break
        case ROUTER_LINK.productCatalogs:
          this.routerLink = '/product-catalogs-without-tabs'
          break
        default:
          this.routerLink = '/products'
          break
      }
    } else {
      this.routerLink = '/products'
    }
    this.catalogForm = this.formBuilder.group({
      nameCtrl: new UntypedFormControl('', Validators.required),
      widthCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
      heightCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
    })

    let urlParams = this.utilitiesService.getUrlParams(window.location.href)
    if (!urlParams['product_id']) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('InvalidUrl'))
      this.router.navigateByUrl('/')
    } else {
      this.productId = urlParams['product_id']
      this.loadProduct(urlParams['product_id'])
    }

  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight
    if (this.lastScrollTop < document.documentElement.scrollTop) {
      if (maxScroll - document.documentElement.scrollTop < 100 && !this.pauseScrollAction) {
        this.pauseScrollAction = true
        this.getCatalogImages(this.catalogSelected.id)
      }
    }
    this.lastScrollTop = document.documentElement.scrollTop

  }

  async loadProduct(productId: string) {
    const res = await this.dal.getProduct(productId)
    this.imagesCatalogs = res.imageCatalogs.sort((a, b) => a.name.localeCompare(b.name))
    this.product = this.dataService.prepareProduct(res.products[0], this.imagesCatalogs)
  }

  async getCatalogImages(catalogId) {
    if (this.catalogsImages[catalogId] && !this.catalogsImages[catalogId].nextPageToken) { return }
    const pageToken = this.catalogsImages[catalogId]?.nextPageToken
    const res = await this.dal.listCatalogImages({ productId: this.productId, catalogId, pageToken, direction: 'desc' })
    this.catalogsImages[catalogId] = { ...res, images: [...this.catalogsImages[catalogId]?.images || [], ...res.images] }
    this.catalogSelected.images = this.catalogsImages[catalogId].images
    this.pauseScrollAction = false
  }

  getPrimaryImage(catalog: ImagesCatalogEx) {
    return catalog.thumbnailUrl
  }

  // async inactiveChange(inactive: boolean) {
  //   if (confirm(this.languageService.translateSync('ToggleProductInactive'))) {
  //     try {
  //       const res = await this.dal.editProduct({ id: this.productId, inactive })
  //       this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ProductUpdated'))
  //       this.product.inactive = inactive
  //     }
  //     catch (err) {
  //       this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ErrorOccurred'))
  //     }
  //   }
  // }

  cloneCatalog(catalogOrg: ImagesCatalogEx, catalog: ImagesCatalogEx) {
    catalog = this.utilitiesService.deepCopy(catalogOrg)
    return catalog
  }

  addImagesCatalog() {
    this.cancelCatalog()
    this.currentMode = MODE.INSERT
    this.catalogSelected = {
      id: null,
      mc1ProductId: this.product.mc1Id,
      name: undefined,
      width: undefined,
      height: undefined,
      images: [],
      thumbnailUrl: ''
    }
    this.catalogForm.controls['nameCtrl'].setValidators(uniqueValidator('name', 'id', true, null, this.imagesCatalogs))
  }

  async addCatalog() {
    if (!this.isValidationCatalog()) {
      return
    }
    if (this.catalogSelected.images.length == 0) {
      this.snackbarService.openSnackBar(2000, "Please upload primary image for catalog")
      return
    }

    const catalogRes: ImagesCatalog = await this.dal.addImagesCatalog(this.catalogSelected.mc1ProductId, this.catalogSelected.name, this.catalogSelected.height, this.catalogSelected.width, this.catalogSelected.images[0].fileLocation, this.catalogSelected.depth)
    this.imagesCatalogs = [...this.imagesCatalogs, catalogRes].sort((a, b) => a.name.localeCompare(b.name))
    this.dataService.prepareProduct(this.product, this.imagesCatalogs)
    this.editCatalog(null, catalogRes)
  }

  async removeCatalog(catalog: ImagesCatalog) {

    if (!confirm(this.languageService.translateSync('RemoveCatalog'))) {
      return
    }

    const isCatalogAssociatedWithPlanogram = await this.dal.getPlanogramFromCatalog(catalog.id, catalog.mc1ProductId)

    if (isCatalogAssociatedWithPlanogram.totalPlanograms > 0) {
      let errorMessage = ''
      this.translateService.get('ImageCatalogCannotBeDeleted').subscribe(res => {
        errorMessage = res
      })

      this.snackbarService.openSnackBar(5000, errorMessage)
      return
    }
    await this.dal.deleteImageCatalog(catalog.mc1ProductId, catalog.id, false)
    this.imagesCatalogs = this.imagesCatalogs.filter(el => el.id !== catalog.id)
    this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ImageCatalogDeletedSuccessfully'))
    if (catalog.id == this.catalogSelected.id) {
      this.cancelCatalog()
    }
    this.dataService.prepareProduct(this.product, this.imagesCatalogs)
  }

  catalogFilter = (catalog, val: string) => {
    if (!val) { return true }
    val = val.toLocaleLowerCase()
    return catalog.name.toLocaleLowerCase().includes(val)
  }

  editCatalog(event, catalog: ImagesCatalog) {
    if (event && event.target.classList.contains("mat-icon")) {
      return
    }
    this.editOpen = false
    this.currentMode = MODE.EDIT
    this.catalogSelected = this.cloneCatalog(catalog, this.catalogSelected)
    this.catalogSelected.images = this.catalogsImages[this.catalogSelected.id]?.images || []
    this.catalogForm.controls['nameCtrl'].setValidators(uniqueValidator('name', 'id', false, this.catalogSelected.id, this.imagesCatalogs))
    if (!this.catalogSelected.images.length) this.getCatalogImages(this.catalogSelected.id)
  }

  async uploadImages(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      throw new Error('uploadImages event.target should be of type HTMLInputElement')
    }

    const files = event.target.files

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      const uploadFunc = async () => {
        const uploadProgress = { name: file.name, progress: 0 }

        if (this.utilitiesService.validateImageType(file) == false) {
          return
        }

        this.uploadingFiles.push(uploadProgress)
        const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => uploadProgress.progress = progress, true)
        const fileValid = await this.utilitiesService.isFileUrlExist(fileUrl)

        if (fileValid === false) {
          this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
          return
        }

        let response

        try {
          response = await this.dal.uploadImageCatalog(this.product.mc1Id, this.catalogSelected.id, fileUrl)
          this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
          this.catalogsImages[this.catalogSelected.id].images.unshift(response)
          this.catalogSelected.images = this.catalogsImages[this.catalogSelected.id].images
        } catch (e) {
          this.uploadingFiles.pop()
          return
        }
      }

      uploadFunc()
    }
  }

  async uploadPrimaryImage(event: { target: { files: File[] } }) {
    const file: File = event.target.files[0]

    if (this.utilitiesService.validateImageType(file) == false) {
      return
    }

    const uploadProgress = { name: file.name, progress: 0 }
    this.uploadingFiles = [uploadProgress]
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadingFiles[0].progress = progress)
    const resultFileCreation = await this.utilitiesService.isFileUrlExist(fileUrl)

    if (resultFileCreation === false) {
      this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
      return
    }

    this.catalogSelected.images.push({ fileLocation: fileUrl, isPrimary: true, thumbnailPath: fileUrl, lastUpdate: new Date() })
    this.uploadingFiles = []
  }

  uploadPrimaryImageBtnClick() {
    document.getElementById("singleFile").click()
  }

  uploadImageBtnClick() {
    document.getElementById("multipleFiles").click()
  }

  async saveImage(imageSrc) {
    const image = await fetch(imageSrc)
    const imageBlob = await image.blob()
    const imageURL = URL.createObjectURL(imageBlob)
    const link = document.createElement('a')
    link.href = imageURL
    link.download = this.product.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async setPrimaryImage(imageId: string, imgUrl) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.languageService.translateSync('ClickingYesAllPlanogramsWillBeChangedWithTheSelectedImage') }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }

      const isCatalogAssociatedWithPlanogram = await this.dal.getPlanogramFromCatalog(this.catalogSelected.id, this.product.mc1Id)

      if (isCatalogAssociatedWithPlanogram.totalPlanograms > 0) {
        await this.dal.updateImageCatalogFromPlanograms(isCatalogAssociatedWithPlanogram.planograms, this.catalogSelected.id, imgUrl)
      }

      await this.dal.updatePrimaryImageCatalog(this.product.mc1Id, this.catalogSelected.id, imageId)
      this.setPrimaryImageLocaly(imageId)
      this.loadProduct(this.product.mc1Id)
    })
  }

  // localy set primary image instead getting images from server again
  async setPrimaryImageLocaly(imgId) {
    this.catalogSelected.images.forEach(i => i.isPrimary = false)
    const newPrimaryImage = this.catalogSelected.images.find(i => i.fileLocation == imgId)
    newPrimaryImage.isPrimary = true
    this.catalogsImages[this.catalogSelected.id].images = this.catalogSelected.images
  }

  async removeImage(fileLocation) {
    await this.dal.deleteImageFromImageCatalog(this.product.mc1Id, this.catalogSelected.id, fileLocation)
    let indexFound = _.findIndex(this.catalogSelected.images, i => i.fileLocation == fileLocation)

    if (indexFound != -1) {
      this.catalogsImages[this.catalogSelected.id].images.splice(indexFound, 1)
    }

    this.catalogSelected.images = this.catalogsImages[this.catalogSelected.id].images
  }

  cancelCatalog() {
    this.catalogForm.reset()
    this.currentMode = null
    this.catalogSelected = null
  }

  isValidationCatalog() {
    if (!this.catalogForm.valid) {
      this.utilitiesService.validateAllFormFields(this.catalogForm)
      return false
    }

    return true
  }

  async saveChangesCatalog() {
    if (!this.isValidationCatalog()) {
      return
    }

    const organizateScreen = (imageCatalogRes) => {
      this.imagesCatalogs = this.imagesCatalogs.filter(o => o.id !== imageCatalogRes.id)
      this.imagesCatalogs = [...this.imagesCatalogs, imageCatalogRes].sort((a, b) => a.name.localeCompare(b.name))
    }

    const isCatalogAssociatedWithPlanogram = await this.dal.getPlanogramFromCatalog(this.catalogSelected.id, this.product.mc1Id)
    if (isCatalogAssociatedWithPlanogram.totalPlanograms > 0 &&
      (this.catalogForm.controls.widthCtrl.touched ||
      this.catalogForm.controls.heightCtrl.touched)) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { message: this.languageService.translateSync('AllPlanogramsThatContainsThisCatalogMustBeCreatedAgain') }
      })

      dialogRef.afterClosed().subscribe(async confirmResult => {
        if (!confirmResult) {
          return
        }

        const catalog = {
          mc1ProductId: this.catalogSelected.mc1ProductId,
          id: this.catalogSelected.id,
          name: this.catalogSelected.name,
          height: this.catalogSelected.height,
          width: this.catalogSelected.width,
          depth: this.catalogSelected.depth,
          file: ''
        }

        await this.dal.updateInfoCatalogFromPlanograms(isCatalogAssociatedWithPlanogram.planograms, catalog)
      })
    }
    const imageCatalogRes = await this.dal.updateImageCatalog(this.catalogSelected.mc1ProductId, this.catalogSelected.id, this.catalogSelected.name, this.catalogSelected.height, this.catalogSelected.width, this.catalogSelected.depth, true)
    organizateScreen(imageCatalogRes)
    this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ImageCatalogSavedSuccessfully'))
  }
}

