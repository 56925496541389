
<div style="padding:20px">

	<h2 mat-dialog-title>{{'Update'|translate}}
		<br/> 
	</h2>

	<div class="row">
		<div class="col-sm-6">
			<mat-form-field>
				<input matInput placeholder="{{'Name'|translate}}" [(ngModel)]="name">
			</mat-form-field>
		</div>

		<div class="col-sm-6">
			<mat-form-field>					
				<input matInput placeholder="{{'Email'|translate}}" value="{{email}}" disabled><br><br>					
			</mat-form-field>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-6">
			<mat-form-field>					
				<input [minlength]="11" matInput placeholder="{{'Phone'|translate}}" [(ngModel)]="phone"  type="string"><br><br>
			</mat-form-field>
		</div>

		<div class="col-sm-6">
			<mat-form-field>
				<mat-select [(value)]="role" placeholder="{{'Role'|translate}}" aria-label="{{'Role'|translate}}">
					<mat-option *ngFor="let role of roleOptions" [value]="role">{{displayUserRole(role)|translate}}</mat-option>
					<mat-option *ngIf="isSuperAdmin" value="super_admin">{{'SuperAdmin'|translate}}</mat-option>
				</mat-select>
				<mat-icon matSuffix *ngIf="isLoadingRoles">
					<i class="fa fa-spinner fa-spin"></i>
				</mat-icon>
			</mat-form-field>
		</div>
	</div>
	
	<div class="row">
		<div class="col-sm-12">
			<div mat-dialog-actions>
				<button mat-button color="primary" (click)="onCancelClick()" matTooltip="{{'CANCEL'|translate}}">
					<mat-icon color="primary">cancel</mat-icon>
				</button>
				<button mat-button color="primary" cdkFocusInitial (click)="save()" matTooltip="{{'SaveChanges'|translate}}">
					<mat-icon color="primary">save</mat-icon>
				</button>
			</div>
		</div>
	</div>
</div>