<div class="light_card main">
  <div class="header flex">
    <div class="flex-1">
      <button mat-flat-button color="warn" (click)="removeRule()" class="float-right">
        <mat-icon>delete</mat-icon>
        {{'DeleteRule' | translate}}
      </button>
    </div>  
  </div>

  <form [formGroup]="form">
    <div class="flex flex_gap_15">
      <div class="flex-3">
        <mat-form-field>
          <mat-label>{{'FieldName' | translate}}</mat-label>
          <mat-select formControlName="fieldName">
            <mat-option *ngFor="let val of customFieldSettings" [value]="val.name">
              {{val.name}} ({{val.fieldType}})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-3">
        <custom-field-input *ngIf="fieldSettings" [fieldType]="fieldSettings.fieldType"
          [required]="fieldSettings.isRequired" [placeholder]="fieldSettings.name" [options]="fieldSettings.options"
          formControlName="value"></custom-field-input>
        <mat-form-field *ngIf="!fieldSettings">
          <input matInput placeholder="{{'SelectFieldType' | translate}}" disabled>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>