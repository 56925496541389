// tslint:disable: max-classes-per-file
import { classToPlain, ClassTransformOptions, plainToClass } from 'class-transformer'
import { ClassType, transformAndValidateSync } from 'class-transformer-validator'
import { ValidationError, ValidatorOptions } from 'class-validator'

export class ValidationErrorEx extends Error {
    isValidationError = true; name = 'Validation Error'
    errors: string[]

    constructor(errors: string[]) { super(); this.errors = errors }
}

export class DataTransformer {
    validatorOptions: ValidatorOptions = {}
    transformerOptions: ClassTransformOptions = {}

    toPlainObject(o: any) {
        const result = classToPlain(o)
        return result as any
    }

    transform<T extends object>(classType: ClassType<T>, o: any) {
        const result = plainToClass(classType, o, this.transformerOptions)
        return result
    }

    transformAndValidate<T extends object>(classType: ClassType<T>, o: any) {
        try {
            const result = transformAndValidateSync(classType, o, {
                validator: this.validatorOptions,
                transformer: this.transformerOptions,
            })

            return result as T
        } catch (err: any) {
            if (this.isValidationError(err) === true) {
                const errors = this.simplifyValidationErrors(err)
                const se = new ValidationErrorEx(errors)
                throw se
            }
            throw err
        }
    }

    private isValidationError(err: any) {
        let a = err

        while (Array.isArray(a) === true) {
            if (a.length === 0) {
                return false
            }

            a = a[0]
        }

        const result = a instanceof ValidationError
        return result
    }

    private simplifyValidationErrors(verr: any[]) {
        const result: string[] = []

        for (const ver of verr) {
            if (Array.isArray(ver) === true) {
                this.simplifyValidationErrors(ver).forEach(v => result.push(v))
            } else {
                this.simplifyValidationError(ver).forEach(v => result.push(v))
            }
        }

        return result
    }

    private simplifyValidationError(verr: ValidationError) {
        const result: string[] = []

        if (verr.constraints !== undefined) {
            Object.keys(verr.constraints as object)
                .forEach(key => result.push(verr.constraints![key]))
        }

        if (verr.children !== undefined) {
            for (const child of verr.children) {
                this.simplifyValidationError(child).forEach(v => result.push(v))
            }
        }

        return result
    }
}