import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callbackFilter',
  standalone: true
})
export class CallbackFilterPipe implements PipeTransform {
  transform(items: any[], callback: (item: any, val?: any) => boolean, val?: any): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item, val));
  }
}
