<div *ngIf="nodes" class="container">
    <p-tree [value]="nodes">
        <ng-template let-node pTemplate="editable">
            <div *ngIf="!node.data.isEditMode">
                <div class="p">{{node.label}}: <b>{{node.data.val}}</b></div>
                <button pButton pRipple type="button" icon="pi pi-user-edit" class="p-button-rounded"
                    (click)="edit(node)"></button>
            </div>

            <ng-container *ngIf="node.data.isEditMode">
                <ng-container *ngIf="_.isString(node.data.val)">
                    <span class="p-float-label">
                        <input id="float-input" style="width: 40rem" type="text" pInputText
                            [(ngModel)]="node.data.newVal">
                        <label for="float-input">{{node.label}}</label>
                    </span>
                </ng-container>

                <ng-container *ngIf="_.isNumber(node.data.val)">
                    <p-inputNumber [(ngModel)]="node.data.newVal" mode="decimal" [showButtons]="true"></p-inputNumber>
                </ng-container>

                <ng-container *ngIf="_.isBoolean(node.data.val)">
                    <p-dropdown [options]="[{label: 'false',value: false},{label: 'true',value: true}]"
                        [(ngModel)]="node.data.newVal" placeholder="{{node.label}}"></p-dropdown>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="node.data.isEditMode">
                <button pButton pRipple type="button" icon="pi pi-check"
                    class="p-button-rounded p-button-info p-button-text" (click)="saveEdit(node)"></button>
                <button pButton pRipple type="button" icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-text" (click)="cancelEdit(node)"></button>
            </ng-container>
        </ng-template>
    </p-tree>
</div>