import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerWithId } from 'src/app/shared/api-structures/admin/customer';

@Component({
  selector: 'associated-customers-card',
  templateUrl: './associated-customers-card.component.html',
  styleUrls: ['./associated-customers-card.component.scss']
})
export class AssociatedCustomersCardComponent implements OnInit {

  // @Input() data:
  @Input() selected = false
  @Input() id: string
  @Input() data: CustomerWithId
  @Output() onToggleSelect = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
  }

  emitToggleSelect() {
    this.onToggleSelect.emit(this.id)
  }
  
}
