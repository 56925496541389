import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListUsersForAdmin } from 'src/app/shared/api-structures/admin/user';
import { ListImagestoApproveFilter } from 'src/app/shared/api-structures/misc/toApprove';
import { UserManagementService } from 'src/app/shared/services/user-management.service';

@Component({
  selector: 'app-unapproved-images-filters',
  template: `
  <h2>{{'FilterOptions'|translate}}</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill">
      <mat-label>{{'UpdatedAt'|translate}}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
      <input matStartDate [placeholder]="'StartDate' | translate" formControlName="dateStart">
        <input matEndDate [placeholder]="'EndDate' | translate" formControlName="dateEnd">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="fill">
    <input matInput [placeholder]="'SearchUser' | translate"  [matAutocomplete]="auto"
          (keyup)="listUsersForAutocomplete($event.target.value)"
          [(value)]="filter" #MatAutocompleteTrigger>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)"
          [displayWith]="displayFn">
          <mat-option *ngFor="let option of users" [value]="option">
              {{option.name}} - {{option.email}}
          </mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix *ngIf="isLoadingUsers">
          <i class="fa fa-spinner fa-spin"></i>
      </mat-icon>
    </mat-form-field>
    <div *ngFor="let user of usersSelected" class="users-selected" (click)="removeUser(user.id)">
      {{user.name}}
    </div><br>

    <mat-form-field appearance="fill">
      <mat-label>{{'Origin'|translate}}</mat-label>
      <mat-select formControlName="origin">
        <mat-option value="">{{'All'|translate}}</mat-option>
        <mat-option value="Mobile">{{'Mobile'|translate}}</mat-option>
        <mat-option value="Web">{{'Web'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'Recognition'|translate}}</mat-label>
      <mat-select formControlName="methodRecognition">
        <mat-option value="">{{'All'|translate}}</mat-option>
        <mat-option value="user_feedback">{{'Manual'|translate}}</mat-option>
        <mat-option value="machine_learning">{{'Automatic'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button type="submit" [disabled]="!form.valid">{{'Submit'|translate}}</button>
  </form>
  `,
  styles: [`
  .slider-div {
    width: 100%;
  };
  .users-selected:hover {
    cursor: pointer;
    color: red;
  }
`]
})
export class FilterUnapprovedImageDialog {

  form = new FormGroup({
    dateStart: new FormControl(this.data.dateStart),
    dateEnd: new FormControl(this.data.dateEnd),
    user: new FormControl(this.data.userIds),
    origin: new FormControl(this.data.origin),
    methodRecognition: new FormControl(this.data.methodRecognition),
  });
  users: ListUsersForAdmin.Item[] = []
  usersSelected = []
  isLoadingUsers: boolean = false
  filter: string = ''

  constructor(public dialogRef: MatDialogRef<FilterUnapprovedImageDialog>,
    @Inject(MAT_DIALOG_DATA) private data: ListImagestoApproveFilter,
    private userManagementService: UserManagementService) { }

  onSubmit() {
    const dateStart = this.form.value.dateStart;
    const endOfDay = new Date(this.form.value.dateEnd);
    endOfDay.setHours(23, 59, 59, 999);
    const dateEnd = endOfDay;
    const user = this.usersSelected.map(u => {return u.id});
    const origin = this.form.value.origin === "" ? null : this.form.value.origin;
    const methodRecognition = this.form.value.methodRecognition === "" ? null : this.form.value.methodRecognition;

    delete this.form.value.dateStart;
    delete this.form.value.dateEnd;
    this.usersSelected = []
    delete this.form.value.origin;
    delete this.form.value.methodRecognition;

    this.dialogRef.close({ dateStart, dateEnd, user, origin, methodRecognition });
  }

  removeUser(userId) {
    this.usersSelected = this.usersSelected.filter(u => {return u.id !== userId})
  }

  listUsersForAutocomplete(value) {
    if (value.length > 2) {
      this.getUsers(50, value.toLowerCase())
    }
  }

  async getUsers(pageSize: number, query: string) {
    this.isLoadingUsers = true
    try {
      const usersRes = await this.userManagementService.loadUsers({ currentPage: 0, pageSize, query }, true)
      this.users = usersRes.items
    } finally {
      this.isLoadingUsers = false
    }
  }

  async optionSelected(option) {
    this.usersSelected.push({id: option.value.id, name: option.value.name})
  }

  displayFn(user: ListUsersForAdmin.Item): string {
    return user && user.name ? user.name : this.filter
  }
}
