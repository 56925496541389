import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaService } from 'src/app/admin/services/area.service';
import { ClusterService } from 'src/app/shared/services/cluster.service';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { ClusterWithId } from 'src/app/shared/api-structures/admin/cluster';
import { Area, ListAreasRequest } from 'src/app/shared/api-structures/misc/area';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { MapComponent, PolyData } from 'src/app/shared/components/map/map.component';
import { ClusterAssociationsComponent } from '../cluster-associations/cluster-associations.component';
import { unique, without } from 'underscore';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CustomFieldSettingsService } from 'src/app/admin/services/customFieldSettings.service';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';
import { CustomFieldValue } from 'src/app/shared/api-structures/common';
import { CustomField } from 'src/app/shared/components/custom-fields/custom-fields.component';

@Component({
  selector: 'app-add-edit-cluster',
  templateUrl: './add-edit-cluster.component.html',
  styleUrls: ['./add-edit-cluster.component.scss']
})
export class AddEditClusterComponent extends AutoDestroy implements OnInit {

  @ViewChild('map') mapElement: MapComponent
  selectedArea: Area | null = null;
  areas: Area[] = []
  polygons: PolyData[] = []
  categories: string[] = []
  territories: string[] = []
  chains: string[] = []
  mode: 'add' | 'edit' = 'add'
  cluster: ClusterWithId | null = null
  customerCountLoad = false
  customersCount = 0
  ccTimer
  clusterForm = new FormGroup({
    name: new FormControl(''),
    customerChains: new FormControl([]),
    customerTypes: new FormControl([]),
    customerCategories: new FormControl([]),
    customerTerritories: new FormControl([]),
    regions: new FormControl([]),
    additionalCustomers: new FormControl([]),
    removedCustomers: new FormControl([]),
    customFields: new FormArray([])
  })
  query: ListAreasRequest = {
    currentPage: 0,
    pageSize: 1000
  }
  advancedRules: any[] = []
  customerCustomFields: CustomFieldSettingsWithId[] = []

  constructor(
    private clusterService: ClusterService,
    private areaService: AreaService,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private languageService: LanguageService,
    private customFieldSettingsService: CustomFieldSettingsService,
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') !== 'add') {
      this.mode = 'edit'
      this.loadCluster(this.route.snapshot.paramMap.get('id'))
      this.countCustomers()
    } 
    this.getCustomerAttributes()
    this.subscriptions.push(this.areaService.areas$.subscribe((data) => {
      this.onAreasChange(data)
    }))
    this.areaService.listAreas(this.query)
    this.subscriptions.push(this.clusterForm.get('regions').valueChanges.subscribe((data) => {
      this.setPolygons()
      const paths: google.maps.LatLngLiteral[] = []
      this.polygons.forEach(polygon => {
        if (data.includes(polygon.id)) {
          paths.push(...polygon.options.paths as google.maps.LatLngLiteral[])
        }
      })
      if (paths.length) {
        this.mapElement.centerPolygon(paths)
      }
    }))
    this.subscriptions.push(this.clusterForm.valueChanges.subscribe((val) => {
      this.countCustomersDelayed()
    }))
    this.fetchCustomFieldSettings()
  }

  async fetchCustomFieldSettings() {
    const { items } = await this.customFieldSettingsService.listCustomFieldsByEntity({ id: 'customers' })
    this.customerCustomFields = items
  }

  async loadCluster(id: string) {
    this.cluster = await this.clusterService.getCluster(id)

    for (const field in this.clusterForm.controls) {
      if (this.cluster[field] === undefined) continue
      if (field === 'customFields') {
        if (this.cluster.customFields) {
          Object.entries(this.cluster.customFields).forEach(([key, value]) => this.addAdvancedRule(key, value))
        }
      } else {
        this.clusterForm.get(field).setValue(this.cluster[field])
      }

    }
  }

  countCustomersDelayed() {
    clearTimeout(this.ccTimer)
    this.customerCountLoad = true
    this.ccTimer = setTimeout(async () => {
      await this.countCustomers()
      this.customerCountLoad = false
    }, 1500)

  }

  async countCustomers() {
    try {
      const result = await this.clusterService.countCustomersByCluster({ cluster: this.tempCluster() })
      this.customersCount = result.count
    } catch (error) {
      throw error
    }
  }

  async getCustomerAttributes() {
    const attr = await this.customerService.listCustomersAttributes()
    this.categories = attr.categories
    this.chains = attr.chains
    this.territories = attr.territories
  }

  onAreasChange(areas: Area[]) {
    this.areas = areas
    this.setPolygons()
  }

  back() {
    this.router.navigate(['/admin/clusters'])
  }

  async save() {
    this.clusterForm.markAllAsTouched()

    if (this.clusterForm.valid) {
      const customFields = this.arrToCustomFields(this.clusterForm.get('customFields').value)
      const clusterToSend = {
        ...this.clusterForm.value,
        customersCount: this.customersCount,
        name: this.clusterForm.get('name').value.trim(),
        customFields: customFields
      }
      try {
        if (this.mode === 'add') {
          await this.clusterService.addCluster(clusterToSend)
        } else {
          await this.clusterService.updateCluster({ id: this.cluster?.id, createdAt: this.cluster?.createdAt, ...clusterToSend })
        }
        this.back()
      } catch (error) {
        this.snackbarService.openSnackBar(2000, this.languageService.translateSync(`AddClusterError`))
      }
    }
  }

  setPolygons() {
    this.polygons = this.areas.map(area => {
      return this.areaService.createPolygonData(area, (polygon: PolyData) => {
        this.mapElement.centerPolygon(polygon.options.paths as google.maps.LatLngLiteral[])
      }, this.clusterForm.get('regions').value.includes(area.id) ? 'green' : '#0000FF')
    })
  }

  // for searching customers
  tempCluster() {
    const customFields = this.arrToCustomFields(this.clusterForm.get('customFields').getRawValue())
    const tempCluster = {
      ...this.clusterForm.value,
      name: 'temp name',
      customFields: customFields,
    }
    return tempCluster
  }

  addAdvancedRule(fieldName = '', value: CustomFieldValue = '') {
    const arControl = new FormGroup({
      fieldName: new FormControl(fieldName),
      value: new FormControl(value)
    })
    this.clusterForm.controls['customFields'].push(arControl)
  }

  removeAdvancedRule(index: number) {
    this.clusterForm.controls['customFields'].removeAt(index)
  }

  advancedRuleChanged(rule: { id: string, fieldName: string, value: CustomFieldValue }) {
    const index = this.advancedRules.findIndex(r => r.id === rule.id)
    this.advancedRules[index] = rule
  }

  showCustomersList() {
    const dialogRef = this.dialog.open(ClusterAssociationsComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '60vw',
      height: '80vh',
      data: {
        name: 'clusterName',
        associationType: 'cluster',
        id: this.cluster?.id,
        add: (id: string) => {
          const additionalCustomers = unique([...this.clusterForm.get('additionalCustomers').value, id])
          this.clusterForm.get('additionalCustomers').setValue(additionalCustomers)

          const removedCustomers = without(this.clusterForm.get('removedCustomers').value, id)
          this.clusterForm.get('removedCustomers').setValue(removedCustomers)
        },
        delete: (id: string) => {
          const additionalCustomers = without(this.clusterForm.get('additionalCustomers').value, id)
          this.clusterForm.get('additionalCustomers').setValue(additionalCustomers)

          const removedCustomers = unique([...this.clusterForm.get('removedCustomers').value, id])
          this.clusterForm.get('removedCustomers').setValue(removedCustomers)
        },
        fetch: (currentPage: number, pageSize: number, query: string) => {
          return this.customerService.listClusterCustomers({ cluster: this.tempCluster(), currentPage, pageSize, query })
        },
        fetchCustomers: async (currentPage: number, pageSize: number, query: string) => {
          const result = await this.customerService.loadCustomers({ currentPage, pageSize, query }, true)
          const customers = result.items.map(customer => {
            return {
              key: customer.id,
              displayName: `${customer.id} - ${customer.name}`
            }
          })
          return customers
        }
      }
    });
    const instance = dialogRef.componentInstance

    // dialogRef.afterClosed().subscribe(async priceTarget => {})
  }

  private arrToCustomFields(arr: CustomField[]) {
    const res = arr.reduce((acc, v) => acc = { ...acc, [v.fieldName]: v.value }, {})
    return res
  }
}
