import PQueue from 'p-queue'

export class SlowPromises {
    queue: PQueue

    constructor(limit: number) {
        this.queue = new PQueue({ concurrency: limit })
    }

    add(fun: () => Promise<void>) {
        this.queue.add(fun)
    }

    async whenAll() {
        await this.queue.onIdle()
    }
}