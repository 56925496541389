import { CompanyType } from "../api-structures/common"

let _companyType: CompanyType | undefined

export function getCompanyType() {

  const fog = localStorage.getItem('fog') as CompanyType// force org type, for development
  const wtmToken = localStorage.getItem('token')
  if (fog === 'MC1' || fog === 'Standalone') {
    _companyType = fog
  } else if (window.location !== window.parent.location || window.location.href.includes('/communicator') || wtmToken) {
    _companyType = 'MC1'
  } else {
    _companyType = 'Standalone'
  }
  return _companyType
}
