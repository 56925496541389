import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EquipmentWithId } from 'src/app/shared/api-structures/misc/equipment';
import { DataService } from '../../../services/data.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { uniqueValidator } from '../../../shared/custom-validations';
import { EquipmentService } from 'src/app/super-admin/services/equipment.service';
import { Dal } from 'src/app/dal/dal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-equipment-dialog',
  templateUrl: './create-equipment-dialog.component.html',
  styleUrls: ['./create-equipment-dialog.component.scss']
})

export class CreateEquipmentDialogComponent implements OnInit {
  equipmentEdit: EquipmentWithId
  equipmentForm;
  equipment: EquipmentWithId;
  isUpdating: boolean = false;
  step

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { equipment: EquipmentWithId },
    public dialogRef: MatDialogRef<CreateEquipmentDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private utilitiesService: UtilitiesService,
    private dataService: DataService,
    private equipmentService: EquipmentService,
    private dal: Dal) {}

  ngOnInit() {
    this.createEquipmentEntity()
  }

  onChangeShelfHeight(shelf) {
    if ((shelf.shelfHeight + shelf.shelfSeparatorHeight) > this.equipment.width && this.equipment.shelves?.length > 1) {
      this.equipment.width = (shelf.shelfHeight + shelf.shelfSeparatorHeight)
    }
  }

  createFormValidation() {
    this.equipmentForm = this.formBuilder.group({
      nameCtrl: new UntypedFormControl('', [Validators.required, uniqueValidator('name', 'id', true, null, this.dataService.equipments)]),
      widthCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(1000)]),
      isVerticalCtrl: new UntypedFormControl(''),
      backgroundColorCtrl: new UntypedFormControl(''),
      shelves: this.formBuilder.array([this.createShelfValidation()])
    })
  }

  createShelfValidation(obj?: any): FormGroup {
    return this.formBuilder.group({
      heightCtrl: new UntypedFormControl(obj?.shelfHeight ?? '', [Validators.required, Validators.min(1), Validators.max(1000)]),
      depthCtrl: new UntypedFormControl(obj?.depth ?? '', [Validators.min(1), Validators.max(99)]),
      shelfSeparatorHeightCtrl: new UntypedFormControl(obj?.shelfSeparatorHeight ?? '', [Validators.min(1), Validators.max(99)])
    })
  }

  createEquipmentEntity() {
    if (this.data) {
      this.equipmentEdit = this.data.equipment
      this.equipment = this.data.equipment
      this.isUpdating = true
      this.equipmentForm = this.formBuilder.group({
        nameCtrl: new UntypedFormControl('', [Validators.required, uniqueValidator('name', 'id', true, null, this.dataService.equipments)]),
        widthCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(1000)]),
        isVerticalCtrl: new UntypedFormControl(''),
        backgroundColorCtrl: new UntypedFormControl(''),
        shelves: this.formBuilder.array([])
      })
      this.equipment = {
        id: this.equipmentEdit.id,
        name: this.equipmentEdit.name,
        isVertical: this.equipmentEdit.isVertical,
        width: this.equipmentEdit.width,
        backgroundColor: this.equipmentEdit.backgroundColor,
        status: this.equipmentEdit.status ?? 'active',
        shelves: [],
        isUpdating: this.isUpdating
      }
      this.equipmentEdit.shelves.forEach((shelf) => {
        this.addShelf()
        this.equipment.shelves[this.equipment.shelves.length - 1].depth = shelf.depth
        this.equipment.shelves[this.equipment.shelves.length - 1].shelfHeight = shelf.shelfHeight
        this.equipment.shelves[this.equipment.shelves.length - 1].shelfSeparatorHeight = shelf.shelfSeparatorHeight
      })
      return
    }
    this.equipment = {
      id: null,
      name: null,
      isVertical: false,
      width: 1,
      backgroundColor: '#FFFFFF',
      status: 'active',
      isUpdating: this.isUpdating,
      shelves: [{
        shelfHeight: 1,
        depth: 1,
        shelfSeparatorHeight: 1
      }]
    }
    this.createFormValidation()
  }

  setStep(i) {
    this.step = i
  }

  onClose() {
    this.dialogRef.close({ mode: 'close' })
  }

  async onSave() {
    if (!this.equipmentForm.valid) {
      this.utilitiesService.validateAllFormFields(this.equipmentForm);
      return;
    }
    this.dialogRef.close({ equipment: this.equipment, mode: 'save' })
  }

  getShelfSizePlaceHolder() {
    return this.equipmentService.getShelfSizePlaceHolder(this.equipment)
  }

  getEquipmentSizePlaceHolder() {
    return this.equipmentService.getEquipmentSizePlaceHolder(this.equipment)
  }

  getSeparatorSizePlaceHolder() {
    return this.equipmentService.getSeparatorSizePlaceHolder(this.equipment)
  }

  getEquipmentHeight() {
    const result = this.equipmentService.getEquipmentShelvesLength(this.equipment)
    return result
  }

  get shelves(): FormArray {
    return this.equipmentForm.get('shelves') as FormArray;
  }

  addShelf() {
    const obj = {
      shelfHeight: this.equipment.shelves[this.equipment.shelves.length - 1]?.shelfHeight ?? 1,
      depth: this.equipment.shelves[this.equipment.shelves.length - 1]?.depth ?? 1,
      shelfSeparatorHeight: this.equipment.shelves[this.equipment.shelves.length - 1]?.shelfSeparatorHeight ?? 1
    }
    this.equipment.shelves.push(
      obj
    )
    this.shelves.push(this.createShelfValidation(obj))
  }

  removeShelf(index) {
    this.equipment.shelves.splice(index, 1)
  }

  async deleteEquipment() {
    await this.dal.deleteEquipment(this.equipment.id)
    this.dialogRef.close({ mode: 'close' })
  }
}
