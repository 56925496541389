import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';
import { CustomFields, LatLng } from 'src/app/shared/api-structures/common';


@Component({
  selector: 'update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.scss']
})
export class UpdateCustomerComponent implements OnInit {

  customerId: string
  externalId: string
  name: string
  category: string
  address: string
  territory: string
  chain: string
  latLng: LatLng
  taxId: string
  customerCode: string
  customFields: CustomFields

  listCategories: string[] = []
  listChains: string[] = []
  listTerritories: string[] = []
  isLoadingAttributes: boolean

  categoryCtrl = new FormControl('')
  filteredCategories: Observable<string[]>

  terrCtrl = new FormControl('')
  filteredTerritories: Observable<string[]>

  chainCtrl = new FormControl('')
  filteredChains: Observable<string[]>

  constructor(
    public dialogRef: MatDialogRef<UpdateCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { event: any; customFields: CustomFieldSettingsWithId[] },
    private customerService: CustomerService,
  ) {
    const row = data.event
    this.externalId = row.externalId
    this.customerId = row.id
    this.name = row.name
    this.category = row.category
    this.territory = row.territory
    this.address = row.address
    this.chain = row.chain
    this.taxId = row.taxId
    this.customerCode = row.customerCode
    this.customFields = row.customFields
    this.latLng = row.location
  }

  ngOnInit() {
    this.getCustomerAttributes()
  }

  async getCustomerAttributes() {
    function _filter(arr: string[], value: string): string[] {
      const filterValue = value.toLowerCase()
      return arr.filter(option => option.toLowerCase().includes(filterValue))
    }
    this.isLoadingAttributes = true
    try {
      const attr = await this.customerService.listCustomersAttributes()
      this.listCategories = attr.categories
      this.listChains = attr.chains
      this.listTerritories = attr.territories
    } finally {
      this.isLoadingAttributes = false
      this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
        startWith(''),
        map(value => _filter(this.listCategories, value || '')),
      )

      this.filteredTerritories = this.terrCtrl.valueChanges.pipe(
        startWith(''),
        map(value => _filter(this.listTerritories, value || '')),
      )

      this.filteredChains = this.chainCtrl.valueChanges.pipe(
        startWith(''),
        map(value => _filter(this.listChains, value || '')),
      )
    }
  }

  dropDownOptions(name: 'category' | 'territory' | 'chain') {
    switch (name) {
      case 'category':
        this.categoryCtrl.setValue(this.categoryCtrl.value)
        break
      case 'territory':
        this.terrCtrl.setValue(this.terrCtrl.value)
        break
      case 'chain':
        this.chainCtrl.setValue(this.chainCtrl.value)
        break
    }
  }

  onPlaceChanged(place) {
    this.address = place.formatted_address
    this.latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
  }

  onCancelClick() {
    this.dialogRef.close()
  }

  async save() {
    await this.customerService.updateCustomer({
      id: this.customerId,
      name: this.name,
      category: this.category,
      type: 'Individual',
      externalId: this.externalId,
      address: this.address,
      location: this.latLng,
      territory: this.territory,
      chain: this.chain,
      taxId: this.taxId,
      customerCode: this.customerCode,
      customFields: this.customFields
    })
    this.dialogRef.close({ isUpdated: true })

  }

  customFieldsChanged(data: CustomFields) {
    this.customFields = data
  }
}