import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'cluster-associations',
    template: `
  <div class="table-main min-height-100">
    <div class="table-content-container">
        <div class="table-title-container">
            <div class="table-title">
                {{'AssociatedCustomers'|translate}}
            </div>
        </div>
        <div class="unapproved-mat-tab-main">
            <associated-generic 
                [displayedColumns]="['id','name','lastUpdate','delete']" 
                [fetch]="data.fetch" 
                [add]="data.add"
                [delete]="data.delete"
                [fetchAddOptions]="data.fetchCustomers" 
                [id]="data.id">
            </associated-generic>
        </div>
    </div>
</div>
  `,
    styles: []
})
export class ClusterAssociationsComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,) { }

    ngOnInit(): void {
    }

}
