<mat-form-field *ngIf="fieldType=== 'string'">
  <input matInput [placeholder]="placeholder" [formControl]="ctrl" [required]="required">
</mat-form-field>

<mat-form-field *ngIf="fieldType === 'number'">
  <input matInput type="number" [placeholder]="placeholder" [formControl]="ctrl">
</mat-form-field>

<mat-form-field *ngIf="fieldType === 'date'">
  <mat-label>{{placeholder}}</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="ctrl">
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<div *ngIf="fieldType === 'boolean'">
  <span class="booleanLabel">{{placeholder}}</span>
  <mat-button-toggle-group [formControl]="ctrl">
    <mat-button-toggle [value]="true">True</mat-button-toggle>
    <mat-button-toggle [value]="false">False</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<mat-form-field *ngIf="fieldType === 'select'">
  <mat-select [placeholder]="placeholder" [formControl]="ctrl">
    <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
  </mat-select>
</mat-form-field>