import { Directive, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Directive()
export abstract class AutoDestroy implements OnDestroy {
    subscriptions: Subscription[] = []

    ngOnDestroy() {
        this.subscriptions.forEach(v => v.unsubscribe())
    }
}
