import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { FrontScreen } from '../api-structures/admin/user';
import { AutoDestroy } from '../base-directives/auto-destroy';
import { AvailableScreensService } from '../services/available-screens.service';

@Directive({ selector: '[checkPermission]' })
export class PermissionDirective extends AutoDestroy {

  screens: FrontScreen[]

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private availableScreensService: AvailableScreensService) {
    super()
    this.subscriptions.push(this.availableScreensService.availableScreens$.subscribe(s => this.displayTemplate()))
  }

  private displayTemplate() {
    this.viewContainer.clear()
    if (this.isSomeScreenAvailAble()) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }

  private isSomeScreenAvailAble() {
    for (let screen of this.screens) {
      if (this.availableScreensService.isScreenAvailable(screen) === true) {
        return true
      }
    }
    return false
  }

  @Input() set checkPermission(screens: FrontScreen) {
    if (screens.indexOf('|') > -1) {
      this.screens = (screens.split('|') as FrontScreen[])
    }
    else {
      this.screens = [screens]
    }
    this.displayTemplate()
  }
}
