import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { GetStartInfoResponse } from 'src/app/shared/api-structures/misc/misc';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-login-extrenal',
  templateUrl: './login-extrenal.component.html',
  styleUrls: ['./login-extrenal.component.scss']
})
export class LoginExtrenalComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private memoryStorageService: MemoryStorageService
  ) { }

  ngOnInit(): void {
    this.run()
  }

  async run() {
    const params = this.route.snapshot.queryParamMap;
    const code = params.get('code') as string
    const goto = params.get('goto') || '/'

    let logged: boolean
    try {
      await this.apiService.miscRequest<never, GetStartInfoResponse>({ endpoint: '/getStartInfo' }, { noLogoutOn401: true })
      logged = true
    } catch {
      logged = false
    }

    if (!logged) {
      try {
        const res = await this.apiService.unsecureRequest<{ code: string }, { token: string }>(
          '/loginCodeAuthenticate',
          {
            endpoint: '',
            body: {
              code
            }
          })
        this.memoryStorageService.token = res.token
        logged = true
      } catch (err) {
        logged = false
      }
    }

    if (logged) {
      this.router.navigate([goto])
    } else { 
      this.router.navigate(['/login'])
    }
  }
}
