import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { UserManagementService } from 'src/app/shared/services/user-management.service'
import { TrainingsService } from 'src/app/admin/services/trainings.service';
import { Dal } from 'src/app/dal/dal';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { filter, take } from 'rxjs/operators';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends AutoDestroy implements OnInit {
  moment = moment
  userName: String
  isSevenDaysAgoNegativeReport = true
  isSevenDaysAgoNegativeProduct = true
  totalReports
  howManyReportIdSevenDaysAgo: number
  howManyProductsSevenDaysAgo: number
  displayedColumnsCustomer = ['customer', 'category', 'createdAt']
  displayedColumnsUser = ['user', 'email']
  trainingCount
  totalTrainings
  lastTrainingData
  totalProducts
  listCustomers
  listUsers
  reportAvailable = false
  productAvailable = false
  trainingAvailable = false
  customerAvailable = false
  userAvailable = false
  constructor(private memoryStorageService: MemoryStorageService, private trainingService: TrainingsService,
    private dal: Dal, private router: Router, private customerService: CustomerService, private userManagementService: UserManagementService) {
    super()
  }

  async ngOnInit() {
    this.dal.getUserName().then(userName => this.userName = userName)
    this.getTotalReports()
    this.reportAvailable = true
    const res = await this.dal.countAllProducts()
    this.productAvailable = true
    if (res.diff > 0) {
      this.isSevenDaysAgoNegativeProduct = false
    }
    this.totalProducts = res.totalProducts
    this.howManyProductsSevenDaysAgo = Math.abs(res.diff)
    this.userManagementService.loadUsers({ pageSize: 10, currentPage: 0, query: '', sortBy: 'createdAt', sortDirection: 'desc' }).then(res => this.listUsers = res.items)
    this.userAvailable = true
    this.customerService.loadCustomers({ currentPage: 0, query: '', pageSize: 10 }).then(res => this.listCustomers = res.items)
    this.customerAvailable = true
    this.trainingService.loadContractTraining()
    this.trainingService.getActiveTraining().then(() => {
      this.lastTrainingData = this.trainingService.activeTraining.lastUpdate
    })
    this.trainingAvailable = true
    this.subscriptions.push(this.memoryStorageService.contractTrainingObs.pipe(
      filter(v => v != null && Object.keys(v).length !== 0),
      take(1)
    ).subscribe(v => {
      if (v) {
        this.trainingCount = v.trainingCount
        this.totalTrainings = v.trainingQuota + v.singleTrainingSold
      }
    }))

  }

  async getTotalReports() {
    const totalReports = await this.dal.getTotalReportsFromDashboard()
    this.totalReports = totalReports.data[0].reportId
    if (totalReports.data[0].howManyReportIdSevenDaysAgo > 0) {
      this.isSevenDaysAgoNegativeReport = false
    }
    this.howManyReportIdSevenDaysAgo = Math.abs(totalReports.data[0].howManyReportIdSevenDaysAgo)
  }

  redirectTo(url: string) {
    this.router.navigateByUrl(url)
  }

  ngOnDestroy() {

  }
}
