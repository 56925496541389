import { Component } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MessageService } from "primeng/api";
import { AutoDestroy } from "../base-directives/auto-destroy";
import { ApiTrackerService } from "../services/api.tracker.service";

@Component({
    selector: 'network-status',
    template: '<ngx-spinner bdColor="rgba(0,0,0,0.4)" type="ball-scale-multiple"></ngx-spinner>',
})
export class NetworkStatus extends AutoDestroy {
    constructor(apiTrackerService: ApiTrackerService, private messageService: MessageService, private spinner: NgxSpinnerService) {
        super()

        this.showHideSpinner(apiTrackerService.isSpinnerNeeded)

        this.subscriptions.push(apiTrackerService.onSpinnerNeededChanged$.subscribe(d => this.showHideSpinner(d.isShow)))

        this.subscriptions.push(apiTrackerService.onError$.subscribe(d => {
            if (d.errors !== undefined) {
                this.messageService.addAll(d.errors.map(v => {
                    return {
                        severity: 'error',
                        summary: v,
                        key: 'networkStatus',
                        closable: true,
                        life: 2000,
                    }
                }))
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: d.error,
                    key: 'networkStatus',
                    closable: true,
                    life: 2000,
                })
            }
        }))
    }

    showHideSpinner(isShow: boolean) {
        if (isShow === true) {
            this.spinner.show()
        } else {
            this.spinner.hide()
        }
    }
}
