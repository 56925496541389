<div class="body-flex">
    <div class="body-flex-c1">
        <div class="search-header">
            <div class="search-header-title">
                <div>{{ 'AllCustomers' | translate }} - ({{data?.length}} {{'Total' | translate}})</div>
            </div>
            <div class="search-header-input">
                <mat-form-field class="aligned">
                    <mat-chip-list #filterChipList>
                        <mat-chip *ngFor="let filter of searchFilter" [selectable]="selectable" [removable]="true"
                            (removed)="removeFilter(filter)">
                            {{filter}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input
                            placeholder="{{'SearchCustomers' | translate}} ({{'YouCanSearchMultipleCustomersBySeparatingTheIDAithAnSymbol'|translate}})"
                            [matChipInputFor]="filterChipList"
                            [matChipInputSeparatorKeyCodes]="filterSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addFilter($event)">
                    </mat-chip-list>
                </mat-form-field>
            </div>
            <div class="search-header-select">
                <mat-checkbox color="primary" type="checkbox" [checked]="allSelected"
                    (change)="emitSelectAll()"></mat-checkbox> <span class="text1">{{'SelectAll' | translate}}
                    {{data?.length}} {{'Customers' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="body-flex-c1-1">
        <div class="flex flex_gap_15 filters">
            <div class="flex-1">
                <div class="filters-txt">{{'Filters' | translate}}</div>
            </div>
            <div class="flex-3">
                <mat-form-field>
                    <mat-label>{{'Territory' | translate}}</mat-label>
                    <mat-select (closed)="emitSearchFilter()" [(ngModel)]="territories" multiple>
                        <mat-option *ngFor="let v of territoryOptions" [value]="v">{{v}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex-3">
                <mat-form-field>
                    <mat-label>{{'Category' | translate}}</mat-label>
                    <mat-select (closed)="emitSearchFilter()" [(ngModel)]="categories" multiple>
                        <mat-option *ngFor="let v of categoryOptions" [value]="v">{{v}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex-3">
                <mat-form-field>
                    <mat-label>{{'Area' | translate}}</mat-label>
                    <mat-select (closed)="emitSearchFilter()" [(ngModel)]="areas" multiple>
                        <mat-option *ngFor="let v of areaOptions" [value]="v.value">{{v.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="body-flex-c2">
        <div class="cards">
            <associated-customers-card *ngFor="let customer of data" (onToggleSelect)="onToggleSelect.emit($event)"
                [id]="customer.id" [selected]="selected[customer.id]" [data]="customer"></associated-customers-card>
        </div>
    </div>
</div>