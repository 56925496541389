import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "capitalize" })
export class Capitalize implements PipeTransform {
  transform(str: string) {
    if (!str) return str;
    const result = str.charAt(0).toUpperCase() + str.slice(1);
    return result;
  }
}

@Pipe({ name: "capitalizeAll" })
export class CapitalizeAll implements PipeTransform {
  transform(str: string) {
    if (!str) return str;
    const strArr = str.split(" ");

    const capitalize = (str: string) => {
      str = str.toLowerCase();
      if (str.length > 1) {
        return `${str.charAt(0).toUpperCase() + str.slice(1)} `;
      }
      return str;
    };

    const newStr = strArr.reduce((accu, cur) => {
      accu = capitalize(accu);
      accu += capitalize(cur);
      return accu;
    });

    return newStr;
  }
}
