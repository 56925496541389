<div class="home-background">
    <section class="container">
        <span class="title">{{'Hey' | translate}}, {{userName}}!</span>
        <span class="sub-title">{{'WelcomeToImageRecognitionStudio' | translate}}</span>
        <div class="card">
            <div class="info">
                <span class="title-card">{{'RegisteredProducts' | translate}}</span><br>
                <span class="evidence-number">{{totalProducts}}</span><br>
                <span *ngIf="!isSevenDaysAgoNegativeProduct" class="sub-info">{{howManyProductsSevenDaysAgo}} {{'MoreThanLastWeek' | translate}} <mat-icon style="color: #1EBF2E; font-size: medium; vertical-align: -webkit-baseline-middle;">trending_up</mat-icon></span>
                <span *ngIf="isSevenDaysAgoNegativeProduct" class="sub-info">{{howManyProductsSevenDaysAgo}} {{'LessThanLastWeek' | translate}} <mat-icon style="color: #D64040; font-size: medium; vertical-align: -webkit-baseline-middle;">trending_down</mat-icon></span>
            </div>
            <div class="action">
                <mat-icon class="blue-ire icon">list_alt</mat-icon><br>
                <button *ngIf="productAvailable" mat-button class="action-button blue-ire" (click)="redirectTo('/products')">{{'SeeAllProducts'|translate}} <mat-icon class="icon-button">arrow_forward</mat-icon></button>
            </div>
        </div>
        <div class="card">
            <div class="info">
                <span class="title-card">{{'TotalReports' | translate}}</span><br>
                <span class="evidence-number">{{totalReports}}</span><br>
                <span *ngIf="!isSevenDaysAgoNegativeReport" class="sub-info">{{howManyReportIdSevenDaysAgo}} {{'MoreThanLastWeek' | translate}} <mat-icon style="color: #1EBF2E; font-size: medium; vertical-align: -webkit-baseline-middle;">trending_up</mat-icon></span>
                <span *ngIf="isSevenDaysAgoNegativeReport" class="sub-info">{{howManyReportIdSevenDaysAgo}} {{'LessThanLastWeek' | translate}} <mat-icon style="color: #D64040; font-size: medium; vertical-align: -webkit-baseline-middle;">trending_down</mat-icon></span>
            </div>
            <div class="action">
                <mat-icon class="blue-ire icon">list</mat-icon><br>
                <button *ngIf="reportAvailable" mat-button class="action-button blue-ire" (click)="redirectTo('/mobileReports')">{{'SeeAllReports'|translate}} <mat-icon class="icon-button">arrow_forward</mat-icon></button>
            </div>
        </div>
        <div class="card">
            <div class="info">
                <span class="title-card">{{'RemainingMachineLearningTraining' | translate}}</span><br>
                <span class="evidence-number">{{trainingCount}}/{{totalTrainings}}</span><br>
                <span class="sub-info">{{'LastTrainingWasHeldIn' | translate}}: {{lastTrainingData | date: 'dd/MM/yyyy HH:mm'}}</span>
            </div>
            <div class="action">
                <mat-icon class="blue-ire icon">settings</mat-icon><br>
                <button *ngIf="trainingAvailable" mat-button class="action-button blue-ire" (click)="redirectTo('/admin/trainings')">{{'TrainingCenter'|translate}} <mat-icon class="icon-button">arrow_forward</mat-icon></button>
            </div>
        </div>
        <div class="last-customers">
            <div class="main-square">
                <div style="margin: 8px 0px 16px 0px;">
                    <span class="title-square">{{'LastCustomersAdded' | translate}}</span>
                    <button mat-button class="blue-ire" style="float: right; border-radius: 30px;" (click)="redirectTo('/admin/customer')">{{'SeeAllCustomers'|translate}} <mat-icon class="icon-button">arrow_forward</mat-icon></button>
                </div>
                <table mat-table [dataSource]="listCustomers" style="width: 100%;">
                    <ng-container matColumnDef="customer">
                      <th mat-header-cell *matHeaderCellDef> {{'Customer' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="category">
                      <th mat-header-cell *matHeaderCellDef> {{'Category' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef> {{'CreatedAt' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{moment(element.lastUpdate).format('L') }} </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCustomer"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCustomer;"></tr>
                  </table>
            </div>
        </div>
        <div class="last-users">
            <div class="main-square">
                <div style="margin: 8px 0px 16px 0px;">
                    <span class="title-square">{{'LastUsersAdded' | translate}}</span>
                    <button mat-button class="blue-ire" style="float: right; border-radius: 30px;" (click)="redirectTo('/admin/user-management')">{{'SeeAllUsers'|translate}} <mat-icon class="icon-button">arrow_forward</mat-icon></button>
                </div>
                <table mat-table [dataSource]="listUsers" style="width: 100%;">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell *matHeaderCellDef> {{'User' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                      <th mat-header-cell *matHeaderCellDef> {{'E-mail' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>
                  </table>
            </div>
        </div>
    </section>
</div>