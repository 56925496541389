import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddEditCustomFieldSettingsComponent } from './add-edit-custom-field-settings/add-edit-custom-field-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomFieldSettingsService } from '../../services/customFieldSettings.service';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';
import { SortDirection } from 'src/app/shared/api-structures/common';
import moment from 'moment';


@Component({
  selector: 'app-custom-fields-settings',
  templateUrl: './custom-fields-settings.component.html',
  styleUrls: ['./custom-fields-settings.component.scss']
})
export class CustomFieldsSettingsComponent implements OnInit {

  constructor(private matDialog: MatDialog, private customFieldSettingsService: CustomFieldSettingsService) { }

  displayedColumns = ['id', 'name', 'entityType', 'fieldType', 'defaultValue', 'isRequired', 'createdAt', 'lastUpdate', 'edit', 'delete']
  currentPage = 0
  pageSize = 20
  hasNext = false
  entityTypes = ['products', 'planograms', 'customers']
  fieldTypes = ['string', 'number', 'date', 'boolean', 'select']
  sortBy: keyof CustomFieldSettingsWithId = 'id'
  sortDirection: SortDirection = 'asc'
  query = ''
  data = []
  dataSource: MatTableDataSource<Record<string, string>>

  fetchData() {
    this.customFieldSettingsService.list({
      currentPage: this.currentPage,
      pageSize: this.pageSize,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      query: this.query
    }).then(v => {
      this.data = this.formatData(v.items)
      this.hasNext = v.items.length > 0
      this.dataSource = new MatTableDataSource(this.data)
    })
  }

  formatData(data: CustomFieldSettingsWithId[]) {
    return data.map(row => {
      return {
        ...row,
        defaultValue: row.fieldType === 'date' ? moment(row.defaultValue as string).format('L LT') : row.defaultValue,
        isRequired: row.isRequired ? 'Required' : '',
      }
    })
  }

  ngOnInit(): void {
    this.fetchData()
  }

  onFilter(value: string) {
    this.query = value
    this.fetchData()
  }

  onSort(value: Sort) {
    this.sortBy = value.active as keyof CustomFieldSettingsWithId
    this.sortDirection = value.direction as SortDirection
    this.fetchData()
  }


  onPaginate(page: number) {
    this.currentPage = page
    this.fetchData()
  }

  async onDelete(row) {
    await this.customFieldSettingsService.delete(row.id)
    this.fetchData()
  }

  onAdd() {
    const dialogRef = this.matDialog.open(AddEditCustomFieldSettingsComponent, {
      data: {
        entityTypes: this.entityTypes,
        fieldTypes: this.fieldTypes
      }
    })
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.customFieldSettingsService.add(v)
        this.fetchData()
      }
    })
  }


  onEdit(row) {
    const dialogRef = this.matDialog.open(AddEditCustomFieldSettingsComponent, {
      data: {
        item: row,
        entityTypes: this.entityTypes,
        fieldTypes: this.fieldTypes
      }
    })
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        const changedData = { ...row, ...v }
        await this.customFieldSettingsService.update(changedData)
        this.fetchData()
      }
    })
  }

}
