import { 
  Component, 
  OnInit,
  Inject
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { Dal } from 'src/app/dal/dal';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { PlanogramWithEditFlag } from 'src/app/shared/api-structures/misc/planogram';
import { PLANOGRAM_STATUS } from 'src/app/shared/const';

@Component({
  selector: 'app-products-planograms-dialog',
  templateUrl: './products-planograms-dialog.component.html',
  styleUrls: ['./products-planograms-dialog.component.scss']
})
export class ProductsPlanogramsDialogComponent implements OnInit  {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productId: string, productName: string, productThumbnailUrl: string },
    private memoryStorageService: MemoryStorageService,
    private router: Router,
    private dal: Dal,
  ) { }

  PLANOGRAM_STATUS = PLANOGRAM_STATUS

  displayedColumns: string[] = ['code', 'state', 'editIcon']
  dataSource: MatTableDataSource<PlanogramWithEditFlag>;
  planograms: PlanogramWithEditFlag[] = []
  productInfo: {
    name: string
    thumbnailUrl: string
  }

  ngOnInit(): void {
    this.listPlanogramsByProductId()
    this.productInfo = {
      name: this.data.productName,
      thumbnailUrl: this.data.productThumbnailUrl
    }
  }

  onEditPlanogram(row: PlanogramWithEditFlag) {
    if(row.isEditable) {
      this.router.navigate(['/planogram-planning'], { queryParams: { planogram_id: row.id } })
    } else {
      this.router.navigate(['/planograms'], { queryParams: { query: row.name } })
    }
  }

  async listPlanogramsByProductId() {
    const res = await this.dal.listPlanogramsByProductId(this.data.productId, this.memoryStorageService.mc1PlanogramId)
    this.planograms = res.planograms
    this.dataSource = new MatTableDataSource(this.planograms)
  }
}
