<div class="main-card" style="border: 2px solid #F0F0F1; border-radius: 8px;">
  <div class="pre-table-header">
    <mat-form-field appearance="standard">
      <mat-label>Filter</mat-label>
      <input matInput (keyup.enter)="applyFilter()" [(ngModel)]="filter" #input>
    </mat-form-field>
  </div>
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="customerId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CustomerId'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="territory">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Territory'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.territory}}</td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Address'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.address}}</td>
    </ng-container>

    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Area'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.area}}</td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Category'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.category}}</td>
    </ng-container>

    <ng-container matColumnDef="taxId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TaxId'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.taxId}}</td>
    </ng-container>

    <ng-container matColumnDef="chain">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Chain'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.chain}}</td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'UpdatedAt'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.lastUpdate | date : 'dd/MM/yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ExternalId'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.externalId}}</td>
    </ng-container>

    <ng-container *ngFor="let customField of customFields" [matColumnDef]="customField.name">
      <th mat-header-cell *matHeaderCellDef>{{customField.name | translate | titlecase}}</th>
      <td mat-cell *matCellDef="let row">{{ getCustomFieldValue(row, customField.name) }}</td>
    </ng-container>

    <ng-container matColumnDef="associatedUsers">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'AssociatedUsers'|translate}}" mat-button style="min-width: 1%"
          (click)="popupAssociatedUsers(row)">
          <mat-icon style="color:#919191;transform: scale(0.8);">supervisor_account</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="editIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Edit'|translate}}" mat-button (click)="updateCustomer(row)">
          <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Delete'|translate}}" mat-button (click)="ondeleteClick(row.id,row.name)">
          <mat-icon style="color:#919191;transform: scale(0.9);">delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
  </table>

  <div
    class="card-footer">
    <button class="btn export" (click)="export()">
      Export <mat-icon class="mat-icon-export">arrow_downward</mat-icon>
    </button>
    <mat-paginator style="background-color: transparent;" [length]="totalCustomers" [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
    </mat-paginator>

  </div>
  <p-dialog [(visible)]="isShowConfirmDeleteDialog">
    <p-header>{{ 'Warning' | translate }}</p-header>
    <p>{{'Customer' | translate}}:
      <br />
      <span>{{customerToDelete.name}}</span>
      <br />
      <span>{{'DeleteMessage' | translate}}</span>
    </p>
    <p>{{'DeleteCaution' | translate}}</p>
    <div class="dialog-button-container">
      <button mat-flat-button color="primary" (click)="isShowConfirmDeleteDialog=false">{{'Cancel' |translate}}</button>
      <button mat-flat-button (click)="deleteUser()">{{'Delete' | translate}}</button>
    </div>
  </p-dialog>
</div>