<h2 mat-dialog-title>{{'Warning'|translate}}</h2>
<mat-dialog-content>
    {{'Session will be expired in'| translate }} {{sessionExpireMinutes}} {{'minutes,'| translate }}
    <br/>
    {{'And you will be loged out.'| translate }}
    <p>
        {{'Close or click button below to keep your session active.'| translate }}
    </p>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color='primary' mat-dialog-close>
        {{'keepSessionActive'| translate }}
    </button>  
</mat-dialog-actions>