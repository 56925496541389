import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OrganizationsService } from 'src/app/super-admin/services/organizations.service';


@Component({
  selector: 'organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss'],
})
export class OrganizationSettingsComponent implements OnInit {

  ReginOptions: any
  selectedRegion: any
  uploadProgress: {
    name: string,
    progress: number
  }

  organizationName: string
  organizationId: string
  language: string
  logoUrl: string
  primaryColor: string
  secondaryColor: string
  samplesPerCall: number
  samplesPerProducts: number
  trainBudget: number
  isLoadingSettings: boolean

  constructor(private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<OrganizationSettingsComponent>,
    private organizationsService: OrganizationsService,
    private utilService: UtilitiesService, private appService: AppService,
    private languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== undefined) {
      this.organizationName = data.name
      this.organizationId = data.id
    }
  }

  ngOnInit() {
    this.getOrganizationSettings(this.organizationId)
  }

  async getOrganizationSettings(id: string) {
    this.isLoadingSettings = true
    const res = await this.organizationsService.getOrganizationSettings({ id })
    this.language = res.general?.language
    this.logoUrl = res.theme?.logoUrl
    this.primaryColor = res.theme?.primaryColor
    this.secondaryColor = res.theme?.secondaryColor
    this.samplesPerCall = res.training?.samplesPerCall
    this.samplesPerProducts = res.training?.samplesPerProducts
    this.trainBudget = res.training?.trainBudget
    this.isLoadingSettings = false
  }

  async saveSettings() {
    if ((this.samplesPerCall < 1 || this.samplesPerCall > 1000) || (this.samplesPerProducts < 1 || this.samplesPerProducts > 1000)) {
      this.snackbarService.openSnackBar(4000, `${this.languageService.translateSync('SamplesPerCall')} & ` +
        `${this.languageService.translateSync('SamplesPerProducts')} ${this.languageService.translateSync('MustBeBetween')} 1-1000`)
      return
    }
    const request = {
      organizationId: this.organizationId,
      general: {
        language: this.language
      },
      theme: {
        logoUrl: this.logoUrl,
        primaryColor: this.primaryColor,
        secondaryColor: this.secondaryColor
      },
      training: {
        samplesPerCall: this.samplesPerCall,
        samplesPerProducts: this.samplesPerProducts,
        trainBudget: this.trainBudget
      }
    }
    await this.organizationsService.updateOrganizationSettings(request)
    this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('organizationSettingsSavedSuccessfully')}`)
    this.dialogRef.close()
  }

  async saveLogoFileToStorga(event: FileList) {
    const file: File = event[0]
    if (this.utilService.validateImageType(file) == false) {
      return
    }
    this.uploadProgress = { name: file.name, progress: 0 }
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadProgress.progress = progress, true, this.organizationId)
    await this.utilService.isFileUrlExist(fileUrl)
    this.logoUrl = fileUrl
    this.uploadProgress = undefined
  }


}