import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dal } from 'src/app/dal/dal';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-health-check-modal',
  templateUrl: './health-check-modal.component.html',
  styleUrls: ['./health-check-modal.component.scss']
})
export class HealthCheckModalComponent implements OnInit {
  Products: any[] = [];
  groupedProductPages: any[][] = [];
  currentPageIndex = 0;
  productIds: string[] = [];
  totalProducts = 0;
  pageSize = 5;

  constructor(
    public dialogRef: MatDialogRef<HealthCheckModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dal: Dal
  ) { }

  ngOnInit() {
    this.totalProducts = this.data.collection.length;
    this.loadInitialProducts();
  }

  loadInitialProducts() {
    const initialIds = this.data.collection.slice(0, 5);
    this.productIds = initialIds;
    this.loadProducts(initialIds);
  }

  async loadProducts(ids: string[]) {
    const result = await this.dal.searchProductsByIds(ids);
    const eans = result.items.map(item => item.ean);

    if (this.data.issue === 'DuplicateProducts') {
      const result2 = await this.dal.searchProducts(0, 25, eans, {
        "minimumImages": "0",
        "maximumImages": "150",
        "minimumCatalogs": "0",
        "maximumCatalogs": "150"
      }, false);

      const groupedProducts = result2.items.reduce<{ [key: string]: any[] }>((acc, item) => {
        acc[item.ean] = acc[item.ean] || [];
        acc[item.ean].push(item);
        return acc;
      }, {});
      
      this.groupedProductPages = Object.values(groupedProducts)
      this.totalProducts = this.groupedProductPages.length
      this.setPage(0)
      this.pageSize = 2

    } else {

      this.Products = result.items;
      this.pageSize = 5
      this.totalProducts = this.data.collection.length;
      
    }
  }

  setPage(pageIndex: number) {
    this.currentPageIndex = pageIndex;
    this.Products = this.groupedProductPages.slice(pageIndex * 2, (pageIndex + 1) * 2).map(group => Array.isArray(group) ? group : [group]);
  }


  async handlePageEvent(event: PageEvent) {
    if (this.data.issue === 'DuplicateProducts') {
      this.setPage(event.pageIndex);
    } else {
      const startIndex = event.pageIndex * event.pageSize;
      const endIndex = startIndex + event.pageSize;
      const idsToFetch = this.data.collection.slice(startIndex, endIndex);
      this.productIds = idsToFetch;
      await this.loadProducts(idsToFetch);
    }
  }

  getProductId(id: any) {
    return this.productIds[id];
  }

  close() {
    this.dialogRef.close();
  }
}
