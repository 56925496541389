<div class="light_card main" [ngClass]="{closed: !isOpen}">
    <div>
        <div class="flex">
            <div class="flex-4">
                <div class="gray_header">{{title}}</div>
            </div>
            <div class="flex-1">
                <div class="fold_button" (click)="toggle()">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </div>
            </div>
        </div>
        <div class="line"></div>
    </div>
    <div class="body">
        <ng-content></ng-content>
    </div>
</div>