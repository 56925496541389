import { Injectable } from "@angular/core";
import { EquipmentWithId } from "src/app/shared/api-structures/misc/equipment";

@Injectable({
    providedIn: 'root'
})
export class EquipmentService {

    constructor() { }

    public getShelfSizePlaceHolder(equipment: EquipmentWithId) {
        return (equipment.isVertical) ? "ShelfWidth" : "ShelfHeight"
    }

    public getEquipmentSizePlaceHolder(equipment: EquipmentWithId) {
        return (equipment.isVertical) ? "Height" : "Width";
    }

    public getSeparatorSizePlaceHolder(equipment: EquipmentWithId) {
        return (equipment.isVertical) ? "WidthSeparatorBetweenShelves" : "HeightSeparatorBetweenShelves";
    }

    // height if horizontal, width if vertical
    public getEquipmentShelvesLength(equipment: EquipmentWithId) {
        let equipmentHeight = 0
        if (!equipment.shelves) {
            equipmentHeight = equipment.shelfHeight * equipment.numberOfShelves
            if (equipment.shelfSeparatorHeight > 0) {
                equipmentHeight += equipment.shelfSeparatorHeight * (equipment.numberOfShelves - 1)
            }
            return equipmentHeight
        }
        equipment.shelves.forEach((shelf, i) => {
            equipmentHeight += shelf.shelfHeight
            if (i < equipment.shelves.length - 1) {
                equipmentHeight += shelf.shelfSeparatorHeight
            }
        })

        return equipmentHeight;
    }
}