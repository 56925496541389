<h2 mat-dialog-title>{{'AddStaticModel'|translate}}</h2>
<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>{{'Name'|translate}}</mat-label>
        <input matInput [(ngModel)]="name" placeholder="yolo">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{'ModelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="modelUrl" placeholder="http://...">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{'LabelsUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="labelsUrl" placeholder="http://...">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'PriceModelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="priceModelUrl" placeholder="http://...">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{'PriceLabelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="priceLabelUrl" placeholder="http://...">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'ShelfModelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="shelfModelUrl" placeholder="http://...">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{'ShelfLabelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="shelfLabelUrl" placeholder="http://...">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'OCRModelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="ocrModelUrl" placeholder="http://...">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'PopModelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="popModelUrl" placeholder="http://...">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{'PopLabelUrl'|translate}}</mat-label>
        <input matInput [(ngModel)]="popLabelUrl" placeholder="http://...">
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><mat-icon color="primary">cancel</mat-icon></button>
    <button mat-button (click)="save()"><mat-icon color="primary">save</mat-icon></button>
</mat-dialog-actions>