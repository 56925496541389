import { Expose, Type } from 'class-transformer'
import { IsString, IsNumber, IsOptional, ValidateNested, IsArray } from 'class-validator'
import { CustomerWithId } from './customer'
import { UserRole } from './user'

export class ListUserCustomerRequest {
    @Expose() @IsNumber() currentPage!: number
    @Expose() @IsNumber() pageSize!: number
    @Expose() @IsString() @IsOptional() filter?: string
}

export class AddUserCustomerRequest {
    @Expose() @IsString() customerId!: string
    @Expose() @IsString() userId!: string
}

export class DeleteUserCustomerRequest extends AddUserCustomerRequest { }

// Users To Customer
export class ListAssociatedUsersToCustomerRequest extends ListUserCustomerRequest {
    @Expose() @IsString() customerId!: string
}

export namespace ListAssociatedUsersToCustomer {
    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
        @Expose() @IsString() email!: string
        @Expose() @IsString() role!: UserRole
    }

    export class Response {
        @Expose() @IsNumber() totalItems!: number
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

// customers to user
export class ListAssociatedCustomersToUserRequest extends ListUserCustomerRequest {
    @Expose() @IsString() userId!: string
}

export class ListAssociatedCustomersToUserResponse {
    @Expose() @IsNumber() totalItems!: number
    @Expose() @Type(() => CustomerWithId) @ValidateNested() @IsArray() items!: CustomerWithId[]
}
