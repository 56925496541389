import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AddStaticModelRequest, ListStaticModel } from 'src/app/shared/api-structures/admin/staticModel';
import { ByIdRequest } from 'src/app/shared/api-structures/common';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class StaticModelsService {
  private staticModels = new Subject<ListStaticModel.Item[]>()

  staticModels$ = this.staticModels.asObservable()

  constructor(private api: ApiService) { }

  async addStaticModel(sm: AddStaticModelRequest) {
    await this.api.adminRequest({ endpoint: '/addStaticModel', body: sm })
    await this.loadStaticModels()
  }

  async deleteStaticModel(id: string) {
    await this.api.adminRequest<ByIdRequest>({ endpoint: '/deleteStaticModel', body: { id } })
    await this.loadStaticModels()
  }

  async setActiveStaticModel(id: string) {
    await this.api.adminRequest<ByIdRequest>({ endpoint: '/setActiveStaticModel', body: { id } })
    await this.loadStaticModels()
  }

  async loadStaticModels() {
    const data = await this.api.adminRequest({ endpoint: '/listStaticModels', responseType: ListStaticModel.Response })
    this.staticModels.next(data.arr)
  }
}
