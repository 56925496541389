import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';
import { getCompanyType } from './services/companyType.service';
import { ConfigService } from '../services/config.service';
import { AvailableScreensService } from './services/available-screens.service';
import { filter, map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class NoAuthGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthService, private configService: ConfigService, private availableScreensService: AvailableScreensService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.$logged.pipe(
            filter((isLogged) => isLogged !== undefined),
            map((isLogged) => {
                if (isLogged || getCompanyType() === 'MC1') {
                    this.router.navigate(['/'])
                    return false
                }

                return true
            })
        )
    }

}