import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Organization } from 'src/app/shared/api-structures/super-admin/organization';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OrganizationsService } from 'src/app/super-admin/services/organizations.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CreateUpdateOrganizationComponent } from '../create-update-organization/create-update-organization.component';
import { OrganizationSettingsComponent } from '../organization-settings/organization-settings.component';
@Component({
  selector: 'organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss'],
})
export class OrganizationsListComponent extends AutoDestroy implements AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  totalOrganizations: number
  filter: string = ''
  organizationIdToDelete: string
  isShowConfirmDeleteDialog: boolean
  dataSource = new MatTableDataSource<Organization>()
  columnsToDisplay = [
    'organizationId',
    'name',
    'companies',
    'updatedAt',
    'icons',
  ]

  constructor(private organizationsService: OrganizationsService,
    private dialog: MatDialog, private languageService: LanguageService,) {
    super()
  }

  ngAfterViewInit() {
    this.isShowConfirmDeleteDialog = false
    this.paginator.pageSize = 25;
    this.subscriptions.push(this.organizationsService.listOrganizations$.subscribe(d => {
      this.dataSource.data = d.items
      this.totalOrganizations = d.totalItems
      this.paginator.length = this.totalOrganizations
      this.dataSource.sort = this.sort;
    }))
  }

  applyFilter() {
    this.getOrganizations()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  async getOrganizations() {
    const res = await this.organizationsService.loadOrganizations({ currentPage: this.paginator.pageIndex, pageSize: this.paginator.pageSize, query: this.filter })
  }

  ondeleteClick(id: string) {
    this.organizationIdToDelete = id
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: {
        title: `${this.languageService.translateSync('ConfirmDeletionTitle')}`,
        message: `${this.languageService.translateSync('confirmOrganizationDeletionMessage')} '${id}'?`
      }
    })
    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }
      this.deleteOrganization()
    })
  }

  async deleteOrganization() {
    await this.organizationsService.deleteOrganization({ id: this.organizationIdToDelete })
    this.getOrganizations()
  }

  async updateOrganization(row: any) {
    const dialogRef = this.dialog.open(CreateUpdateOrganizationComponent, {
      width: '40vw',
      data: { id: row.id, name: row.name, language: row.general.language, logoUrl: row.theme.logoUrl, bannerUrl: row.theme.bannerUrl, primaryColor: row.theme.primaryColor, secondaryColor: row.theme.secondaryColor, samplesPerCall: row.training.samplesPerCall, samplesPerProducts: row.training.samplesPerProducts, trainBudget: row.training.trainBudget }
    })

    dialogRef.afterClosed().subscribe(v => {
      if (v && v.isOrganizationUpdateOrCreated) {
        this.getOrganizations()
      }
    })
  }

  popupSettings(id: string, name: string,) {
    const dialogRef = this.dialog.open(OrganizationSettingsComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      data: { id, name }
    })
  }

}