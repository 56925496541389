import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Context, CreateUserRequest } from 'src/app/shared/api-structures/admin/user';
import { UserManagementService } from 'src/app/shared/services/user-management.service';
import {
  GroupedOrgItem,
  groupByOrganization,
} from '../../../../super-admin/helpers'

@Component({
  selector: 'create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  name = ''
  email = ''
  role: string
  phone: string
  roleOptions: string[]
  isShowpasswordDialog = false
  password = ''
  isSuperAdmin = false
  organizationId: string
  organizationOpotions: string[]
  context: Context = { company: '', environment: '', organization: '' }
  contextOptions: Context[] = []
  isLoadingContexts = false
  isLoadingRoles = false
  myControl = new FormControl('')
  filteredOptions: Observable<Context[]>
  newContext: string[] = []
  isContextValidate = false
  comps: string[];
  envs: string[];
  orgs: GroupedOrgItem[]
  orgId: any;
  selectedOrganizationId: string;
  selectedCompanyId: string;
  selectedEnvironmentId: string;

  constructor(
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private userManagementService: UserManagementService,
    private router: Router) {
    this.isShowpasswordDialog = false

  }

  ngOnInit() {
    if (this.router.url.includes('/super')) {
      this.isSuperAdmin = true
    }
    if (this.isSuperAdmin === false) {
      this.getAvailableRoles()
    }
    if (this.isSuperAdmin === true) {
      this.getAvaliableContexts()
    }
  }


  async getAvailableRoles() {
    this.roleOptions = []
    this.isLoadingRoles = true
    const res = await this.userManagementService.getAvailableRoles(this.isSuperAdmin, this.context)
    this.roleOptions = res.roles
    this.isLoadingRoles = false
  }

  async getAvaliableContexts() {
    this.isLoadingContexts = true
    const res = await this.userManagementService.getAvaliableContexts()
    this.contextOptions = res.contexts
    this.orgs = groupByOrganization(this.contextOptions)
    this.organizationOpotions = [...new Set(this.contextOptions.map(v => v.organization))]

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => value ? this._filter(value) : this.contextOptions.slice()),
    )
    this.isLoadingContexts = false
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.contextOptions.filter(option => option.organization.toLowerCase().includes(filterValue));
  }

  async optionSelected(option) {
    this.context = option.value
    this.getAvailableRoles()
  }

  displayFn(context: Context) {
    return context.organization ? context.organization + '/' + context.environment + '/' + context.company : ''
  }

  async save() {
    const user = new CreateUserRequest()
    user.name = this.name
    user.email = this.email
    user.role = this.role
    user.phone = this.phone

    if (this.phone != undefined) {
      user.phone = this.phone.toString().replace('+', '').replace('-', '').replace('(', '').replace(')', '').replace('*', '').replace('%', '').replace('=', '')
    }
    else {
      user.phone = ''
    }

    if (this.role === 'organization_admin') {
      user.organizationId = this.organizationId
    } else if (this.isSuperAdmin && this.role !== 'super_admin') {
      user.context = this.context
    }

    const userRes = await this.userManagementService.createUser(user, this.isSuperAdmin)
    this.password = userRes.pw
    this.isShowpasswordDialog = true
  }

  getRolesWhenMouseOut() {
    let wordSearch = this.myControl.value
    setTimeout(() => {
      if (wordSearch === this.myControl.value) {
        this.context = this.getContext()
        if (this.context.organization !== undefined && this.context.environment !== undefined && this.context.company !== undefined
          && this.context.organization !== '' && this.context.environment !== '' && this.context.company !== '') {
          this.getAvailableRoles()
        }
      }
    }, 2000);
  }

  getContext() {
    const option = this.myControl.value
    const c = option.split('/')
    if (c.length === 3) {
      this.context.organization = c[0].trim()
      this.context.environment = c[1].trim()
      this.context.company = c[2].trim()
      return this.context
    }
    return { company: undefined, environment: undefined, organization: undefined }
  }


  afterConfirmPw() {
    this.isShowpasswordDialog = false
    this.dialogRef.close({ isUserCreated: true })
  }

  onOrganizationSelected() {
    this.orgId = this.selectedOrganizationId;
    let selectedOrg = this.orgs.filter(x => x.organization == this.selectedOrganizationId)
    this.comps = selectedOrg[0].companies
    this.envs = selectedOrg[0].environments
  }

  onCompanySelected() {
    this.context = {
      company: this.selectedCompanyId,
      environment: this.selectedEnvironmentId,
      organization: this.selectedOrganizationId
    };

    this.getAvailableRoles()

  }
}
