export interface OrgItem {
    organization: string;
    environment: string;
    company: string;
}

export interface GroupedOrgItem {
    organization: string;
    environments: string[];
    companies: string[];
}

export const groupByOrganization = (items: OrgItem[]): GroupedOrgItem[] => {
    return items.reduce((acc: GroupedOrgItem[], item: OrgItem) => {
        let org = acc.find(o => o.organization === item.organization);

        if (!org) {
            acc.push({
                organization: item.organization,
                environments: [item.environment],
                companies: [item.company]
            });
        } else {
            if (!org.environments.includes(item.environment)) {
                org.environments.push(item.environment);
            }
            if (!org.companies.includes(item.company)) {
                org.companies.push(item.company);
            }
        }

        return acc;
    }, []);
};