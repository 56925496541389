import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Ctx } from 'src/app/shared/api-structures/common';
import { CreateApiKeyRequest } from 'src/app/shared/api-structures/superAdmin';
import { UserManagementService } from 'src/app/shared/services/user-management.service';
import { ApiKeysService } from 'src/app/super-admin/services/api-keys.service';
import {
  GroupedOrgItem,
  groupByOrganization,
} from '../../../helpers'


@Component({
  selector: 'app-add-edit-api-key-form',
  templateUrl: './add-edit-api-key-form.component.html',
  styleUrls: ['./add-edit-api-key-form.component.scss']
})
export class AddEditApiKeyFormComponent implements OnInit {

  contextOptions = []
  contextFiltered: Observable<string[]>
  isLoadingContexts = false
  organizationOptions: string[]
  name: string
  apiKeyName: string
  comps: string[];
  envs: string[];
  orgs: GroupedOrgItem[]
  orgId: any;
  selectedOrganizationId: string
  selectedEnvironmentId: string
  selectedCompanyId: string
  organizationOpotions: string[]
  context: string

  apiKeyForm = new FormGroup({
    name: new FormControl(''),
  })

  constructor(
    public dialogRef: MatDialogRef<CreateApiKeyRequest>,
    private userManagementService: UserManagementService,
    private apiKeyService: ApiKeysService) { }

  ngOnInit(): void {
    this.getAvaliableContexts()
  }

  async getAvaliableContexts() {
    this.isLoadingContexts = true

    const res = await this.userManagementService.getAvaliableContexts()
    this.contextOptions = res.contexts
    this.orgs = groupByOrganization(this.contextOptions)
    this.organizationOpotions = [...new Set(this.contextOptions.map(v => v.organization))]


    this.isLoadingContexts = false
  }


  onCancel() {
    this.dialogRef.close()
  }

  async onSave() {
    if (!this.name || !this.context) {
      return
    }
    const res = await this.apiKeyService.createApiKey({
      name: this.name,
      ctx: this.context as Ctx,
    })
    this.dialogRef.close(res)
  }


  onOrganizationSelected() {
    this.orgId = this.selectedOrganizationId;
    let selectedOrg = this.orgs.filter(x => x.organization == this.selectedOrganizationId)
    this.comps = selectedOrg[0].companies
    this.envs = selectedOrg[0].environments
  }

  onCompanySelected() {
    let context = {
      company: this.selectedCompanyId,
      environment: this.selectedEnvironmentId,
      organization: this.selectedOrganizationId
    };

    if (!context.company || !context.environment || !context.organization) {
      this.context = 'invalid context'
      return
    }

    this.context = `${context.organization}/${context.environment}/${context.company}`
  }



}
