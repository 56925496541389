<div class="blue-background">
  <div class="container">
    <div class="row" style="display: flex; justify-content: space-between; margin-left: 0px; width: 100%;">
      <span class="title"><a class="icon-anchor"[routerLink]="['/home']"><i class="pi pi-arrow-left mr-2"></i></a> {{'MySettings'|translate}}</span>
    </div>
    <div class="table">
      <mat-tab-group animationDuration="0ms" dynamicHeight>
        <mat-tab label="{{'ProfileDetails'|translate}}">
          <ng-template matTabContent>
            <div class="main-square">
              <div class="user-info-container">
                <mat-form-field>
                  <input matInput placeholder="{{'Name'|translate}}" formControlName="name" [value]="userInfo?.name" disabled>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="{{'Company'|translate}}" formControlName="company" [value]="userInfo?.company" disabled>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="{{'Role'|translate}}" formControlName="role" [value]="userInfo?.role" disabled>
                </mat-form-field>

                <mat-divider></mat-divider>

                <mat-form-field [style]="{
                  marginTop: '20px'
                }">
                  <mat-label>{{'Language'|translate}}</mat-label>
                  <mat-select [(ngModel)]="_language" (ngModelChange)="onLanguageChange($event)">
                      <mat-option value="pt"><span class="fi fi-br"></span> Portuguese</mat-option>
                      <mat-option value="en"><span class="fi fi-us"></span> English</mat-option>
                      <mat-option value="es"><span class="fi fi-es"></span> Spanish</mat-option>
                      <mat-option value="ar"><span class="fi fi-sa"></span> Arabic</mat-option>
                      <mat-option value="el"><span class="fi fi-gr"></span> Greek</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <app-image-upload 
              [imageUrl]="userInfo?.profileImgUrl" 
              [title]="'ProfilePhoto'" 
              (imageUpload)="uploadPrimaryImage($event)" 
              (imageRemove)="removeImage()">
            </app-image-upload>            
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>