<div class="notification-card-wide">
  <div class="left-wrapper">
    <div class="icon-wrapper" [ngClass]="{
        'warning-notification': notification.severity == 'warning',
        'critical-notification': notification.severity == 'critical',
        'info-notification': notification.severity == 'notification'
      }">
      <i style="font-size: 20px; line-height: 1;" [ngStyle]="{'color': getColor(notification.severity)}"
        [class]="getNotificationClass(notification.severity)"></i>
    </div>
    <div class="text-column">
      <span class="severity" [ngStyle]="{'color': getColor(notification.severity)}">{{notification.severity | translate}}</span>
      <span class="description" [ngStyle]="{'color': getColor(notification.severity)}">{{notification.issue | translate}}</span>
      <span >{{ "YouHave" | translate}}&nbsp;<b>{{ notification.amount}}</b>&nbsp;{{notification.description | translate}}</span>
    </div>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openModal()">{{"Review" | translate}}</button>
  </div>
</div>