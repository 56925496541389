import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';
import { getCompanyType } from './services/companyType.service';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.$logged.pipe(
            filter((isLogged) => isLogged !== undefined),
            map((isLogged) => {
                if (getCompanyType() === 'MC1') {
                    return true
                }

                if (isLogged) {
                    return true
                }

                this.auth.navigateToLogin(state.url)

                return false
            })
        )
    }
}