import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { Subject } from 'rxjs';
import { Context, CreateUserRequest, CreateUserResponse, CreateUserWithPasswordRequest, CreateUserWithPasswordResponse, ExportResponse, GetAvailableRolesResponse, ListUsers, ListUsersForAdmin, UpdateUserRequest } from 'src/app/shared/api-structures/admin/user';
import { ByIdRequest } from '../api-structures/common';
import { GetAvaliableContexts } from '../api-structures/superAdmin';
import { AuthService } from './auth.service';
import { LanguageService } from './language.service';


@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private listUsers = new Subject<ListUsersForAdmin.Response>()
  private listUsersRequest = new Subject<ListUsersForAdmin.Request>()

  listUsers$ = this.listUsers.asObservable()
  listUsersRequest$ = this.listUsersRequest.asObservable()
  $profilePictureUrl = new Subject<string>()

  constructor(private api: ApiService, private auth: AuthService, private languageService: LanguageService) { }

  updateProfilePictureUrl(url: string) {
    this.$profilePictureUrl.next(url)
  }

  getProfilePictureUrl() {
    return this.$profilePictureUrl.asObservable()
  }

  async createUser(user: CreateUserRequest, isSuper: boolean = false) {
    let response: CreateUserResponse
    if (isSuper) {
      response = await this.api.superRequest<CreateUserRequest, CreateUserResponse>({ endpoint: '/createUser', body: user, responseType: CreateUserResponse })
    } else {
      response = await this.api.adminRequest<CreateUserRequest, CreateUserResponse>({ endpoint: '/createUser', body: user, responseType: CreateUserResponse })
    }

    return response
  }

  async createUserWithPassword(user: CreateUserWithPasswordRequest) {
    let response: CreateUserWithPasswordResponse
      response = await this.api.adminRequest<CreateUserWithPasswordRequest, CreateUserWithPasswordResponse>({ endpoint: '/createUserWithPassword', body: user, responseType: CreateUserWithPasswordResponse })    
    return response
  }

  async deleteUser(id: ByIdRequest) {
    await this.api.adminRequest({ endpoint: '/deleteUser', body: id })
  }

  async logicalDeleteUser(id: ByIdRequest) {
    return await this.api.miscRequest({ endpoint: '/requestUserDeletion', body: id, errorMessage: this.languageService.translateSync('ErrorRequestUserDeletion') })
  }

  async updateUser(body: UpdateUserRequest) {
    await this.api.adminRequest({ endpoint: '/updateUser', body })
  }

  async loadUsers(request: ListUsersForAdmin.Request, isHideProgress?: boolean) {
    const data = await this.api.adminRequest({ endpoint: '/listUsers', body: request, context: this.auth.context, responseType: ListUsersForAdmin.Response }, { isHideProgress })
    this.listUsers.next(data)
    return data
  }

  async getAvailableRoles(isSuper?: boolean, context?: Context,) {
    let response: GetAvailableRolesResponse
    if (isSuper === true) {
      response = await this.api.superRequest(
        { endpoint: '/getAvailableRoles', body: context, responseType: GetAvailableRolesResponse },
        { isHideProgress: true })
    } else {
      response = await this.api.adminRequest(
        { endpoint: '/getAvailableRolesForCurrentUser', responseType: GetAvailableRolesResponse }, { isHideProgress: true }
      )
    }
    return response
  }

  async getAvaliableContexts() {
    const response = await this.api.superRequest(
      { endpoint: '/getAvaliableContexts', responseType: GetAvaliableContexts.Response },
      { isHideProgress: true }
    )
    return response
  }

  async exportUsers() {
    const res = await this.api.adminRequest({ endpoint: '/exportUsers', responseType: ExportResponse })
    return res
  }
}
