import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserCustomerService } from 'src/app/admin/services/user-customer.service';
import { Dal } from 'src/app/dal/dal';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ListAssociatedUsersToCustomer } from 'src/app/shared/api-structures/admin/userCustomer';
import { SortDirection } from 'src/app/shared/api-structures/common';

@Component({
  selector: 'associated-users2',
  templateUrl: './associated-users.component.html',
  styleUrls: ['./associated-users.component.scss']
})
export class CustomerAssociatedUsersDialogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @Input() id: string

  displayedColumns: string[] = ['name', 'email', 'role', 'deleteIcon']
  dataSource: MatTableDataSource<ListAssociatedUsersToCustomer.Item>
  associatedUsers: ListAssociatedUsersToCustomer.Item[] = []
  totalAssociatedUsers: number;

  sortBy: string;
  sortDirection: SortDirection
  filter: string = ''

  associationType: 'customer' | 'planogram'
  customerName: string
  userId: string
  isShowAddUserField: boolean = false
  isShowConfirmDeleteDialog = false

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService,
    private userCustomerService: UserCustomerService,
    private dal: Dal) {
    this.customerName = data.name
    this.associationType = data.associationType ?? 'planogram'
    if (this.associationType === 'customer') this.id = data.id
  }

  ngOnInit() {
    this.paginator.pageSize = 25
    const currentPage = this.paginator.pageIndex
    const pageSize = this.paginator.pageSize
    this.getAssociatedUsers(currentPage, pageSize, this.filter)
  }

  sortData(sort: Sort) {
    this.sortBy = sort.active;
    this.sortDirection = (sort.direction as SortDirection)
    this.applyFilter()
    this.sortBy = ""
    this.sortDirection = null
  }

  onUserSelected(filter: string) {
    this.filter = filter
    this.applyFilter()
  }

  applyFilter() {
    this.paginator.pageIndex = 0
    this.getAssociatedUsers(this.paginator.pageIndex, this.paginator.pageSize, this.filter)
  }

  async getAssociatedUsers(currentPage: number, pageSize: number, filter: string) {
    const res = await this.getAssociatedUserByAssociationType(currentPage, pageSize, filter)
    this.associatedUsers = res.items
    this.totalAssociatedUsers = res.totalItems
    this.dataSource = new MatTableDataSource(this.associatedUsers)
    this.dataSource.sort = this.sort
    this.paginator.length = this.totalAssociatedUsers
    this.dataSource.sort = this.sort
  }

  async getAssociatedUserByAssociationType(currentPage: number, pageSize: number, filter: string) {
    switch (this.associationType) {
      case 'customer': return await this.userCustomerService.listAssociatedUsersToCustomer({ customerId: this.id, currentPage, pageSize, filter })
      case 'planogram': return await this.dal.listAssociatedUsersToPlanogram({ planogramId: this.id, currentPage, pageSize, filter })
    }
  }

  closeAddUser() {
    this.filter = ''
    this.getAssociatedUsers(this.paginator.pageIndex, this.paginator.pageSize, this.filter)
  }

  ondeleteClick(userId: string) {
    this.userId = userId
    this.isShowConfirmDeleteDialog = true
  }

  async deleteUserCustomer() {
    this.isShowConfirmDeleteDialog = false

    switch (this.associationType) {
      case 'customer': await this.userCustomerService.deleteUserCustomer({ customerId: this.id, userId: this.userId })
        break
      case 'planogram': await this.dal.deleteAssociatedUsersToPlanogram({ planogramId: this.id, userId: this.userId })
        break
    }

    this.getAssociatedUsers(this.paginator.pageIndex, this.paginator.pageSize, this.filter)
    this.snackbarService.openSnackBar(2000, `User was successfully deleted from ${this.associationType}`)
  }
}
