import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { AutoDestroy } from "../base-directives/auto-destroy";
import { ApiService, IWrapperError } from "./api.service";
import { LanguageService } from "./language.service";
import { getCompanyType } from "./companyType.service";
import { AuthService } from "./auth.service";
import { CANCEL_REQUEST_ERROR } from "../const";


@Injectable({ providedIn: 'root' })
export class ApiTrackerService extends AutoDestroy {
    private onSpinnerNeededChanged = new Subject<{ isShow: boolean }>()
    private onError = new Subject<{ error: string, errors?: string[] }>()
    private requestCount = 0

    onSpinnerNeededChanged$ = this.onSpinnerNeededChanged.asObservable()
    onError$ = this.onError.asObservable()

    get isSpinnerNeeded() { return this.requestCount > 0 }

    constructor(private api: ApiService, private languageService: LanguageService, private router: Router, private route: ActivatedRoute, private authService: AuthService) {
        super()
    }

    init() {
        this.subscriptions.push(this.api.onRequest$.subscribe(d => {
            switch (d.w) {
                case 'start':
                    if (!(d.isHideProgress === true)) {
                        this.requestCount++
                        this.onSpinnerNeededChanged.next({ isShow: true })
                    }
                    break
                case 'error':
                    if (d.data.isWrapperError === true) {
                        const err: IWrapperError = d.data
                        this.onError.next({ error: err.errorMessage })
                    } else {
                        const err = d.data as HttpErrorResponse

                        if (err.status === 401) {
                            if (getCompanyType() === 'Standalone' && !d.noLogoutOn401) {
                                this.authService.logOut(true)
                            }
                        } else if (err.status === 403) {
                            this.onError.next({ error: this.languageService.translateSync('UnauthorizedError') })
                        } else if (err.error === CANCEL_REQUEST_ERROR) {
                            console.warn(CANCEL_REQUEST_ERROR)
                        } else {
                            const errors: string[] = err.error.errors || [this.languageService.translateSync('UnknownError')]
                            this.onError.next({ error: this.languageService.translateSync(errors[0]), errors })
                        }
                    }
                // We dont break here, so we can continue as done flow
                case 'done':
                    if (!(d.isHideProgress === true)) {
                        this.requestCount--
                        if (this.requestCount === 0) {
                            this.onSpinnerNeededChanged.next({ isShow: false })
                        }
                    }
                    break
                case 'show-loading':
                    this.onSpinnerNeededChanged.next({ isShow: true })
                    break
                case 'hide-loading':
                    this.onSpinnerNeededChanged.next({ isShow: false })
                    break
            }
        }))
    }

}
