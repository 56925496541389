import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OrganizationsService } from 'src/app/super-admin/services/organizations.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CreateOrganizationRequest } from 'src/app/shared/api-structures/super-admin/organization';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AppService } from 'src/app/services/app.service';

export enum ComponentMode {
  CREATE,
  UPDATE
}

@Component({
  selector: 'create-update-organization',
  templateUrl: './create-update-organization.component.html',
  styleUrls: ['./create-update-organization.component.scss']
})
export class CreateUpdateOrganizationComponent {
  organizationId: string;
  organizationName: string;
  organizationLanguage: string;
  id: string;
  createUpdateMode: ComponentMode;
  createUpdatetitle: string = 'CreateOrganization';
  confirmTitle: string = 'ConfirmCreationTitle';
  confirmMessage: string = 'confirmOrganizationCreationMessage';
  public componentModes = ComponentMode;
  samplesPerCall: number = 20
  samplesPerProduct: number = 200
  trainingBudget: number = 5
  language: string = "en"
  languages: any[] = [
    {
      id: "pt",
      name: "Portuguese"
    },
    {
      id: "es",
      name: "Spanish"
    },
    {
      id: "en",
      name: "English"
    },
    {
      id: "el",
      name: "Greek"
    },
    {
      id: "ar",
      name: "Arabic"
    }
  ]
  _logoUrl: string
  _bannerUrl: string
  isLoadingSettings: boolean
  primaryColor: string = "#000000"
  secondaryColor: string = "#000000"
  isEdition: boolean = true

  createOrganization: CreateOrganizationRequest = {
    general: {},
    theme: {},
    training: {}
  } as CreateOrganizationRequest

  uploadProgress: {
    name: string,
    progress: number
  }

  constructor(
    public dialogRef: MatDialogRef<CreateUpdateOrganizationComponent>,
    private languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private organizationsService: OrganizationsService,
    private utilService: UtilitiesService,
    private appService: AppService,
  ) {

    this.isLoadingSettings = false
    if (data) {
      this.isEdition = true
      this.createUpdateMode = ComponentMode.UPDATE;
      this.organizationId = data.id;
      this.organizationName = data.name;
      //this.organizationLanguage = data.language;
      this.id = data.id;
      this.createUpdatetitle = 'UpdateOrganization';
      this.confirmTitle = 'ConfirmUpdateTitle';
      this.confirmMessage = 'ConfirmOrganizationUpdateMessage';

      this.language = data.language
      this._logoUrl = data.logoUrl
      this._bannerUrl = data.bannerUrl
      this.primaryColor = data.primaryColor
      this.secondaryColor = data.secondaryColor
      this.samplesPerCall = data.samplesPerCall
      this.samplesPerProduct = data.samplesPerProducts
      this.trainingBudget = data.trainBudget

    } else {
      this.isEdition = false
      this.createUpdateMode = ComponentMode.CREATE;
    }
  }

  isFormValid(): boolean {

    return this.organizationId && this.organizationId?.trim() !== '' &&
      this.organizationName && this.organizationName?.trim() !== '' &&
      this.samplesPerCall && this.samplesPerCall &&
      this.samplesPerProduct && this.samplesPerProduct &&
      this.trainingBudget && this.trainingBudget &&
      this.language && this.language?.trim() !== '';
  }

  openConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: {
        title: `${this.languageService.translateSync(this.confirmTitle)}`,
        message: `${this.languageService.translateSync(this.confirmMessage)} '${this.createUpdateMode === ComponentMode.CREATE ? this.organizationName : this.id}'?`
      }
    });
    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return;
      }
      this.save();
    });
  }

  async save() {

    this.createOrganization.id = this.organizationId
    this.createOrganization.name = this.organizationName
    this.createOrganization.general.language = this.language
    this.createOrganization.theme.logoUrl = this._logoUrl
    this.createOrganization.theme.bannerUrl = this._bannerUrl
    this.createOrganization.theme.primaryColor = this.primaryColor
    this.createOrganization.theme.secondaryColor = this.secondaryColor
    this.createOrganization.training.samplesPerCall = this.samplesPerCall
    this.createOrganization.training.samplesPerProducts = this.samplesPerProduct
    this.createOrganization.training.trainBudget = this.trainingBudget

    if (this.createUpdateMode === ComponentMode.CREATE) {
      await this.organizationsService.createOrganization(this.createOrganization);
      this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('organizationSuccessfullyCreated')}`);
    } else if (this.createUpdateMode === ComponentMode.UPDATE) {
      await this.organizationsService.updateOrganization(this.createOrganization);
      this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('organizationSuccessfullyUpdated')}`);
    }
    this.dialogRef.close({ isOrganizationUpdateOrCreated: true });
  }

  onFileSelected(e) {

  }

  async saveLogoFileToStorageLogo(event: FileList) {
    const file: File = event[0]
    if (this.utilService.validateImageType(file) == false) {
      return
    }
    this.uploadProgress = { name: file.name, progress: 0 }
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadProgress.progress = progress, true, this.organizationId)
    await this.utilService.isFileUrlExist(fileUrl)
    this._logoUrl = fileUrl
    this.uploadProgress = undefined
  }

  async saveLogoFileToStorageBanner(event: FileList) {
    const file: File = event[0]
    if (this.utilService.validateImageType(file) == false) {
      return
    }
    this.uploadProgress = { name: file.name, progress: 0 }
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadProgress.progress = progress, true, this.organizationId)
    await this.utilService.isFileUrlExist(fileUrl)
    this._bannerUrl = fileUrl
    this.uploadProgress = undefined
  }
}
