<h2 mat-dialog-title>
  {{headerText|translate}}
</h2>
<mat-dialog-content style="min-width: 600px">
  <form [formGroup]="form">

    <mat-form-field>
      <input matInput placeholder="{{'Name' | translate}}" formControlName="name" required>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="{{'EntityType' | translate}}" formControlName="entityType" required>
        <mat-option *ngFor="let entityType of entityTypes" [value]="entityType">
          {{entityType}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="{{'Type' | translate}}" formControlName="fieldType" required>
        <mat-option *ngFor="let fieldType of fieldTypes" [value]="fieldType">
          {{fieldType}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <list-input *ngIf="form.get('fieldType').value === 'select' " placeholder="{{'AddSelectOptions' | translate}}"
      formControlName="options"></list-input>

    <custom-field-input [fieldType]="form.get('fieldType').value" [options]="form.get('options').value"
      placeholder="{{'DefaultValue' | translate}}" formControlName="defaultValue">
    </custom-field-input>

    <div class="formSlideToggle">
      {{"IsFieldRequired?" | translate}}<mat-slide-toggle formControlName="isRequired"></mat-slide-toggle>
    </div>

    <div class="row" style="gap: 6px">
      <button mat-stroked-button style="width: 48%;" type="button" color="primary" (click)="onCancel()">{{'Cancel'
        | translate}}</button>
      <button mat-flat-button style="width: 48%;" type="button" color="primary" cdkFocusInitial
        (click)="onSave()">{{'Save' | translate}}</button>&nbsp;
    </div>
  </form>
</mat-dialog-content>