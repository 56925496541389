import { Injectable } from '@angular/core'
import { CustomField } from '../components/custom-fields/custom-fields.component';


@Injectable({ providedIn: 'root' })
export class HelpersService {
    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    runNextTick(a: () => void) {
        this.delay(0).then(() => a())
    }

    capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}