<!-- <div class="page-layout px-4 px-sm-5 py-3">
  <div class="flex justify-content-between align-content-center flex-xs-column flex-wrap">
    <div class="flex flex-column flex-grow-1 breadcumb pr-3">
      <span class="title">{{ title}}</span>
      <span class="sub-title">{{ description }}</span>
    </div>

    <div class="flex justify-content-end py-3">
      <div>
        <ng-content select="page-layout-header"></ng-content>
      </div>
    </div>
  </div>

  <div class="mt-3 layout-content mat-elevation-z8 p-3">
    <ng-content></ng-content>

  </div>
</div> -->

<ng-template #centerHeader>
  <div class="flex justify-content-between align-content-center mt-2 flex-xs-column flex-wrap">
    <div class="flex flex-column flex-grow-1 breadcumb pr-3">
      <span class="title">{{ title}}</span>
      <span class="sub-title">{{ description }}</span>
    </div>

    <div class="flex justify-content-end py-3">
      <div>
        <ng-content select="page-layout-header"></ng-content>
      </div>
    </div>
  </div>
</ng-template>


<div class="page-layout">
  <div ngClass="page-layout-{{layout}}">
    <ng-container *ngIf="layout !== layoutType.Centered; else centerHeader">
      <ng-content select="page-layout-header"></ng-content>
    </ng-container>

    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="page-footer border-top" *ngIf="layout === layoutType.Full ">
    <ng-content select="page-layout-footer"></ng-content>
  </div>
</div>