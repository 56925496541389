import { Injectable } from '@angular/core';
import { ListAssociatedUsersToCustomerRequest, ListAssociatedUsersToCustomer, ListAssociatedCustomersToUserRequest, ListAssociatedCustomersToUserResponse, AddUserCustomerRequest, DeleteUserCustomerRequest } from '../../shared/api-structures/admin/userCustomer';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserCustomerService {
  constructor(private api: ApiService, private auth: AuthService) { }

  async listAssociatedUsersToCustomer(data: ListAssociatedUsersToCustomerRequest) {
    const result = await this.api.adminRequest<ListAssociatedUsersToCustomerRequest, ListAssociatedUsersToCustomer.Response>({
      endpoint: '/listAssociatedUsersToCustomer',
      body: data,
      context: this.auth.context,
      responseType: ListAssociatedUsersToCustomer.Response
    })

    return result
  }

  async listAssociatedCustomersToUser(data: ListAssociatedCustomersToUserRequest) {
    const result = await this.api.adminRequest<ListAssociatedCustomersToUserRequest, ListAssociatedCustomersToUserResponse>({
      endpoint: '/listAssociatedCustomersToUser',
      body: data,
      context: this.auth.context,
      responseType: ListAssociatedCustomersToUserResponse
    })

    return result
  }

  async addUserCustomer(data: AddUserCustomerRequest) {
    const result = await this.api.adminRequest<AddUserCustomerRequest>({
      endpoint: '/addUserCustomer',
      context: this.auth.context,
      body: data,
    })

    return result
  }

  async deleteUserCustomer(data: DeleteUserCustomerRequest) {
    const result = await this.api.adminRequest<DeleteUserCustomerRequest>({
      endpoint: '/deleteUsersCustomer',
      context: this.auth.context,
      body: data
    })

    return result
  }
}
