import { AfterViewInit, Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Dal } from "src/app/dal/dal";
import { SearchProductsRequestFilters } from "src/app/shared/api-structures/misc/product";
import { DatePipe } from "@angular/common";
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: "products-filter-options",
  templateUrl: "./products-filter-options.component.html",
  styleUrls: ["./products-filter-options.component.scss"],
})
export class ProductFilterOptions implements AfterViewInit {
  originalFilterInitDate: string;
  originalFilterEndDate: string;
  pattern: string = "dd/MM/yyyy";
  categoriesOptions = [];
  brandsOptions = [];
  minimumImages: number = 0;
  maximumImages: number = 0;
  minimumCatalogs: number = 0;
  maximumCatalogs: number = 0;
  maxNumberImagesInCatalog: number;
  maxNumberOfCatalogs: number;

  ngAfterViewInit(): void {
    let iniFormatedDate = "";
    let endFormatedDate = "";

    if (this.data.updatedAtStart != undefined) {
      this.originalFilterInitDate = this.data.updatedAtStart;
      iniFormatedDate = this.formatDate(this.data.updatedAtStart, this.pattern);
    }

    if (this.data.updatedAtEnd != undefined) {
      this.originalFilterEndDate = this.data.updatedAtEnd;
      endFormatedDate = this.formatDate(this.data.updatedAtStart, this.pattern);
    }

    const patchValue = {
      minimumImages: this.data.minimumImages,
      maximumImages: this.data.maximumImages,
      minimumCatalogs: this.data.minimumCatalogs,
      maximumCatalogs: this.data.maximumCatalogs,
      updatedAtStart: iniFormatedDate,
      updatedAtEnd: endFormatedDate,
      category: this.data.category,
      brand: this.data.brand,
      isUsedInPlanogram: this.data.isUsedInPlanogram,
      productStatus: this.data.productStatus,
    };

    this.form.patchValue(patchValue);
    this.cdr.detectChanges();
  }

  constructor(
    public dialogRef: MatDialogRef<ProductFilterOptions>,
    private dal: Dal,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: SearchProductsRequestFilters
  ) {
    this.dal.getCompanySettings().then(companySettings => {
      this.maxNumberImagesInCatalog = companySettings.maxNumberImagesInCatalog;
      this.maxNumberOfCatalogs = companySettings.maxNumberOfCatalogs;
    });

    this.getCategories().then(() => {
      this.categoriesOptions = this.categoriesOptions.filter(function (a) {
        return (
          !this[JSON.stringify(a.name)] && (this[JSON.stringify(a.name)] = true)
        );
      }, Object.create(null));
    });
    this.getBrandsOptions().then(() => {
      this.brandsOptions = this.brandsOptions.filter(function (a) {
        return (
          !this[JSON.stringify(a.name)] && (this[JSON.stringify(a.name)] = true)
        );
      }, Object.create(null));
    });
  }

  form = new FormGroup({
    minimumImages: new FormControl(),
    maximumImages: new FormControl(),
    minimumCatalogs: new FormControl(),
    maximumCatalogs: new FormControl(),
    updatedAtStart: new FormControl(),
    updatedAtEnd: new FormControl(),
    category: new FormControl(),
    brand: new FormControl(),
    isUsedInPlanogram: new FormControl(),
    productStatus: new FormControl(),
  });

  async getCategories() {
    this.categoriesOptions = await this.dal.getCategoriesWithIds();
  }

  async getBrandsOptions() {
    this.brandsOptions = await this.dal.getBrandsWithIds();
  }

  onSubmit() {
    this.form.controls.updatedAtStart = new FormControl(
      this.originalFilterInitDate
    );
    this.form.controls.updatedAtEnd = new FormControl(
      this.originalFilterEndDate
    );
    this.dialogRef.close(this.form.value);
  }

  formatDate(dateString: string, pattern: string) {
    const formattedDate = this.datePipe.transform(dateString, pattern);
    return formattedDate;
  }

  dateRangeChange(
    event: any,
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ) {
    if (
      event.target.ngControl.name === "updatedAtStart" &&
      event.target.value != ""
    ) {
      if (
        event.target.value.toString().length != this.pattern.toString().length
      ) {
        this.originalFilterInitDate = dateRangeStart.value;
        dateRangeStart.value = this.formatDate(
          dateRangeStart.value,
          this.pattern
        );
      }
    }

    if (
      event.target.ngControl.name === "updatedAtEnd" &&
      event.target.value != ""
    ) {
      if (
        event.target.value.toString().length != this.pattern.toString().length
      ) {
        this.originalFilterEndDate = dateRangeEnd.value;
        dateRangeEnd.value = this.formatDate(dateRangeEnd.value, this.pattern);
      }
    }
  }

  Clear() {
    setTimeout(() => {
      this.minimumImages = 0;
      this.maximumImages = 150;
      this.minimumCatalogs = 0;
      this.maximumCatalogs = 150;
    }, 0);
  }
}
