import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AreaService } from 'src/app/admin/services/area.service';
import { ClusterWithId } from 'src/app/shared/api-structures/admin/cluster';

@Component({
  selector: 'associated-clusters',
  templateUrl: './associated-clusters.component.html',
  styleUrls: ['./associated-clusters.component.scss']
})
export class AssociatedClustersComponent implements OnInit {

  @Input() data: ClusterWithId[] = []
  @Input() selected: Record<string, boolean> = {}
  @Output() onSearch = new EventEmitter<string>()
  @Output() onToggleSelect = new EventEmitter<string>()
  @Output() onToggleSelectAll = new EventEmitter<void>()
  @Output() onConfirm = new EventEmitter<void>()
  @Output() onCancel = new EventEmitter<void>()
  searchFilter: string = ''
  allSelected: boolean = false
  areas: { name: string, id: string }[] = []

  constructor(public areaService: AreaService) { 
  }

  ngOnInit(): void {
    const ob = this.areaService.areas$.subscribe(areas => {
      this.areas = areas.map(area => ({ name: area.name, id: area.id }))
      ob.unsubscribe()
    })
    this.areaService.listAreas({ currentPage: 0, pageSize: 10000 })
  }

  ngOnChanges() {
    this.allSelected = this.data.length && this.data.every(cluster => this.selected[cluster.id])
  }

  emitSearchFilter(searchFilter: string) {
    this.searchFilter = searchFilter
    this.onSearch.emit(this.searchFilter)
  }

  emitSelectAll() {
    this.onToggleSelectAll.emit()
  }

  emitConfirm() {
    this.onConfirm.emit()
  }

  emitCancel() {
    this.onCancel.emit()
  }

}
