import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { saveAs } from "file-saver";
import * as moment from 'moment-timezone';
import { AppService } from 'src/app/services/app.service';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SortDirection } from 'src/app/shared/api-structures/common';
import { SearchProducts } from 'src/app/shared/api-structures/misc/catalog';
import { SearchProductsRequestFilters } from 'src/app/shared/api-structures/misc/product';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import * as _ from 'underscore';
import { isEqual } from 'underscore';
import { Dal } from '../../../dal/dal';
import { DataService } from '../../../services/data.service';
import { ImagesCatalog } from '../../../shared/interfaces';
import { ProductNewDialogComponent } from '../product-new-dialog/product-new-dialog.component';
import { ProductFilterOptions } from './product-filter/products-filter-options.component';
import { DatePipe } from '@angular/common';
import { ProductsPlanogramsDialogComponent } from '../products-planograms-dialog/products-planograms-dialog.component';
import { mockCsvData } from './csvTemplateData'
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements AfterViewInit {
  moment = moment;
  displayedColumns: string[] = ['image', 'productInfo', 'ean', 'categories', 'brands', 'qtUsedInPlanograms', 'totalImageCatalogs', 'totalImages', 'updatedAt', 'status', 'editIcon'];
  displayedColumnsOthers: string[] = ['image', 'productInfo', 'qtUsedInPlanograms', 'totalImageCatalogs', 'totalImages', 'updatedAt', 'editIcon']
  dataSource: MatTableDataSource<SearchProducts.Item>
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort
  products: SearchProducts.Item[]
  totalProducts: number;
  imagesCatalogs: ImagesCatalog[]
  sortBy: string
  sortDirection: SortDirection
  filterShowSpinner: false
  filters: string[] = []
  newFilters: SearchProductsRequestFilters = {
    minimumImages: null,
    maximumImages: null,
    updatedAtEnd: null,
    updatedAtStart: null,
    category: null,
    brand: null,
    isUsedInPlanogram: null,
    productStatus: null
  }
  companyType = getCompanyType()
  showFilters = false
  isPlanogramListVisible = false
  remainingTrainings: number
  readonly filterSeparatorKeysCodes: number[] = [ENTER, COMMA]

  importDialogData: any = {
    template: mockCsvData,
    title: "Products",
    id: "products",
  };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog,
    private dal: Dal,
    private router: Router,
    private appService: AppService,
    private snackbarService: SnackbarService,
    private memoryStorageService: MemoryStorageService,
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe) { }


  ngAfterViewInit() {
    this.paginator.pageSize = 25
    let currentPage = this.paginator.pageIndex
    this.newFilters = {
      isCompetitors: false, 
      minimumImages:'0',
      maximumImages:null,
      minimumCatalogs:'0',
      maximumCatalogs:null
    }
    this.getProducts(currentPage)
    this.getContractTraining()
    this.cdr.detectChanges();
  }

  changeTab(index) {
    if (index === 0) {
      this.newFilters = {
        ...this.newFilters,
        isCompetitors: false,
        othersProducts: false
      }
      this.getProducts(0)
    } else if (index === 1) {
      this.newFilters = {
        ...this.newFilters,
        isCompetitors: true,
        othersProducts: false
      }
      this.getProducts(0)
    } else {
      this.newFilters = {
        ...this.newFilters,
        isCompetitors: false,
        othersProducts: true
      }
      this.getProducts(0)
    }
  }

  handleOpenProductPanogramList(item: SearchProducts.Item): void {
    if (item.qtUsedInPlanograms === 0) {
      return
    }
    const dialogRef = this.dialog.open(ProductsPlanogramsDialogComponent, {
      data: {
        productId: item.productId,
        productName: item.name,
        productThumbnailUrl: item.thumbnailUrl
      },
      panelClass: 'no-padding-dialog-container',
      width: '50vw',
    });
  }

  async getContractTraining() {
    this.memoryStorageService.contractTrainingObs.subscribe(v => {
      if (v) {
        this.remainingTrainings = Math.abs(v.trainingCount - v.trainingQuota + v.singleTrainingSold)
      }
    }).unsubscribe()
  }

  async getProducts(currentPage: number, sortBy?: string, sortDirection?: SortDirection) {
    this.products = []
    const res = await this.dal.searchProducts(currentPage, this.paginator.pageSize, this.filters, this.newFilters, false, sortBy as any, sortDirection)
    this.totalProducts = res.totalProducts
    this.products = res.items
    this.dataSource = new MatTableDataSource(this.products)
    this.paginator.length = this.totalProducts
  }

 async applyFilter() {
   await this.getProducts(this.paginator.pageIndex, this.sortBy, this.sortDirection)
   this.cdr.detectChanges();
  }

  sortData(sort: Sort) {
    this.sortBy = undefined
    this.sortDirection = undefined
    if (sort.direction !== '') {
      this.sortBy = sort.active
      this.sortDirection = sort.direction as SortDirection 
    }
    this.applyFilter()
  }


  addFilter(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement
    const value = event.value

    if ((value || '').trim()) {
      this.filters.push(value.trim())
      this.applyFilter()
      setTimeout(() => {
        this.paginator.firstPage()
      }, 0);
    }

    // Reset the input value
    if (input) {
      input.value = ''
    }
  }
  removeFilter(filter: string) {
    const index = this.filters.indexOf(filter);

    if (index >= 0) {
      this.filters.splice(index, 1);
      this.applyFilter();
    }
  }

  async exportProducts() {
    const res = await this.dal.exportProductsWithFilters(this.filters, this.newFilters)
    saveAs(res.url, "products.csv")
  }

  openFilterOptions(event: MouseEvent) {
    event.stopPropagation();

    if (this.newFilters.maximumImages === null) {
      this.newFilters.maximumImages = '150'
      this.newFilters.maximumCatalogs = '150'
    }

    const dialogRef = this.dialog.open(ProductFilterOptions, { data: this.newFilters });
    dialogRef.afterClosed().subscribe(data => {
      if (data && !isEqual(this.newFilters, data)) {
        this.newFilters = {
          ...this.newFilters,
          ...data
        }
        this.applyFilter()
      }
    });
  }

  formatDate(dateString: string) {
    const formattedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy'); 
    return formattedDate;
  }
}
