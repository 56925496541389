<div class="list-input-main" #listInputMain (outsideClick)="hideList()">
  <mat-form-field>
    <input matInput (focus)="showList()" (keyup.enter)="addValue()"
      [placeholder]="placeholder + (values.length ? ' ('+values.length+') ' : '') " [formControl]="ctrl">
  </mat-form-field>
  <div class="floating-list" [style]="listStyle" *ngIf="showListFlag && values.length">
    <div class="floating-list-item" *ngFor="let value of values; index as index">
      {{value}} <mat-icon (click)="removeValue($event,index)">delete</mat-icon>
    </div>
  </div>
</div>