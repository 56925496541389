import { Expose, Type } from 'class-transformer'
import { IsArray, IsDate, IsIn, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { DateType } from '../customDecorators'
import { ByPageRequest, SortDirection, SortDirectionArray } from '../common'

export class BasePriceTarget {
  @Expose() @IsString() productId!: string
  @Expose() @IsString() productName!: string
  @Expose() @IsString() @IsOptional() thumbnailUrl?: string
  @Expose() @IsNumber() @IsOptional() minPrice?: number
  @Expose() @IsNumber() minPriceLabel?: string
  @Expose() @IsNumber() price!: number
  @Expose() @IsNumber() priceLabel?: string
  @Expose() @IsNumber() @IsOptional() maxPrice?: number
  @Expose() @IsNumber() maxPriceLabel?: string
  @Expose() @IsString() @IsOptional() customerTerritory?: string
  @Expose() @IsString() @IsOptional() customerArea?: string
  @Expose() @IsString() @IsOptional() customerCategory?: string
  @Expose() @IsString() @IsOptional() customerChain?: string
  @Expose() @DateType() @IsOptional() @IsDate() dateFrom?: Date
  @Expose() @DateType() @IsOptional() @IsDate() dateTo?: Date
}

export class PriceTargetFull extends BasePriceTarget {
  @Expose() @IsString() id!: string
  @Expose() @DateType() @IsDate() lastUpdate!: Date
  @Expose() @DateType() @IsDate() createdAt!: Date
}

export class AddPriceTargetRequest extends BasePriceTarget { }
export class AddPriceTargetResponse extends PriceTargetFull { }

export class UpdatePriceTargetRequest {
  @Expose() @IsString() id!: string
  @Expose() @Type(() => BasePriceTarget) @ValidateNested() data!: BasePriceTarget
}
export class UpdatePriceTargetResponse extends PriceTargetFull { }

export class ListPriceTargetsRequest extends ByPageRequest {
  @Expose() @IsString() @IsOptional() @IsIn(SortDirectionArray) sort?: SortDirection
  @Expose() @IsString() @IsOptional() sortBy?: keyof PriceTargetFull
  @Expose() @IsString() @IsOptional() textFilter?: string
}

export class ListPriceTargetsResponse {
  @Expose() @IsNumber() totalItems!: number
  @Expose() @Type(() => PriceTargetFull) @IsArray() items!: PriceTargetFull[]
}