import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserComponent } from 'src/app/shared/components/user-management/create-user/create-user.component';
import { SuperUserManagementService } from '../../services/super-user-management.service';
import { saveAs } from "file-saver";
import { UserManagementService } from 'src/app/shared/services/user-management.service';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  nextPageToken: string | undefined;
  previousTokens: string[] = [];
  pageSizeOptions = [5, 10, 25, 100];
  pageSize = 10;
  userData: any[] = [];
  totalUsers: number = 0

  constructor(
    private superUserManagementService: SuperUserManagementService,
    private dialog: MatDialog,
    private userManagementService: UserManagementService
  ) { }

  ngOnInit() {
    this.getUsers(undefined);
  }

  refresh() {
    this.getUsers(undefined);
    this.previousTokens = [];
  }

  async getUsers(nextPageToken?: string) {
    try {
      const res = await this.superUserManagementService.getUsers({ maxPage: this.pageSize, token: nextPageToken });
      this.userData = res.items
      this.totalUsers = res.totalUsers
      console.log(res)
      if (res.nextPageToken !== this.nextPageToken) {
        if (nextPageToken) this.previousTokens.push(nextPageToken);
        this.nextPageToken = res.nextPageToken;
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  getNextPage() {
    if (this.nextPageToken) {
      this.getUsers(this.nextPageToken);
    }
  }

  getPreviousPage() {
    const previousToken = this.previousTokens.pop();
    if (previousToken !== undefined) {
      this.getUsers(previousToken);
    }
  }

  onPageSizeChanged() {
    this.getUsers(undefined);
  }

  add() {
    const dialogRef = this.dialog.open(CreateUserComponent);
    dialogRef.afterClosed().subscribe(v => {
      if (v?.isUserCreated) {
        this.refresh();
      }
    });
  }

  async export() {
    const data = this.userData;
    if (!data || data.length === 0) {
      return;
    }
    const replacer = (key: string, value: any) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'user_list.csv';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
