<div class="mat-tab-main" *ngIf="isExternalDashboards">
    <mat-tab-group style="margin-bottom:5px;" animationDuration="0" mat-align-tabs="start"
        (selectedIndexChange)="index=$event" mat-stretch-tabs>
        <mat-tab *ngFor="let tab of dashboards" style="height: calc(100vh - 200px)" label="{{tab.title}}">
        </mat-tab>
    </mat-tab-group>

    <div *ngFor="let tab of dashboards" [style.display]="index!=tab.index?'none':null">
        <iframe class="dashboard-iframe" [src]="tab.url | safe"></iframe>
    </div>
</div>
<iframe [src]="internalDashboardsUrl | safe" *ngIf="!isExternalDashboards && internalDashboardsUrl"></iframe>