import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SelectTableFetchFunction } from '../../interfaces';
interface SelectTableRow {
  id: string,
  [key: string]: string | number | Date
}

@Component({
  selector: 'app-select-table',
  templateUrl: './select-table.component.html',
  styleUrls: ['./select-table.component.scss']
})

export class SelectTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @Input() fields: string[]
  @Input() fetchData: SelectTableFetchFunction<SelectTableRow>
  @Input() fieldsOptions: { [key: string]: { label: string, type: 'text' | 'date' } } = {}
  @Input() disableSort: boolean = false
  @Output() select = new EventEmitter<SelectTableRow>()

  sortBy = 'id'
  sortDirection: SortDirection = 'desc'
  textFilter = ''
  totalItems = 0
  data: SelectTableRow[] = []
  constructor(private snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.paginator.pageSize = 10
    this.fetch()
  }

  async fetch() {
    try {
      const res = await this.fetchData({ page: this.paginator.pageIndex, pageSize: this.paginator.pageSize, textFilter: this.textFilter, sortDirection: this.sortDirection, sortBy: this.sortBy })
      this.data = res.items
      this.totalItems = res.totalItems
    } catch (error) {
      console.error(error)
      this.snackbarService.openSnackBar(2000, "CouldntFetchData")
    }
  }

  onSearch(event: KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value
    this.textFilter = value
    this.fetch()
  }

  onSort(sort: { active: string, direction: SortDirection }) {
    if (!sort.direction) {
      this.sortBy = null
      this.sortDirection = null
    } else {
      this.sortBy = sort.active
      this.sortDirection = sort.direction
    }
    this.fetch()
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  selectRow(row: SelectTableRow) {
    this.select.emit(row)
  }

}
