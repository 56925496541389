<form [formGroup]="formCustomer" (ngSubmit)="submitForm()">
  <div class="container-tab">
    <mat-tab-group animationDuration="0ms" mat-stretch-tabs="true" dynamicHeight>
      <mat-tab label="{{'General' | translate}}"><br>
        <span class="section-title">{{'CustomerInfo' | translate}}</span>
        <div style="display: flex; gap: 24px; margin-top: 12px;">
          <mat-form-field>
            <mat-label>{{'CustomerName' | translate}}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'CustomerCategory' | translate}}</mat-label>
            <input matInput formControlName="category">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'CustomerType' | translate}}</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let v of customerTypes" [value]="v">{{v | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'customerCode'|translate}}</mat-label>
            <input matInput formControlName="customerCode">
          </mat-form-field>
        </div>
        <div style="display: flex; gap: 24px;">
          <mat-form-field>
            <mat-label>{{'ExternalId' | translate}}</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'CustomerChain' | translate}}</mat-label>
            <input matInput formControlName="chain">
          </mat-form-field>
        </div>
      </mat-tab>
      <mat-tab label="{{'AddressTerritory' | translate}}">
        <div style="display: flex; gap: 24px;">
          <div class="section-square">
            <div class="section-button">
              <span class="section-title">{{'Addresses' | translate}}</span>
              <button class="blue-stroked-button" type="button" mat-flat-button (click)="onAddAdress()">
                <mat-icon>add</mat-icon>
                {{'AddAddress'|translate}}
              </button>
            </div>
            <div formArrayName="addresses">
              <div *ngFor="let address of addressControls; let i = index">
                <address-input (setAddress)="onPlaceChanged($event, i)" [initialAddress]="address.value"></address-input>
              </div>
            </div>
            <div style="display: flex;
              flex-direction: column;
              gap: 12px;
              width: -webkit-fill-available;">
              <span class="section-title">{{'Areas' | translate}}</span>
              <div style="display: grid; grid-template-columns: auto auto; gap: 12px;">
                <div class="square-border" *ngFor="let area of areas">
                  <span class="square-name">{{area.name}}</span><br>
                  <span class="square-id">{{area.id}}</span>
                </div>
              </div>
            </div>
          </div>
          <div formArrayName="territories" class="section-square">
            <div class="section-button">
              <span class="section-title">{{'Territory' | translate}}</span>
              <button class="blue-stroked-button" type="button" mat-flat-button (click)="onAddTerritory()">
                <mat-icon>add</mat-icon>
                {{'AddTerritory'|translate}}
              </button>
            </div>
            <div style="display: grid; grid-template-columns: auto; gap: 12px;">
              <div class="square-border" *ngFor="let territory of territoryControls; let i = index">
                <span class="square-name" style="display: flex;">
                  <input matInput [formControlName]="i">
                  <span style="cursor: pointer;" (click)="deleteTerritory(i)">
                    <mat-icon style="color: rgba(162, 51, 51, 1);">delete</mat-icon>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'CustomFields' | translate}}">
        <div *ngIf="customFieldsValue && customFields">
          <custom-fields [customFieldsValues]="customFieldsValue" (formValue)="customFieldsChanged($event)"
          [fieldsSettings]="customFields"></custom-fields>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  
  <div class="action-buttons">
    <button mat-stroked-button color="primary" routerLink="/admin/customer">
      <mat-icon>arrow_back</mat-icon>
      {{'Back'|translate}}
    </button>
    <button type="submit" color="primary" [disabled]="!formCustomer.valid" mat-flat-button>
      {{'Save&Continue'|translate}}
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</form>