import { style } from "@angular/animations"
import { CommonModule } from "@angular/common"
import { Component, OnInit, ViewEncapsulation } from "@angular/core"

@Component({
  selector: "table-layout",
  standalone: true,
  styleUrls: ["./table.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
  template: `
    <div class="table">
      <div class="px-3 border-bottom">
        <ng-content select="table-layout-caption"></ng-content>
      </div>

      <div
        class="table-scroll"
      >
        <ng-content></ng-content>
      </div>

      <div class="table-fotter py-1">
        <ng-content select="table-layout-footer"></ng-content>
      </div>
    </div>
  `,
})
export class TableLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
