<p-sidebar #sidebar [(visible)]="isShowNav">
    <mat-nav-list>
        <a mat-list-item [routerLink]="['/home']" *checkPermission="'Planograms|ImagesReview|Products|dashboard'">
            <mat-icon>home</mat-icon>{{'Home'|translate}}
        </a>
        <a mat-list-item [routerLink]="['trainings']" *checkPermission="'Train'">
            <mat-icon>list</mat-icon>{{'Trainings'|translate}}
        </a>
        <a mat-list-item [routerLink]="['static-models']" *checkPermission="'Models'">
            <mat-icon>list</mat-icon>{{'StaticModels'|translate}}
        </a>
        <ng-container *ngIf="companyType === 'Standalone'">
            <a mat-list-item [routerLink]="['customer']" *checkPermission="'Customer'">
                <mat-icon>supervisor_account</mat-icon>{{'CustomerManagement'|translate}}
            </a>
            <a mat-list-item [routerLink]="['user-management']" *checkPermission="'Users'">
                <mat-icon>supervisor_account</mat-icon>{{'UserManagement'|translate}}
            </a>
        </ng-container>
        <a mat-list-item [routerLink]="['areas']" *ngIf="companyType !== 'MC1'">
            <mat-icon>map</mat-icon>{{'Areas'|translate}}
        </a>
        <a mat-list-item [routerLink]="['price-target']" *ngIf="companyType !== 'MC1'">
            <mat-icon>attach_money</mat-icon>{{'PriceTarget'|translate}}
        </a>
        <a mat-list-item [routerLink]="['clusters']">
            <mat-icon>workspaces</mat-icon>{{'Clusters'|translate}}
        </a>
        <a mat-list-item [routerLink]="['custom-fields']">
            <mat-icon>edit_square</mat-icon>{{'CustomFields'|translate}}
        </a>
        <!-- <a mat-list-item [routerLink]="['copier']">
            <mat-icon>list</mat-icon>{{'EnvironmentCopier'|translate}}
        </a>-->
    </mat-nav-list>
</p-sidebar>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="isShowNav=true">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>{{'Admin'|translate}}</span>
            <div style="width: 100%; text-align: end;">
                <span *ngIf="contractTraining">{{remainingTrainig}} {{'RemainingMonthlyTrainings'|translate}}</span>
            </div>
            <!-- <button *ngIf = "companyType === 'Standalone'" mat-icon-button matTooltip="{{ 'Home' | translate }}" aria-label="Home" (click)="goHome()">
                <mat-icon>home</mat-icon>
            </button> -->
            <logout *ngIf="companyType === 'Standalone'" class="move-right"></logout>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>