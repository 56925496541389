<div *ngIf="_logged && showNavbar" class="navigation-listing">
    <side-nav
        [(isShowNav)]="isShowNav"
        [userInfo]="userInfo"
        [menuItems]="menuItems">
</side-nav>
    <mat-sidenav-container class="sidenav-container-user">
        <mat-sidenav-content>
            <mat-toolbar class="toolbar">
                <div>
                    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="isShowNav=true">
                        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                    </button>
                    <span>
                        <img src="/assets/images/white-logo.png" alt="logo" />
                    </span>
                </div>

                <div style="margin-left: 50px; text-align: center;">
                    <h1>WTM Image Recognition</h1>
                </div>

                <div style="display: flex; justify-content: end;">
                    <button mat-button [matMenuTriggerFor]="notificationMenu">
                        <i class="pi pi-bell" style="color: 'rgba(255, 255, 255, 1)'"></i>

                        <div class="notification-trigger"></div>

                    </button>

                    <mat-menu class="mat-menu" #notificationMenu="matMenu" xPosition="before">

                        <app-notification-menu></app-notification-menu>


                    </mat-menu>

                    <button style="padding: 0 8px 0 16px" class="menu-dropdown-btn" mat-button [matMenuTriggerFor]="belowMenu">
                        <i class="pi pi-user" style="color: 'rgba(255, 255, 255, 1)'; margin-right: 4px;"></i>
                        <span class="user-info-name">{{ userInfo?.name }}</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                            aria-hidden="true" data-mat-icon-type="font">arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #belowMenu="matMenu" yPosition="below">
                        <button [routerLink]="['user-settings']" mat-menu-item><i class="pi pi-user"
                                style="color: 'rgba(var(--primary-color-rgb), 1)'"></i><a mat-list-item
                                [routerLink]="['user-settings']"> {{'Settings'|translate}}</a></button>
                        <button [routerLink]="['admin']" *checkPermission="'Train|Users|Models'" mat-menu-item><i
                                class="pi pi-users" style="color: 'rgba(var(--primary-color-rgb), 1)'"></i><a mat-list-item
                                [routerLink]="['/admin']"> {{'Admin'|translate}}</a></button>

                        <button (click)="logout()" mat-menu-item [style]="{
                        color: 'rgba(214, 64, 64, 1)',
                        marginTop: '20px'
                    }">
                            <i class="pi pi-sign-out" style="color: 'rgba(214, 64, 64, 1)'"></i>
                            {{'Logout'|translate}}
                        </button>
                    </mat-menu>
                </div>
            </mat-toolbar>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>