<app-create-equipment-dialog (close)="mode = 'view'"
    *ngIf="mode === 'add'" [equipmentEdit]="equipmentEdit"></app-create-equipment-dialog>
<div *ngIf="mode === 'view'" class="table-main" infiniteScroll [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50" [infiniteScrollDisabled]="isInfiniteScrollDisabled">
    <div class="table-content-container">
        <div class="table-header">
            <div class="table-title-container">
                <div class="title">
                    {{'Equipments'|translate}}
                </div>
                <div class="sub-title">
                    {{'EquipmentsList'|translate}}
                </div>
            </div>
            <div class="buttons-container">
                <button mat-flat-button (click)="openCreateForm()" class="addNew"><mat-icon>add</mat-icon>&nbsp;&nbsp;{{'AddNEW'|translate }}</button>
            </div>
        </div>

        <div class="content">
            <div class="pre-table-header">
                <mat-form-field class="aligned">
                  <mat-chip-list #filterChipList>
                    <mat-chip *ngFor="let filter of filters" [selectable]="selectable" [removable]="true"
                      (removed)="removeFilter(filter)">
                      {{filter}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="{{'SearchEquipment'|translate}}" [matChipInputFor]="filterChipList"
                      [matChipInputSeparatorKeyCodes]="filterSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="addFilter($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-icon (click)="openFilterOptions($event)" style="cursor: pointer;">tune</mat-icon>
              </div>
            <br />
            <ul>
                <li *ngFor="let equipment of equipments let i=index">
                    <mat-card class="mat-elevation-z0">
                        <!-- OLD ONE -->
                        <label [for]="'id'+i" *ngIf="!equipment.shelves">
                            <!-- VERTICAL -->
                            <svg *ngIf="equipment.isVertical; else horizontal"
                                attr.viewBox="0 0 {{getEquipmentHeight(equipment)}} {{equipment.width}}"
                                [ngStyle]="{'background': equipment.backgroundColor}" style="border:solid 1px">
                                <rect *ngFor="let item of [].constructor(equipment.numberOfShelves); let i = index"
                                    [attr.x]="equipment.shelfHeight*(i+1)+equipment.shelfSeparatorHeight*i"
                                    [attr.height]="equipment.width"
                                    [attr.width]="equipment.shelfSeparatorHeight?equipment.shelfSeparatorHeight: '0.5'" />
                            </svg>
                            <!-- HORIZONTAL -->
                            <ng-template #horizontal>
                                <svg attr.viewBox="0 0 {{equipment.width}} {{getEquipmentHeight(equipment)}}"
                                    [ngStyle]="{'background': equipment.backgroundColor}" style="border:solid 1px">
                                    <rect *ngFor="let item of [].constructor(equipment.numberOfShelves); let i = index"
                                        [attr.y]="equipment.shelfHeight*(i+1)+equipment.shelfSeparatorHeight*i"
                                        [attr.width]="equipment.width"
                                        [attr.height]="equipment.shelfSeparatorHeight?equipment.shelfSeparatorHeight: '0.5'" />
                                </svg>
                            </ng-template>
                        </label>
                        <!-- NEW ONE -->
                        <label [for]="'id'+i" *ngIf="equipment.shelves">
                            <div *ngIf="equipment.shelves && equipment.shelves.length > 0">
                                <!-- VERTICAL -->
                                <div class="preview-vertical" *ngIf="equipment.isVertical">
                                    <span style="color: white; height: 1px;">.</span><br>
                                    <div *ngFor="let item of [].constructor(equipment.shelves.length); let i = index">
                                        <svg
                                          attr.viewBox="0 0 {{equipment.shelves[i].shelfHeight + equipment.shelves[i].shelfSeparatorHeight}} {{equipment.width}}"
                                          [ngStyle]="{'background': equipment.backgroundColor}" style="border:solid 1px; max-height: 300px; width: 100%; height: 100%;">
                                          <rect
                                            [attr.x]="equipment.shelves[i].shelfHeight" [attr.height]="equipment.width"
                                            [attr.width]="equipment.shelves[i].shelfSeparatorHeight?equipment.shelves[i].shelfSeparatorHeight: '0.5'" />
                                        </svg>
                                    </div>
                                </div>
                                <!-- HORIZONTAL -->
                                <div class="preview-horizontal" *ngIf="!equipment.isVertical">
                                    <span style="color: white; height: 1px;">NEW ONE HORIZONTAL</span>
                                    <svg *ngFor="let item of [].constructor(equipment.shelves.length); let i = index"
                                      attr.viewBox="0 0 {{equipment.width}} {{equipment.shelves[i].shelfHeight + equipment.shelves[i].shelfSeparatorHeight}}"
                                      [ngStyle]="{'background': equipment.backgroundColor}" style="border:solid 1px">
                                      <rect 
                                        [attr.y]="equipment.shelves[i].shelfHeight" [attr.width]="equipment.width"
                                        [attr.height]="equipment.shelves[i].shelfSeparatorHeight?equipment.shelves[i].shelfSeparatorHeight: '0.5'" />
                                    </svg>
                                </div>
                            </div>
                        </label>
                        
                        <mat-card-content>
                            <div class="crop-long-text" matTooltip="{{equipment.name}}"
                                [matTooltipDisabled]="isMatTooltipDisabled(toolTip2)" #toolTip2>
                                {{equipment.name}}
                            </div>
                            <br />
                            <div style="color:#919191;" class="crop-long-text" matTooltip="{{equipment.id}}"
                                [matTooltipDisabled]="isMatTooltipDisabled(toolTip2)" #toolTip2>
                                {{equipment.id}}
                            </div>
                            <div class="divider flex-space-between">
                                <span>{{'Direction'|translate}}:</span>
                                <span class="value">
                                    {{equipment.isVertical?'Vertical':'Horizontal' |translate}}</span>
                            </div>
                            <div class="flex-space-between">
                                <span>{{'Height'|translate}}({{'CentimeterAb'|translate}}):</span>
                                <span class="value">
                                    {{getEquipmentHeight(equipment)}}</span>
                            </div>
                            <div class="flex-space-between">
                                <span>{{'Width'|translate}}({{'CentimeterAb'|translate}}):</span>
                                <span class="value">
                                    {{equipment.width}}</span>
                            </div>
                            <div class="flex-space-between">
                                <span>{{'ShelfHeight'|translate}}({{'CentimeterAb'|translate}}):</span>
                                <span class="value">
                                    {{equipment.shelfHeight}}</span>
                            </div>
                            <div class="flex-space-between">
                                <span>{{'Depth'|translate}}({{'CentimeterAb'|translate}}):</span>
                                <span class="value">
                                    {{equipment.depth}}</span>
                            </div>
                            <div class="flex-space-between">
                                <span>{{'numberOfShelves'|translate}}:</span>
                                <span class="value">
                                    {{equipment.numberOfShelves}}</span>
                            </div>
                            <div class="flex-space-between">
                                <span>{{'SeparatorHeight'|translate}}({{'CentimeterAb'|translate}}):</span>
                                <span class="value">
                                    {{equipment.shelfSeparatorHeight}}</span>
                            </div>
                            <div class="flex-space-between" style="margin-bottom: 16px;">
                                <span>{{'BackgroundColor'|translate}}:</span>
                                <span class="value" [ngStyle]="{'background': equipment.backgroundColor}">
                                    {{equipment.backgroundColor}}
                                </span>
                            </div>
                            <div *ngIf="!equipment.shelves"matTooltip="{{'ThisEquipmentIsInTheOldVersionSoItCannotBeEdited'|translate}}">
                                <button mat-button mat-flat-button color="primary" style="width: 100%;"
                                [disabled]="true">
                                    {{'EditEquipment'|translate}}
                                </button>
                            </div>
                            <div *ngIf="equipment.isUsed && equipment.shelves"matTooltip="{{'EquipmentHasBeenUsed'|translate}}">
                                <button mat-button mat-flat-button color="primary" style="width: 100%;"
                                [disabled]="equipment.isUsed" (click)="editEquipment(equipment)">
                                    {{'EditEquipment'|translate}}
                                </button>
                            </div>
                            <div *ngIf="!equipment.isUsed && equipment.shelves">
                                <button mat-button mat-flat-button color="primary" style="width: 100%;"
                                [disabled]="equipment.isUsed" (click)="editEquipment(equipment)">
                                    {{'EditEquipment'|translate}}
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </li>
            </ul>
            <br />
        </div>
    </div>
</div>