import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'accuracy-bar',
  imports: [
    CommonModule,
    TranslateModule
  ],
  standalone: true,
  template: `
    <div class="main" >
      <div class="bar-title" [ngStyle]="{color: selectedColor}" >{{'Accuracy' | translate | uppercase }}</div>
      <div class="bar" >
        <div class="bar-inner" [ngStyle]="{width: accuracy + '%', background: selectedColor}"></div>
      </div>
      <div class="percent" [ngStyle]="{color: selectedColor}"> {{accuracy | number: '1.0-2'}}%</div>
    </div>
  `,
  styles: [`
    .main {
      /* width: 100%; */
      border: 1px solid #e6e6e6;
      margin: 0 30px;
      border-radius: 7px;
      padding: 5px;
      margin-bottom: 10px;
      letter-spacing: 1.5px;
      font-size: 15px;
    }
    .bar-title {
      display: inline-block;
      width: 100px;
      padding-left: 10px;
      flex: 1;
    }
    .percent {
      display: inline-block;
      width: 70px;
      text-align: right;
      flex: 1;
      font-size: 15px;
      padding-right: 10px;
    }
    .bar {
      display: inline-block;
      width: calc(100% - 190px);
      flex: 10;
      height: 6px;
      margin: 0px 10px;
      background: lightgray;
      border-radius: 5px;
      margin-bottom: 2px;
    }
    .bar-inner {
      height: 100%;
    }
  `]
})

export class AccuracyBar {
  _accuracy = 0;
  @Input() set accuracy(value: number) {
    this._accuracy = value;
    if (value < 60) {
      this.selectedColor = this.colors.red;
    } else if (value < 90) {
      this.selectedColor = this.colors.yellow;
    } else {
      this.selectedColor = this.colors.green;
    }
  }
  get accuracy() {
    return this._accuracy;
  }

  colors = {
    red: '#ba1f1f',
    yellow: '#dabe00',
    green: '#00ac00'
  }
  selectedColor = this.colors.red;
}
