// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { BaseStructure, ByPageRequest } from '../common'

export class Organization extends BaseStructure {
    @Expose() @IsNumber() companies!: number
}
export class ListOrganizationsRequest extends ByPageRequest {
    @Expose() @IsString() @IsOptional() query?: string
}

export class ListOrganizationsResponse {
    @Expose() @IsNumber() totalItems!: number
    @Expose() @Type(() => Organization) @ValidateNested() @IsArray() items!: Organization[]
}

export class CreateOrganizationRequest {
    @Expose() @IsString() id!: string
    @Expose() @IsString() name!: string
    @Expose() @Type(() => OrganizationGeneralSettings) @IsOptional() @ValidateNested() general?: OrganizationGeneralSettings
    @Expose() @Type(() => OrganizationThemeSettings) @IsOptional() @ValidateNested() theme?: OrganizationThemeSettings
    @Expose() @Type(() => OrganizationTrainingSettings) @IsOptional() @ValidateNested() training?: OrganizationTrainingSettings
}

export class CreateOrganizationResponse {
    @Expose() @IsString() id!: string
    @Expose() @IsString() name!: string
    @Expose() @Type(() => OrganizationGeneralSettings) @IsOptional() @ValidateNested() general?: OrganizationGeneralSettings
    @Expose() @Type(() => OrganizationThemeSettings) @IsOptional() @ValidateNested() theme?: OrganizationThemeSettings
    @Expose() @Type(() => OrganizationTrainingSettings) @IsOptional() @ValidateNested() training?: OrganizationTrainingSettings
}

export class UpdateOrganizationRequestAndResponse extends CreateOrganizationResponse { }

export class UpdateOrganizationSettingsRequest {
    @Expose() @IsString() organizationId!: string
    @Expose() @Type(() => OrganizationGeneralSettings) @IsOptional() @ValidateNested() general?: OrganizationGeneralSettings
    @Expose() @Type(() => OrganizationThemeSettings) @IsOptional() @ValidateNested() theme?: OrganizationThemeSettings
    @Expose() @Type(() => OrganizationTrainingSettings) @IsOptional() @ValidateNested() training?: OrganizationTrainingSettings
}

export class OrganizationGeneralSettings {
    @Expose() @IsString() @IsOptional() language?: string
}

export class OrganizationThemeSettings {
    @Expose() @IsString() @IsOptional() logoUrl?: string
    @Expose() @IsString() @IsOptional() bannerUrl?: string
    @Expose() @IsString() @IsOptional() primaryColor?: string
    @Expose() @IsString() @IsOptional() secondaryColor?: string
}

export class OrganizationTrainingSettings {
    @Expose() @IsNumber() @IsOptional() samplesPerCall?: number
    @Expose() @IsNumber() @IsOptional() samplesPerProducts?: number
    @Expose() @IsNumber() @IsOptional() trainBudget?: number
}

export class GetOrganizationSettingsResponse {
    @Expose() @IsString() organizationId!: string
    @Expose() @IsString() name!: string
    @Expose() @Type(() => OrganizationGeneralSettings) @IsOptional() @ValidateNested() general?: OrganizationGeneralSettings
    @Expose() @Type(() => OrganizationThemeSettings) @IsOptional() @ValidateNested() theme?: OrganizationThemeSettings
    @Expose() @Type(() => OrganizationTrainingSettings) @IsOptional() @ValidateNested() training?: OrganizationTrainingSettings
}