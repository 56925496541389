<div class="main">
    <div class="table-content-container">
        <!-- <div class="table-title-container">
            <div class="table-title">
                {{data.name}}{{' - '}}{{'AssociatedCustomers'|translate}}
            </div>
        </div> -->

        <div class="tabs">
            <mat-tab-group animationDuration="0ms" mat-stretch-tabs>
                <mat-tab label="{{'Clusters'|translate|uppercase}}">
                    <association-logic #clustersGen [fetch]="getAssociatedClusters"
                        [fetchAddOptions]="getClustersOptions">
                        <associated-clusters (onToggleSelect)="clustersGen.toggleSelect($event)"
                            (onSearch)="clustersGen.getResults($event)" [selected]="clustersGen.selected"
                            (onToggleSelectAll)="clustersGen.toggleSelectAll()" [data]="clustersGen.dataSource">
                        </associated-clusters>
                    </association-logic>

                </mat-tab>
                <mat-tab label="{{'CUSTOMERS'|translate}}">
                    <association-logic #customersGen [fetch]="getAssociatedCustomers"
                        [fetchAddOptions]="getCustomersOptions">
                        <associated-customers (onToggleSelect)="customersGen.toggleSelect($event)"
                            (onSearch)="customersGen.getResults($event)" [selected]="customersGen.selected"
                            (onToggleSelectAll)="customersGen.toggleSelectAll()"
                            [data]="customersGen.dataSource"></associated-customers>
                    </association-logic>
                </mat-tab>

                <mat-tab label="{{'USERS'|translate}}">
                    <association-logic #usersGen [fetch]="getAssociatedUsers" [fetchAddOptions]="getUsersOptions">
                        <associated-users (onToggleSelect)="usersGen.toggleSelect($event)"
                            (onSearch)="usersGen.getResults($event)" [selected]="usersGen.selected"
                            (onToggleSelectAll)="usersGen.toggleSelectAll()"
                            [data]="usersGen.dataSource"></associated-users>
                    </association-logic>
                </mat-tab>

            </mat-tab-group>
            <div class="footer">
                <div class="footer-sum">
                    <mat-icon>error</mat-icon>
                    <span>{{ 'CurrntlySelected' | translate }}: </span>
                    <span class="bold-text">{{usersGen.selectedCount || 0}} {{'Users' | translate}}, </span>
                    <span class="bold-text">{{customersGen.selectedCount || 0}} {{'Customers' | translate}} </span>
                    <span>{{'And' | translate}} </span>
                    <span class="bold-text">{{clustersGen.selectedCount || 0}} {{'Clusters' | translate}}</span>
                </div>
                <div class="float-right">
                    <button mat-stroked-button color="primary" (click)="onCancel()">{{'Close' | translate}}</button>
                    <button mat-flat-button color="primary" (click)="onConfirm()">{{'CONFIRM' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>