import { Component, EventEmitter, Output } from "@angular/core"
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators, FormGroup } from "@angular/forms";
import { Dal } from "src/app/dal/dal";
import { DataService } from "src/app/services/data.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { EquipmentWithId } from "src/app/shared/api-structures/misc/equipment";
import { uniqueValidator } from "src/app/shared/custom-validations";
import { LanguageService } from "src/app/shared/services/language.service";
import _ from "underscore";

@Component({
  selector: 'app-equipment-create-planogram',
  templateUrl: './equipment-create-planogram.component.html'
})

export class EquipmentCreatePlanogramComponent {
    @Output() currentEquipment = new EventEmitter<EquipmentWithId>();
    equipmentForm: UntypedFormGroup;
    equipment: EquipmentWithId;
    constructor(private formBuilder: UntypedFormBuilder,
                private utilitiesService: UtilitiesService,
                private dal: Dal,
                private dataService: DataService,
                private languageService: LanguageService){
        
        this.equipmentForm = this.formBuilder.group({
            nameCtrl: new UntypedFormControl('', [Validators.required, uniqueValidator('name', 'id', true, null, this.dataService.equipments)]),
            widthCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
            isVerticalCtrl: new UntypedFormControl(''),
            shelves: this.formBuilder.array([this.createShelf()])
            })
            this.equipment = {
            id: null,
            name: null,
            isVertical: false,
            width: 1,
            backgroundColor: '#FFFFFF',
            status: 'active',
            shelves: [{
                shelfHeight: 1,
                depth: 1,
                shelfSeparatorHeight: 1
              }]
        }
    }

    createShelf(): FormGroup {
        return this.formBuilder.group({
          heightCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
          depthCtrl: new UntypedFormControl('', Validators.min(1)),
          numberOfShelvesCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
          shelfSeparatorHeightCtrl: new UntypedFormControl('', Validators.min(1)),
          backgroundColorCtrl: new UntypedFormControl('')
        })
      }

    async onSubmit(formDirective: FormGroupDirective) {
        if (!this.equipmentForm.valid) {
          this.utilitiesService.validateAllFormFields(this.equipmentForm);
          return;
        }
        if (_.isUndefined(this.equipment)) {
          return;
        }
    
        const equipmentRes = await this.dal.addEquipment(this.equipment)
        this.dataService.equipments.push(this.utilitiesService.cloneObject(equipmentRes))
        const currentEquip = this.utilitiesService.cloneObject(equipmentRes);
        this.currentEquipment.emit(currentEquip)
        formDirective.resetForm()
        this.equipmentForm.reset();
    }
    
    getShelfSizePlaceHolder() {
        return (this.equipment.isVertical) ? this.languageService.translateSync('ShelfWidth') : this.languageService.translateSync('ShelfHeight')
    }

    getEquipmentSizePlaceHolder() {
        return (this.equipment.isVertical) ? this.languageService.translateSync('Height') :this.languageService.translateSync('Width');
    }

    getSeparatorSizePlaceHolder() {
        return (this.equipment.isVertical) ? this.languageService.translateSync('WidthSeparatorBetweenShelves') : this.languageService.translateSync('HeightSeparatorBetweenShelves');
    }
}