<div class="main">
  <div class="inner shadow">
    <div class="flex flex_gap_15">
      <div class="flex-2">
        <div class="light_card form_card">
          <h2>{{'ClusterInfo'|translate}}</h2>
          <form [formGroup]="clusterForm">
            <mat-form-field>
              <input matInput placeholder="{{'ClusterName' | translate}}" formControlName="name" required>
            </mat-form-field>

            <foldable-card [isOpen]="true" [title]="'BasicRules' | translate">

              <div class="flex flex_gap_15">
                <div class="flex-1">
                  <mat-form-field>
                    <mat-label>{{'CustomerChains' | translate}}</mat-label>
                    <mat-select formControlName="customerChains" multiple>
                      <mat-option *ngFor="let v of chains" [value]="v">{{v}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="flex-1">
                  <mat-form-field>
                    <mat-label>{{'CustomerCategories' | translate}}</mat-label>
                    <mat-select formControlName="customerCategories" multiple>
                      <mat-option *ngFor="let v of categories" [value]="v">{{v}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex_gap_15">
                <div class="flex-1">
                  <mat-form-field>
                    <mat-label>{{'Regions' | translate}}</mat-label>
                    <mat-select formControlName="regions" multiple>
                      <mat-option *ngFor="let area of areas" [value]="area.id">{{area.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="flex-1">
                  <mat-form-field>
                    <mat-label>{{'Territories' | translate}}</mat-label>
                    <mat-select formControlName="customerTerritories" multiple>
                      <mat-option *ngFor="let v of territories" [value]="v">{{v}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              
              </div>
            </foldable-card>

          </form>
          <div style="height: 15px"></div>
          <!-- advanced rules -->
          <foldable-card [isOpen]="true" [title]="'AdvancedRules' | translate">
            <add-advanced-rule-form [customFieldSettings]="customerCustomFields"
              *ngFor="let control of clusterForm.get('customFields')['controls']; let i = index" [index]="i"
              [form]="control" (ruleChangedEvent)="advancedRuleChanged($event)"
              (removeRuleEvent)="removeAdvancedRule($event)"></add-advanced-rule-form>
            <div class="center-content">
              <button mat-flat-button color="primary" class="full_width ire_btn_01" (click)="addAdvancedRule()">
                <mat-icon>add</mat-icon>
                {{'AddAdvancedRules'|translate}}
              </button>
            </div>
          </foldable-card>
        </div>
      </div>
      <div class="flex-1">
        <div class="light_card height-100">
          <h2>{{'ClusterPreview'|translate}}</h2>
          <div class="gray_header">{{'RegionPreview'|translate}}</div>
          <div class="map_wrap">
            <app-map #map [polygons]="polygons"></app-map>
          </div>
          <div class="flex">
            <div class="flex-1">
              <div class="gray_header">{{'GeneralInformation'|translate}}</div>
              <div class="total_customers">{{'TotalCustomers'|translate}}<span class="total"><content-loading
                    [loading]="customerCountLoad">{{customersCount}}</content-loading></span>
              </div>
            </div>
            <div class="flex-1">
              <button mat-flat-button color="primary" class="full_width ire_btn_01 pull-right"
                (click)="showCustomersList()">
                <mat-icon>groups</mat-icon>
                {{'CustomersList'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<add-edit-footer (back)="back()" (save)="save()"></add-edit-footer>