<p-dialog [visible]="isShowingUplaodStatus" [closable]="false" position="bottom-right" [style]="{width: '35vw' ,resize: 'both'}">
    <p-header position="right">
        <ng-container>
            {{ 'UploadingStatus' | translate }}
            <span *ngIf="countUplaoded!=countToUplaod">{{countUplaoded}}/{{countToUplaod}}</span>
                <span *ngIf="countUplaoded == countToUplaod">
                    <mat-icon>done</mat-icon>{{ 'Success' | translate}}
                </span>
                <button mat-flat-button type="button" (click)="isShowProgresses=!isShowProgresses "
                    *ngIf="isShowProgresses ">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button mat-flat-button type="button" (click)="isShowProgresses=!isShowProgresses " *ngIf="!isShowProgresses ">
                    <mat-icon>expand_less</mat-icon>
                </button>
                <button mat-flat-button type="button" (click)="isShowingUplaodStatus=false" *ngIf="isDialogCloseAble ">
                    <mat-icon>close</mat-icon>
                </button>
        </ng-container>
    </p-header>
    <div *ngIf="isShowProgresses ">
        <mat-list>
            <ng-container *ngFor="let p of progressDict | keyvalue">
                <mat-list-item *ngIf="p.value.countUplaoded != p.value.countImages">
                    {{ 'ProductId' | translate }} - {{p.key}}:
                </mat-list-item>
                <mat-list>
                    <ng-container *ngFor="let catalog of p.value.catalogs | keyvalue">
                        <mat-list-item *ngIf="catalog.value.countUplaoded != catalog.value.countImages">
                            {{ 'Catalog' | translate }} - {{catalog.key}}:
                        </mat-list-item>
                        <mat-list>
                            <ng-container *ngFor="let image of catalog.value.images | keyvalue">
                                <mat-list-item [ngClass]="image.value.isExist? 'green': null" *ngIf="!image.value.isUplaoded">
                                    <mat-icon color="primary" *ngIf=" image.value.progress==100 ">cloud_done</mat-icon>
                                    <span style="margin:10px;">
                                        {{image.key}}
                                    </span>
                                    <mat-progress-bar mode="determinate " [value]="image.value.progress ">
                                    </mat-progress-bar>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </ng-container>
                </mat-list>
            </ng-container>
        </mat-list>
    </div>
</p-dialog>