import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { fromEvent } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { MobileReportMarker } from 'src/app/shared/interfaces';

@Component({
  selector: 'marker',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  template: `
    <div 
    [id]="marker.id" class="marker" 
    [style]="style"
    #tooltip="matTooltip"
    [matTooltip]="selected ? null : marker.tooltipTxt"
    matTooltipPosition="above"
    [matTooltipClass]="'tooltipCustom'"
    [ngClass]="{'tag_selected':tagSelected, 'selected':selected,'rotated':imageRotation === 90 || imageRotation === 270}"
    [ngStyle]="{
      'background': marker.color || 'rgba(100, 100, 100, 0.5)',
      'border': 'solid 2px',
      'border-color': marker.borderColor || 'rgb(180,180,180)'
    }"
    #mark
    >
    <div class="edge top cursor-row-resize" ></div>
    <div class="edge bottom cursor-row-resize"></div>
    <div class="edge right cursor-col-resize"></div>
    <div class="edge left cursor-col-resize"></div>
    
  </div>
  `,
  styles: [`
  .marker {
    cursor: pointer;
    position: absolute;
  }
  ::ng-deep .tooltipCustom {
    font-size:14px;
    background-color: rgba(0,0,0,0.7);
    /* white-space: nowrap; */
  } 
  .marker.tag_selected {
    border: solid 3px #ffcd14 !important;
  }
  .marker.selected, {
    border: solid 3px cyan !important;
    cursor: move;
    z-index: 1;
  }
  .selected .top {
    position: absolute;
    height: 10px;
    width: 100%;
    top: -6px;
  }
  .selected .bottom {
    position: absolute;
    height: 10px;
    width: 100%;
    bottom: -6px;
  }
  .selected .left { 
    position: absolute;
    height: 100%;
    width: 10px;
    left: -6px;
  }
  .selected .right {
    position: absolute;
    height: 100%;
    width: 10px;
    right: -6px;
  }
  .cursor-col-resize {
    cursor: col-resize;
  }
  .cursor-row-resize {
    cursor: row-resize;
  }
  .rotated {
    .cursor-col-resize {
    cursor: row-resize;
  }
  .cursor-row-resize {
    cursor: col-resize;
  }
  }
  `]
})

export class MarkerComponent extends AutoDestroy implements OnInit {
  @ViewChild('tooltip') tooltip: MatTooltip
  @ViewChild('mark') mark: ElementRef;
  @Input() marker: MobileReportMarker | null = null
  @Input() imageHeight = 0
  @Input() imageWidth = 0
  @Input() tagSelected = false
  @Input() selected = false
  @Input() imageRotation = 90
  @Output() markChange = new EventEmitter<MobileReportMarker>()

  style: Record<string, string> = {}
  key: string = ''

  onMarkChange(marker: MobileReportMarker) {
    this.markChange.emit(marker)
  }

  constructor(private utils: UtilitiesService) {
    super()
  }

  ngOnInit(): void {
    this.calcPosition(this.marker)
  }

  ngOnChanges() {
    this.calcPosition(this.marker)
  }

  ngAfterViewInit() {
    this.registerForDragMove()
  }

  showTooltip() {
    this.tooltip.show()
  }

  calcPosition(marker: MobileReportMarker) {
    const top = marker.position.top
    const left = marker.position.left
    const bottom = marker.position.bottom
    const right = marker.position.right

    const topP = this.utils.percentOf(top, this.imageHeight)
    const leftP = this.utils.percentOf(left, this.imageWidth)
    const heightP = this.utils.percentOf(bottom - top, this.imageHeight)
    const widthP = this.utils.percentOf(right - left, this.imageWidth)

    this.style.top = `${topP}%`
    this.style.left = `${leftP}%`
    this.style.height = `${heightP}%`
    this.style.width = `${widthP}%`
    this.style = { ...this.style }
  }

  registerForDragMove() {
    const parent = this.mark.nativeElement.parentElement.parentElement.parentElement
    const mouseDownStream$ = fromEvent(this.mark.nativeElement, 'mousedown');
    const mouseMoveStream$ = fromEvent(parent, 'mousemove');
    const mouseUpStream$ = fromEvent(window, 'mouseup');
    let lastMouseX = 0
    let lastMouseY = 0
    let dragMode = ''
    let mouseDownEvent = 0
    let edited = false
    let markerCopy = this.utils.deepCopy(this.marker)

    this.subscriptions.push(mouseDownStream$.pipe(
      tap((event: MouseEvent) => {
        markerCopy = this.utils.deepCopy(this.marker)
        edited = false
        mouseDownEvent = event.button
        lastMouseX = event.pageX
        lastMouseY = event.pageY
        const target = event.target as HTMLElement
        if (target.classList.contains('top')) {
          dragMode = 'top'
        } else if (target.classList.contains('bottom')) {
          dragMode = 'bottom'
        } else if (target.classList.contains('left')) {
          dragMode = 'left'
        } else if (target.classList.contains('right')) {
          dragMode = 'right'
        } else {
          dragMode = 'move'
        }
      }),
      filter(() => mouseDownEvent === 0 && this.selected),
      switchMap(() => mouseMoveStream$.pipe(
        tap((event: MouseEvent) => {
          const mousex = event.pageX
          const mousey = event.pageY

          let movementYP = (mousey - lastMouseY) / (parent.offsetHeight)
          let movementXP = (mousex - lastMouseX) / (parent.offsetWidth)
          if (this.imageRotation === 90) {
            movementYP = (lastMouseX - mousex) / (parent.offsetHeight)
            movementXP = (mousey - lastMouseY) / (parent.offsetWidth)
          } else if (this.imageRotation === 180) {
            movementYP = (lastMouseY - mousey) / (parent.offsetHeight)
            movementXP = (lastMouseX - mousex) / (parent.offsetWidth)
          } else if (this.imageRotation === 270) {
            movementYP = (mousex - lastMouseX) / (parent.offsetHeight)
            movementXP = (lastMouseY - mousey) / (parent.offsetWidth)
          }
          // const angleInRadians = this.imageRotation * Math.PI / 180;
          // const cosAngle = Math.cos(angleInRadians);
          // const sinAngle = Math.sin(angleInRadians);

          // let movementYP = (mousey - lastMouseY) / parent.offsetHeight;
          // let movementXP = (mousex - lastMouseX) / parent.offsetWidth;

          // if (this.imageRotation !== 0) {
          //   const rotatedMovementX = movementXP * cosAngle - movementYP * sinAngle;
          //   const rotatedMovementY = movementXP * sinAngle + movementYP * cosAngle;
          //   movementXP = rotatedMovementX;
          //   movementYP = rotatedMovementY;
          // }

          switch (dragMode) {
            case 'top':
              markerCopy.position.top = markerCopy.position.top + movementYP * this.imageHeight
              break
            case 'bottom':
              markerCopy.position.bottom = markerCopy.position.bottom + movementYP * this.imageHeight
              break
            case 'left':
              markerCopy.position.left = markerCopy.position.left + movementXP * this.imageWidth
              break
            case 'right':
              markerCopy.position.right = markerCopy.position.right + movementXP * this.imageWidth
              break
            case 'move':
              markerCopy.position.top = markerCopy.position.top + movementYP * this.imageHeight
              markerCopy.position.bottom = markerCopy.position.bottom + movementYP * this.imageHeight
              markerCopy.position.left = markerCopy.position.left + movementXP * this.imageWidth
              markerCopy.position.right = markerCopy.position.right + movementXP * this.imageWidth
          }
          this.calcPosition(markerCopy)
          edited = true

          lastMouseX = mousex
          lastMouseY = mousey

        }),
        takeUntil(mouseUpStream$),
        finalize(() => {
          if (edited) {
            this.onMarkChange(markerCopy)
          }
        })
      ))
    ).subscribe())
  }
}
