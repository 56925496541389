import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-custom-field-settings',
  templateUrl: './add-edit-custom-field-settings.component.html',
  styleUrls: ['./add-edit-custom-field-settings.component.scss']
})
export class AddEditCustomFieldSettingsComponent implements OnInit {

  form = new FormGroup({
    name: new FormControl(''),
    entityType: new FormControl(''),
    fieldType: new FormControl(''),
    defaultValue: new FormControl('', {
      validators: [this.validateDefaultValue()]
    }),
    options: new FormControl([]),
    isRequired: new FormControl(false),
  })
  entityTypes = this.data.entityTypes
  fieldTypes = this.data.fieldTypes
  headerText = ''
  mode: 'add' | 'edit' = 'add'

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    if (this.data.item) {
      this.headerText = 'EditCustomFieldSettings'
      this.mode = 'edit'
      this.form.patchValue(this.data.item)
      this.form.get('name').setValue(this.data.item.name)
      this.form.get('entityType').setValue(this.data.item.entityType)
      this.form.get('fieldType').setValue(this.data.item.fieldType)
      this.form.get('defaultValue').setValue(this.data.item.defaultValue)
      this.form.get('isRequired').setValue(this.data.item.isRequired)
      this.form.get('options').setValue(this.data.item.options)
    } else {
      this.headerText = 'CreateCustomFieldSettings'
    }

    this.form.get('fieldType').valueChanges.subscribe((value: string) => {
      this.form.get('defaultValue').setValue('')
    })
  }

  onCancel() {
    this.dialogRef.close()
  }

  validateDefaultValue() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null
      const fieldType = this.form.get('fieldType').value
      if (fieldType === 'number' && isNaN(Number(control.value))) {
        return { invalidNumber: true }
      } else if (fieldType === 'string' && typeof control.value !== 'string') {
        return { invalidString: true }
      } else if (fieldType === 'stringArray') {
        try {
          const parsed = JSON.parse(control.value)
          if (!Array.isArray(parsed) || !parsed.every(v => typeof v === 'string')) {
            return { invalidStringArray: true }
          }
        } catch (e) {
          return { invalidStringArray: true }
        }
      } else if (fieldType === 'numberArray') {
        try {
          const parsed = JSON.parse(control.value)
          if (!Array.isArray(parsed) || !parsed.every(v => typeof v === 'number')) {
            return { invalidNumberArray: true }
          }
        } catch (e) {
          return { invalidNumberArray: true }
        }

        return { invalidNumberArray: true }
      } else if (fieldType === 'date' && isNaN(Date.parse(control.value))) {
        return { invalidDate: true }
      }
      return null
    }
  }

  async onSave() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      if (this.form.get('fieldType').value !== 'select') {
        this.form.get('options').setValue([])
      }
      const req = this.form.value
      this.dialogRef.close(req)
    }
  }
}
