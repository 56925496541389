<!-- create-update-organization.component.html -->
<div style="padding: 24px 16px 16px 16px;">
    <div class="header-container">
        <div class="title">
            {{ createUpdateMode === componentModes.CREATE ? 'New Organization' : 'Edit Organization' }}
        </div>
        <div class="subtitle">Please enter the name and parameters of your new organization.</div>
    </div>
    <div class="scrollable-content" style="padding: 0px 16px 16px 16px; border: 1px solid #E3E4EB; margin-bottom: 40px;">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" animationDuration="0ms"
            style="justify-content: space-between;">
            <mat-tab label="General">
                <mat-form-field style="margin-top: 12px;">
                    <mat-label>{{'Organization ID'|translate}}</mat-label>
                    <input matInput [(ngModel)]="organizationId" required [disabled]="isEdition" >
                </mat-form-field>
                <div class="warning-box">
                    <mat-icon class="warning-icon" color="warn">warning</mat-icon>
                    <div>
                        <div class="warning-text" style="color: #A41616;">Warning</div>
                        <div class="warning-description">Please be aware that the Organization ID cannot be changed once
                            it has been created</div>
                    </div>
                </div>
                <mat-form-field>
                    <mat-label>{{'Organization Name'|translate}}</mat-label>
                    <input matInput [(ngModel)]="organizationName" required>
                </mat-form-field>                
                <mat-form-field>
                    <mat-select placeholder="{{'Language'|translate}}" [(ngModel)]="language">
                        <mat-option *ngFor='let language of languages' [value]="language.id">{{language.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-tab>
            <mat-tab label="Theme">
                <div class="img-container">
                    <div>
                        <br><label class="margin-top">{{'Logo'|translate}}</label><br>
                        <div>
                            <mat-card class="img-card mat-elevation-z0" *ngIf="_logoUrl">
                                <img class="img-logo full-width" mat-card-image src="{{_logoUrl}}" alt="LOGO" />
                            </mat-card>
                            <drag-and-drop-files isMultipleFiles="false" accept="image/*" (onFileSelected)="saveLogoFileToStorageLogo($event)"
                                *ngIf="!_logoUrl && !isLoadingSettings"></drag-and-drop-files>
                            <div *ngIf="uploadProgress">
                                {{uploadProgress.name}}
                                <!--<mat-progress-bar mode="determinate" value="{{uploadProgress.progress}}"></mat-progress-bar>-->
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <br><label class="margin-top">{{'Banner'|translate}}</label><br>
                        <div>
                            <mat-card class="img-card mat-elevation-z0" *ngIf="_bannerUrl">
                                <img class="img-logo full-width" mat-card-image src="{{_bannerUrl}}" alt="LOGO" />
                            </mat-card>
                            <drag-and-drop-files isMultipleFiles="false" accept="image/*" (onFileSelected)="saveLogoFileToStorageBanner($event)"
                                *ngIf="!_bannerUrl && !isLoadingSettings"></drag-and-drop-files>
                            <div *ngIf="uploadProgress">
                                {{uploadProgress.name}}
                                <!--<mat-progress-bar mode="determinate" value="{{uploadProgress.progress}}"></mat-progress-bar>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style="display: flex; flex-direction: column; justify-content: space-between; padding: 16px; gap: 24px;">
                    <div style="display: flex; flex-direction: row; gap: 32px;">
                        Primary Color <input type="color" [(ngModel)]="primaryColor"/>
                    </div>
                    <div style="display: flex; flex-direction: row; gap: 32px;">
                        Secondary Color <input type="color" [(ngModel)]="secondaryColor"/>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Training">
                <div
                    style="display: flex; flex-direction: row; justify-content: space-between; padding: 16px; gap: 16px;">
                    <mat-form-field style="margin-top: 12px;">
                        <mat-label>{{'SamplesPerCall'|translate}}</mat-label>
                        <input [placeholder]="'Min 1 - Max 1000'|translate" matInput [(ngModel)]="samplesPerCall"
                            required>
                    </mat-form-field>
                    <mat-form-field style="margin-top: 12px;">
                        <mat-label>{{'SamplesPerProducts'|translate}}</mat-label>
                        <input [placeholder]="'Min 1 - Max 1000'|translate" matInput [(ngModel)]="samplesPerProduct"
                            required>
                    </mat-form-field>
                    <mat-form-field style="margin-top: 12px;">
                        <mat-label>{{'TrainingBudget'|translate}}</mat-label>
                        <input [placeholder]="'Min 1 - Max 1000'|translate" matInput [(ngModel)]="trainingBudget"
                            required>
                    </mat-form-field>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div class="footer-box">
    <div class="button-container">
        <button class="cancel" mat-button mat-dialog-close matTooltip="{{'CancelLower'|translate}}">
            {{'CancelLower'|translate}}
        </button>
        <button class="confirm" mat-button (click)="save()" matTooltip="{{'SaveChanges'|translate}}" [disabled]="!isFormValid()">
            {{'Confirm'|translate}}
        </button>
    </div>
</div>