import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthGuard } from '../authGuard';
import { RoleGuard } from '../role.guard';

@Injectable({
  providedIn: 'root'
})
export class RunInOrderGuard implements CanActivate {
  constructor(private gA: AuthGuard, private gB: RoleGuard) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,): Observable<boolean> {
    return this.gA.canActivate(route, state).pipe(
      switchMap((res) => {
        if (res === true) {
          return this.gB.canActivate(route, state)
        }
        return from([false])
      })
    )
  }

}
