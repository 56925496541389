import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'splitUpper' })
export class SplitUpperPipe implements PipeTransform {

  transform(str: string, ...args: unknown[]) {
    const result = str.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g)!.join(' ')
    return result
  }
}
