<div class="terr-form">
  <h4>
    <span *ngIf="mode === 'add'">{{'AddPriceTarget' | translate}}</span>
    <span *ngIf="mode === 'edit'">{{'EditPriceTarget' | translate}}</span>
  </h4>
  <form [formGroup]="priceTargetForm">

    <div class="ptButtonWrapper">
      <button mat-stroked-button type="button" color="primary" (click)="openPriceTargetDialog()">
        {{'ChooseProduct' | translate}}
      </button>
      {{priceTargetForm.value.productName}} {{priceTargetForm.value.productId}}
      <span class="error" *ngIf="priceTargetForm.controls.productId.touched && !priceTargetForm.value.productId">*
        {{'MustChooseProduct' |
        translate}}</span>
    </div>

    <mat-form-field>
      <input matInput type="number" placeholder="{{'Price' | translate}}" formControlName="price" required>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input type="text" [placeholder]="'CustomerSalesTerritory' | translate" aria-label="Select Territory" matInput
        (click)="dropDownOptions('territory')" [matAutocomplete]="autoTerritory"
        formControlName="customerSalesTerritory">
      <mat-autocomplete autoActiveFirstOption #autoTerritory="matAutocomplete">
        <mat-option *ngFor="let option of filteredTerritories | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix *ngIf="isLoadingAttributes">
        <i class="fa fa-spinner fa-spin"></i>
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input type="text" aria-label="Select category" matInput [matAutocomplete]="autoCategory"
        [placeholder]="'CustomerCategory' | translate" formControlName="customerCategory"
        (click)="dropDownOptions('category')" required>
      <mat-autocomplete autoActiveFirstOption #autoCategory="matAutocomplete">
        <mat-option *ngFor="let option of filteredCategories  | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix *ngIf="isLoadingAttributes">
        <i class="fa fa-spinner fa-spin"></i>
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input type="text" [placeholder]="'CustomerChain' | translate" aria-label="Select Chain" matInput
        (click)="dropDownOptions('chain')" [matAutocomplete]="auto" formControlName="customerChain">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredChains  | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix *ngIf="isLoadingAttributes">
        <i class="fa fa-spinner fa-spin"></i>
      </mat-icon>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" placeholder="{{'CustomerArea' | translate}}" formControlName="customerArea">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'DateFrom' | translate}}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dateFrom">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'DateTo' | translate}}</mat-label>
      <input matInput [matDatepicker]="picker2" formControlName="dateTo">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <button mat-stroked-button type="button" color="primary" cdkFocusInitial (click)="onSave()">{{'Save' |
      translate}}</button>
    <button mat-button type="button" (click)="onCancel()">{{'CANCEL' | translate}}</button>
  </form>