import { Expose, Transform, Type } from 'class-transformer'
import { IsArray, IsOptional, IsString, ValidateNested } from 'class-validator'

export class Notification {
    @Expose() @IsString() severity!: string
    @Expose() @IsString() description!: string
    @Expose() @IsString() issue!: string
    @Expose() @IsString() @IsOptional() amount?: number
    @Expose() @IsString() @IsOptional() collectionName?: string
    @Expose() @IsOptional() @ValidateNested({ each: true })
    @IsArray() @Type(() => Object)
    @Transform(({ value }) => Array.isArray(value) ? value : [value], { toClassOnly: true })
    Collection?: any[]
}

export namespace GetAllNotifications {
    export class Response extends Notification {
        @Expose() @IsString() createdAt!: string
    }
}