import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ConfigService } from 'src/app/services/config.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  @ViewChild('emailModel') emailModel: NgModel;

  email: string = ''
  emailSent: boolean = false
  error: boolean = false
  constructor(private configService: ConfigService, private utils: UtilitiesService) { }

  ngOnInit(): void {
    const params = this.utils.getUrlParams(window.location.href);
    if(params['email']) {
      this.email = decodeURIComponent(params['email'])
    }
  }

  async sendResetPasswordEmail() {
    const url = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${this.configService.config.firebase.apiKey}`
    this.emailModel.control.markAsTouched()
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ email: this.email, requestType: 'PASSWORD_RESET' }),
      })
      if(res.status === 400 ){
        this.emailModel.control.setErrors({email: true})
        return
      }
      this.emailSent = true
    } catch (error){
      console.log(error)
    }
   
  }
}
