import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})

export class FormatPhonePipe implements PipeTransform {
  transform(phoneValue: string): string {
    const value = phoneValue[0] === '+' ? phoneValue.substr(1).replace(/\D/g, '') : phoneValue.replace(/\D/g, '');
    const countryFormats = {
      '1': [{ len: 10, format: "(xxx) xxx-xxxx" }],
      '44': [{ len: 10, format: "(xxxx) xxxxxx" }],
      '61': [{ len: 9, format: "(xx) xxxx-xxxx" }],
      '64': [{ len: 9, format: "(xx) xxx-xxxx" }],
      '27': [{ len: 9, format: "(xx) xxx-xxxx" }],
      '52': [{ len: 10, format: "(xxx) xxx-xxxx" }],
      '57': [{ len: 10, format: "(xxx) xxx-xxxx" }],
      '55': [
        { len: 10, format: "(xx) xxxx-xxxx" },
        { len: 11, format: "(xx) xxxxx-xxxx" }
      ],
      '54': [{ len: 10, format: "(xxx) xxx-xxxx" }],
      '56': [{ len: 9, format: "(xx) xxxx-xxxx" }],
      '51': [{ len: 9, format: "(xxx) xxx-xxx" }],
      '20': [{ len: 10, format: "(xx) xxxx-xxxx" }],
      '966': [{ len: 9, format: "(xx) xxx-xxxx" }],
      '971': [{ len: 9, format: "(xx) xxx-xxxx" }],
      '212': [{ len: 9, format: "(xx) xxx-xxxx" }],
      '963': [{ len: 9, format: "(xx) xxx-xxxx" }],
      '30': [{ len: 10, format: "(xxx) xxx-xxxx" }],
      '91': [{ len: 10, format: "(xx) xxxxx-xxxx" }]
    }
    for (let code in countryFormats) {
      if (value.startsWith(code)) {
        for (let formatObj of countryFormats[code]) {
          let format = formatObj.format;
          let number = value.substr(code.length);
          if (number.length === formatObj.len) {
            for (let i = 0; i < number.length; i++) {
              format = format.replace('x', number[i]);
            }
            return '+' + code + ' ' + format;
          }
        }
        break;
      }
    }
    return phoneValue;
  }
}
