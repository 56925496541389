<div class="padding">
    <div class="title">
        {{'CompaniesManagement'|translate}}
    </div>
    <div style='padding-right: 15px;padding-bottom: 20px;padding-left: 5px;'>
        <button matTooltip="{{'Refresh'|translate}}" mat-mini-fab color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <app-companies-list [organizationId]="organizationId"></app-companies-list>
</div>