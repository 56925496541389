import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ContractTrainingResponse, ListTrainings } from 'src/app/shared/api-structures/admin/training';
import { ByIdRequest } from 'src/app/shared/api-structures/common';
import { ApiService } from '../../shared/services/api.service';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';

@Injectable()
export class TrainingsService {
  private trainings = new Subject<ListTrainings.Item[]>()
  public activeTraining: ListTrainings.Item
  public lastTraining: ListTrainings.Item

  trainings$ = this.trainings.asObservable()

  constructor(private api: ApiService, private memoryStorageService: MemoryStorageService) { }

  async startNewTraining() {
    await this.api.adminRequest({ endpoint: '/startTraining' })
    await this.loadTrainings()
  }

  async loadContractTraining(isHideProgress = false) {
    const contractTraining = await this.api.adminRequest({ endpoint: '/getContractTraining', responseType: ContractTrainingResponse},{isHideProgress})
    this.memoryStorageService.setContractTraining(contractTraining)
  }

  async isThereTrainingRunning() {
    const isThereTrainingRunning = await this.api.adminRequest({ endpoint: '/isThereTrainingRunning' })
    return isThereTrainingRunning
  }

  async loadTrainings() {
    const data = await this.api.adminRequest({ endpoint: '/listTrainings', responseType: ListTrainings.Response, body: {} })
    this.trainings.next(data.arr)
    this.lastTraining = data.arr[0]
  }

  async getActiveTraining() {
    const data = await this.api.adminRequest({ endpoint: '/listTrainings', responseType: ListTrainings.Response, body: { active: true} })
    this.activeTraining = data.arr[0]
  }

  async setActiveModel(modelId: string) {
    await this.api.adminRequest<ByIdRequest>({ endpoint: '/setActiveModel', body: { id: modelId } })
    await this.loadTrainings()
  }

  async getContractTraining() {
    const result = await this.api.miscRequest({ endpoint: '/getContractTraining', responseType: ContractTrainingResponse })
    return result
  }

  async isProduction() {
    const result = await this.api.miscRequest({ endpoint: '/isProduction', responseType: Boolean })
    return result
  }
}
