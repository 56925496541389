<div class="padding">
  <div class="header-wrapper">
    <div class="title">
      <p style="margin-bottom: 0;">{{'APIKeys'|translate}}</p>
      <small>{{'OverviewOfAPIkey' |translate}}</small>
    </div>
    <div>
      <button (click)="listApiKeys()" class="button-primary btn refresh-btn" matTooltip="{{ 'Refresh' | translate }}">
        <mat-icon>refresh</mat-icon> {{ 'Refresh' | translate }}
      </button>
      <span class='padding-span'></span>
      <button class="button-primary btn add-btn" (click)="onCreateClick()">
        <mat-icon>add</mat-icon> {{ 'AddNew' | translate }}
      </button>
    </div>
  </div>

  <div class="main-card">
    <div style="border: 2px solid #F0F0F1; border-radius: 8px;">
      <div class="pre-table-header">
        <mat-form-field appearance="standard">
          <mat-label>Search</mat-label>
          <input matInput (keyup.enter)="applyFilter()" [(ngModel)]="filter" #input>
        </mat-form-field>
      </div>

      <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
          <td mat-cell *matCellDef="let row"> <span style="display:none">{{row.id}}</span>{{row.name}}</td>
        </ng-container>

        <ng-container matColumnDef="ctx">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Context'|translate}}</th>
          <td mat-cell *matCellDef="let row">{{row.ctx}}</td>
        </ng-container>

        <ng-container matColumnDef="deleteIcon">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td style="padding-right: 0;" mat-cell *matCellDef="let row" class="delete-cell">
            <button matTooltip="{{'Delete'|translate}}" mat-button
              (click)="ondeleteClick(row.id)">
              <mat-icon style="transform: scale(0.9);">delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>

      </table>
      <div class="card-footer">
        <button class="btn export" (click)="export()">
          Export <mat-icon class="mat-icon-export">arrow_downward</mat-icon>
        </button>
        <mat-paginator style="background-color: transparent;" [length]="dataSource.data.length"
          [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
        </mat-paginator>

      </div>
    </div>
  </div>
</div>