import { style } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ContentChild, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { LanguageService } from 'shared/services/language.service';

@Component({
    selector: 'app-dialog',
    standalone: true,
    templateUrl: './dialog.html',
    styleUrls: ['./dialog.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, DialogModule, TranslateModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppDialogComponent implements OnInit, AfterViewInit {
    @Input() visible = true;
    @Input() title: string = ''
    @Input() closeble: boolean = true
    @Input() maximizable: boolean = true
    @Input() maxWidth: number = 75
    @Input() width?: number
    @Input() style?: string

    @Output() onClose = new EventEmitter()


    @ContentChild('footer') footerRef: TemplateRef<any>;

    constructor(private langService: LanguageService, private renderer: Renderer2) {
        this.langService.setLanguage()
    }

    ngOnInit(): void { }
    ngAfterViewInit(): void {
        const dialogEl = document.querySelector('.p-dialog')
        if (dialogEl && this.style) {
            dialogEl.setAttribute('style', this.style)
            // this.renderer()
        }
    }

    onCloseDialog(){
        this.onClose.emit()
    }
}
