<div (click)="uploadCsvFileBtnClick()" class="drag-drop-container" appDragAndDrop (fileDropped)="onFileDropped($event)">
  <mat-icon class="img-icon" *ngIf="files.length === 0" svgIcon="import-icon" aria-hidden="false"
    aria-label="import icon"></mat-icon>
  <input *ngIf="files.length === 0" type="file" #fileDropRef id="fileDropRef" [multiple]="isMultipleFiles"
    [accept]="accept" (change)="fileBrowseHandler($event.target.files)" />
  <div class="content">
    <div class="file-card" *ngFor="let file of files; let i = index">
      <div class="icon-wrapper">
        <mat-icon [class]="
            progress[i].percent == 100
              ? progress[i].status
                ? 'green'
                : 'red'
              : ''
          ">insert_drive_file</mat-icon>
      </div>
      <div class="content-rows-wrapper">
        <div class="row-name-progress">
          <div class="text-wrapper">
            <span class="file-name">
              {{ file.name }}
            </span>
            <span>({{ sizeConvertToMb(file.size) }})</span>
          </div>
          <ng-container *ngIf="progress[i].percent == 100; else elseBlock">
            <div class="card-finished" [class]="progress[i].status ? 'green' : 'red'">
              {{
              progress[i].status
              ? ("Success" | translate)
              : ("Error" | translate)
              }}
            </div>
          </ng-container>
          <ng-template #elseBlock>
            <span style="cursor: pointer" (click)="removeFile($event, i)">X</span>
          </ng-template>
        </div>
        <div class="progress-row-wrapper">
          <div class="progress-wrapper" [ngStyle]="{width: barLength+'px' }">
            <mat-progress-bar [class]="
                progress[i].percent == 100
                  ? progress[i].status
                    ? 'completed'
                    : 'failed'
                  : ''
              " mode="determinate" [value]="progress[i].percent" style="width: 100%"></mat-progress-bar>
          </div>
          <span>{{ progress[i].percent }}%</span>
        </div>
        <div *ngIf="!progress[i].status && progress[i].percent == 100" class="row-error">
          <span>{{ "Error" | translate }}:</span>{{ "ErrorImportDoesntMatchTemplate" | translate }}
        </div>
      </div>
    </div>

    <div *ngIf="files.length === 0" class="intructions-wrapper">
      <h3>{{ "ClickToUpload" | translate }}</h3>
      <span class="span-instructions">{{ "Max5Mb" | translate }}</span>
    </div>
  </div>
</div>