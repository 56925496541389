<div *ngIf="showOldLogin" class="main flex">
  <div class="flex-7 left-part">
    <div class="login-images-grid">
      <div class="area1">
        <div class="login-image-txt">
          {{'PlanogramPlanning' | translate}}
        </div>
      </div>
      <div class="area2">
        <div class="login-image-txt">
          {{'TraditionalSalesandMom&Pops' | translate}}
        </div>
      </div>
      <div class="area3">
        <div class="login-image-txt">
          {{'RetailStore' | translate}}
        </div>
      </div>
      <div class="area4">
        <div class="login-image-txt">
          {{'Analytics' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="flex-5">
    <div class="login-container">
      <div class="logo-container">
        <img src="/assets/images/login_wtm_ire_logo.png" alt="logo" />
      </div>
      <h3 class="text-center">{{'ImageRecognitionStudio'|translate}}</h3>
      <div class="login-form-container">
        <firebase-ui (signInSuccessWithAuthResult)="successCallback($event)" (signInFailure)="errorCallback($event)"
          (uiShown)="uiShownCallback()">
        </firebase-ui>
      </div>
      <div>
        <div class="not-customer-header">
          {{ 'Notacustomeryet?' | translate }}
          <br />
          <a href="https://materiais.mc1global.com/mc1-demo-cadastro-en" target="_blank">
            {{ 'Filloutthisformandcontactus' | translate }}</a>
        </div>
        <div class="app-stores-links">
          <div class="app-store-link">
            <div class="app-store-link-inner">
              <div class="ire-logo">
                <img src="/assets/images/login_wtm_ire_logo.png" class="small-logo" alt="logo" />
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.mc1global.ire" target="_blank">
                  <img src="/assets/images/google-play-badge.png" class="small-logo" alt="logo" />
                </a>
              </div>
              <div>
                <a href="https://apps.apple.com/us/app/wtm-ire/id1672125423" target="_blank">
                  <img src="/assets/images/apple-store-badge.png" class="small-logo" alt="logo" />
                </a>
              </div>
            </div>
          </div>

          <div class="app-store-link">
            <div class="app-store-link-inner">
              <div class="ire-logo">
                <img src="/assets/images/login_wtm_ire_store_logo.png" class="small-logo" alt="logo" />
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.mc1global.ire.store" target="_blank">
                  <img src="/assets/images/google-play-badge.png" class="small-logo" alt="logo" />
                </a>
              </div>
              <div>
                <a href="https://apps.apple.com/app/wtm-ire-for-stores/id6444678019" target="_blank">
                  <img src="/assets/images/apple-store-badge.png" class="small-logo" alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>