import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { BehaviorSubject, Subject } from "rxjs";
import { MemoryStorageService } from "src/app/services/memory-storage.service";
import { Context } from "../api-structures/admin/user";
import { ApiService } from "./api.service";
import { getCompanyType } from "./companyType.service";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/services/config.service";

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _context: Partial<Context> = undefined
  logged: boolean
  expireSessionWarningTimer: ReturnType<typeof setTimeout>
  expireSessionTimer: ReturnType<typeof setTimeout>
  sessionWarningMinutes = 20
  sessionExpireAfterWarningMinutes = 5
  checkSessionInterval: ReturnType<typeof setInterval>
  $logged = new BehaviorSubject<boolean>(undefined)
  performingLogout = false

  private sessionExpireWarning = new Subject()
  sessionExpireWarning$ = this.sessionExpireWarning.asObservable()

  constructor(private memoryStorageService: MemoryStorageService, private api: ApiService, private aAuth: AngularFireAuth, private router: Router, private configService: ConfigService) {
    this.$logged.subscribe((logged) => {
      this.logged = logged
    })
  }

  get context() {
    return this._context
  }

  set context(val: Partial<Context>) {
    this._context = val
  }

  async checkSession() {
    try {
      if (getCompanyType() === 'MC1') {
        this.$logged.next(true)
        return
      }
      await this.api.rawRequest<null, { alive: boolean }>({ route: '/checkSession', isHideProgress: true })
      this.$logged.next(true)
    } catch (error) {
      if (error.status === 403) {
        await this.logOut()
      } else if (error.status === 401 && this.performingLogout === false) {
        this.$logged.next(false)
      }
    }
  }

  isLogged() {
    return this.logged
  }

  async getCurrentUserToken() {
    let currentToken = ''

    if (getCompanyType() === 'MC1') {
      if (this.memoryStorageService.token) currentToken = this.memoryStorageService.token
      else if (this.memoryStorageService.superToken) currentToken = this.memoryStorageService.superToken
    }

    return currentToken
  }

  stopExpireSessionWarningTimer() {
    if (this.expireSessionWarningTimer) {
      clearTimeout(this.expireSessionWarningTimer)
      this.expireSessionWarningTimer = undefined
    }
  }

  stopExpireSessionTimer() {
    if (this.expireSessionTimer) {
      clearTimeout(this.expireSessionTimer)
      this.expireSessionTimer = undefined
    }
  }


  checkContinueToUrl(url: string) {
    try {
      const urlObj = new URL(url)
      if (urlObj.hostname.endsWith(window.location.hostname)) {
        return true
      }
      return false
    } catch {
      return false
    }
  }

  async login(token: string, continueToUrl?: string) {
    this.aAuth.signOut()
    try {
      await this.api.rawRequest({ route: '/login', body: { data: { idToken: token } } })
      if (continueToUrl && this.checkContinueToUrl(continueToUrl)) {
        window.location.href = continueToUrl
      }
      this.$logged.next(true)
    } catch (e) {
      throw e
    }

  }

  navigateToLogin(redirectAfterLogin) {
    if (!['/login', '/home', '/', ''].includes(this.router.url) && redirectAfterLogin) {
      this.router.navigate(['/login'], { "queryParams": { returnUrl: this.router.url } })
    } else if (!['/login'].includes(this.router.url)) {
      this.router.navigate(['/login'])
    }
  }

  async logOut(redirectAfterLogin = false) {
    if (this.performingLogout) return
    if (getCompanyType() === 'Standalone') {
      this.performingLogout = true
      await this.api.rawRequest({ route: '/logout', noLogoutOn401: true })
      this.$logged.next(false)
      this.navigateToLogin(redirectAfterLogin)
      this.performingLogout = false
    }
  }
}