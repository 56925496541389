<div class="table-main" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50"
  [infiniteScrollDisabled]="isInfiniteScrollDisabled" (scrolled)="nextPage()">
  <div class="table-content-container">
    <div class="table-header">
      <div class="table-title-container">
        <div class="title">
          {{'ImageReview'|translate}}
        </div>
        <div *ngIf="remainingTrainings > 0" class="notification-alert-can-training">
          <mat-icon *ngIf="remainingTrainings > 0"
            matTooltip="{{remainingTrainings}} {{'RemainingMonthlyTrainings'|translate}}"
            class="notification-icon">notification_important</mat-icon>
        </div>
        <div class="sub-title">
          {{'ProductsList'|translate}}
        </div>
      </div>
    </div>

    <div class="content">
      <div class="pre-table-header">
        <mat-form-field class="aligned">
          <mat-chip-list #filterChipList>
            <mat-chip *ngFor="let filter of filters" [selectable]="selectable" [removable]="true"
              (removed)="removeFilter(filter)">
              {{filter}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="{{'SearchProduct'|translate}}" [matChipInputFor]="filterChipList"
              [matChipInputSeparatorKeyCodes]="filterSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addFilter($event)">
          </mat-chip-list>
          <mat-icon matSuffix *ngIf="filterShowSpinner">
            <i class="fa fa-spinner fa-spin"></i>
          </mat-icon>
        </mat-form-field>
        <mat-icon (click)="openFilterOptions($event)" style="cursor: pointer;">tune</mat-icon>
      </div>
      <br />
      <ul>
        <li *ngFor="let image of products let i=index">
          <mat-card class="mat-elevation-z0">
            <div style="display: flex; align-items: center;">
              {{image.countImagesCatalogs}}
              <mat-icon matTooltip="{{'ImageCatalogsList'|translate}}" style="color:#919191;transform: scale(0.75);">
                image</mat-icon>
              <span style="font-size: medium;">{{image.imageCount}}</span>
              <span class="oval-background invader" *ngIf="image.isCompetitors">{{'Competitor'|translate}}</span>
              <span class="oval-background noise" *ngIf="image.productId === 'noise'">{{'NOISE'|translate}}</span>
              <span class="oval-background own" *ngIf="!image.isCompetitors">{{'OWN'|translate}}</span>
            </div>

            <label [for]="'id'+i">
              <ng-container *ngIf="image.isHaveMoreImageCatalogs; else elseBlock">
                <img class="show-product-icon" [src]="image.thumbnail"
                  (click)="selectImageCatalog(image.productId, image.productName)" />
              </ng-container>
              <ng-template #elseBlock>
                <img class="show-product-icon" [src]="image.thumbnail" routerLink="/image-review"
                  [queryParams]="{ product_id : image.productId,product_name: image.productName, imageCatalog_id: image.imageCatalogId,product_thumbnail: image.thumbnail,
                    is_have_more_image_catalogs: false, are_enough_images: image.areEnoughImages}" />
              </ng-template>
            </label>
            <mat-card-content>
              <span matTooltip="{{image.productName}}" class="crop-long-text"
                #toolTip1>{{image.productName}}</span>
              <br />
              <div style="color:#919191;" class="crop-long-text" matTooltip="{{image.productId}}"
                [matTooltipDisabled]="isMatTooltipDisabled(toolTip2)" #toolTip2>
                {{image.productId}}
              </div>
              <div class="card-details" style="border-top: solid 1px #e0e0e0; padding-top: 15px;">
                <span>{{'Category'|translate}}:</span>
                <span class="crop-long-text" style="text-align: right; padding-left: 6px;"
                  matTooltip="{{image.category}}" [matTooltipDisabled]="isMatTooltipDisabled(toolTip3)"
                  #toolTip3>{{image.category}}</span>
              </div>
              <div class="card-details">
                <span style="min-width: 70%;">{{'SuccessRate'|translate}}:</span>
                <span class="crop-long-text" style="text-align: right; padding-left: 6px;"
                  matTooltip="{{image.accuracy}}%" [matTooltipDisabled]="isMatTooltipDisabled(toolTip3)"
                  #toolTip3>{{image.accuracy}}%</span>
              </div>
              <div class="card-details">
                <mat-progress-bar [color]="updateColor(image.accuracy)" mode="determinate" value="{{image.accuracy}}"></mat-progress-bar>
              </div>
            </mat-card-content>
          </mat-card>
        </li>
      </ul>
      <br />
    </div>
  </div>