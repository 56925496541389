import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'simple-paginator',
  template: `
    <div class="main">
      <div class="flex">
      <div class="flex-3">
          <div class="pageNum">
            {{'Page' | translate}}: {{(currentPage ?? 0) + 1}}
          </div>
        </div>
        <div class="flex-1">
          <button mat-icon-button [disabled]='!currentPage' (click)='onPaginate(currentPage - 1)'><mat-icon>arrow_back_ios</mat-icon></button>
        </div>
        
        <div class="flex-1">
          <button mat-icon-button [disabled]='!hasNext' (click)='onPaginate(currentPage + 1)'><mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .main {
      width: 100%;
      display: flex;
      justify-content: right;
      height: 35px;
    }
    .main > div {
      width: 150px;
      justify-content: right;
    }
    .pageNum {
      font-size: 13px;
      color: gray;
      line-height: 41px;
    }
    `]
})
export class SimplePaginatorComponent implements OnInit {
  @Input() currentPage = 0
  @Input() hasNext = false
  @Output() page = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  onPaginate(pageNumber: number) {
    this.page.emit(pageNumber)
  }

}
