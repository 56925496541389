export const IMPORT_ICON = `
<svg width="90" height="113" viewBox="0 0 90 113" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 3C4.477 3 0 7.5 0 13V53V73V103C0 108.5 4.477 113 10 113H80C85.525 113 90 108.5 90 103V73V53V33L60 3H10Z" fill="#E3E4EB"/>
<path d="M10 0C4.477 0 0 4.5 0 10V50V70V100C0 105.5 4.477 110 10 110H80C85.525 110 90 105.5 90 100V70V50V30L60 0H10Z" fill="#F4F4F8"/>
<path d="M90 30L60 0V20C60 25.5 64.475 30 70 30H90Z" fill="#E3E4EB"/>
<g clip-path="url(#clip0_10917_362)">
<path d="M52.35 58.04C51.67 54.59 48.64 52 45 52C42.11 52 39.6 53.64 38.35 56.04C35.34 56.36 33 58.91 33 62C33 65.31 35.69 68 39 68H52C54.76 68 57 65.76 57 63C57 60.36 54.95 58.22 52.35 58.04ZM47 61V65H43V61H40L45 56L50 61H47Z" fill="#3364A2"/>
</g>
<defs>
<clipPath id="clip0_10917_362">
<rect width="24" height="24" fill="white" transform="translate(33 48)"/>
</clipPath>
</defs>
</svg>  
`;