<div class="flex main">
  <div class="flex-5">
    <div class="login-container">
      <div class="logo-container">
        <img src="/assets/images/login_wtm_ire_logo.png" alt="logo" />
      </div>
      <h3 class="text-center">{{'Image Recognition Studio'|translate}}</h3>
      <div class="login-form-container">
        <mat-card *ngIf="!emailSent">
          <mat-card-title>Verify Your Account</mat-card-title>
          <mat-card-content>
            <div class="card-txt">
              Please enter your email address below and we will send you a link to choose your password.
            </div>
          </mat-card-content>
          <mat-card-content>
            <mat-form-field>
              <input matInput placeholder="Email" [(ngModel)]="email" #emailModel="ngModel" required/>
              <mat-error >
                Email Not Found
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <mat-card-actions>
            <div class="confirm-button">
              <button mat-raised-button color="primary" (click)="sendResetPasswordEmail()">CONFIRM</button>
            </div>
          </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="emailSent">
          <mat-card-title>Reset Password Email Sent</mat-card-title>
          <mat-card-content>
            <div class="card-txt">
              Go to your email and click on the link to reset your password.
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- <div>
        <div class="not-customer-header">
          {{ 'Notacustomeryet?' | translate }}
          <br />
          <a href="https://materiais.mc1global.com/mc1-demo-cadastro-en" target="_blank">
            {{ 'Filloutthisformandcontactus' | translate }}</a>
        </div>
      </div> -->
    </div>
  </div>

</div>