import { Component, OnInit } from '@angular/core';
import { Notification } from './notification.model';
import { Dal } from 'src/app/dal/dal';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})
export class HealthCheckComponent implements OnInit {

  runOnce = true
  notifications: Notification[] = [];
  severityAmountMap = {
    critical: 0,
    warning: 0,
    notification: 0
  }
  severities: string[] = []

  getNotificationClass(severity: string): string {
    switch (severity) {
      case 'warning':
        return '';
      case 'critical':
        return '';
      case 'notification':
        return 'pi pi-info-circle';
      default:
        return '';
    }
  }

  getColor(severity: string): string {
    if (this.runOnce) {
      this.runOnce = false
    }
    switch (severity) {
      case 'warning':
        return '#E07800';
      case 'critical':
        return '#D64040';
      case 'notification':
        return '#1B3555';
      default:
        return '#000';
    }
  }

  constructor(
    private dal: Dal
  ) { }

  getSeverityAmount(severity: string): number {
    this.severityAmountMap = {
      critical: 0,
      warning: 0,
      notification: 0
    };

    for (let notification of this.notifications) {
      if (notification.severity) {
        this.severityAmountMap[notification.severity] += 1;
      }
    }

    return this.severityAmountMap[severity];
  }

  async ngOnInit() {
    this.severities = Object.keys(this.severityAmountMap)
    await this.dal.getNotifications().then((notifications: any) => this.notifications = notifications)
  }

  async checkNotifications() {
    await this.dal.checkNotifications().then((notifications: any) => this.notifications = notifications)
  }

  getIcon(severity: string) {
    switch (severity) {
      case 'warning':
        return 'warning';
      case 'critical':
        return 'error';
      case 'notification':
        return 'info';
      default:
        return '';
    }
  }

}
