import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { isNull, isUndefined } from "underscore";

@Injectable({ providedIn: 'root' })
export class MemoryStorageService {
    private isReloadTrainingPage = new BehaviorSubject<boolean>(false);
    public isReloadTrainingPageObservable = this.isReloadTrainingPage.asObservable();
    private _token: string = undefined
    private _superToken: string = undefined
    private _mc1PlanogramId: string = undefined
    private _mc1PlanogramName: string = undefined
    private _syncOnlyEditedImages: string = undefined
    private _routerLink: string = ''
    private _lastRequestTime: string = undefined
    private _language: string = undefined
    private _mapsKey: string = undefined
    private contractTraining = new BehaviorSubject<any>({})
    public contractTrainingObs = this.contractTraining.asObservable()

    setReloadTrainingPage(val: boolean) {
        this.isReloadTrainingPage.next(val)
    }

    setContractTraining(val: any) {
        this.contractTraining.next(val)
    }

    private validateValue(value: string) {
        const invalidValues = ['null', 'undefined', '']
        const hasInvalidValue = invalidValues.includes(value)
        const isValid = !isNull(value) && !isUndefined(value) && !hasInvalidValue
        return isValid
    }

    get token() {
        if (this._token === undefined) {
            this._token = localStorage.getItem('token')
        }
        return this._token
    }
    set token(val: string) {
        this._token = val
        try {
            localStorage.setItem('token', val)
        } catch { }
    }

    get superToken() {
        if (this._superToken === undefined) {
            this._superToken = localStorage.getItem('superToken')
        }
        return this._superToken
    }
    set superToken(val: string) {
        this._superToken = val
        try {
            localStorage.setItem('superToken', val)
        } catch { }
    }

    get mc1PlanogramId() {
        if (this._mc1PlanogramId === undefined) {
            const value = localStorage.getItem('mc1PlanogramId')
            const isValid = this.validateValue(value)
            if (isValid) {
                this._mc1PlanogramId = value
            }
        }
        return this._mc1PlanogramId
    }

    set mc1PlanogramId(value: string) {
        const isValid = this.validateValue(value)

        if (isValid) {
            this._mc1PlanogramId = value
            localStorage.setItem('mc1PlanogramId', value)
        }
    }

    get mc1PlanogramName() {
        if (this._mc1PlanogramName === undefined) {
            this._mc1PlanogramName = localStorage.getItem('mc1PlanogramName')
        }
        return this._mc1PlanogramName
    }
    set mc1PlanogramName(val: string) {
        this._mc1PlanogramName = val
        try {
            localStorage.setItem('mc1PlanogramName', val)
        } catch { }
    }

    get syncOnlyEditedImages() {
        if (this._syncOnlyEditedImages === undefined) {
            this._syncOnlyEditedImages = localStorage.getItem('syncOnlyEditedImages')
        }
        return this._syncOnlyEditedImages
    }
    set syncOnlyEditedImages(val: string) {
        this._syncOnlyEditedImages = val
        try {
            localStorage.setItem('syncOnlyEditedImages', val)
        } catch { }
    }

    get routerLink() {
        if (this._routerLink === undefined) {
            this._routerLink = localStorage.getItem('routerLink')
        }
        return this._routerLink
    }
    set routerLink(val: string) {
        this._routerLink = val
        try {
            localStorage.setItem('routerLink', val)
        } catch { }
    }

    get lastRequestTime() {
        if (this._lastRequestTime === undefined) {
            this._lastRequestTime = localStorage.getItem('lastRequestTime')
        }
        return new Date(this._lastRequestTime)
    }
    set lastRequestTime(val: Date) {
        this._lastRequestTime = String(val)
        try {
            localStorage.setItem('lastRequestTime', this._lastRequestTime)
        } catch { }
    }

    get language() {
        if (this._language === undefined) {
            this._language = localStorage.getItem('language')
        }
        return this._language
    }
    set language(val: string) {
        this._language = val
        try {
            localStorage.setItem('language', this._language)
        } catch { }
    }

    get mapsKey() {
        if (this._mapsKey === undefined) {
            this._mapsKey = localStorage.getItem('mapsKey')
        }
        return this._mapsKey
    }
    set mapsKey(val: string) {
        this._mapsKey = val
        localStorage.setItem('mapsKey', this._mapsKey)
    }
}
