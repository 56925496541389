import { Component, Input } from '@angular/core';
import { Notification } from '../notification.model';
import { HealthCheckModalComponent } from '../health-check-modal/health-check-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent {

  @Input() notification: Notification;

  constructor(public dialog: MatDialog) { }

  getNotificationClass(type: string): string {
    switch (type) {
      case 'warning':
        return 'pi pi-exclamation-triangle';
      case 'critical':
        return 'pi pi-exclamation-circle';
      case 'notification':
        return 'pi pi-info-circle';
      default:
        return '';
    }
  }

  getColor(type: string): string {
    switch (type) {
      case 'warning':
        return '#E07800';
      case 'critical':
        return '#D64040';
      case 'notification':
        return '#1B3555';
      default:
        return '#000';
    }
  }

  openModal() {
    this.dialog.open(HealthCheckModalComponent, {
      width: '842px',
      data:this.notification
    })
  }
}
