

// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsNumber, IsOptional, IsString, MinLength, ValidateNested } from 'class-validator'
import { BulkChangeBase, BulkChangeResponseBase } from '../bulk'
import { SortDirection } from '../common'

export type UserRole = string | 'super_admin'

export type FrontScreen =
    'Home' | 'Planograms' | 'Products' | 'Products' | 'ImagesReview' | 'UserSettings' |
    'Train' | 'Dashboard' | 'Models' | 'Reports' |
    'Users' | 'Users' | 'Mobile' | 'Super' | 'Images' | 'Customer' | 'Customer' | 'Bulk' | 'ChatCpg'

export class Context {
    @Expose() @IsString() @MinLength(1) organization!: string
    @Expose() @IsString() @MinLength(1) environment!: string
    @Expose() @IsString() @MinLength(1) company!: string
}

export class CreateUserRequest {
    @Expose() @IsString() name!: string
    @Expose() @IsString() email!: string
    @Expose() @IsString() role!: UserRole
    @Expose() @IsString() @IsOptional() phone?: string
    @Expose() @Type(() => Context) @IsOptional() context?: Context
    @Expose() @IsString() @IsOptional() organizationId?: string
}

export class CreateUserWithPasswordRequest {
    @Expose() @IsString() name!: string
    @Expose() @IsString() email!: string
    @Expose() @IsString() role!: UserRole
    @Expose() @IsString() password!: string
    @Expose() @IsString() @IsOptional() phone?: string
    @Expose() @Type(() => Context) @IsOptional() context?: Context
    @Expose() @IsString() @IsOptional() organizationId?: string
}

export class CreateUserResponse {
    @Expose() @IsString() id!: string
    @Expose() @IsString() pw!: string
}

export class CreateUserWithPasswordResponse {
    @Expose() @IsString() id!: string
    @Expose() @IsString() pw!: string
    @Expose() @IsString() message!: string
}

export class UpdateUserLanguageRequest {
    @Expose() @IsString() id!: string
    @Expose() @IsString() language!: string
}

export class UpdateUserProfileImageRequest {
    @Expose() @IsString() id!: string
    @Expose() @IsString() url!: string
}

export class UpdateUserRequest {
    @Expose() @IsString() id!: string
    @Expose() @IsString() name!: string
    @Expose() @IsString() role!: UserRole
    @Expose() @IsString() @IsOptional() phone?: string
}

export class ResetPasswordResponse {
    @Expose() @IsString() newPassword!: string
}

export namespace ListUsers {
    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
        @Expose() @IsString() email!: string
        @Expose() @IsString() context?: string
        @Expose() @IsString() role!: UserRole
        @Expose() @IsString() @IsOptional() phone?: string
    }

    export class Response {
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
        @Expose() @IsString() @IsOptional() nextPageToken?: string
        @Expose() @IsNumber() totalUsers!: number
    }

    export class Request {
        @Expose() @IsNumber() maxPage!: number
        @Expose() @IsString() @IsOptional() token?: string
    }
}

export class GetUserInfoResponse extends ListUsers.Item {
    @Expose() @IsString() company?: string
    @Expose() @IsString() language?: string
    @Expose() @IsString() profileImgUrl?: string
    @Expose() @IsString() imagePath?: string
}


export namespace ListUsersForAdmin {
    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
        @Expose() @IsString() email!: string
        @Expose() @IsString() phone?: string
        @Expose() @IsString() role!: UserRole
    }

    export class Response {
        @Expose() @IsNumber() totalUsers!: number
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }

    export class Request {
        @Expose() @IsNumber() currentPage!: number
        @Expose() @IsNumber() pageSize!: number
        @Expose() @IsString() query!: string
        @Expose() @IsString() @IsOptional() sortBy?: 'createdAt'
        @Expose() @IsString() @IsOptional() sortDirection?: SortDirection
        @Expose() @IsOptional() @IsArray() roles?: UserRole[]
    }
}

export class ExportResponse {
    @Expose() @IsString() url!: string
}

export class ImportRequest {
    @Expose() @IsString() url!: string
}


export class GetAvailableRolesResponse {
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) roles!: string[]
}

export class BulkUser {
    @Expose() @IsString() name!: string
    @Expose() @IsString() email!: string
    @Expose() @IsString() role!: UserRole
    @Expose() @IsString() @IsOptional() phone?: string
}

export class BulkAddUsersRequest {
    @Expose() @IsString() entity?: string
    @Expose() @Type(() => BulkUser) @ValidateNested() @IsArray() data!: BulkUser[]
}

export class BulkAddUsersReport {
    @Expose() @IsString() error!: string
    @Expose() @Type(() => BulkUser) @ValidateNested() item!: BulkUser
}

export class BulkAddUsersResponse extends BulkChangeResponseBase {
    @Expose() @Type(() => BulkAddUsersReport) @ValidateNested() @IsArray() report!: BulkAddUsersReport[]
}

export class ChangeUserEntity extends BulkChangeBase {
    @Expose() @ValidateNested() @Type(() => BulkUser) data?: BulkUser
}

export class BulkChangeUsersRequest {
    @Expose() @ValidateNested() @Type(() => ChangeUserEntity) @IsArray() data!: ChangeUserEntity[]
}

export class BulkChangeUsersReport {
    @Expose() @IsString() error!: string
    @Expose() @Type(() => ChangeUserEntity) @ValidateNested() item!: ChangeUserEntity
}

export class BulkChangeUsersResponse extends BulkChangeResponseBase {
    @Expose() @Type(() => BulkChangeUsersReport) @ValidateNested() @IsArray() report!: BulkChangeUsersReport[]
}
