import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardsCommunicatorService implements OnDestroy {
  private messageListener: (event: MessageEvent) => void
  private isStarted = false

  constructor() {  }

  initSession() {
    if (this.isStarted) {
      return
    }

    this.messageListener = (event: MessageEvent) => {
      if (event.data.type === 'iframeReady') {
        event.source.postMessage({ type: 'sendDomain', domain: location.origin },{ targetOrigin: event.origin })
      }
    }
    window.addEventListener('message', this.messageListener)
    this.isStarted = true
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.messageListener)
    this.isStarted = false
  }
}
