import { Component, Input } from '@angular/core';

@Component({
  selector: 'content-loading',
  template: `
    <ng-content *ngIf="!loading"></ng-content>
    <span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span>
    `
})
export class ContentLoadingComponent {

  @Input() loading = false
  constructor() { }
}
