<div style="max-width: 500px;">
    <h2 style="margin-bottom: 0;" mat-dialog-title>{{'CreateUser'|translate}}</h2>
    <span style="margin-bottom: 24px;">Please enter the details to create a new user account.</span>
    <mat-dialog-content>
        <mat-form-field>
            <mat-label>{{'Name'|translate}}</mat-label>
            <input placeholder="e.g., Maria Andersson" matInput [(ngModel)]="name" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Email'|translate}}</mat-label>
            <input placeholder="you@email.com" matInput [(ngModel)]="email"
                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Phone'|translate}}</mat-label>
            <input placeholder="+1 (555) 123-4567" matInput [(ngModel)]="phone" type="number">
        </mat-form-field>

        <div class="scrollbar" style="border: 2px solid #E3E4EB; margin: 1px; padding: 16px; overflow: hidden;">
            <div *ngIf="isSuperAdmin === true" class="context-title-wrapper">
                <mat-icon class="acount-tree">account_tree_outline</mat-icon>
                <span class="role-n-context">{{'roleContext' |translate}}</span>
            </div> 

            <mat-form-field *ngIf="isSuperAdmin === true">
                <mat-label>{{'Organization'|translate}}</mat-label>
                <mat-select [(value)]="selectedOrganizationId" (selectionChange)="onOrganizationSelected()">
                    <mat-option *ngFor="let org of orgs" [value]="org.organization">{{org.organization}}</mat-option>
                </mat-select>
                <mat-icon matSuffix *ngIf="isLoadingContexts">
                    <i class="fa fa-spinner fa-spin"></i>
                </mat-icon>
            </mat-form-field>


            <mat-form-field *ngIf="isSuperAdmin === true">
                <mat-label>{{'Enviroment'|translate}}</mat-label>
                <mat-select [(value)]="selectedEnvironmentId" [disabled]="!selectedOrganizationId">
                    <mat-option *ngFor="let env of envs" [value]="env">{{env}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isSuperAdmin === true">
                <mat-label>{{'Company'|translate}}</mat-label>
                <mat-select [(value)]="selectedCompanyId" [disabled]="!selectedOrganizationId" (selectionChange)="onCompanySelected()">
                    <mat-option *ngFor="let comp of comps" [value]="comp">{{comp}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="isSuperAdmin === true && (selectedOrganizationId && selectedEnvironmentId && selectedCompanyId)" class="container">
                <mat-icon class="icon">info</mat-icon>
                <div class="text-box">
                    <h3 class="context-title">{{'contextPath' |translate}} </h3>
                    <span class="context-subTitle">{{'thisWillContext' |translate}}
                        <b>{{selectedOrganizationId}}/{{selectedEnvironmentId}}/{{selectedCompanyId}}</b></span>
                </div>
            </div>
            <mat-form-field style="margin-top: 8px;">
                <mat-select [(value)]="role" placeholder="{{'Role'|translate}}">
                    <mat-option *ngIf="isSuperAdmin" value="">{{'---'|translate}}</mat-option>
                    <mat-option *ngFor="let role of roleOptions" [value]="role">{{role|translate}}</mat-option>
                    <mat-option *ngIf="isSuperAdmin" value="super_admin">{{'SuperAdmin'|translate}}</mat-option>
                    <mat-option *ngIf="isSuperAdmin"
                        value="organization_admin">{{'OrganizationAdmin'|translate}}</mat-option>
                </mat-select>
                <mat-icon matSuffix *ngIf="isLoadingRoles">
                    <i class="fa fa-spinner fa-spin"></i>
                </mat-icon>
            </mat-form-field>
        </div>

    </mat-dialog-content>


    <p-dialog [(visible)]="isShowpasswordDialog">
        <p-header>{{ 'user saved succesfully' | translate }}</p-header>
        <p>{{'ConfirmNewUserPassword'|translate}}</p>
        <p>{{password}}</p>
        <div class="dialog-button-container">
            <button mat-flat-button color="primary" (click)="afterConfirmPw()">{{'Ok' |translate}}</button>
        </div>
    </p-dialog>
</div>
<mat-dialog-actions align="end" style="margin-top: 24px; background-color: #F4F4F8; width: 109%; margin-left: -4.5%;">
    <button class="cancel" mat-button mat-dialog-close matTooltip="{{'CancelLower'|translate}}">
        {{'CancelLower'|translate}}
    </button>
    <button class="confirm" mat-button (click)="save()" matTooltip="{{'SaveChanges'|translate}}">
        {{'Confirm'|translate}}
    </button>
</mat-dialog-actions>