// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace
import { Expose, Type } from 'class-transformer'
import { IsArray, IsString, ValidateNested } from 'class-validator'
import { Context } from './admin/user'
import { Ctx } from './common'
import { IsCtx } from './customDecorators'

export namespace GetAvaliableContexts {
    export class Response {
        @Expose() @Type(() => Context) @ValidateNested() @IsArray() contexts!: Context[]
    }
}

export class ApiKey {
    @Expose() @IsString() id!: string
    @Expose() @IsString() name!: string
    @Expose() @IsCtx() ctx!: Ctx
}

export class ListApiKeyResponse {
    @Expose() @Type(() => ApiKey) @ValidateNested() @IsArray() items!: ApiKey[]
}

export class CreateApiKeyRequest {
    @Expose() @IsString() name!: string
    @Expose() @IsCtx() ctx!: Ctx
}

export class CreateApiKeyResponse {
    @Expose() @IsString() fullKey!: string
}
