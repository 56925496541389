import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Inject, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CompanySettings } from 'src/app/shared/api-structures/admin/companySettings';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CompaniesService } from 'src/app/super-admin/services/companies.service';
import { Dal } from 'src/app/dal/dal';
import { PlanogramSettingsGettingResponse } from 'src/app/shared/api-structures/misc/planogramSettings';
import { MobileSettings } from './mobile-settings.interface';


@Component({
  selector: 'company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
})
export class CompanySettingsComponent implements OnInit, AfterViewInit {

  uploadProgress: {
    name: string,
    progress: number
  }
  _metabaseAddTitle: string
  _metabaseAddId: string
  _dataStudioAddTitle: string
  _dataStudioAddUrl: string
  _isNoiseEnabled: boolean
  _threshold: number
  _syncOnlyEditedImages: boolean
  _sharingProjectCompany: string
  _sharingProjectEnabled: boolean
  _sharingProjectEnvironment: string
  _sharingProjectOrganization: string
  _trainingSettingsTrainingQuota: number
  _trainingSettingsSingleTrainingSold: number
  _metabaseSecretKey: string
  _metabaseSiteUrl: string
  _dataSourceMetabase
  _dataSourceDataStudio
  _language: string
  _country: string
  _currency: string
  _logoUrl: string
  _blurDetectionThreshold: number
  _blurDetectionCaptureTries: number
  _imagesQualityAndroid: number
  _imagesQualityIOS: number
  _automaticCropping: boolean
  _enableAsPartner: boolean
  _kpis: string
  _zonesSelection: string
  _annotationsSelection: string
  _singlePicturePriceOcr: boolean
  _multiPicturePriceOcr: boolean
  _overlayBounds: number
  _galeryPhotoEnabled: boolean
  _showCatalogDimensions: boolean
  _priceOCR: 'multi' | 'single'
  _ocrReadMethod: 'manual' | 'automatic' | string
  _maxNumberImagesInCatalog: number
  _maxNumberOfCatalogs: number
  _mobileTabsFullUser: string[]
  _mobileTabsSimpleUser: string[]
  _mc1DataSourceUrl: string


  
  minimumImages: number = 0;
  maximumImages: number = 0;
  minimumCatalogs: number = 0;
  maximumCatalogs: number = 0;
  maxNumberImagesInCatalog: number;
  maxNumberOfCatalogs: number;

  companyId: string
  environmentId: string
  organizationId: string
  companySettings: CompanySettings.Item
  optionsSettings: PlanogramSettingsGettingResponse
  isLoadingSettings: boolean
  countries = []
  selectTabsFull: { [key: string]: boolean } = {};
  selectTabsSimple: { [key: string]: boolean } = {};
  defaultMobileTabs = Object.freeze({
    fullUser: ["Compliance", "Instructions", "Misplaced", "Missing", "Presence", "Price", "Replenishment", "Unavailable", "Unplanned"],
    simpleUser: ["Compliance", "Instructions", "Misplaced", "Missing", "Presence", "Price", "Replenishment", "Unavailable", "Unplanned"]
  })

  mobileSettings: MobileSettings[]

  constructor(private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<CompanySettingsComponent>,
    private companiesService: CompaniesService,
    private languageService: LanguageService,
    private _focusMonitor: FocusMonitor,
    private utilService: UtilitiesService,
    private appService: AppService,
    private dal: Dal,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== undefined) {
      this.companyId = data.companyId
      this.environmentId = data.environmentId
      this.organizationId = data.organizationId
    }
  }

  loadMobileSettings(): void {
    this.mobileSettings = [
      {
        title: 'Classification Threshold Level',
        description: 'Set the threshold level for classification (Default: 50%)',
        toggleValue: true,
        disabled: true,
        hasControl: true,
        type: 'slider',
        controlText: 'Threshold',
        controlRef: '_threshold',
      },
      {
        title: 'Android Image Quality',
        description: 'Adjust the image quality for Android devices. (Default: 50%)',
        toggleValue: true,
        disabled: true,
        hasControl: true,
        type: 'slider',
        controlText: 'ImagesQualityAndroid',
        controlRef: '_imagesQualityAndroid',
      },
      {
        title: 'iOS Image Quality',
        description: 'Adjust the image quality for iOS devices. (Default: 50%)',
        toggleValue: true,
        disabled: true,
        hasControl: true,
        type: 'slider',
        controlText: 'ImagesQualityIOS',
        controlRef: '_imagesQualityIOS',
      },
      {
        title: 'Automatic Cropping',
        description: 'Enable or disable automatic cropping on the camera.',
        toggleValue: false,
        disabled: false,
        hasControl: false,
        type: 'checkbox',
        controlText: 'AutomaticCropping',
        controlRef: '_automaticCropping',
      },
      {
        title: 'Enable as Partner',
        description: 'Make this company available as a partner on Store Shelfie.',
        toggleValue: false,
        disabled: false,
        hasControl: false,
        type: 'checkbox',
        controlText: 'EnableAsPartner',
        controlRef: '_enableAsPartner'
      },
      {
        title: 'Key Performance Indicators',
        description: 'Select the performance indicators to display on the mobile results screen.',
        toggleValue: false,
        disabled: false,
        hasControl: true,
        type: 'select',
        options: [...this.optionsSettings.kpisFull],
        controlText: 'Kpis',
        controlRef: '_kpis'
      },
      {
        title: 'Detection Zones',
        description: 'Select which detection zones you want to enable in the mobile photo editing screen.',
        toggleValue: false,
        disabled: false,
        hasControl: true,
        type: 'select',
        options: [...this.optionsSettings.zones],
        controlText: 'Zones Selection',
        controlRef: '_zonesSelection'
      },
      {
        title: 'Annotation Settings',
        description: 'Select the annotations to display on the mobile annotation screen.',
        toggleValue: false,
        disabled: true,
        hasControl: true,
        type: 'select',
        options: [...this.optionsSettings.annotations],
        controlText: 'Annotations Selection',
        controlRef: '_annotationsSelection'
      },
      {
        title: 'Price OCR',
        description: 'Activate automatic price recognition and display prices on your mobile device.',
        toggleValue: false,
        disabled: false,
        hasControl: true,
        type: 'toggle',
        controlText: 'PriceOCR',
        controlRef: '_priceOCR',
        options: [
          {
            key: 'multi',
            description: 'Multi Picture OCR'
          },
          {
            key: 'single',
            description: 'Single Picture OCR'
          }
        ]
      },
      {
        title: 'OCR Read Method',
        description: 'Define the method for recognizing text in images on mobile devices.',
        toggleValue: false,
        disabled: false,
        hasControl: true,
        type: 'select',
        controlText: 'OCRReadMethod',
        controlRef: '_ocrReadMethod',
        options: [
          {
            key: 'manual',
            description: 'Manual'
          },
          {
            key: 'automatic',
            description: 'Automatic'
          }
        ]
      },
      {
        title: 'Sync Edited Results Only',
        description: 'Sync only results that have been edited on the mobile annotation screen.',
        toggleValue: true,
        disabled: false,
        hasControl: false,
        type: 'checkbox',
        controlText: 'Sync Edited Results Only',
        controlRef: '_syncOnlyEditedImages'
      },
      {
        title: 'Overlay Boundaries',
        description: 'Define the boundaries for overlays on images.',
        toggleValue: true,
        disabled: true,
        hasControl: true,
        type: 'slider',
        controlText: 'Overlay Bounds',
        controlRef: '_overlayBounds'
      },
      {
        title: 'Blur Detection Level',
        description: 'Set the threshold level for detecting blurriness in images.',
        toggleValue: true,
        disabled: true,
        hasControl: true,
        type: 'slider',
        controlText: 'Blur Detection Threshold',
        controlRef: '_blurDetectionThreshold'
      },
      {
        title: 'Blur Detection Capture Attempts',
        description: 'Set the number of attempts to capture a clear image before showing a blur warning.',
        toggleValue: true,
        disabled: true,
        hasControl: true,
        type: 'slider',
        controlText: 'Blur Detection Capture Tries',
        controlRef: '_blurDetectionCaptureTries',
        sliderMax: 10,
        isNotPercent: true
      },
    ];
  }

  ngOnInit(): void {
    this.initLoadSettings()
  }

  async initLoadSettings(): Promise<void> {
    await Promise.all([
      this.getDefaultSettingsOptions(),
      this.getCompanySettings(this.organizationId, this.environmentId, this.companyId),
      this.getCountries()
    ])
    this.loadMobileSettings()
  }

  ngAfterViewInit() {
    this._focusMonitor.stopMonitoring(document.getElementById('matSlider'));
  }

  async saveLogoFileToStorga(event: FileList) {
    const file: File = event[0]
    if (this.utilService.validateImageType(file) == false) {
      return
    }
    this.uploadProgress = { name: file.name, progress: 0 }
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadProgress.progress = progress, true, this.organizationId)
    await this.utilService.isFileUrlExist(fileUrl)
    this._logoUrl = fileUrl
    this.uploadProgress = undefined
  }

  changeCountry(country: any){
    this._currency = this.countries.filter(c => {
      return c.name === country.value
    })[0].currency
  }

  async getCountries() {
    (await this.utilService.getCountries()).subscribe((res: any) => {
      res.forEach(c => {
        this.countries.push({name: c.nome['abreviado-EN'], currency: c['unidades-monetarias'][0].id['ISO-4217-ALPHA']})
      })
      const names = this.countries.map(({ name }) => name);
      this.countries = this.countries.filter(({ name }, index) => !names.includes(name, index + 1));
    })
  }

  addInputMetabase() {
    if (!this._metabaseAddId || !this._metabaseAddTitle) {
      this.snackbarService.openSnackBar(2000, `Title/Id ` + `${this.languageService.translateSync('RequiredField')}`)
      return
    }
    if (!this._dataSourceMetabase) {
      this._dataSourceMetabase = []
    }
    this._dataSourceMetabase.push({ id: this._metabaseAddId, title: this._metabaseAddTitle, order: this._dataSourceMetabase.length })
    this._metabaseAddId = undefined
    this._metabaseAddTitle = undefined
  }

  removeItemFromMetabaseDatasource(screen) {
    const auxDataSourceMetabase = []
    this._dataSourceMetabase.forEach(d => {
      if (d.id !== screen.id) {
        auxDataSourceMetabase.push(d)
      }
    })
    this._dataSourceMetabase = auxDataSourceMetabase
    this._dataSourceMetabase.map((ds, index) => { ds.order = index })
  }

  addInputDataStudio() {
    if (!this._dataStudioAddTitle || !this._dataStudioAddUrl) {
      this.snackbarService.openSnackBar(2000, `Title/Url ` + `${this.languageService.translateSync('RequiredField')}`)
      return
    }
    if (!this._dataSourceDataStudio) {
      this._dataSourceDataStudio = []
    }
    this._dataSourceDataStudio.push({ url: this._dataStudioAddUrl, title: this._dataStudioAddTitle, order: this._dataSourceDataStudio.length })
    this._dataStudioAddTitle = undefined
    this._dataStudioAddUrl = undefined
  }

  removeItemFromDataStudioDatasource(screen) {
    const auxDataSourceDataStudio = []
    this._dataSourceDataStudio.forEach(d => {
      if (d.url !== screen.url) {
        auxDataSourceDataStudio.push(d)
      }
    })
    this._dataSourceDataStudio = auxDataSourceDataStudio
    this._dataSourceDataStudio.map((ds, index) => { ds.order = index })
  }

  dropMetabase(event: CdkDragDrop<string[]>) {
    moveItemInArray(this._dataSourceMetabase, event.previousIndex, event.currentIndex);
    this._dataSourceMetabase.map((ds, index) => { ds.order = index })
  }

  dropDataStudio(event: CdkDragDrop<string[]>) {
    moveItemInArray(this._dataSourceDataStudio, event.previousIndex, event.currentIndex);
    this._dataSourceDataStudio.map((ds, index) => { ds.order = index })
  }

  async getDefaultSettingsOptions(): Promise<void> {
    this.optionsSettings = await this.dal.getPlanogramSettings()
  }

  async getCompanySettings(organizationId: string, environmentId: string, companyId: string) {
    this.isLoadingSettings = true
    this.companySettings = await this.companiesService.getCompanySettings({ organization: organizationId, environment: environmentId, company: companyId })
    this._logoUrl = this.companySettings?.logoUrl
    this._blurDetectionThreshold = this.companySettings?.blurDetectionThreshold ?? 65
    this._blurDetectionCaptureTries = this.companySettings?.blurDetectionCaptureTries ?? 1
    this._imagesQualityAndroid = this.companySettings?.imagesQualityAndroid !== undefined ? this.companySettings?.imagesQualityAndroid : 50
    this._imagesQualityIOS = this.companySettings?.imagesQualityIOS !== undefined ? this.companySettings?.imagesQualityIOS : 50
    this._singlePicturePriceOcr = this.companySettings?.singlePicturePriceOcr
    this._multiPicturePriceOcr = this.companySettings?.multiPicturePriceOcr
    this._priceOCR = this.companySettings?.multiPicturePriceOcr ? 'multi' : this.companySettings?.singlePicturePriceOcr ? 'single' : undefined
    this._overlayBounds = this.companySettings?.overlayBounds ?? 50
    this._threshold = this.companySettings?.threshold ? this.companySettings?.threshold : 50
    this._maxNumberImagesInCatalog = this.companySettings?.maxNumberImagesInCatalog
    this._maxNumberOfCatalogs = this.companySettings?.maxNumberOfCatalogs
    this._isNoiseEnabled = this.companySettings?.isNoiseEnabled
    this._language = this.companySettings?.language
    this._country = this.companySettings?.country
    this._currency = this.companySettings?.currency
    this._syncOnlyEditedImages = this.companySettings?.syncOnlyEditedImages === undefined ? this._syncOnlyEditedImages : this.companySettings?.syncOnlyEditedImages === '0' ? false : true
    this._galeryPhotoEnabled = this.companySettings?.galeryPhotoEnabled
    this._mc1DataSourceUrl = this.companySettings?.mc1DataSourceUrl ?? null
    this._showCatalogDimensions = this.companySettings?.showCatalogDimensions
    this._ocrReadMethod = this.companySettings?.ocrReadMethod
    this._sharingProjectCompany = this.companySettings?.sharingProject?.company
    this._sharingProjectEnabled = this.companySettings?.sharingProject?.enabled
    this._sharingProjectEnvironment = this.companySettings?.sharingProject?.environment
    this._sharingProjectOrganization = this.companySettings?.sharingProject?.organization
    this._trainingSettingsTrainingQuota = this.companySettings?.trainingSettings?.trainingQuota
    this._trainingSettingsSingleTrainingSold = this.companySettings?.trainingSettings?.singleTrainingSold

    this._metabaseSecretKey = this.companySettings?.dashboards?.metabase?.secretKey
    this._metabaseSiteUrl = this.companySettings?.dashboards?.metabase?.siteUrl
    this._dataSourceMetabase = this.companySettings?.dashboards?.metabase?.screens
    this._dataSourceDataStudio = this.companySettings?.dashboards?.dataStudio?.screens
    this.isLoadingSettings = false
    this._mobileTabsFullUser = this.companySettings?.mobileTabs?.fullUser ?? this.defaultMobileTabs?.fullUser
    this._mobileTabsSimpleUser = this.companySettings?.mobileTabs?.simpleUser ?? this.defaultMobileTabs?.simpleUser     

    this._mobileTabsFullUser.forEach(item => {
      this.selectTabsFull[item] = this.defaultMobileTabs.fullUser.includes(item);
    });
    
    this._mobileTabsSimpleUser.forEach(item => {
      this.selectTabsSimple[item] = this.defaultMobileTabs.simpleUser.includes(item);
    });
  }

  async saveSettings() {
    if (!this._country || !this._language) {
      this.snackbarService.openSnackBar(4000, `${this.languageService.translateSync('CountryAndLanguageIsMandatory')}`)
      return
    }
    const request = {
      sharingProject: {
        company: this._sharingProjectCompany,
        enabled: this._sharingProjectEnabled,
        environment: this._sharingProjectEnvironment,
        organization: this._sharingProjectOrganization,
      },
      trainingSettings: {
        trainingQuota: this._trainingSettingsTrainingQuota,
        singleTrainingSold: this._trainingSettingsSingleTrainingSold
      },
      dashboards: {
        metabase: {
          secretKey: this._metabaseSecretKey,
          siteUrl: this._metabaseSiteUrl,
          screens: this._dataSourceMetabase
        },
        dataStudio: {
          screens: this._dataSourceDataStudio
        }
      },
      threshold: this._threshold,
      maxNumberImagesInCatalog: this._maxNumberImagesInCatalog,
      maxNumberOfCatalogs: this._maxNumberOfCatalogs,
      isNoiseEnabled: this._isNoiseEnabled,
      logoUrl: this._logoUrl,
      blurDetectionThreshold: this._blurDetectionThreshold,
      mc1DataSourceUrl: this._mc1DataSourceUrl ?? undefined,
      blurDetectionCaptureTries: this._blurDetectionCaptureTries,
      imagesQualityAndroid: this._imagesQualityAndroid,
      imagesQualityIOS: this._imagesQualityIOS,
      singlePicturePriceOcr: this._priceOCR === 'single',
      multiPicturePriceOcr: this._priceOCR === 'multi',
      overlayBounds: this._overlayBounds,
      galeryPhotoEnabled: this._galeryPhotoEnabled,
      showCatalogDimensions: this._showCatalogDimensions,
      ocrReadMethod: this._ocrReadMethod,
      language: this._language,
      country: this._country,
      currency: this._currency,
      syncOnlyEditedImages: this._syncOnlyEditedImages ? '1' : '0',
      mobileTabs: {
        fullUser: [...new Set(this._mobileTabsFullUser)],
        simpleUser: [...new Set(this._mobileTabsSimpleUser)]
      }
    }

    await this.companiesService.saveCompanySettings({ organization: this.organizationId, environment: this.environmentId, company: this.companyId }, request)
    this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('companySuccessfullyUpdated')}`)
  }

  removeFromListFullUser(checked: any, item: string) {
    if(checked && this._mobileTabsFullUser.find(x => x == item) === undefined) {
      this._mobileTabsFullUser.push(item)
    }
    else {
      this._mobileTabsFullUser = this._mobileTabsFullUser.filter(i => i !== item);
    }
  }

  removeFromListSimpleUser(checked: any, item: string) {
    if(checked && this._mobileTabsSimpleUser.find(x => x == item) === undefined) {
      this._mobileTabsSimpleUser.push(item)
    }
    else {
      this._mobileTabsSimpleUser = this._mobileTabsSimpleUser.filter(i => i !== item);
    }
  }
}
