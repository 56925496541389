<div class="image_buttons">
  <div class="flex-1">
    <button (click)="onReset()" mat-flat-button class="button1 flex-1">
      <div class="inner_txt_btn">{{'Undo' | translate}}</div>
    </button>
    <div class="button-wrap">
      <button (click)="onButtonPressed('remove')" mat-flat-button [ngClass]="{active: mode === 'remove' }" class="button1">
        <div class="inner_icon_txt_btn"><mat-icon>delete</mat-icon>{{'REMOVE' | translate}}</div>
      </button>
    </div>
    <div class="button-wrap">
      <button (click)="onButtonPressed('add')" mat-flat-button [ngClass]="{active: mode === 'add' }" class="button1">
        <div class="inner_icon_txt_btn"><mat-icon>add</mat-icon>{{'ADD' | translate}}</div>
      </button>
    </div>
    <div class="button-wrap">
      <button (click)="onButtonPressed('replace')" mat-flat-button [ngClass]="{active: mode === 'replace' }"
        class="button1">
        <div class="inner_icon_txt_btn"><mat-icon>replay</mat-icon>{{'Replace' | translate}}</div>
      </button>
    </div>
  </div>
</div>