<mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex"
  animationDuration="0ms">

  <div *ngIf='companyType === "MC1"'>
    <ng-container *checkPermission="'Planograms'">
      <mat-tab label="{{'Planograms'|translate}}" aria-label="planograms">
        <planograms *ngIf='selectedTab=="planograms"'></planograms>
      </mat-tab>
    </ng-container>
  </div>

  <div *ngIf='companyType !== "MC1"'>
    <ng-container *checkPermission="'Home'">
      <mat-tab label="{{'Home'|translate}}" aria-label="home">
        <home *ngIf='selectedTab=="home"'></home>
      </mat-tab>
    </ng-container>
  </div>

  <ng-container *checkPermission="'Products'">
    <mat-tab label="{{'Products'|translate}}" aria-label="products">
      <products *ngIf='selectedTab=="products"'></products>
    </mat-tab>
  </ng-container>

  <ng-container *checkPermission="'Planograms'">
    <mat-tab label="{{'Equipments'|translate}}" aria-label="equipments">
      <equipments *ngIf='selectedTab=="equipments"'></equipments>
    </mat-tab>
  </ng-container>

  <div *ngIf='companyType !== "MC1"'>
    <ng-container *checkPermission="'Planograms'">
      <mat-tab label="{{'Planograms'|translate}}" aria-label="planograms">
        <planograms *ngIf='selectedTab=="planograms"'></planograms>
      </mat-tab>
    </ng-container>
  </div>

  <ng-container *checkPermission="'ImagesReview'">
    <mat-tab label="{{ 'UnapprovedImages' | translate }}" [disabled]="isUnapprovedDisabled" aria-label="unapproved">
      <ng-template mat-tab-label *ngIf="unapprovedBadgeTitle.length > 0">
        <span [matBadge]="unapprovedBadgeTitle" matBadgeOverlap="false">{{ 'UnapprovedImages' | translate }}</span>
      </ng-template>
      <unapproved (onImageCountChange)="onImageCountChange($event)" *ngIf='selectedTab=="unapproved"'></unapproved>
      <!-- <unapproved-mc1 (onImageCountChange)="onImageCountChange($event)" *ngIf='selectedTab=="unapproved"  && companyType === "MC1"'></unapproved-mc1> -->
    </mat-tab>
  </ng-container>

  <ng-container *checkPermission="'Dashboard'">
    <mat-tab label="{{'Dashboards'|translate}}" aria-label="dashboard">
      <dashboard *ngIf='selectedTab=="dashboard"'></dashboard>
    </mat-tab>
  </ng-container>

  <ng-container *checkPermission="'Reports'">
    <mat-tab label="{{'MobileReports'|translate}}" aria-label="mobileReports">
      <app-reports *ngIf='selectedTab=="mobileReports"'></app-reports>
    </mat-tab>
  </ng-container>

  <ng-container *checkPermission="'ChatCpg'">
    <mat-tab label="{{'ChatCPG'|translate}}" aria-label="chatcpg">
      <chat-cpg *ngIf='selectedTab=="chatcpg"'></chat-cpg>
    </mat-tab>
  </ng-container>

  <mat-tab disabled>
    <ng-template class="logout" mat-tab-label>
      <!-- <button *ngIf = "companyType === 'Standalone'" mat-icon-button matTooltip="{{ 'Home' | translate }}" aria-label="Home" (click)="goHome()">
        <mat-icon>home</mat-icon>
      </button> -->
      <logout *ngIf="companyType !== 'MC1'"></logout>
    </ng-template>
  </mat-tab>


</mat-tab-group>
<div class="footer" *checkPermission="'Train|Users|Models'">
  <a [routerLink]="['/admin']">
    <mat-icon color="primary">supervisor_account</mat-icon>
  </a>
  <span>{{version}}</span>
</div>