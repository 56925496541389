<div class="padding">
    <div class="header-wrapper">
        <div class="title">
            <p style="margin-bottom: 0;">{{'RegisteredOrganizations'|translate}}</p>
            <small>{{'listsRegisteredOrganizations' |translate}}</small>
        </div>
        <div>
            <button class="button-primary btn refresh-btn" matTooltip="{{ 'Refresh' | translate }}" (click)="refresh()">
                <mat-icon>refresh</mat-icon> {{ 'Refresh' | translate }}
            </button>
            <span class='padding-span'></span>
            <button class="button-primary btn add-btn" (click)="add()">
                <mat-icon>add</mat-icon> {{ 'AddNew' | translate }}
            </button>
        </div>
    </div>
    <organizations-list></organizations-list>
</div> 