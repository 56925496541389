import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AvailableScreensService } from './services/available-screens.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private availableScreensService: AvailableScreensService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.availableScreensService.$isAvailableScreensInit.pipe(
      filter(isInit => isInit !== undefined),
      map(isInit => {
        if (this.availableScreensService.isScreenAvailable(route.data.accessRole)) {
          return true
        }

        if (route.data.accessRole === 'Home') {
          return this.homeNavigation()
        }

        if (route.data.accessRole === 'Admin') {
          return this.adminNavigation()
        }
        
        this.router.navigate(['/'])
        return false
      }))
  }

  homeNavigation() {
    switch (true) {
      case this.availableScreensService.isScreenAvailable('Home'):
        this.router.navigate(['/home'])
        return true
      case this.availableScreensService.isScreenAvailable('Products'):
        this.router.navigate(['/products'])
        return true
      case this.availableScreensService.isScreenAvailable('Planograms'):
        this.router.navigate(['/planograms'])
        return true
      case this.availableScreensService.isScreenAvailable('ImagesReview'):
        this.router.navigate(['/unapproved'])
        return true
      case this.availableScreensService.isScreenAvailable('Dashboard'):
        this.router.navigate(['/dashboard'])
        return true
    }
    this.adminNavigation()
  }

  adminNavigation() {
    switch (true) {
      case this.availableScreensService.isScreenAvailable('Train'):
        this.router.navigate(['/admin/trainings'])
        break
      case this.availableScreensService.isScreenAvailable('Models'):
        this.router.navigate(['/admin/staticModels'])
        break
      case this.availableScreensService.isScreenAvailable('Users'):
        this.router.navigate(['/admin/userManagement'])
        break
      case this.availableScreensService.isScreenAvailable('Customer'):
        this.router.navigate(['/admin/customerManagement'])
        break
      default:
        return false
    }
    return true
  }

}
