<div class="FoldersInput">
    <input type="file" (change)="onFolderSelected(folderInput.files)" (click)="folderInput.value = null" multiple="true" webkitdirectory="webkitdirectory" [hidden]="true" id="multipleFfolders" accept=".gif, .jpg, .png" #folderInput>
    <button mat-flat-button matTooltip="{{ 'UploadImageCatalogFolders' | translate }}" color="primary"
    (click)="uploadImageFoldersBtnClick()" class="dialog-button-container">
        <mat-icon>drive_folder_upload</mat-icon>
        {{ 'Catalog' | translate }}
    </button>
</div>
<p-dialog [(visible)]="isShowDialog" [closable]="false">
    <div *ngIf="dialogType == 'showFolderStructure'">
        <p-header>
            <span class="warningIncorrectStructure">
                <mat-icon>warning</mat-icon>
                {{ 'IncorrectFolderStructure' | translate }}
            </span>
        </p-header>
        <p>
            '{{ incorrectPath }}' - {{ 'IncorrectPathStructure' | translate }}
            <br /> 
            {{ 'CorrectPathStructureFolders' | translate }}
        </p>
        <p style="display: flex; white-space: 10px;">
            <mat-icon>folder</mat-icon>
            {{ 'SelectedFolder' | translate }}/
            <mat-icon>folder</mat-icon>
            {{ 'ProductId' | translate }}/
            <mat-icon>folder</mat-icon>
            {{ 'CatalogName' | translate }}/
            <mat-icon>image</mat-icon>
            {{ 'Image' | translate }}
        </p>

        <p style="display: flex; white-space: 10px;">
            <mat-icon>folder</mat-icon>
            {{ 'SelectedFolder' | translate }}/
            <mat-icon>description</mat-icon>
            {{ sizeFileName }} -> {{ csvPattern }}
        </p>
        <div class="dialog-button-container">
            <button mat-flat-button color="primary" (click)="isShowDialog=false">{{ 'Confirm' | translate }}</button>
        </div>
    </div>
    <div *ngIf="dialogType == 'products'">
        <p-header>
            <span class="warningProductNotFound">
                <mat-icon>error</mat-icon>
                {{ 'ErrorProductNotFound' | translate }}
            </span>
        </p-header>
        <p>
            {{ 'ErrorProductNotFoundDescription' | translate }}
        </p>
        <mat-list>
            <ng-container *ngFor="let content of notExistingProducts">
                <mat-list-item>
                    <mat-icon>folder</mat-icon>
                    <span class="mat-list-content">{{content}}</span>
                </mat-list-item>
            </ng-container>
        </mat-list>
        <div class="dialog-button-container">
            <button mat-flat-button color="primary" (click)="isShowDialog=false">{{ 'Confirm' | translate }}</button>
        </div>
    </div>

    <div *ngIf="dialogType == 'csv'">
        <p-header>
            <span class="warningProductNotFound">
                <mat-icon>error</mat-icon>
                {{'ErrorProductNotFound'|translate}}
            </span>
        </p-header>
        <p>
            {{ 'ErrorProductNotFoundCSV' | translate }}
        </p>
        <mat-list>
            <ng-container *ngFor="let content of notExistingProductsCsv">
                <mat-list-item>
                    <mat-icon>attach_file</mat-icon>
                    <span class="mat-list-content">{{content}}</span>
                </mat-list-item>
            </ng-container>
        </mat-list>
        <div class="dialog-button-container">
            <button mat-flat-button color="primary" (click)="isShowDialog=false; this.notExistingProductsCsv=[]">{{ 'Confirm' | translate }}</button>
        </div>
    </div>

    <div *ngIf="dialogType == 'incorrectPhotosFormat'">
        <p-header>
            <span class="warningIncorrectStructure">
                <mat-icon>warning</mat-icon>
                {{ 'IncorrectFolderStructure' | translate }}
            </span>
        </p-header>
        <p>
            '{{ incorrectPath }}'
            <br /> 
            {{ 'UnsupportedFormat' | translate }} - {{ supportedFormats }}
        </p>
        <div class="dialog-button-container">
            <button mat-flat-button color="primary" (click)="isShowDialog=false">{{ 'Confirm' | translate }}</button>
        </div>
    </div>

    <div *ngIf="dialogType == 'catalog'">
        <p-header>
            <span style="color:green; margin:10px; font-weight: bold; display: flex;">
                <mat-icon>done</mat-icon>
                {{ 'Confirm' | translate }}
            </span>
        </p-header>
        <p>{{ 'UploadNextStepDescription' | translate }}</p>
        <mat-list>
            <ng-container *ngFor="let productFolder of imageFoldersToUpload | keyvalue">
                <mat-list-item style="margin-bottom:5px;">
                    <mat-icon>folder</mat-icon>
                    <span class="mat-list-content">{{productFolder.key}}</span>
                </mat-list-item>
                <mat-list style="margin-left:25px;">
                    <ng-container *ngFor="let v of productFolder.value | keyvalue">
                        <mat-list-item [ngClass]="!v.value.isCatalogExist ? 'green': null">
                            <mat-icon>folder</mat-icon>
                            <span class="mat-list-content">{{v.key}}</span>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </ng-container>
        </mat-list>
        <div class="dialog-button-container">
            <button mat-flat-button color="primary" (click)="uploadFoldersToSever()">{{ 'Approve' | translate }}</button>
            <button mat-flat-button (click)="isShowDialog=false">{{ 'CancelLower' | translate }}</button>
        </div>
    </div>
</p-dialog>