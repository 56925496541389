import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UserCustomerService } from 'src/app/admin/services/user-customer.service';
import { Dal } from 'src/app/dal/dal';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ListUsersForAdmin } from 'src/app/shared/api-structures/admin/user';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { UserManagementService } from 'src/app/shared/services/user-management.service';

type UserAssociationType = 'customer' | 'planogram'

@Component({
  selector: 'add-associated-user2',
  templateUrl: './add-associated-user.component.html',
  styleUrls: ['./add-associated-user.component.scss']
})
export class AddAssociatedUserToCustomerComponent extends AutoDestroy {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger
  @Input() filter: string = ''
  @Input() id: string
  @Input() idType: UserAssociationType
  @Output() close = new EventEmitter()
  @Output() onUserSelected = new EventEmitter<string>()

  userId: string
  users: ListUsersForAdmin.Item[] = []
  totalUsers: Number
  isLoadingUsers: boolean = false


  constructor(
    private snackbarService: SnackbarService,
    private userManagementService: UserManagementService,
    private dal: Dal, private userCustomerService: UserCustomerService) {
    super()
  }

  private async getUsers(pageSize: number, query: string) {
    this.isLoadingUsers = true
    try {
      const roles = ['Mobile', 'Simple Mobile']
      const usersRes = await this.userManagementService.loadUsers({ currentPage: 0, pageSize, query, roles }, true)
      this.users = usersRes.items
    } finally {
      this.isLoadingUsers = false
    }
  }

  async listUsersForAutocomplete(value: string) {
    if (value.length > 2) {
      this.getUsers(50, value.toLowerCase())
    } else {
      this.autocomplete.closePanel()
    }
  }

  async optionSelected(option) {
    this.userId = option.value.id
    this.onUserSelected.emit(option.value.email)
  }

  emitFilter(filter: string) {
    this.onUserSelected.emit(filter)
    this.autocomplete.closePanel()
  }

  displayFn(user: ListUsersForAdmin.Item): string {

    const email = user.email ? user.email : '';
    const name = user.name ? user.name : '';
    const phone = user.phone ? user.phone : '';

    return user && user.name ? name + '  ' + email + '  ' + phone + '  ' + user.role : this.filter
  }

  async addAssociatedUsersToCustomer() {
    switch (this.idType) {
      case 'customer': await this.userCustomerService.addUserCustomer({ customerId: this.id, userId: this.userId })
        this.snackbarService.openSnackBar(2000, "User-Customer association was successfully added")
        break
      case 'planogram': await this.dal.addAssociatedUsersToPlanogram({ planogramId: this.id, userId: this.userId })
        this.snackbarService.openSnackBar(2000, "User-Planogram association was successfully added")
    }
    this.filter = ''
    this.close.emit()
  }

  onCancelClick() {
    this.close.emit()
  }
}