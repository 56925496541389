import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { Area } from 'src/app/shared/api-structures/misc/area';
import { SelectTableComponent } from 'src/app/shared/components/select-table/select-table.component';

@Component({
  selector: 'app-area-customers',
  templateUrl: './area-customers.component.html',
  styleUrls: ['./area-customers.component.scss']
})
export class AreaCustomersComponent implements OnInit {
  @ViewChild('table') table: SelectTableComponent
  _area: Area = null
  @Input() set area(a: Area) {
    this._area = a
    this.table && this.table.fetch()
  }
  get area() { return this._area }

  constructor(private customersService: CustomerService) { }

  ngOnInit(): void {
  }

  fetchCustomers = async ({ page, pageSize, textFilter }) => {
    const res = await this.customersService.loadAreaCustomers({
      currentPage: page, pageSize, query: textFilter, area: this.area.id
    })
    return res
  }
}
