export const mockCsvData: string[][] =


    //customerCode,name,type,category,chain,territory,address,externalId,
    [
        ['customerCode', 'name', 'type', 'category', 'chain', 'territory', 'address', 'location', 'externalId'],
        ['CUST00112484', 'John Doe', 'bztesttype', 'Category 1', 'Chain A', 'Territory 1', '123 Main St', '25.6383, -100.0931', '12412'],
        ['CUST00112486', 'Alice Smith', 'bztesttype', 'Category 2', 'Chain B', 'Territory 2', '456 Elm St', '25.6383, -100.0931', '512512'],
        ['CUST00112488', 'Bob Johnson', 'bztesttype', 'Category 3', 'Chain C', 'Territory 3', '789 Oak St', '25.6383, -100.0931', '51125124']
    ];  



    