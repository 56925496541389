<form [formGroup]="priceTargetForm">
  <page-layout layout="full">
    <div class="row p-2 border shadow">
      <div class="col-sm-3 p-2">
        <div class="product-area px-4 py-3 border">
          <div class="">
            <h6>{{'ProductSelection' | translate}}</h6>
          </div>
          <div class=" py-3">
            <button pButton label="{{'ChangeProduct' | translate | uppercase}}" icon="fas fa-pen"
              class="p-button-secondary w-full" (click)="openProductPriceTarget()">
            </button>
          </div>

          <small class="text-muted text-small mb-2">{{('ProductImage' | translate | uppercase)}}</small>
          <div class="flex flex-column image mb-2">
            <img class="border" src="{{thumbnailUrl}}" (error)=" thumbnailUrl = utilitiesService.emptyImage">
          </div>

          <div class="description">
            <small class="text-muted text-small">{{'ProductName' | translate | uppercase}}</small><br>
            <span class="description-name">{{productName}}</span><br>
            <small class="text-muted text-small">{{'ProductId' | translate | uppercase}}</small><br>
            <span class="description-name">{{productId}}</span>
          </div>
        </div>
      </div>

      <div class="col-sm-9 p-2 ">
        <div class=" p-3 border">
          <div class="titl">
            <h6>{{'priceTarget' | translate}}</h6>
          </div>
          <div class="flex justify-content-between mandatories">
            <!-- <mat-form-field>
              <input matInput type="number" placeholder="{{'MinPrice' | translate}}" formControlName="minPrice">
            </mat-form-field> -->
            <mat-form-field>
              <input matInput type="number" placeholder="{{'Price' | translate}}" formControlName="price" required>
            </mat-form-field>
            <!-- <mat-form-field>
              <input matInput type="number" placeholder="{{'MaxPrice' | translate}}" formControlName="maxPrice">
            </mat-form-field> -->
          </div>


          <div class="optionals">
            <mat-accordion>
              <mat-expansion-panel class="border" [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'BasicRules' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <div style="display: flex;">
                  <mat-form-field appearance="">
                    <mat-label>{{'Date'|translate}}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate [placeholder]="'DateFrom'|translate" formControlName="dateFrom"
                        (dateChange)="dateRangeChange($event, 'dateFrom')">
                      <input matEndDate [placeholder]="'DateTo'|translate" formControlName="dateTo"
                        (dateChange)="dateRangeChange($event, 'dateTo')">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>

                  <mat-form-field class="px-3">
                    <mat-label>{{'CustomerArea'|translate}}</mat-label>
                    <mat-select formControlName="customerArea">
                      <mat-option *ngFor="let v of areaOptions" [value]="v.name">{{v.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{'CustomerTerritory'|translate}}</mat-label>
                    <mat-select formControlName="customerTerritory">
                      <mat-option *ngFor="let v of territoryOptions" [value]="v">{{v}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div><br>

                <div style="display: flex; flex-direction: row;">
                  <mat-form-field class="pr-3">
                    <mat-label>{{'CustomerCategory'|translate}}</mat-label>
                    <mat-select formControlName="customerCategory">
                      <mat-option *ngFor="let v of categoryOptions" [value]="v">{{v}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{'CustomerChain'|translate}}</mat-label>
                    <mat-select formControlName="customerChain">
                      <mat-option *ngFor="let v of chainOptions" [value]="v">{{v}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>

    <page-layout-footer>
      <div class="flex justify-content-between align-items-center">
        <div>
          <button pButton class="p-button-outlined" routerLink="/admin/price-target">
            <mat-icon>arrow_back</mat-icon>
            {{'Back'|translate}}
          </button>
        </div>

        <div>
          <button pButton [disabled]="!priceTargetForm.valid" (click)="save()">
            {{'Save&Continue'|translate}}
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </page-layout-footer>
  </page-layout>
</form>