import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-import-partial",
  templateUrl: "./import-partial.component.html",
  styleUrls: ["./import-partial.component.scss"],
})
export class ImportPartialComponent {
  @Output() onImport = new EventEmitter<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  fileData: File[] = [];

  onFileSelected(files: File[]) {
    this.fileData = files;
  }

  emitImportEvent(): void {
    this.onImport.emit(this.fileData);
  }

  download(data: any[][]): void {
    if (!data) return;
    const csvContent = this.convertToCsv(data);
    this.downloadCsv(csvContent, "template.csv");
  }

  private convertToCsv(data: any[][]): string {
    return data.map((row) => row.join(",")).join("\n");
  }

  private downloadCsv(csvContent: string, fileName: string): void {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    if ("msSaveBlob" in window.navigator) {
      (window.navigator as any).msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Your browser does not support downloading files.");
      }
    }
  }
}
