import { StandaloneNavbarService } from 'src/app/shared/services/standalone.navbar.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import jwt_decode from 'jwt-decode';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  showOldLogin = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memoryStorageService: MemoryStorageService,
    private authService: AuthService,
    private languageService: LanguageService,
    private configService: ConfigService) {
    if (this.configService.config.login_url) {
      window.location.replace(this.configService.config.login_url)
    } else {
      this.showOldLogin = true
    }
    this.languageService.setLanguage()
  }

  async successCallback(data: FirebaseUISignInSuccessWithAuthResult) {
    const token = await data.authResult.user.getIdToken()

    this.memoryStorageService.lastRequestTime = new Date()
    const tokenDecoded = jwt_decode(token)
    await this.authService.login(token, this.route.snapshot.queryParams['continue'])
    if (!this.route.snapshot.queryParams['continue']) {
      this.navigateToReturnUrl(tokenDecoded)
    }
  }

  private navigateToReturnUrl(tokenDecoded: unknown) {
    let returnUrl
    const role = tokenDecoded['role']
    if (role === 'super_admin') {
      returnUrl = '/super'
    }
    else if (role === 'organization_admin') {
      returnUrl = '/organizationAdmin'
    } else {
      returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    }

    this.router.navigateByUrl(returnUrl)
  }

  errorCallback(data: FirebaseUISignInFailure) {
    console.error('errorCallback', data);
  }

  uiShownCallback() {
    console.log('Login UI shown')
  }
}

