import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { AreaService } from 'src/app/admin/services/area.service';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { CustomerWithId, ListCustomersForAssociationRequest } from 'src/app/shared/api-structures/admin/customer';

@Component({
  selector: 'associated-customers',
  templateUrl: './associated-customers.component.html',
  styleUrls: ['./associated-customers.component.scss']
})
export class AssociatedCustomersComponent implements OnInit {

  readonly filterSeparatorKeysCodes: number[] = [ENTER, COMMA]
  @Input() data: CustomerWithId[] = []
  @Input() selected: Record<string, boolean> = {}
  @Output() onSearch = new EventEmitter<ListCustomersForAssociationRequest | void>()
  @Output() onToggleSelect = new EventEmitter<string>()
  @Output() onToggleSelectAll = new EventEmitter<void>()
  @Output() onConfirm = new EventEmitter<void>()
  @Output() onCancel = new EventEmitter<void>()
  searchFilter: string[] = []
  allSelected: boolean = false

  categories: string[] = []
  territories: string[] = []
  areas: string[] = []

  categoryOptions: string[] = []
  territoryOptions: string[] = []
  areaOptions: { name: string, value: string }[] = []

  constructor(public customerService: CustomerService, public areaService: AreaService) {
    this.getCustomerAttributes()
    this.getAreaOptions()
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.allSelected = this.data.length && this.data.every(o => this.selected[o.id])
  }

  emitSearchFilter() {
    if (this.searchFilter?.length === 0 &&
      this.categories?.length === 0 &&
      this.territories?.length === 0 &&
      this.areas?.length === 0) {
      this.onSearch.emit()
      return
    }

    const req = {
      currentPage: 0,
      pageSize: 300,
      nameOrId: this.searchFilter,
      categories: this.categories,
      territories: this.territories,
      areas: this.areas
    }

    this.onSearch.emit(req)
  }

  emitSelectAll() {
    this.onToggleSelectAll.emit()
  }

  emitConfirm() {
    this.onConfirm.emit()
  }

  emitCancel() {
    this.onCancel.emit()
  }

  async getCustomerAttributes() {
    const attr = await this.customerService.listCustomersAttributes()
    this.categoryOptions = attr.categories
    this.territoryOptions = attr.territories
  }

  async getAreaOptions() {
    const areas = await this.areaService.listAreas({ currentPage: 0, pageSize: 10000 })
    this.areaOptions = areas.items.map(area => ({ name: area.name, value: area.id }))
  }

  addFilter(event: MatChipInputEvent) {
    const input = event.input
    const value = event.value

    if ((value || '').trim()) {
      this.searchFilter.push(value.trim())
      this.emitSearchFilter()
    }

    // Reset the input value
    if (input) {
      input.value = ''
    }
  }

  removeFilter(filter: string) {
    const index = this.searchFilter.indexOf(filter)

    if (index >= 0) {
      this.searchFilter.splice(index, 1)
      this.emitSearchFilter()
    }
  }

}
