<div class="body-flex">
    <div class="body-flex-c1">
        <div class="search-header">
            <div class="search-header-title">
                <div>{{ 'AllClusters' | translate }} - ({{data?.length}} {{'Total' | translate}})</div>
            </div>
            <div class="search-header-input">
                <mat-form-field>
                    <input matInput [placeholder]="'Search' | translate"
                        (keyup.enter)="emitSearchFilter($event.target.value)" #MatAutocompleteTrigger>
                </mat-form-field>
            </div>
            <div class="search-header-select">
                <mat-checkbox color="primary" type="checkbox" [checked]="allSelected"
                    (change)="emitSelectAll()"></mat-checkbox> <span class="text1">{{'SelectAll' | translate}}
                    {{data?.length}} {{'Clusters' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="body-flex-c2">
        <div class="cards">
            <associated-clusters-card (onToggleSelect)="onToggleSelect.emit($event)" [id]="cluster.id"
                [selected]="!!selected[cluster.id]" [data]="cluster" [areas]="areas"
                *ngFor="let cluster of data"></associated-clusters-card>
        </div>
    </div>

</div>