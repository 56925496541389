import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ExtendedResult } from "../interfaces";
import { ComplianceReport, ComplianceReportEditedResult, ComplianceReportProduct, ListMobileReportsRequest, ListMobileReportsResponse, ListReportsFiltersResponse, UpdateMobileReportResultsRequest } from "../api-structures/misc/reports";
import { UtilitiesService } from "src/app/services/utilities.service";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { Dal } from "src/app/dal/dal";
import { LanguageService } from "./language.service";
import { ByIdRequest } from "../api-structures/common";
import { AppService } from "src/app/services/app.service";


@Injectable()
export class ComplianceReportService {
    constructor(
        private dal: Dal,
        private utils: UtilitiesService,
        private http: HttpClient,
        private api: ApiService,
        private languageService: LanguageService,
        private appService: AppService,
        private utilitiesService: UtilitiesService
    ) { }

    createCroppedImage(res: ExtendedResult, data: ComplianceReport) {
        const image = document.getElementById(data?.images[res.result.photoIndex].imagePath) as HTMLImageElement
        if (!image) {
            alert(`Could no crop image of ${res.result.name}, ${res.tempId}`)
            return
        }
        const pos = res.result.position
        const canvas = document.createElement('canvas')
        canvas.width = pos.right - pos.left
        canvas.height = pos.bottom - pos.top
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, pos.left, pos.top, pos.right - pos.left, pos.bottom - pos.top, 0, 0, pos.right - pos.left, pos.bottom - pos.top)
        return canvas.toDataURL('image/png')
    }

    async uploadImageToApprove(dataUrl: string, combinedId: string, planogramId: string) {
        const planogramImage = this.utils.dataUrlToFile(dataUrl, combinedId + ".png");
        const url = await this.dal.createSignedUrlFull({ ext: 'png', contentType: 'image/png', isMobileReport: true, id: combinedId, version: 2, planogramId })
        await this.http.request<HttpEvent<any>>('PUT', url.writeUrl, {
            body: planogramImage,
            headers: {
                'Content-Type': 'image/png'
            },
        }).toPromise()
        const imgUrl = url.readUrl.split('?')[0]
        return imgUrl
    }

    async uploadImageToCatalog(dataUrl: string, combinedId: string, planogramId: string) {
        const splited = this.splitCombinedId(combinedId) 
        const planogramImage = this.utils.dataUrlToFile(dataUrl, combinedId + ".png");
        const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(planogramImage, () => { }, true)
        const fileValid = await this.utilitiesService.isFileUrlExist(fileUrl)
        if (!fileValid) {
            throw new Error('File not exist')
        }
        const response = await this.dal.uploadImageCatalog(splited[0], splited[1], fileUrl)
        return response
    }

    async updateMobileReportResults(reportId: string, editedResults: ComplianceReportEditedResult[], products: ComplianceReportProduct[]) {
        const result = await this.api.miscRequest<UpdateMobileReportResultsRequest, { response: string }>({
            endpoint: '/updateMobileReportResults',
            body: { id: reportId, editedResults, products, editedBy: 'web' },
            errorMessage: this.languageService.translateSync('ErrorEditingMobileReport')
        })
        return result
    }

    async getComplianceReport(reportId: string) {
        const result = await this.api.miscRequest<ByIdRequest, ComplianceReport>({
            endpoint: '/getProcessedMobileReport',
            body: { id: reportId },
            errorMessage: this.languageService.translateSync('ErrorGettingComplianceReport')
        })
        return result
    }
    // big query 
    async listMobileReports(requestBody: ListMobileReportsRequest) {
        const result = await this.api.miscRequest<ListMobileReportsRequest, ListMobileReportsResponse>({
            endpoint: '/listMobileReports',
            body: requestBody,
            errorMessage: this.languageService.translateSync('ErrorListingReports')
        })
        return result
    }

    async listMobileReportsFilters() {
        const result = await this.api.miscRequest<{}, ListReportsFiltersResponse>({
            endpoint: '/listMobileReportsFilters',
            body: {},
            errorMessage: this.languageService.translateSync('ErrorListMobileReportsFilters')
        })
        return result
    }

    private replaceAt = (str: string, index: number, replacement: string) => {
        if (index < 0) return str
        return str.substring(0, index) + replacement + str.substring(index + 1)
    }

    splitCombinedId(id = '') { 
        return this.replaceAt(id, id.lastIndexOf('_'), '||').split('||')
    }
}