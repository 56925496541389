import { Component, OnInit } from '@angular/core';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import jwt_decode from 'jwt-decode';

import { UserManagementService } from 'src/app/shared/services/user-management.service';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';

import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-request-account-deletion',
  templateUrl: './request-account-deletion.component.html',
  styleUrls: ['./request-account-deletion.component.scss']
})
export class RequestAccountDeletionComponent implements OnInit {

  constructor(
    private memoryStorageService: MemoryStorageService, 
    private authService: AuthService, 
    languageService: LanguageService, 
    private userManagementService: UserManagementService) 
  {
    languageService.setLanguage()
  }

  showSuccessMessage = false

  ngOnInit(): void {
  }

  async successCallback(data: FirebaseUISignInSuccessWithAuthResult) {
    const token = await data.authResult.user.getIdToken()

    this.memoryStorageService.lastRequestTime = new Date()
    await this.authService.login(token)
    const tokenDecoded = jwt_decode(token)

    await this.userManagementService.logicalDeleteUser({
      id: tokenDecoded['user_id']
    })

    this.showSuccessMessage = true
  }

  async logout() {
    this.authService.logOut()
  }

  errorCallback(data: FirebaseUISignInFailure) {
    console.error('errorCallback', data);
  }

  uiShownCallback() {
    console.log('Login UI shown')
  }
}
