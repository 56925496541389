import { Injectable } from "@angular/core"
import { DataTransformer } from "../api-structures/dataTransformer";

@Injectable({ providedIn: 'root' })
export class DataTransformerService extends DataTransformer {
    constructor() {
        super()

        super.transformerOptions = {
            enableImplicitConversion: true
        }
    }
}
