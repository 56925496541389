<div *ngIf="step === 'UPLOAD_IMAGE'">
    <h2 mat-dialog-title>{{"ImportPlanogram" | translate}}</h2>
    <mat-dialog-content>
        <div class="explanation">
            <mat-icon>info</mat-icon>
            <span class="explanation-txt">{{"PlanogramCanBeImportedUsingEitherImagesOrPDFs" | translate}}</span>
        </div>
        <div class="importBox">
            <drag-and-drop-files #fileDrop [manualProgress]="true" [barLength]="350"
                accept="application/pdf, image/png, image/jpeg" isMultipleFiles="false"
                (onFileSelected)="uploadFile($event)"></drag-and-drop-files>
        </div>
        <div class="error-msg" *ngIf="error">*{{error | translate}}</div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="actions">
        <button mat-stroked-button color="primary" mat-dialog-close>{{"Cancel" | translate}}</button>
        <button mat-flat-button color="primary" cdkFocusInitial [disabled]="!imageUrl" (click)="continueToPreview()">
            {{"Continue" | translate}}
        </button>
        <button *ngIf="test" (click)="test()">test</button>
    </mat-dialog-actions>
</div>

<div *ngIf="step === 'PREVIEW_IMAGE'">
    <mat-dialog-content>
        <div class="imagePreview">
            <div class="flex-1 text-center">
                <div class="icon-box">
                    <mat-icon>
                        import_export
                    </mat-icon>
                </div>
                <h2>{{'ImportPlanogram' | translate}}</h2>
                <p>
                    {{'Are you sure you want to import this planogram?' | translate}} <br>
                    {{'You can change the product placements later.' | translate}}
                </p>
                <div class="button-wrap">
                    <button class="width-100" mat-flat-button color="primary" [disabled]="disableImportButton || error"
                        cdkFocusInitial (click)="detecPlanogramDetails()">
                        {{"Import" | translate}}
                    </button>
                </div>
                <div class="button-wrap">
                    <button class="width-100" mat-stroked-button color="primary" mat-dialog-close>
                        {{"Cancel" | translate}}
                    </button>
                </div>
                <div class="error-msg" *ngIf="error">{{error | translate}}</div>
            </div>
            <div class="flex-1 text-center">
                <div class="imageWrap">
                    <div class="imageLoading" [hidden]="!imageLoading"><i class="fa fa-spinner fa-spin"></i></div>
                    <img *ngIf="fileType !== 'pdf'" [hidden]="imageLoading" [src]="imageUrl" alt="Planogram Image"
                        (load)="imageLoading = false">
                    <!-- <embed *ngIf="fileType === 'pdf'" [src]="file" type="application/pdf" width="100%" height="100%"> -->
                    <object height="600" width="400" *ngIf="fileType === 'pdf'" [data]="file | safe" type="application/pdf"></object>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="step === 'SELECT_EQUIPMENT'">
    <mat-dialog-content>
        <div class="equip-selection">
            <div class="equip-top">
                <div class="icon-box">
                    <mat-icon>view_week</mat-icon>
                </div>
                <h2>{{'EquipmentSelection' | translate}}</h2>
                <p>{{'SelectTheExistingEquipmentOr' | translate}}</p>
            </div>
            <div class="equip-body">
                <div class="flex-1">
                    <div class="equip-options" (click)="continueToCreateEquipment()">
                        <mat-icon>auto_fix_normal</mat-icon>
                        <h3>{{'CreateNewEquipment' | translate}}</h3>
                        <p>{{'GenerateEquipmentBasedOnTheImportedPlanogram' | translate}}</p>
                    </div>
                </div>
                <div class="flex-1">
                    <div class="equip-options" [ngClass]="{'disabled':!matchingEquipment}"
                        [disabled]="!matchingEquipment" (click)="selectExistingEquipment()">
                        <mat-icon>library_add_check</mat-icon>
                        <h3>
                            {{'Select' | translate}}
                            {{matchingEquipment?.name || ('NoEquipmentFound' | translate)}}
                        </h3>
                        <p>{{'UseTheMatchingEquipmentAlreadyInYourSystem' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="step === 'CREATE_EQUIPMENT'">
    <mat-dialog-content>
        <div class="equip-create">
            <div class="equip-top">
                <div class="icon-box">
                    <mat-icon>view_week</mat-icon>
                </div>
                <h2>{{'EquipmentCreation' | translate}}</h2>
                <p>{{'PleaseEnterTheNameOfYourNewEquipment' | translate}}</p>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="{{'EquipmentName' | translate}}" [(ngModel)]="equipmentName">
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="actions">
        <button mat-stroked-button color="primary" mat-dialog-close>{{"Cancel" | translate}}</button>
        <button (click)="confirmCreateEquipment()" [disabled]="!equipmentName" mat-flat-button color="primary"
            cdkFocusInitial>
            {{"Confirm" | translate}}
        </button>
    </mat-dialog-actions>
</div>