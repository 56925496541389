<div class="main">
  <div class="terr-container shadow">
    <div class="flex">
      <div class="flex-2">
        <app-areas-list *ngIf="mode === 'view'" [areas]="areas" [selectedArea]="selectedArea"
          (select)="selectArea($event);" (add)="addArea()">
        </app-areas-list>
        <div *ngIf="(mode === 'add' || mode === 'edit') || mode === 'customersList'">
          <button mat-button color="primary" (click)="stopEditing()"><mat-icon>keyboard_arrow_left</mat-icon></button>
          <mat-tab-group (selectedTabChange)="changeTab($event)">
            <mat-tab label="{{'EditArea' | translate}}">
              <app-add-edit-area *ngIf="mode === 'add' || mode === 'edit'" [editedPolygonPath]="newPath" [mode]="mode"
              [selectedArea]="selectedArea" (submit)="onAddEditSubmit($event)" (delete)="submitDelete()" (cancel)="stopEditing()"
              (polygonChange)="setEditPolygon($event)"></app-add-edit-area>
            </mat-tab>
            <mat-tab *ngIf="mode !== 'add'" label="{{'CustomersList' | translate}}">
              <app-area-customers *ngIf="mode === 'customersList'" [area]="selectedArea"></app-area-customers>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="flex-3">
        <div class="map-container">
          <app-map #map [polygons]="polygons"></app-map>
        </div>
      </div>
    </div>
  </div>
</div>