import { trigger, transition, style, animate } from '@angular/animations';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { SortDirection } from 'src/app/shared/api-structures/common';
import { ListImagesToApprove, ListImagestoApproveFilter, SourceType } from 'src/app/shared/api-structures/misc/toApprove';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Dal } from '../../../dal/dal';
import { SnackbarService } from '../../../services/snackbar.service';
import { ConfirmDialogComponent } from '../../general/confirm-dialog/confirm-dialog.component';
import { FilterUnapprovedImageDialog } from './filter-unapproved-image-dialog';
import { isEqual } from 'underscore';
import { OpenImageDialogComponent } from 'src/app/shared/components/open-image-dialog/open-image-dialog.component';

interface ListImagesToApproveEx extends ListImagesToApprove.Item { isChecked?: boolean, catalog?: Catalog }

class Catalog {
  id: string
  name: string
}

export enum RecognitionMode {
  MACHINE_LEARNING = 'machine_learning',
  USER_FEEDBACK = 'user_feedback',
}

export enum RecognitionReviewImages {
  ALL_IMAGES = '0',
  JUST_EDITED_IMAGES = '1'
}
@Component({
  selector: 'unapproved-images',
  templateUrl: './unapproved-images.component.html',
  styleUrls: ['./unapproved-images.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('150ms', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('150ms', style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ]
    )
  ],
})
export class UnapprovedDialogComponent implements OnInit {

  isAllImagesSelected: boolean = false
  isSomeImagesSelected: boolean = false
  defaultImageCatalog: Catalog
  images: ListImagesToApproveEx[] = []
  productName: string
  productId: string
  PruductImageUrl: string
  imageCatalogId: string
  listCatalogs: Catalog[]
  nextPageToken?: string
  pageSize = 50
  percentGridFormat: string
  filterBy: SourceType
  textFilterOrderImages: string
  textFilterImages: string
  shouldShowFilterButton: string = "0"
  sortDirection: SortDirection = 'desc'
  RecognitionModeEnum = RecognitionMode
  RecognitionReviewImagesEnum = RecognitionReviewImages
  disableButton: boolean = false
  isSelectCatalogDisabled: boolean = false
  isLoadingImages: boolean = true
  selectedImagesCount: number = 0
  isInfiniteScrollDisabled: boolean = false
  isShowTitle: boolean = true
  scrollTimer: ReturnType<typeof setTimeout>
  isHaveMoreImageCatalogs: boolean = false
  areEnoughImages: boolean = true
  amount: number
  maxImagesInCatalog: number
  newFilters: ListImagestoApproveFilter = {
    dateStart: null,
    dateEnd: null,
    userIds: [],
    origin: null,
    methodRecognition: null,
  }

  constructor(private utilitiesService: UtilitiesService,
    public dialog: MatDialog, private router: Router,
    private dal: Dal,
    private snackbarService: SnackbarService,
    private languageService: LanguageService,
    private memoryStorageService: MemoryStorageService,
  ) { }

  ngOnInit() {
    let urlParams = this.utilitiesService.getUrlParams(window.location.href)
    if (!urlParams['product_id'] && !urlParams['imageCatalog_id']) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('InvalidUrl'))
      this.router.navigateByUrl('/')
    }

    this.percentGridFormat = this.definePercentGridFormat(250)//this.data.imagesAvaiableCount
    this.shouldShowFilterButton = this.memoryStorageService.syncOnlyEditedImages || RecognitionReviewImages.ALL_IMAGES;

    if (this.shouldShowFilterButton == RecognitionReviewImages.JUST_EDITED_IMAGES) {
      this.filterBy = this.RecognitionModeEnum.USER_FEEDBACK
    }
    this.productId = urlParams['product_id']
    this.productName = decodeURIComponent(urlParams['product_name']) || ''
    this.PruductImageUrl = decodeURIComponent(urlParams['product_thumbnail']) || ''
    this.imageCatalogId = urlParams['imageCatalog_id']
    this.isHaveMoreImageCatalogs = urlParams['is_have_more_image_catalogs'] === 'true'
    this.initLoading()
  }

  @HostListener('window:scroll', ['$event'])
  scrollEvent() {
    const scrollY = window.scrollY
    if (scrollY < 97) {
      this.isShowTitle = true
    } else {
      this.isShowTitle = false
    }
  }

  async initLoading() {
    const res = await this.dal.countProductCatalogImages(this.productId, this.imageCatalogId)
    const companySettings = await this.dal.getCompanySettings()
    this.maxImagesInCatalog = companySettings.maxNumberImagesInCatalog ?? 1000
    this.amount = res.countImages
    this.areEnoughImages = res.countImages >= this.maxImagesInCatalog
    await this.getCatalogs(this.productId)
    await this.LoadImages()
  }

  returnUnapprovedCatalogs() {
    if (this.isHaveMoreImageCatalogs) {
      this.router.navigate(['/unapproved-catalogs'],
        {
          queryParams: {
            product_id: this.productId,
            product_name: this.productName,
            check_catalogs: true
          }
        })
      return
    }
    this.router.navigate(['/unapproved'])
  }

  async nextPage() {
    if (this.isLoadingImages === true) {
      return
    }
    this.isLoadingImages = true
    await this.LoadImages(this.nextPageToken)
    this.isAllImagesSelected = false
    this.someComplete()
  }

  filterChecked() {
    return this.images.filter(i => i.isChecked)
  }

  async approve() {
    let images
    const { length: totalImagesCount } = this.images

    if (this.isAllImagesSelected === true) {
      images = this.images
    } else {
      images = this.filterChecked()
    }

    const { length: imagesCount } = images
    this.selectedImagesCount = imagesCount

    if (images.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `${this.selectedImagesCount} ${this.languageService.translateSync('ImagesWillBeApproved')}, ${this.languageService.translateSync('ConfirmMessage')}` } })
      dialogRef.afterClosed().subscribe(async confirmResult => {
        if (!confirmResult) {
          return
        }
        await this.ApproveImages(images)
        this.isSomeImagesSelected = false
        this.amount += images.length

        const returnToListing = imagesCount === totalImagesCount

        if (returnToListing)
          this.returnUnapprovedCatalogs()
      })
    }
  }

  async unapprove() {
    let images
    const { length: totalImagesCount } = this.images

    if (this.isSomeImagesSelected === false) {
      images = this.images
    } else {
      images = this.filterChecked()
    }

    const { length: imagesCount } = images
    this.selectedImagesCount = imagesCount
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `${this.selectedImagesCount} ${this.languageService.translateSync('ImagesWillBeRejected')}, ${this.languageService.translateSync('ConfirmMessage')}` } })
    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }

      await this.UnapproveImages(images)
      this.isSomeImagesSelected = false

      const returnToListing = imagesCount === totalImagesCount
      
      if (returnToListing)
        this.returnUnapprovedCatalogs()
    })
  }

  async restartImagesList() {
    delete this.images
    this.images = []
    this.nextPageToken = undefined
    this.isAllImagesSelected = false
    this.LoadImages()
  }

  async LoadImages(pageToken?: string) {
    const res = await this.dal.searchImagesToApprove({
      productId: this.productId,
      imageCatalogId: this.imageCatalogId,
      pageSize: this.pageSize,
      filterBy: this.filterBy,
      newFilters: this.newFilters,
      sortDirection: this.sortDirection,
      pageToken
    })

    this.images = [...this.images, ...res.items.map(x => ({ ...x, catalog: this.defaultImageCatalog }))]
    this.nextPageToken = res.nextPageToken

    // if (this.images.length === 0) {
    //   this.router.navigateByUrl('/unapproved')
    // }
    if (this.nextPageToken === undefined) {
      this.isInfiniteScrollDisabled = true
      return
    }
    this.isLoadingImages = false
  }

  async getCatalogs(productId: string) {
    const product = await this.dal.getProduct(productId)
    this.listCatalogs = product.imageCatalogs.map(c => { return { id: c.id, name: c.name } })
    this.isSelectCatalogDisabled = this.listCatalogs.length < 2
    this.defaultImageCatalog = this.listCatalogs.find(x => x.id === this.imageCatalogId)
  }

  updateAllComplete() {
    const isAllRangeChecked = this.images.every(t => t.isChecked === true)
    this.isAllImagesSelected = isAllRangeChecked
  }

  someComplete(): boolean {
    if (this.images == null) {
      return false
    }

    const someCompleteLength = this.images.filter(t => t.isChecked).length
    this.isSomeImagesSelected = someCompleteLength > 0 && !this.isAllImagesSelected
    return this.isSomeImagesSelected
  }

  onCardClick(image: ListImagesToApproveEx) {
    image.isChecked = !image.isChecked
    this.updateAllComplete()
    this.someComplete()
  }

  setAll(completed: boolean) {
    this.isAllImagesSelected = completed

    if (this.images == null) {
      this.someComplete()
      return
    }
    this.images.forEach(im => im.isChecked = completed)
    this.someComplete()
  }

  async showLatestImages() {
    this.sortDirection = 'desc'
    this.textFilterOrderImages = this.languageService.translateSync('MoreRecent')
    this.nextPageToken = undefined
    this.restartImagesList()
  }

  async showOlderImages() {
    this.sortDirection = 'asc'
    this.textFilterOrderImages = this.languageService.translateSync('MoreOlder')
    this.nextPageToken = undefined
    this.restartImagesList()
  }

  definePercentGridFormat(totalPaged: number) {
    return this.percentGridFormat = '250px'
  }

  onFilterImagesList(textFilter: string, filterBy?: RecognitionMode) {
    this.filterBy = filterBy || undefined
    this.textFilterImages = this.languageService.translateSync(textFilter)
    this.nextPageToken = undefined
    this.restartImagesList()
  }

  expandCape(imageUrl) {
    this.dialog.open(OpenImageDialogComponent, {
      data: { imageUrl }
    })
  }

  openFilters() {
    const filter: ListImagestoApproveFilter = {
      dateStart: null,
      dateEnd: null,
      userIds: [],
      origin: null,
      methodRecognition: null,
    }
    const dialogRef = this.dialog.open(FilterUnapprovedImageDialog, { data: filter });
    dialogRef.afterClosed().subscribe(res => {
      if (res && !isEqual(this.newFilters, res)) {
        this.newFilters = res
        this.images = []
        this.LoadImages()
      }
    });
  }

  openImage(image: ListImagesToApproveEx) {
    const imageUrl = image.thumbnailUrl
    if (!imageUrl) {
      return
    }
    this.onCardClick(image)
    this.dialog.open(OpenImageDialogComponent, {
      data: { imageUrl }
    })
  }

  private async ApproveImages(images: ListImagesToApproveEx[]) {
    this.disableButton = true
    const images1 = images
    await this.dal.approveImages(images1.map(x => ({ imageId: x.id, catalogId: x.catalog.id })))
    this.snackbarService.openSnackBar(2000, `${this.selectedImagesCount} ${this.languageService.translateSync('ImagesApprovedSuccessfully')}`)
    this.deleteSelectedImages()
    this.disableButton = false
  }


  private async UnapproveImages(images: ListImagesToApproveEx[]) {
    this.disableButton = true
    await this.dal.unapproveImages(images.map(x => x.id))
    this.snackbarService.openSnackBar(2000, `${this.selectedImagesCount} ${this.languageService.translateSync('ImagesUnapprovedSuccessfully')}`)
    this.deleteSelectedImages()
    this.disableButton = false
  }

  private deleteSelectedImages() {
    this.images = this.images.filter(v => !v.isChecked)
    if (this.images.length < this.pageSize) {
      this.restartImagesList()
    }
  }

  isMatTooltipDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }
}