<div class="header">
    <div [hidden]="!stats.length">
      <div class="stats">
        <div class="flex">
          <div *ngFor="let stat of stats" class="flex-1">
            <div class="mat-elevation-z8 bordered">
              <div class="stats-header">
                # {{ "of" | translate }} {{ stat.name | capitalize | translate }}
                <span class="stats-count">{{ stat.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filters">
      <div class="mat-elevation-z8 bordered filterCard">
        <div class="center">
          <h6>Filters</h6>
          <mat-icon [@iconFlip]="iconFlipState" (click)="toggleAnimations()"
            >expand_less</mat-icon
          >
        </div>
        <hr style="margin-top: 1px" />
        <form [@cardShrink]="cardShrinkState" [formGroup]="filtersForm">
          <div class="flex" style="gap: 8px; padding: 8px">
            <div class="flex-1">
              <mat-form-field>
                <mat-label>{{ "LastUpdate" | translate }}</mat-label>
                <mat-date-range-input
                  [rangePicker]="picker3"
                  (click)="picker3.open()"
                >
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="startDateLastUpdate"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="endDateLastUpdate"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker3"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker3></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="flex-1">
              <mat-form-field>
                <mat-label>{{ "CreatedAt" | translate }}</mat-label>
                <mat-date-range-input
                  [rangePicker]="picker1"
                  (click)="picker1.open()"
                >
                  <input matStartDate formControlName="startDateCreatedAt" />
                  <input matEndDate formControlName="endDateCreatedAt" />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker1></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="flex-1">
              <mat-form-field>
                <mat-label>{{ "SyncedAt" | translate }}</mat-label>
                <mat-date-range-input
                  [rangePicker]="picker2"
                  (click)="picker2.open()"
                >
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="startDateSyncedAt"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="endDateSyncedAt"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker2></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
          <div class="flex" style="gap: 8px; padding: 8px">
            <div
              class="flex-1"
              *ngFor="
                let selectName of [
                  'territories',
                  'customerCategories',
                  'customerNames',
                  'userNames',
                  'planogramNames',
                  'audit'
                ]
              "
            >
              <mat-form-field>
                <mat-label>{{
                  selectMap[selectName]?.translate | translate
                }}</mat-label>
                <mat-select
                  [formControlName]="selectMap[selectName]?.controlName"
                  multiple
                >
                  <div class="selectSearch">
                    <input
                      placeholder="{{ 'Search' | translate }}"
                      (keydown.space)="$event.stopPropagation()"
                      matInput
                      #input
                      focus
                    />
                  </div>
                  <mat-option
                    value="select-all"
                    (click)="selectAllSwitch(selectName, input)"
                    [selected]="selectAll[selectName]"
                    >{{ "SelectAll" | translate }}</mat-option
                  >
                  <div
                    *ngFor="
                      let v of selectMap[selectName]?.data
                        | textFilter : input.value
                    "
                  >
                    <mat-option [value]="v">
                      <ng-container *ngIf="v.includes(';'); else singleLine">                        
                          <div class="first-part">{{ v.split(';')[0] }}</div>
                          <div class="second-part">{{ v.split(';')[1] }}</div>                        
                      </ng-container>
                      <ng-template #singleLine>
                        {{ v }}
                      </ng-template>
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1" style="text-align: right">
              <button
                mat-stroked-button
                style="margin-right: 5px"
                (click)="resetFilters()"
              >
                {{ "ResetFilters" | translate }}
              </button>
              <button
                mat-flat-button
                color="primary"
                (click)="listMobileReports()"
              >
                {{ "ApplyFilters" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <generic-table
    #table
    (exportEvent)="export()"
    [withHeader]="false"
    [displayedColumns]="displayedColumns"
    [columnsSettings]="columnsSettings"
    [data]="data"
    [hasNext]="hasNext"
    [currentPage]="currentPage"
    (simplePaginateEvent)="paginate($event)"
    (sortEvent)="onSort($event)"
  >
    <ng-template #templateRef let-row="row" let-col="col">
      <a
        *ngIf="col.column === 'linkToReport'"
        target="_blank"
        href="{{ row[col.column] }}"
        title="{{ row[col.column] }}"
      >
        <mat-icon fontIcon="open_in_new"></mat-icon>
      </a>
      <span *ngIf="col.column === 'audit'">
        <mat-icon
          style="color: #9d9d9d"
          fontIcon="{{ row[col.column] ? 'done' : 'close' }}"
        ></mat-icon>
      </span>
    </ng-template>
  </generic-table>
  