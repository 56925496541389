import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListUsersForAdmin } from 'src/app/shared/api-structures/admin/user';

@Component({
  selector: 'associated-users-card',
  templateUrl: './associated-users-card.component.html',
  styleUrls: ['./associated-users-card.component.scss']
})
export class AssociatedUsersCardComponent implements OnInit {

  // @Input() data:
  @Input() selected = false
  @Input() id: string
  @Input() data: ListUsersForAdmin.Item
  @Output() onToggleSelect = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
  }

  emitToggleSelect() {
    this.onToggleSelect.emit(this.id)
  }

}
