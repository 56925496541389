import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() {}

  getCurrentTheme() {
    document.documentElement.style.getPropertyValue('--primary-color')
  }

  setTheme(primary: string, secondary: string, text: string) {
    document.documentElement.style.setProperty('--primary-color', primary);
    document.documentElement.style.setProperty('--secondary-color', secondary);
    document.documentElement.style.setProperty('--text-color', text);
  }
}
