import { Component, ContentChild, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LanguageService } from '../../services/language.service';

export type ColumnSettings = {
  column: string,
  label: string,
  type: string,
  link?: string,
  icon?: string,
}

@Component({
  selector: 'generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})

export class GenericTableComponent<T> implements OnInit {

  constructor(private matDialog: MatDialog, private languageService: LanguageService) {
    // this.setupHeightMutationObserver()
  }

  dataSource: MatTableDataSource<T>
  columns: ColumnSettings[] = []
  moment = moment
  resizeObserver: ResizeObserver
  @ViewChild("tablewrap") tableWrap;
  @ContentChild(TemplateRef) customCol: TemplateRef<any>

  @Input() displayedColumns: string[] = []
  @Input() withHeader = true
  @Input() title: string
  @Input() subTitle: string
  @Input() columnsSettings: ColumnSettings[] = []
  @Input() set data(value: T[]) {
    this.dataSource = new MatTableDataSource<T>(value)
  }
  @Input() hasNext = false
  @Input() currentPage: 0
  @Input() deleteDialogTitle = this.languageService.translateSync('DeleteItem')
  @Input() deleteDialogMessage = this.languageService.translateSync('AreYouSureYouWantToDeleteThisItem?')
  @Input() distanceFromBottom = 70
  @Input() importTooltip = ''
  @Input() showModalFilters = false
  @Input() totalItens = 100
  @Input() importDialogData: any

  @Output() addEvent: EventEmitter<void> = new EventEmitter<void>()
  @Output() usersEvent: EventEmitter<T> = new EventEmitter<T>()
  @Output() editEvent: EventEmitter<T> = new EventEmitter<T>()
  @Output() deleteEvent: EventEmitter<T> = new EventEmitter<T>()
  @Output() exportEvent: EventEmitter<void> = new EventEmitter<void>()
  @Output() importEvent: EventEmitter<void> = new EventEmitter<void>()
  @Output() refreshEvent: EventEmitter<void> = new EventEmitter<void>()
  @Output() filterEvent: EventEmitter<string> = new EventEmitter<string>()
  @Output() sortEvent: EventEmitter<Sort> = new EventEmitter<Sort>()
  @Output() simplePaginateEvent: EventEmitter<number> = new EventEmitter<number>()
  @Output() modalFiltersEvent: EventEmitter<T> = new EventEmitter<T>()
  @Output() paginateEvent: EventEmitter<{ pageIndex: number, pageSize: number }> = new EventEmitter<{ pageIndex: number, pageSize: number }>()

  showExport: boolean
  showImport: boolean
  showRefresh: boolean
  freeTextFilter: boolean

  @HostListener('window:resize', ['$event'])

  ngOnInit() {
    this.showExport = this.exportEvent.observers.length > 0
    this.showImport = this.importEvent.observers.length > 0
    this.showRefresh = this.refreshEvent.observers.length > 0
    this.freeTextFilter = this.filterEvent.observers.length > 0
    this.setColumns(this.displayedColumns)
  }

  ngAfterViewInit() {

    this.resizeObserver = new ResizeObserver(() => {
     this.calcMaxHeight()
    });

    this.resizeObserver.observe(this.tableWrap.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect()
  }

  calcMaxHeight() {
    const top = this.tableWrap.nativeElement.getBoundingClientRect().top
    this.tableWrap.nativeElement.style['max-height'] = `calc(100vh - ${top + this.distanceFromBottom}px)`
  }

  setColumns(displayedColumns) {
    this.columns = displayedColumns.map((column: string) => {
      let columnSettings = this.columnsSettings.find((columnSettings: ColumnSettings) => columnSettings.column === column)
      if (!columnSettings) {
        switch (column) {
          case 'edit':
            columnSettings = { column, label: '', type: 'edit' }
            break
          case 'users':
            columnSettings = { column, label: '', type: 'users' }
            break
          case 'delete':
            columnSettings = { column, label: '', type: 'delete' }
            break
          case 'lastUpdate':
          case 'updatedAt':
          case 'createdAt':
            columnSettings = { column, label: column, type: 'date' }
            break
          default:
            columnSettings = { column, label: column, type: 'text' }
            break
        }
      }
      return columnSettings
    })
  }

  onAdd() {
    this.addEvent.emit()
  }

  onFilter(value: string) {
    this.filterEvent.emit(value)
  }

  onModalFilters(value: T) {
    this.modalFiltersEvent.emit(value)
  }

  onSort(value: Sort) {
    this.sortEvent.emit(value)
  }

  onUsers(value: T) {
    this.usersEvent.emit(value)
  }

  onEdit(value: T) {
    this.editEvent.emit(value)
  }

  onSimplePaginate(page: number) {
    this.simplePaginateEvent.emit(page)
  }

  onPaginate(page: { pageIndex: number, pageSize: number }) {
    this.paginateEvent.emit(page)
  }

  onDelete(row) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: this.deleteDialogTitle,
        message: this.deleteDialogMessage,
      }
    })
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.deleteEvent.emit(row)
      }
    })
  }

  onExport() {
    this.exportEvent.emit()
  }

  onImport(event: any) {
    this.importEvent.emit(event)
  }

  onRefresh() {
    this.refreshEvent.emit()
  }
}
