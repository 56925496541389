import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ImportPartialComponent } from "./import-partial/import-partial.component";
import { Dal } from "src/app/dal/dal";
import { AppService } from "src/app/services/app.service";
import { PriceTargetService } from "src/app/admin/services/price-target.service";

@Component({
  selector: "generic-import",
  templateUrl: "./generic-import.component.html",
  styleUrls: ["./generic-import.component.scss"],
})
export class GenericImportComponent {
  isImportVisible = false;
  constructor(
    public dialog: MatDialog,
    private dal: Dal,
    private appService: AppService,
    private priceTargetService: PriceTargetService
  ) {}

  @Output() onImport = new EventEmitter();
  @Input() data: any;
  @Input() hidden: boolean;
  @Input() isRound: boolean = false;

  openDialog(): void {
    const dialogRef: MatDialogRef<ImportPartialComponent> = this.dialog.open(
      ImportPartialComponent,
      {
        data: this.data,
      }
    );

    dialogRef.componentInstance?.onImport?.subscribe(async (data) => {
      const url = await this.appService.uploadFileToStorageAndCompressIfNeedded(
        data[0],
        undefined,
        true
      );
      if (this.importData(this.data.id, url)) this.onImport.emit(data);
    });

    dialogRef.afterClosed().subscribe((result) => {
      dialogRef.componentInstance?.onImport?.unsubscribe();
    });
  }

  async importData(importType: string, url: string) {

    const importMethods = {
        products: async (url: string) => {
            await this.dal.importProducts(url);
        },
        priceTargets: async (url: string) => {
            await this.priceTargetService.importPriceTargets(url);
        }
    };

    const importMethod = importMethods[importType];

    if (importMethod) {
        await importMethod(url);
    } else {
        return;
    }
}
}
