<h2 class="mb-2">{{ "Filters" | translate }}</h2>
<p class="filter-text">
  {{ "PleaseSelectTheFiltersYouNeedToViewTheCustomersInYourSelection" | translate }}
</p>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <mat-label>{{'LastUpdate'|translate}}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate [placeholder]="'DateFrom'|translate" formControlName="dateFrom"
        (dateChange)="dateRangeChange($event, 'dateFrom')">
      <input matEndDate [placeholder]="'DateTo'|translate" formControlName="dateTo"
        (dateChange)="dateRangeChange($event, 'dateTo')">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <div class="button-row">
    <button mat-stroked-button color="primary" (click)="onClear()">
      {{ "Clear" | translate | uppercase }}
    </button>
    <button
      mat-flat-button
      type="submit"
      color="primary"
      [disabled]="!form.valid"
      style="float: right;"
    >
      {{ "ApplyFilters" | translate | uppercase }}
    </button>
  </div>
</form>