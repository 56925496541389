<style>

</style>

<div class="card flex justify-content-center">
    <p-dialog [header]="title | translate" [(visible)]="visible" [modal]="true" [dismissableMask]="false"
        [maximizable]="maximizable" [draggable]="false" (onHide)="onCloseDialog()">
        <div class="pb-2">
            <ng-content></ng-content>
        </div>

        <ng-template pTemplate="footer">
            <ng-container *ngIf="footerRef">
                <ng-container *ngTemplateOutlet="footerRef"></ng-container>
            </ng-container>
        </ng-template>
    </p-dialog>
</div>