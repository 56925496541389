import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StaticModelsService } from 'src/app/admin/services/static-models.service';
import { AddStaticModelRequest } from 'src/app/shared/api-structures/admin/staticModel';

@Component({
  selector: 'admin-add-edit',
  templateUrl: './add-edit.component.html',
})
export class AddEditComponent {
  name = ''
  modelUrl = ''
  labelsUrl = ''
  priceLabelUrl = ''
  priceModelUrl = ''
  shelfLabelUrl = ''
  shelfModelUrl = ''
  ocrModelUrl = ''
  popModelUrl = ''
  popLabelUrl = ''

  constructor(private dialog: MatDialog, private staticModelsService: StaticModelsService) { }

  async save() {
    const d = new AddStaticModelRequest()
    d.name = this.name
    d.labelsUrl = this.labelsUrl
    d.modelUrl = this.modelUrl

    d.priceLabelUrl = this.priceLabelUrl
    d.priceModelUrl = this.priceModelUrl
    d.shelfLabelUrl = this.shelfLabelUrl
    d.shelfModelUrl = this.shelfModelUrl
    d.ocrModelUrl = this.ocrModelUrl
    d.popModelUrl = this.popModelUrl
    d.popLabelUrl = this.popLabelUrl
    

    await this.staticModelsService.addStaticModel(d)
    this.dialog.closeAll()
  }
}
