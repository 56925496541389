import { CommonModule } from "@angular/common"
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core"

enum PageLayoutType {
  Full = 'full',
  Centered = 'centered'
}

@Component({
  selector: "page-layout",
  standalone: true,
  templateUrl: "./page-layout.html",
  styleUrls: ["./page-layout.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
  providers: [],
})
export class PageLayoutComponent implements OnInit {
  layoutType = PageLayoutType

  @Input() layout: string = PageLayoutType.Centered
  @Input() title: string = ""
  @Input() description?: string = ""


  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterContentInit() { }
}
