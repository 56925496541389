export enum PLANOGRAM_STATUS {
  PUBLISHED = "published",
  DRAFT = "draft",
  DEPRECATED = "deprecated",
  INACTIVE = 'inactive'
}

export const SELECTED_SHELF_COLOR = "#0000FF"
export const UNSELECTED_SHELF_COLOR = "#000000"

export enum ROUTER_LINK { planograms = 'PlanogramIRE', productCatalogs = 'ProductsImageCatalog', unapproved = 'ImagesReview', training = 'TrainingAI', allTabs = 'Marketing', dashboard = 'Dashboards' }

export const CANCEL_REQUEST_ERROR = "Request cancelled"
