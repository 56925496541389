import { Expose, Type } from 'class-transformer'
import { IsArray, IsString, ValidateNested } from 'class-validator'

export class SimilatiryGroupAddingRequest {
    @Expose() @IsString() name!: string
}

export class SimilatiryGroupAddingResponse {
    @Expose() @IsString() id!: string
    @Expose() @IsString() name!: string
}

export class SimilatiryItemGroupListingWithIdsResponse {
    @Expose() @IsString() id!: string
    @Expose() @IsString() name!: string
}

export class SimilatiryGroupListingWithIdsResponse {
    @Expose() @Type(() => SimilatiryItemGroupListingWithIdsResponse) @ValidateNested() @IsArray() items!: SimilatiryItemGroupListingWithIdsResponse[]
}