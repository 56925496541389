<div class="padding">
    <div class="header-wrapper">
        <div class="title">
            <p style="margin-bottom: 0;">{{ 'Users' | translate }}</p>
            <small>{{ 'displaysUserDetails' | translate }}</small>
        </div>
        <div>
            <button class="button-primary btn refresh-btn" matTooltip="{{ 'Refresh' | translate }}" (click)="refresh()">
                <mat-icon>refresh</mat-icon> {{ 'Refresh' | translate }}
            </button>
            <span class='padding-span'></span>
            <button class="button-primary btn add-btn" (click)="add()">
                <mat-icon>add</mat-icon> {{ 'AddNew' | translate }}
            </button>
        </div>
    </div>
    <div class="mat-elevation-z8">
        <super-users-list (getUsersRequest)="getUsers(undefined)"></super-users-list>
        <div class="paginator">
            <button class="btn export" (click)="export()">
                Export <mat-icon class="mat-icon-export">arrow_downward</mat-icon>
            </button>
            <div class="paginator-display">
                <div class="paginator-controls">
                    <span class="paginator-text showing">showing:</span>
                    <mat-form-field class="mat-form-field-size">
                        <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChanged()">
                            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="paginator-text results">of {{totalUsers}} results</span>
                </div>
                <div class="button-wrapper">
                    <mat-icon (click)="getPreviousPage()" class="navigate-icons">navigate_before</mat-icon>
                    <mat-icon (click)="getNextPage()" class="navigate-icons">navigate_next</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>