<button mat-flat-button color="primary" (click)="isThereTrainingRunning()">{{'Train'|translate}}</button>
<p-dialog [(visible)]="isShowDialog">
    <p-header>{{ 'Warning' | translate }}</p-header>
    <p>{{'startTrainingCaution'|translate}}</p>
    <div class="dialog-button-container">
        <button mat-flat-button color="primary" (click)="isShowDialog=false">{{'Cancel' |translate}}</button>
        <button mat-flat-button (click)="startTraining()">{{'StartTraining' | translate}}</button>
    </div>
</p-dialog>
<p-dialog [(visible)]="isShowDialogHaveTrainingInProcess">
    <p-header>{{ 'Warning' | translate }}</p-header>
    <p>{{'AlreadyHaveProcessingTraining'|translate}}</p>
    <div class="dialog-button-container">
        <button mat-flat-button color="primary" (click)="isShowDialogHaveTrainingInProcess=false">{{'Confirm' |translate}}</button>
    </div>
</p-dialog>