import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ListAreasRequest, Area } from 'src/app/shared/api-structures/misc/area';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { MapComponent, PolyData } from 'src/app/shared/components/map/map.component';
import { LanguageService } from 'src/app/shared/services/language.service';
import { AreaService } from '../../services/area.service';
import { AreaFields } from './add-edit-area/add-edit-area.component';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss']
})
export class AreasComponent extends AutoDestroy implements OnInit {
  @ViewChild('map') mapElement: MapComponent
  mode: 'view' | 'add' | 'edit' | 'editCustomers' | 'customersList' = 'view';
  selectedArea: Area | null = null;
  areas: Area[] = []
  polygons: PolyData[] = []
  newPath: google.maps.LatLngLiteral[] = []
  newPathStr = ''
  query: ListAreasRequest = {
    currentPage: 0,
    pageSize: 1000
  }

  constructor(
    private areaService: AreaService,
    private languageService: LanguageService,
    private snackBar: SnackbarService,
    private cdRef: ChangeDetectorRef,
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(this.areaService.areas$.subscribe((data) => {
      this.onAreasChange(data)
    }))
    this.areaService.listAreas(this.query)
  }

  changeTab(event) {
    this.editArea()
    if (event.index === 1) {
      this.showCustomersList()
    }
  }

  setPolygons(areas: Area[]) {
    this.polygons = areas.map(area => {
      return this.areaService.createPolygonData(area, (polygon: PolyData) => {
        this.selectAreaById(polygon.id)
      }, area.id === this.selectedArea?.id ? 'green' : area.color ?? '#0000FF')
    })
  }

  onAreasChange(areas: Area[]) {
    this.areas = areas
    this.setPolygons(areas)
  }

  selectArea(area: Area) {
    if (this.mode !== 'view' && this.mode !== 'customersList') return

    if (this.selectedArea?.id === area.id) {
      this.stopEditing()
      return
    }

    this.selectedArea = area
    const pol = this.polygons.find(polygon => polygon.id === area.id)
    this.mapElement.centerPolygon(pol.options.paths as google.maps.LatLngLiteral[])
    this.setPolygons(this.areas)
    this.editArea()
  }

  selectAreaById(id: string) {
    const area = this.areas.find(area => area.id === id)
    if (area) this.selectArea(area)
  }

  setEditPolygon(path) {
    this.newPath = path
    this.mapElement.showEditPolygon(path, (newPaths) => {
      this.newPath = newPaths
      this.cdRef.detectChanges()
    })
  }

  showAreasList() {
    this.stopEditing(false)
    this.mode = 'view'
  }

  showCustomersList() {
    this.stopEditing(false)
    this.mode = 'customersList'
    const polygon = this.polygons.find(polygon => polygon.id === this.selectedArea?.id)
    const polyPath = polygon.options.paths as google.maps.LatLngLiteral[]
    this.mapElement.centerPolygon(polyPath)
  }

  addArea() {
    this.stopEditing()
    this.mode = 'add'
    const paths = [{ "lat": 40.6, "lng": -74.3 }, { "lat": 41, "lng": -74.3 }, { "lat": 41, "lng": -73.8 }, { "lat": 40.6, "lng": -73.8 }]
    this.setEditPolygon(paths)
    this.mapElement.centerPolygon(paths)
  }

  editArea() {
    this.mode = 'edit'
    const polygon = this.polygons.find(polygon => polygon.id === this.selectedArea?.id)
    const polyPath = polygon.options.paths as google.maps.LatLngLiteral[]
    this.mapElement.centerPolygon(polyPath)
    this.setEditPolygon(polyPath)
    polygon.options.paths = [{ lat: 0, lng: 0 }, { lat: 0, lng: 0 }, { lat: 0, lng: 0 }]
    this.forcePolygonsUpdate()
  }

  editAreaCustomers() {
    this.mode = 'editCustomers'
  }

  stopEditing(clearSelected = true) {
    if (clearSelected) { this.selectedArea = null }
    this.newPath = []
    this.mode = 'view'
    this.mapElement.hideEditPolygon()
    this.setPolygons(this.areas)
  }

  async submitEdit(data: AreaFields) {
    try {
      this.selectedArea = { ...this.selectedArea, ...data }
      const ti = this.areas.findIndex(t => t.id === this.selectedArea.id)
      this.areas[ti] = this.selectedArea
      await this.areaService.updateArea({
        id: this.selectedArea.id,
        data: {
          name: data.name,
          regions: data.regions,
          polygon: this.newPath,
          tags: data.tags,
          color: data.color
        }
      })
      this.snackBar.openSnackBar(3000, this.languageService.translateSync('Success'))
    } catch (err) {
      this.snackBar.openSnackBar(3000, this.languageService.translateSync('CouldNotUpdateArea'))
    }
  }

  async submitAdd(data: AreaFields) {
    if (!data.name) {
      // error
      return
    }
    try {
      await this.areaService.addArea({
        name: data.name,
        regions: data.regions,
        polygon: this.newPath,
        tags: data.tags,
        color: data.color
      })
      this.snackBar.openSnackBar(3000, this.languageService.translateSync('Success'))
    } catch (err) {
      this.snackBar.openSnackBar(3000, this.languageService.translateSync('CouldNotAddArea'))
    }

  }

  async submitDelete() {
    try {
      await this.areaService.deleteArea(this.selectedArea.id)
      await this.areaService.listAreas(this.query)
      this.snackBar.openSnackBar(3000, this.languageService.translateSync('Success'))
      this.stopEditing()
    } catch (err) {
      this.snackBar.openSnackBar(3000, this.languageService.translateSync('CouldNotDeleteArea'))
    }
  }

  // async submitEditAreaCustomers(data: EditAreaCustomersFields) {
  //   const areaId = this.selectedArea.id
  //   this.stopEditing()
  //   if (!Object.values(data).some(d => d)) {
  //     return
  //   }
  //   const countRes = await this.areaService.countCustomersInArea(areaId)

  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     width: '25vw',
  //     data: {
  //       title: `${this.languageService.translateSync('ConfirmEditAreaCustomersTitle')}`,
  //       message: `${this.languageService.translateSync('ThisWillEffect')} '${countRes}' ${this.languageService.translateSync('Customers')}, ${this.languageService.translateSync('Proceed')}?`
  //     }
  //   })
  //   dialogRef.afterClosed().subscribe(async confirmResult => {
  //     if (!confirmResult) {
  //       return
  //     }
  //     this.areaService.editAreaCustomers({ id: areaId, data })
  //   })
  // }

  async onAddEditSubmit(data: AreaFields) {
    if (this.mode === 'edit') await this.submitEdit(data)
    else if (this.mode === 'add') await this.submitAdd(data)
    await this.areaService.listAreas(this.query)
    this.stopEditing()
  }

  forcePolygonsUpdate() {
    this.polygons = JSON.parse(JSON.stringify(this.polygons))
  }
}