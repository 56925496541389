import { Expose, Type } from 'class-transformer'
import { IsArray, IsString, ValidateNested } from 'class-validator'

class PlanogramSettingsValueResponse {
    @Expose() @IsString()
    key!: string;
    @Expose() @IsString()
    description!: string;
}

export class PlanogramSettingsGettingResponse {
    @Expose() @Type(() => PlanogramSettingsValueResponse) @ValidateNested() @IsArray()
    annotations!: PlanogramSettingsValueResponse[];
    @Expose() @Type(() => PlanogramSettingsValueResponse) @ValidateNested() @IsArray()
    kpisFull!: PlanogramSettingsValueResponse[];
    @Expose() @Type(() => PlanogramSettingsValueResponse) @ValidateNested() @IsArray()
    kpisSimple!: PlanogramSettingsValueResponse[];
    @Expose() @Type(() => PlanogramSettingsValueResponse) @ValidateNested() @IsArray()
    zones!: PlanogramSettingsValueResponse[];
}

export class PlanogramSettingsGettingRequest {
}