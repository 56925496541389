import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsIn, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ByPageRequest, CustomFields, SortDirection, SortDirectionArray } from '../common'
import { CustomFieldsType, DateType, } from '../customDecorators'
import { ListCustomersResponse } from './customer'

export class BaseCluster {
	@Expose() @IsString() name!: string
	@Expose() @Type(() => String) @IsArray() @IsOptional() customerTypes?: string[]
	@Expose() @Type(() => String) @IsArray() @IsOptional() customerCategories?: string[]
	@Expose() @Type(() => String) @IsArray() @IsOptional() customerChains?: string[]
	@Expose() @Type(() => String) @IsArray() regions?: string[]
	@Expose() @IsObject() @CustomFieldsType() customFields?: CustomFields
	@Expose() @Type(() => String) @IsArray() @IsOptional() additionalCustomers?: string[]
	@Expose() @Type(() => String) @IsArray() @IsOptional() removedCustomers?: string[]
	@Expose() @IsNumber() @IsOptional() customersCount?: number
	@Expose() @DateType() @IsDate() @IsOptional() lastUpdate?: Date
	@Expose() @DateType() @IsDate() @IsOptional() createdAt?: Date
}

export class ClusterWithId extends BaseCluster {
	@Expose() @IsString() id!: string
}

export class ListClustersRequest extends ByPageRequest {
	@Expose() @IsIn(SortDirectionArray) @IsOptional() sortDirection?: SortDirection
	@Expose() @IsString() @IsOptional() sortBy?: string
	@Expose() @IsString() @IsOptional() query?: string
	@Expose() @IsString() @IsOptional() planogramId?: string
}

export class ListClustersResponse {
	@Expose() @IsBoolean() hasNext!: boolean
	@Expose() @Type(() => ClusterWithId) @IsArray() items!: ClusterWithId[]
}

export class AssociatePlanogramRequest {
	@Expose() @IsString() clusterId!: string
	@Expose() @IsString() planogramId!: string
}

export class DissociatePlanogramRequest {
	@Expose() @IsString() clusterId!: string
	@Expose() @IsString() planogramId!: string
}

export class ListClusterCustomersRequest extends ByPageRequest {
	@Expose() @Type(() => BaseCluster) @ValidateNested() cluster!: BaseCluster
	@Expose() @IsString() @IsOptional() orderBy?: string
	@Expose() @IsString() @IsOptional() sortDirection?: SortDirection
	@Expose() @IsString() @IsOptional() query?: string
}

export class ListClusterCustomersResponse extends ListCustomersResponse { }

export class CountClusterCustomersRequest {
	@Expose() @Type(() => BaseCluster) @ValidateNested() cluster!: BaseCluster
	@Expose() @IsString() @IsOptional() query?: string
}