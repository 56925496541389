import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'open-image-dialog',
  template: `
  <mat-card class="image-card mat-elevation-z0">
    <div class="image" [ngStyle]="{'background-image': 'url('+imagUrl+')'}"></div>
  </mat-card>
`,
  styles: [`
  .image {
    width: 40vh;
    height: 40vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`]
})

export class OpenImageDialogComponent {
  imagUrl: string
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.imagUrl = data.imageUrl
  }
}