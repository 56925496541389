import { Component } from '@angular/core';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})

export class LogoutComponent {
  constructor(private auth: AuthService, private memoryStorageService: MemoryStorageService) { }

  async logout() {
    this.memoryStorageService.token = ""
    await this.auth.logOut()
  }
}