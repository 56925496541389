import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AreaService } from 'src/app/admin/services/area.service';
import { Area, ListAreasRequest } from 'src/app/shared/api-structures/misc/area';
import { MapComponent } from 'src/app/shared/components/map/map.component';

@Component({
  selector: 'app-areas-list',
  templateUrl: './areas-list.component.html',
  styleUrls: ['./areas-list.component.scss']
})
export class AreasListComponent implements OnInit {
  readonly filterSeparatorKeysCodes: number[] = [ENTER, COMMA]
  @ViewChild('map') mapElement: MapComponent
  @Input() areas: Area[]
  @Input() selectedArea: Area | null
  @Output() select = new EventEmitter<Area>()
  @Output() add = new EventEmitter<null>()
  newPath: google.maps.LatLngLiteral[] = []
  isEditing: boolean = false
  query: ListAreasRequest = {
    currentPage: 0,
    pageSize: 25,
    textFilter: ''
  }
  constructor(private areaService: AreaService) { }

  ngOnInit(): void {
  }

  onSelect(area: Area) {
    this.isEditing = true
    this.selectedArea = area
    this.select.emit(area)
  }
  
  addFilter(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value
    this.query.textFilter = value
    this.areaService.listAreas(this.query)
  }

  addArea() {
    this.add.emit()
  }

  setEditPolygon(path) {
    this.newPath = path
    this.mapElement.showEditPolygon(path, (newPaths) => {
      this.newPath = newPaths
    })
  }

  goBack() {
    this.isEditing = false
  }
}
