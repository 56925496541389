import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { LanguageService } from "./language.service";


@Injectable()
export class CustomPaginatorService extends MatPaginatorIntl {

    ofTranslated = 'of'

    constructor(languageService: LanguageService) {
        super()

        this.itemsPerPageLabel = languageService.translateSync('PaginationItemsPerPage')
        this.nextPageLabel = languageService.translateSync('PaginationNextPage')
        this.previousPageLabel = languageService.translateSync('PaginationPreviousPage')
        this.firstPageLabel = languageService.translateSync('PaginationFirstPage')
        this.lastPageLabel = languageService.translateSync('PaginationLastPage')
        this.ofTranslated = languageService.translateSync('PaginationOf')
    }

    getRangeLabel = (
        page: number,
        pageSize: number,
        length: number,
    ) => {

        if (length === 0 || pageSize === 0) {
            return `0 ${this.ofTranslated} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.ofTranslated} ${length}`;
    }
}