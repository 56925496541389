import { Component, EventEmitter, Output, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { IMPORT_ICON } from "./icons";

type Progress = { percent: number; status: boolean };
@Component({
  selector: "drag-and-drop-files",
  templateUrl: "./drag-and-drop-files.component.html",
  styleUrls: ["./drag-and-drop-files.component.scss"],
})
export class DragAndDropFilesComponent {
  @Output() onFileSelected = new EventEmitter<File[]>();

  @Input() accept: string;
  @Input() isMultipleFiles: boolean;
  @Input() template?: any;
  @Input() barLength: number = 487;
  @Input() manualProgress: boolean = false;

  files: File[] = [];
  progress: Progress[] = [];

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral(
      "import-icon",
      sanitizer.bypassSecurityTrustHtml(IMPORT_ICON)
    );
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  uploadCsvFileBtnClick() {
    if (this.files.length > 0) return
    (document.querySelector("#fileDropRef") as HTMLElement).click();
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  async prepareFilesList(files: FileList) {
    this.files = Array.from(files);
    this.progress = [];
    for (let i = 0; i < this.files.length; i++) {
      this.progress.push({ status: false, percent: 0 });
      await this.processFile(this.files[i], i);
    }

    this.onFileSelected.emit(this.files);
  }

  async processFile(file: File, index: number) {
    if (this.manualProgress) return
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        this.animateProgress(index);
        this.progress[index].status = this.templateIsValid(this.template, file);
        resolve();
      }, 500);
    });
  }

  sizeConvertToMb(fileSizeInBytes: number): string {
    const megabyteSize = fileSizeInBytes / (1024 * 1024);
    return megabyteSize.toFixed(2) + " MB";
  }

  removeFile(event: Event, id: any) {
    event.stopPropagation();
    if (id >= 0 && id < this.files.length) {
      this.files.splice(id, 1);
    }
  }

  animateProgress(index: number) {
    let i = 0;
    const intervalId = setInterval(() => {
      if (i <= 100) {
        this.progress[index].percent = i;
        i++;
      } else {
        clearInterval(intervalId);
      }
    }, 10);
  }

  templateIsValid(template: any, file: any): boolean {
    // TODO evaluete file over template
    // once we got the templates for evaluation, templateIsValid can be finished, at the moment i'm sending template false in both use cases
    // atm it will always return true, assuming that the imported file is withim the pattern
    return true
  }
}
