// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsIn, IsNumber, IsOptional, IsString, Max, Min, ValidateNested } from 'class-validator'
import { DateType } from '../customDecorators'

export namespace ListTrainings {

    export class History {
        @Expose() @IsString() state!: string
        @Expose() @DateType() @IsDate() lastUpdate!: Date
    }

    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsBoolean() isActive!: boolean
        @Expose() @IsBoolean() canActivate!: boolean
        @Expose() @IsOptional() @IsString() modelId?: string
        @Expose() @IsIn(['ok', 'error', 'in-progress']) status!: 'ok' | 'error' | 'in-progress'
        @Expose() @IsNumber() @Min(0) @Max(100) progress!: number
        @Expose() @IsNumber() totalProducts!: number
        @Expose() @DateType() @IsDate() lastUpdate!: Date
        @Expose() @IsOptional() @IsNumber() trainCost?: number
        @Expose() @IsOptional() @IsString() error?: string
        @Expose() @Type(() => History) @ValidateNested() @IsArray() history!: History[]
    }

    export class Response {
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() arr!: Item[]
    }
}

export class ContractTrainingResponse {
    @Expose() @IsNumber() @IsOptional() currentMonth?: number
    @Expose() @IsNumber() @IsOptional() currentYear?: number
    @Expose() @IsNumber() @IsOptional() trainingCount?: number
    @Expose() @IsNumber() @IsOptional() trainingQuota?: number
    @Expose() @IsNumber() @IsOptional() singleTrainingSold?: number
}