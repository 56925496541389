<form [formGroup]="copyPlanoframForm">
    <mat-form-field style="width:100%">
    <input matInput [(ngModel)]="name" placeholder="{{'NewName'|translate}}" formControlName="nameCtrl" required>
    <mat-error *ngIf="copyPlanoframForm.controls['nameCtrl'].hasError('required')">
        {{'new-planogram.RequiredField'|translate}}
              </mat-error>
              <mat-error *ngIf="copyPlanoframForm.controls['nameCtrl'].hasError('invalidName') && !copyPlanoframForm.controls['nameCtrl'].hasError('required')">
                {{'new-planogram.InvalidName'|translate}}
              </mat-error>
              <mat-error *ngIf="copyPlanoframForm.controls['nameCtrl'].hasError('unique')&&!copyPlanoframForm.controls['nameCtrl'].hasError('required')">
                {{'new-planogram.NameAllreadyExist'|translate}}
                  </mat-error>
  </mat-form-field> 
    </form>
    <button mat-button class="blue-btn" (click)="close()" style="float:right">{{'DuplicatePlanogram'|translate}}</button>