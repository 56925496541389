<side-nav [(isShowNav)]="isShowNav" [userInfo]="false" [menuItems]="menuItems">
</side-nav>
<mat-sidenav-container class="sidenav-container" style="background-color: transparent">
    <mat-sidenav-content>
        <mat-toolbar style="display: flex; justify-content: space-between; align-content: center;" color="primary">
            <div>
                <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="isShowNav=true">
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <img src="/assets/images/white-logo.png" alt="logo" />
            </div>
            <p style="margin-bottom: 0;">{{"Image Recognition Admin Panel" | uppercase }}</p>
            <button
                style="background-color: #FFFFFF14; color: #E0E9F5; display: flex; justify-content: center; align-items: center; gap: 4px"
                class="btn btn-sm btn-white" [matMenuTriggerFor]="userMenu">
                <mat-icon>admin_panel_settings</mat-icon>
                <a>{{ userInfo?.name ?? 'noName' }}</a>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu" yPosition="below">
                <button (click)="logout()" mat-menu-item>
                    <i class="pi pi-sign-out" style="color: 'rgba(214, 64, 64, 1)'"></i>
                    {{ 'Logout' | translate }}
                </button>
            </mat-menu>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content> 
</mat-sidenav-container>
<div style="position: fixed; bottom: 2%; right: 2%; z-index: 999;">
    <button *ngIf="showButton" class="btn change-color" (click)="onChangeColor()">change primary color</button>
</div>
