import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Dal } from 'src/app/dal/dal';
import { CountImagesToApproveResponse, SourceType } from 'src/app/shared/api-structures/misc/toApprove';
import { AvailableScreensService } from 'src/app/shared/services/available-screens.service';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import packageInfo from '../../../../package.json';
import { RecognitionMode, RecognitionReviewImages } from '../unapproved/unapproved-images/unapproved-images.component';
import { MemoryStorageService } from './../../services/memory-storage.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnDestroy, AfterViewInit {

  public version: string = packageInfo.version
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup

  companyType = getCompanyType()
  imagesCount: number
  disabled: boolean
  refreshIntervalId: ReturnType<typeof setInterval>
  map = {
    home: 0,
    planograms: 1,
    products: 2,
    unapproved: 3,
  }
  unapprovedBadgeTitle: string = ''
  isUnapprovedDisabled: boolean

  selectedTab: 'unapproved' | 'products' | 'planograms' | 'home' | string

  constructor(private activatedRoute: ActivatedRoute, private location: Location,
    private dal: Dal, public dialog: MatDialog, private memoryStorageService: MemoryStorageService,
    private availableScreensService: AvailableScreensService, private router: Router) { }
  selectedIndex = 0
  filterBy: SourceType

  ngAfterViewInitMc1() {
    if (this.activatedRoute.snapshot.url.length > 0) {
      this.selectedIndex = this.map[this.activatedRoute.snapshot.url[0].path]
    }

    if (this.selectedIndex !== 2) {
      const shouldFilterOnlyEditedImages = this.memoryStorageService.syncOnlyEditedImages || RecognitionReviewImages.ALL_IMAGES;

      if (shouldFilterOnlyEditedImages == RecognitionReviewImages.JUST_EDITED_IMAGES) {
        this.filterBy = RecognitionMode.USER_FEEDBACK;
      }

      this.loadUnapprovedBadgeContent()
    }
    this.refreshIntervalId = setInterval(() => this.loadUnapprovedBadgeContent(), 30 * 60 * 1000)
  }

  ngAfterViewInit() {

    Promise.resolve().then(() => {
      if (this.activatedRoute.snapshot.url.length > 0) {
        const toSelect = this.activatedRoute.snapshot.url[0].path
        const tab = this.tabGroup._tabs.find(v => v.ariaLabel === toSelect)
        if (tab === undefined && getCompanyType() === 'MC1') {
          this.selectedTab = 'planograms'
        } else if (tab !== undefined) {
          this.tabGroup.selectedIndex = tab.position
          this.selectedTab = tab.ariaLabel
        } else {
          this.selectedTab = this.tabGroup._tabs.first.ariaLabel
        }
      }
    })


    if (this.selectedTab !== 'unapproved') {
      const shouldFilterOnlyEditedImages = this.memoryStorageService.syncOnlyEditedImages || RecognitionReviewImages.ALL_IMAGES;

      if (shouldFilterOnlyEditedImages == RecognitionReviewImages.JUST_EDITED_IMAGES) {
        this.filterBy = 'user_feedback'
      }

      this.loadUnapprovedBadgeContent()
    }
    this.refreshIntervalId = setInterval(() => this.loadUnapprovedBadgeContent(), 30 * 60 * 1000)
  }

  async loadUnapprovedBadgeContent() {
    if (this.availableScreensService.isScreenAvailable('ImagesReview')) {
      const res = await this.dal.countImagesToApprove(this.filterBy, true)
      this.updateUnapprovedBadgeTitle(res)
    }
  }

  goHome() {
    this.router.navigateByUrl('/home')
  }

  tabChanged(matTabChangeEvent: MatTabChangeEvent) {
    this.selectedTab = matTabChangeEvent.tab.ariaLabel
    this.selectedIndex = matTabChangeEvent.index
    const url = matTabChangeEvent.tab.ariaLabel
    const indexOfQueryParams = this.location.path().indexOf('?')
    const queryParams = indexOfQueryParams == -1 ? '' : this.location.path().slice(indexOfQueryParams)
    this.location.replaceState(`/${url}${queryParams}`)
  }

  onImageChange(event) {
    this.imagesCount = event;
  }

  onImageCountChange(event: CountImagesToApproveResponse) {
    this.updateUnapprovedBadgeTitle(event)
  }

  ngOnDestroy() {
    clearInterval(this.refreshIntervalId)
  }

  private updateUnapprovedBadgeTitle(res: CountImagesToApproveResponse) {
    this.unapprovedBadgeTitle = `${res.count}${res.isHaveMore ? '+' : ''}`
    this.isUnapprovedDisabled = res.count === 0
  }
}
