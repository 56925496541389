<div class="mat-elevation-z8">
  <div class="pre-table-header">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup.enter)="applyFilter()" [(ngModel)]="filter" #input>
    </mat-form-field>
  </div>
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row"><span style="display:none">{{row.id}}</span>{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Email'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Phone'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.phone}}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Role'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{displayUserRole(row.role)|translate}}</td>
    </ng-container>

    <ng-container matColumnDef="associatedUsers">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button  matTooltip="{{'AssociatedUsers'|translate}}" mat-button style="min-width: 1%" (click)="popupAssociatedCustomers(row)">
          <mat-icon style="color:#919191;transform: scale(0.8);">supervisor_account</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="editIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Edit'|translate}}" mat-button  (click)="updateUser(row.id, row.email, row.name, row.role, row.phone)">
          <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
          <button matTooltip="{{'Delete'|translate}}" mat-button (click)="ondeleteClick(row.id, row.name, row.email, row.phone)">
              <mat-icon style="color:#919191;transform: scale(0.9);">delete_outline</mat-icon>
          </button>
      </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
  </table>

  <mat-paginator [length]="totalUsers" [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
  </mat-paginator>

  <p-dialog [(visible)]="isShowConfirmDeleteDialog">
    <p-header>{{ 'Warning' | translate }}</p-header>
    <p>{{'User' | translate}}:
      <br/>
      <span>{{userToDelete.email}}</span>
      <br/>
      <span>{{'DeleteMessage' | translate}}</span>
    </p>
    <p>{{'DeleteCaution' | translate}}</p>
    <div class="dialog-button-container">
        <button mat-flat-button color="primary" (click)="isShowConfirmDeleteDialog=false">{{'Cancel' |translate}}</button>
        <button mat-flat-button (click)="deleteUser()">{{'Delete' | translate}}</button>
    </div>
</p-dialog>
</div>