<mat-form-field>
  <input matInput [placeholder]="'Search' | translate" [matAutocomplete]="auto"
    (keyup)="listOptionsForAutocomplete($event.target.value)" (keyup.enter)="emitFilter($event.target.value)"
    [(value)]="filter" #MatAutocompleteTrigger>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)" [displayWith]="displayFn">

    <mat-option *ngFor="let option of options" [value]="option">
      {{option.displayName || option.value}}
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix *ngIf="isLoadingOptions">
    <i class="fa fa-spinner fa-spin"></i>
  </mat-icon>
</mat-form-field>

<div class="dialog-button-container">
  <span style="padding-right: 15px;">
    <button mat-flat-button color="primary" cdkFocusInitial (click)="addAndClose()">{{'ASSOCIATE'|translate}}</button>
  </span>
</div>