import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dal } from 'src/app/dal/dal';
import { SearchProductsRequestFiltersPlanogram } from 'src/app/shared/api-structures/misc/product';

@Component({
  selector: 'products-filter-options',
  template: `
  <h2>{{'FilterOptions'|translate}}</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill">
      <mat-label>{{'Category'|translate}}</mat-label>
      <mat-select formControlName="categories" multiple>
        <mat-option *ngFor="let category of categoriesList" value="{{category.name}}">{{category.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{'Competitor'|translate}}</mat-label>
      <mat-select formControlName="isCompetitor">
        <mat-option>{{'All'|translate}}</mat-option>
        <mat-option value=true>{{'True'|translate}}</mat-option>
        <mat-option value=false>{{'False'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button type="reset">{{'ClearFilters'|translate}}</button>
    <button mat-button type="submit" color="primary" [disabled]="!form.valid" style="float: right;">{{'Apply'|translate}}</button>
  </form>
  `,
  styles: [`
    .slider-div {
      width: 100%;
    }
  `]
})
export class ProductsFilterOptions {
    categoriesList
  constructor(public dialogRef: MatDialogRef<ProductsFilterOptions>,
    private dal: Dal,
    @Inject(MAT_DIALOG_DATA) private data: SearchProductsRequestFiltersPlanogram
  ) {
    this.getCategories()
  }

  async getCategories() {
    this.categoriesList = await this.dal.getCategoriesWithIds()
  }
  
  form = new FormGroup({
    categories: new FormControl(this.data.categories),
    isCompetitor: new FormControl(this.data.isCompetitor)
  });

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }
}