import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommunicatorComponent } from './communicator.component';

function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http) }

const routes: Routes = [
  {
    path: 'communicator',
    component: CommunicatorComponent,
  }
];

@NgModule({
  declarations: [
    CommunicatorComponent,
  ],
  imports: [
    NgxSpinnerModule,
    RouterModule.forChild(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
})

export class CommunicatorModule { }
