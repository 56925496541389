// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsOptional, IsString, ValidateNested } from 'class-validator'

export namespace GetDashboardUrl {
    export class Item {
        @Expose() @IsString() url!: string
        @Expose() @IsString() title!: string
        @Expose() @IsString() order?: number
        @Expose() @IsString() type?: 'dataStudio' | 'metabase' | 'internal'
    }

    export class Response {
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

export class GetInternalDashboardUrlResponse {
    @Expose() @IsString() url!: string
}

export class GetGlobalDashboardUrlResponse {
    @Expose() @IsString() url!: string
}

export class GetMobileReportsUrlResponse {
    @Expose() @IsString() url!: string
}

export namespace GetTotalReports {
    export class Item {
        @Expose() @IsString() @IsOptional() customerCategory?: string
        @Expose() @IsString() @IsOptional() customerId?: string
        @Expose() @IsString() @IsOptional() customerTerritory?: string
        @Expose() @IsString() @IsOptional() productBrand?: string
        @Expose() @IsString() @IsOptional() productCategory?: string
        @Expose() @IsString() @IsOptional() productId?: string
        @Expose() @IsString() @IsOptional() reportId?: string
        @Expose() @IsString() howManyReportIdSevenDaysAgo!: number
    }

    export class Response {
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() data!: Item[]
    }

}