import { Component } from '@angular/core';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { ProductsImageCatalogsBatchUplaodService, UploadStatus } from '../../../../shared/services/products-image-catalogs-batch-uplaod.service';


@Component({
  selector: 'uplaod-status',
  templateUrl: './uplaod-status.component.html',
  styleUrls: ['./uplaod-status.component.css']
})
export class UplaodStatusComponent extends AutoDestroy {

  isShowProgresses = true
  isShowingUplaodStatus = false
  isDialogCloseAble = false
  countToUplaod = 0
  countUplaoded = 0
  progressDict: UploadStatus = {}

  constructor(private batchUploadService: ProductsImageCatalogsBatchUplaodService) {
    super()
    this.subscriptions.push(batchUploadService.onProgressChanged.subscribe(v => this.setProgressDict(v.productId, v.catalogName, v.image, v.progress)))
    this.subscriptions.push(batchUploadService.onImageUplaodComplete.subscribe(v => this.onImageUplaodComplete(v.productId, v.catalogName, v.image)))
    this.subscriptions.push(batchUploadService.onUplaodingStatusChanged.subscribe(v => this.onUplaudStatusChanged(v)))
  }

  setProgressDict(productId: string, catalogName: string, image: string, progress: number) {
    this.progressDict[productId].catalogs[catalogName].images[image].progress = progress
  }

  onUplaudStatusChanged(status: boolean) {
    if (status) {
      this.countUplaoded = 0
      this.isDialogCloseAble = false
      this.isShowProgresses = true
      this.progressDict = this.batchUploadService.getImageFolderUploadStatus
      this.countToUplaod = this.batchUploadService.getCountToUpload
      this.isShowingUplaodStatus = status
    } else {
      this.isDialogCloseAble = true
      this.isShowProgresses = false
    }
  }

  onImageUplaodComplete(productId: string, catalogName: string, image: string,) {
    this.countUplaoded++
    this.progressDict[productId].countUplaoded++
    this.progressDict[productId].catalogs[catalogName].countUplaoded++
    this.progressDict[productId].catalogs[catalogName].images[image].isUplaoded = true
  }
}
