import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textFilter',
  standalone: true
})
export class TextFilterPipe implements PipeTransform {
  transform(items: string[], str: string): string[] {
    if (!items || !str) {
      return items;
    }
    return items.filter(item => item.toLowerCase().includes(str.toLowerCase()))
  }
}
