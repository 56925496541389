<div  class="padding">

    <div class="title">
        {{'Users'|translate}}
    </div>
    <div style='padding-right: 15px;padding-bottom: 20px;padding-left: 5px;'>
        <button matTooltip="{{'Refresh'|translate}}" mat-mini-fab color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>

        <span style='padding: 10px;'></span>

        <button mat-mini-fab matTooltip="{{ 'Export' | translate }}" color="primary" (click)="exportUsers()">
            <mat-icon>cloud_download</mat-icon>
        </button>

        <span style='padding: 10px;'></span>

        <button mat-mini-fab matTooltip="{{ 'ADD' | translate }}" color="primary" (click)="add()">
            <mat-icon>add</mat-icon>
        </button>

        <span style='padding: 10px;'></span>
        
        <generic-import (onImport)="importExcel($event)" [isRound]="true" [data]="importDialogData"></generic-import>

    </div>
    <user-management-list></user-management-list>
</div>

<p-dialog [(visible)]="isShowModal" class="scroll-container">
    <p-header>{{ 'AddingMultipleUsers' | translate }}</p-header>
    
    <p *ngFor="let item of arrErrors" >
        {{item}}
    </p>
    <div class="dialog-button-container">
        <button mat-flat-button color="primary" (click)="afterConfirmModal()">{{'Ok' |translate}}</button>
    </div>
</p-dialog>