import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'foldable-card',
  templateUrl: './foldable-card.component.html',
  styleUrls: ['./foldable-card.component.scss']
})
export class FoldableCardComponent implements OnInit {
  
  @Input() isOpen: boolean = false;
  @Input() title: string = 'Content';

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.isOpen = !this.isOpen
  }

}
