import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitiesService } from '../../../services/utilities.service';
import { regexValidator, uniqueValidator } from '../../../shared/custom-validations';

@Component({
  selector: 'app-copy-planogram-dialog',
  templateUrl: './copy-planogram-dialog.component.html',
  styleUrls: ['./copy-planogram-dialog.component.css']
})
export class CopyPlanogramDialogComponent {

  copyPlanoframForm: UntypedFormGroup;
  name: string;
  constructor(public dialogRef: MatDialogRef<CopyPlanogramDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private utilitiesService: UtilitiesService) {
    this.copyPlanoframForm = this.formBuilder.group({
      nameCtrl: new UntypedFormControl(),
      //nameCtrl: new UntypedFormControl('', [Validators.required,regexValidator(/[^a-zA-Z0-9\-, ]/, { invalidName: true }), 
      //uniqueValidator('name', 'id', true, null, this.data.planograms)]),
    })
    this.name = data.name
  }
  close() {
    if (!this.copyPlanoframForm.valid) {
      this.utilitiesService.validateAllFormFields(this.copyPlanoframForm);
      return;
    }
    this.dialogRef.close(this.name);
  }

}
