<div class="print-background" *ngIf="isPrint" (click)="isPrintSwitch()"></div>
<div  class="table-main">
  <div class="wrapper">
    <div class="header">
      <div class="row-flex">
        <div  class="header-text-wrapper">
          <div class="header-column-icon">
            <mat-icon routerLink="/planograms">arrow_back </mat-icon>
          </div>
          <div class="header-column-wrapper">
            <div class="header-txt-top-first">{{ planogram?.id }}</div>
            <div class="header-txt-bot-first">
              {{ planogram?.name }}
            </div>
          </div>
          <div class="header-column-wrapper">
            <div class="header-txt-top">{{ "Type" | translate }}</div>
            <div class="header-txt-bot">{{ planogram?.planogramType }}</div>
          </div>
          <div class="header-column-wrapper">
            <div class="header-txt-top">{{ "DateAdded" | translate }}</div>
            <div *ngIf="render" class="header-txt-bot">
              {{ formatDate(planogram?.lastUpdate) }}
            </div>
          </div>
          <div class="header-column-wrapper">
            <div class="header-txt-top">{{ "LastUpdate" | translate }}</div>
            <div *ngIf="render" class="header-txt-bot">
              {{ formatDate(planogram?.lastUpdate) }}
            </div>
          </div>
          <div class="header-column-wrapper">
            <div class="header-txt-top">{{ "PublishDate" | translate }}</div>
            <div *ngIf="render" class="header-txt-bot">
              {{ formatDate(planogram?.lastUpdate) }}
            </div>
          </div>
          <div class="header-column-wrapper">
            <div class="header-txt-top">{{ "Status" | translate }}</div>
            <div class="header-txt-bot">
              {{ capitalizeFirstLetter(planogram?.state) | translate }}
            </div>
          </div>
        </div>
        <div class="button-wrapper" *ngIf="!isPrint">
          <button (click)="print()" mat-flat-button class="button-print">
            <mat-icon style="margin-right: 8px">print</mat-icon
            >{{ "Print" | translate }}
          </button>
          <button
            *ngIf="planogram?.state == 'draft'"
            class="button-edit"
            mat-flat-button
            routerLink="/planogram-planning"
            [queryParams]="{ planogram_id: planogram?.id }"
          >
            {{ "EditPlanogram" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="image-row">
      <div class="image-wrapper">
        <img [style.max-height]="imageHeight + 'px'" [src]="planogram?.imageUrl" alt="" />
      </div>
      <div style="width: 100%">
        <div *ngFor="let shelf of shelfs; let i = index">
          <div class="uprow-wrapper" [style.height]="shelfHeight + 'px'">
            <div>
              <div class="uprow-header">
                {{ "Shelf" | translate }} #{{ i + 1 }}
              </div>
              <div class="uprow-titles-wrapper">
                <span
                  >{{ "DistanceFromTheGround" | translate }}
                  <span class="span-bold"
                    >{{ getDistance(shelf, "ground", i) }} cm</span
                  ></span
                >
                <span
                  >{{ "DistanceFromLeftEdge" | translate }}
                  <span class="span-bold"
                    >{{ getDistance(shelf, "left", i) }} cm</span
                  ></span
                >
                <span
                  >{{ "Height" | translate }}
                  <span class="span-bold"
                    >{{ shelf?.rect.height }} cm</span
                  ></span
                >
                <span
                  >{{ "Width" | translate }}
                  <span class="span-bold"
                    >{{ shelf?.rect.width }} cm</span
                  ></span
                >
                <span
                  >{{ "Depth" | translate }}
                  <span class="span-bold">{{ shelf?.rect.y }} cm</span></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngFor="let shelf of shelfs; let i = index"
      class="botrow-table-wrapper"
    >
      <div class="botrow-table-header">
        <h2>{{ "Shelf" | translate }} #{{ i + 1 }}</h2>
        <mat-icon [@flipAnimation]="isFlipped[i]" (click)="toggleAnimation(i)"
          >expand_less</mat-icon
        >
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th class="small">#</th>
              <th class="one">{{ "EAN" | translate }}</th>
              <th class="one">{{ "ProductId" | translate }}</th>
              <th class="half">{{ "Category" | translate }}</th>
              <th class="two">{{ "Description" | translate }}</th>
              <th class="half">{{ "Brand" | translate }}</th>
              <th class="small">{{ "Facings" | translate }}</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of shelf?.items">
            <tr *ngIf="tableStates[i] === 'visible'">
              <td class="small">{{ i + 1 }}</td>
              <td class="one">{{ item?.ean }}</td>
              <td class="one">{{ item?.productId }}</td>
              <td class="half">{{ item?.category }}</td>
              <td class="two">{{ item?.name }}</td>
              <td class="half">{{ item?.brand }}</td>
              <td class="small">{{ item?.quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
