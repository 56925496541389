import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComplianceReportImage } from 'src/app/shared/api-structures/misc/reports';
import { MobileReportMarker } from 'src/app/shared/interfaces';
import { MarkerComponent } from './marker.component';

@Component({
  selector: 'markers-container',
  standalone: true,
  imports: [CommonModule, MarkerComponent],
  template: `
    <marker *ngFor="let marker of markers trackBy: trackBy" 
      [marker]="marker" 
      [imageHeight]="image.height" 
      [imageWidth]="image.width" 
      [selected]="marker.id === selectedMarker" 
      [tagSelected]="marker.tag && marker.tag === tagSelected"
      [imageRotation]="imageRotation"
      (markChange)='markChange.emit($event)' 
      (click)="markClicked(marker)" 
    ></marker>
  `,
})

export class MarkersContainerComponent implements OnInit {
  @Input() image: ComplianceReportImage | null = null
  @Input() markers: MobileReportMarker[] = []
  @Input() tagSelected = ''
  @Input() selectedMarker = ''
  @Input() imageRotation = 0
  @Output() markChange = new EventEmitter<MobileReportMarker>();
  @Output() markSelected = new EventEmitter<MobileReportMarker>();

  constructor() { }

  ngOnInit(): void {
  }

  trackBy(index: number, marker: MobileReportMarker) {
    return marker.id;
  }

  markClicked(marker: MobileReportMarker) {
    this.markSelected.emit(marker);
  }

}
