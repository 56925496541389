import { Component, OnInit } from '@angular/core';
import { ListCustomersRequest } from 'src/app/shared/api-structures/admin/customer';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { MatDialog } from '@angular/material/dialog';
import { CreateCustomerComponent } from 'src/app/shared/components/customers/create-customer/create-customer.component';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})

export class CustomersComponent extends AutoDestroy implements OnInit {
  listUsersRequest: ListCustomersRequest
  organizationId: string
  companyId: string

  constructor(private customerService: CustomerService, private dialog: MatDialog,
    private snackbarService: SnackbarService, private auth: AuthService,
    private utilitiesService: UtilitiesService,
    private router: Router,
    private languageService: LanguageService) {
    super()
    const urlParams = this.utilitiesService.getUrlParams(window.location.href);
    if (!urlParams['organization_id'] || !urlParams['company_id'] || !urlParams['environment_id']) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('InvalidUrl'))
      this.router.navigateByUrl('/super/organizations/companies')
    }
    this.organizationId = urlParams['organization_id']
    this.companyId = urlParams['company_id']
    this.auth.context = {
      company: urlParams['company_id'],
      environment: urlParams['environment_id'],
      organization: urlParams['organization_id']
    }
    this.listUsersRequest = { currentPage: 0, query: '', pageSize: 25 }
  }

  ngOnInit() {
    this.customerService.loadCustomers(this.listUsersRequest)
  }

  refresh() {
    this.customerService.loadCustomers(this.listUsersRequest)
  }

  add() {
    const dialogRef = this.dialog.open(CreateCustomerComponent)

    dialogRef.afterClosed().subscribe(v => {
      if (v && v.isCustomerCreated) {
        this.refresh()
      }
    })
  }
} 
