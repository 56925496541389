import { Component, Input, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { GoogleMapsService } from '../../services/google-maps.service';


export interface PolyData {
    id: string
    options: google.maps.PolygonOptions,
    polygonClicked?: (e: PolyData) => void
}

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})

export class MapComponent {
    @ViewChild(GoogleMap, { static: false }) private map: GoogleMap;
    @Input() polygons: google.maps.PolygonOptions[] = []
    editPolygon: google.maps.Polygon | null = null
    editPolygonListener: google.maps.MapsEventListener | null = null
    apiLoaded: Observable<boolean>;
    drawingManager: any;
    options: google.maps.MapOptions = {
        zoom: 18,
        mapTypeId: 'satellite',
        disableDefaultUI: true
    };
    center: google.maps.LatLngLiteral = {
        lat: 40.730610,
        lng: -73.935242
    };
    zoom = 4;
    newPolygon: google.maps.PolygonOptions = {
        paths: [{ lat: 1, lng: 1 }, { lat: 2, lng: 2 }, { lat: 2, lng: 1 }],
        strokeColor: "red",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "red",
        fillOpacity: 0.35,
        editable: true,
        draggable: true,
        clickable: true,
        geodesic: false,
    }

    constructor(googleMapsService: GoogleMapsService) {
        this.apiLoaded = googleMapsService.loadApi()
    }

    showEditPolygon(paths: google.maps.LatLngLiteral[], onPathsChanged: (paths: google.maps.LatLngLiteral[]) => void) {
        if (!this.map) return
        if (this.editPolygon) this.editPolygon.setMap(null)
        this.editPolygon = new google.maps.Polygon({
            paths: paths,
            strokeColor: "red",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "red",
            fillOpacity: 0.35,
            editable: true,
            draggable: true,
            clickable: true,
            zIndex: 100
        });

        this.editPolygon.setMap(this.map.googleMap)
        this.editPolygonListener?.remove()
        this.editPolygonListener = google.maps.event.addListener(this.editPolygon, 'mouseup', (e) => {
            const path = this.editPolygon.getPath().getArray().map((p) => ({ lat: p.lat(), lng: p.lng() }));
            onPathsChanged(path)
        });
    }

    hideEditPolygon() {
        if (!this.editPolygon) return
        this.editPolygonListener?.remove()
        this.editPolygon.setMap(null)
    }

    centerPolygon(path?: google.maps.LatLngLiteral[]) {
        if (!this.map) return
        var bounds = new google.maps.LatLngBounds();
        path.forEach((p) => bounds.extend(p))
        this.center = { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() }
        this.map.googleMap.fitBounds(bounds);
    }

}