import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ListCustomersModalFiltersRequest } from 'src/app/shared/api-structures/admin/customer';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: ListCustomersModalFiltersRequest
  ) { }

  ngOnInit(): void {
  }

  form = new FormGroup({
    dateFrom: this.data.dateFrom ? new FormControl(this.data.dateFrom) : new FormControl(null),
    dateTo: this.data.dateTo ? new FormControl(this.data.dateTo) : new FormControl(null),
  });

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }

  onClear() {
    this.form.reset();
    this.dialogRef.close(this.form.value);
  }

  dateRangeChange(event, type: 'dateFrom' | 'dateTo') {
    if (type === 'dateFrom') {
      this.form.controls.dateFrom.setValue(event.value)
      return
    }
    const endOfDay = new Date(event.value)
    endOfDay.setHours(23, 59, 59, 999)
    this.form.controls.dateTo.setValue(endOfDay)
  }
}
