// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace
import { Expose, Type } from 'class-transformer'
import { IsArray, IsOptional, IsString, ValidateNested } from 'class-validator'
import { BulkAddResponseBase } from '../bulk'

export class BaseCategory {
    @Expose() @IsString() name!: string
}

export class Category extends BaseCategory {
    @Expose() @IsString() id!: string
}

export class AddCategoryRequest extends BaseCategory { }

export class BulkAddCategoriesRequest {
    @Expose() @IsString() @IsOptional() entity?: string
    @Expose() @ValidateNested() @Type(() => BaseCategory) @IsArray() data!: BaseCategory[]
}

export class BulkAddCategoriesReport {
    @Expose() @IsString() error!: string
    @Expose() @ValidateNested() @Type(() => BaseCategory) item!: BaseCategory
}

export class BulkAddCategoriesResponse extends BulkAddResponseBase {
    @Expose() @ValidateNested() @Type(() => BulkAddCategoriesReport) @IsArray() report!: BulkAddCategoriesReport[]
}

export class AddCategoryResponse extends Category { }

export class ListCategoriesResponse {
    @Expose() @Type(() => Category) @IsArray() items!: Category[]
}