import { Injectable } from '@angular/core';
import { GetStartInfoResponse } from '../api-structures/misc/misc';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _settings: GetStartInfoResponse

  constructor(private apiService: ApiService) { }

  async getSettings() {
    if (!this._settings) {
      this._settings = await this.apiService.miscRequest<never, GetStartInfoResponse>({ endpoint: '/getStartInfo' })
    }
    return this._settings
  }
  
}
