import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedProduct } from 'src/app/shared/interfaces';
import { PriceReport } from 'src/app/shared/api-structures/misc/reports';
import { MatDialog } from '@angular/material/dialog';
import { OpenImageDialogComponent } from 'src/app/shared/components/open-image-dialog/open-image-dialog.component';

@Component({
  selector: 'list-product',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule],
  template: `
    <div class="product" [style]="{'background': selected ? hoverColor : null, 'border': annotationSelected ? 'solid 3px #ffcd14' : null}">
      <div class="product_image flex-1">
        <img (click)="openImage(extendedProduct.product.imageUrl)" src="{{extendedProduct.product.imageUrl}}" [hidden]='!extendedProduct.product.imageUrl' alt="product">
      </div>
      <div class="product_details flex-5">
        <div>
          <div class="identified" >{{extendedProduct.product.facesCount}} {{'ProductsIdentified' | translate | uppercase }}</div>
          <div class="name">{{extendedProduct.product.name}}</div>
          <div class="little_gray_text">{{extendedProduct.product.ean}}</div>
          <div *ngIf="price" class="little_gray_text">
            Price: <span class="priceTxt"> $\{{price}}</span>
          </div>
        </div>
      </div>
     <div class="flex-2">
      <div class="pull-right">
      <div class="circle" [ngStyle]="{background: circleColor}" ></div>
      <div *ngIf="!hideVisibility" class="visibility_wrap" (click)="toggleVisibility($event)" >
        <mat-icon  *ngIf="extendedProduct.visible" >visibility</mat-icon> 
        <mat-icon *ngIf="!extendedProduct.visible" style="color:red" >visibility_off</mat-icon>
      </div> 
      </div>
     </div>
    </div>
  `,
  styles: [`
    .visibility_wrap {
      display: inline-block; 
      padding: 20px 0
    }
    .product {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #e6e6e6;;
      border-radius: 5px;
      cursor: pointer
    }
    .product:hover {
      background-color: #f5f5f5;
    }
    .product_image img {
      max-width: 40px;
      max-height: 60px;
      padding-right: 5px;
    }
    .little_gray_text {
      color: #999;
      font-size: 12px
    }
    .name {
      color:#4b686d;
      font-weight: 600;
      font-size: .9em;
      /* margin: 2px 0; */
    }
    .identified {
      color:gray;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .circle {
      background: yellow;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      margin-bottom: 4px;
      border: 1px solid black;
      display: inline-block;
    }
    .pull-right {
      float: right;
    }
    .priceTxt {
      color: #43497c;
    }
`]
})

export class ListProductComponent implements OnInit {
  @Input() extendedProduct: ExtendedProduct
  @Input() selected = false
  @Input() annotationSelected = false
  @Input() hideVisibility = false
  @Input() prices: PriceReport[] = []
  @Output() onToggleVisibility = new EventEmitter<ExtendedProduct>();
  circleColor: string = ''
  hoverColor: string = ''
  price = ''
  constructor(public utils: UtilitiesService, private dialog: MatDialog) { }

  ngOnInit(): void {
    const priceReport = this.prices?.find(p => p.productId === this.extendedProduct.product.id)
    if (priceReport) {
      this.price = priceReport.editedPriceCaptured || priceReport.originalPriceCaptured
    }
    this.circleColor = this.extendedProduct.color ?? 'lightgray'
    this.hoverColor = this.utils.colorToRgba(this.circleColor, 0.2)
  }

  toggleVisibility(e) {
    e.stopPropagation()
    this.onToggleVisibility.emit(this.extendedProduct)
  }

  openImage(imageUrl) {
    this.dialog.open(OpenImageDialogComponent, {
      data: { imageUrl }
    })
  }

}
