import { Component, OnInit } from '@angular/core';
import { Dal } from 'src/app/dal/dal';
import { Notification } from 'src/app/views/health-check/notification.model';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit {

  notifications: Notification[] = [];

  constructor(
    private dal: Dal) { }

  getBackgroundColor(severity: string): string {
    switch (severity) {
      case 'critical': return '#D6404029';
      case 'warning': return '#D6751C29';
      case 'notification': return '#1B355529';
      default: return '#ffffff';
    }
  }

  getIconColor(severity: string): string {
    switch (severity) {
      case 'critical': return '#D64040';
      case 'warning': return '#E07800';
      case 'notification': return '#1B3555';
      default: return '#000000';
    }
  }

  getTextColor(severity: string): string {
    return this.getIconColor(severity);
  }

  getIcon(severity: string): string {
    switch (severity) {
      case 'critical': return 'pi-exclamation-triangle';
      case 'warning': return 'pi-exclamation-circle';
      case 'notification': return 'pi-info-circle';
      default: return 'pi-circle-off';
    }
  }

  async ngOnInit() {
    await this.dal.getNotifications().then((notifications :any) => this.notifications = notifications)
  }

}
