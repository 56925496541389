import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CreateUserRequest, CreateUserResponse, ListUsers } from 'src/app/shared/api-structures/admin/user';
import { ByIdRequest } from 'src/app/shared/api-structures/common';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SuperUserManagementService {
  private listUsers = new Subject<ListUsers.Item[]>()
  listUsers$ = this.listUsers.asObservable()


  constructor(private api: ApiService) { }

  async createUser(user: CreateUserRequest) {
    const response = await this.api.superRequest({ endpoint: '/createUser', body: user, responseType: CreateUserResponse })
    return response
  }

  async deleteUser(body: ByIdRequest){
    await this.api.superRequest({ endpoint: '/deleteUser', body})
  }
  
  async getUsers(body: ListUsers.Request) {
    const data = await this.api.superRequest({ endpoint: '/listUsers', body: body, responseType: ListUsers.Response}) 
    this.listUsers.next(data.items)

    return data
  }
}
