import { Expose, Type } from 'class-transformer'
import { IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'

export type BulkAction = 'insert' | 'update' | 'upsert' | 'delete'

export class BulkChangeKey {
  @Expose() @IsString() field!: string
  @Expose() @IsString() value!: string
}

export class BulkChangeBase {
  @Expose() @Type(() => BulkChangeKey) @IsOptional() @ValidateNested() key?: BulkChangeKey
  @Expose() @IsString() action!: BulkAction
}

export class BulkAddResponseBase {
  @Expose() @IsNumber() sent!: number
  @Expose() @IsNumber() created!: number
  @Expose() @IsNumber() errors!: number
}

export class BulkChangeResponseBase {
  @Expose() @IsNumber() sent!: number
  @Expose() @IsNumber() created!: number
  @Expose() @IsNumber() updated!: number
  @Expose() @IsNumber() deleted!: number
  @Expose() @IsNumber() errors!: number
}
