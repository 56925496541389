import { Component, ViewChild, EventEmitter, Output, AfterViewInit, Input } from '@angular/core';
import { GoogleMapsService } from '../../services/google-maps.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'address-input',
  template: `
   <mat-form-field>
    <mat-label>{{'Address'|translate}}</mat-label>
    <input matInput class="input"
      type="text"
      placeholder=" "
      [(ngModel)]="autocompleteInput"
      #addresstext
      [required]="required"
      >
  </mat-form-field>
    `,
})
export class AddressInputComponent implements AfterViewInit {


  @Input() initialAddress: string;
  @Input() addressType: string = 'address';
  @Input() required: boolean
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  @Input() set input(str: string) {
    this.autocompleteInput = str
  };

  autocompleteInput: string;
  queryWait: boolean;
  apiLoaded: Observable<boolean>;
  geocoder: google.maps.Geocoder;

  constructor(googleMapsService: GoogleMapsService) {
    this.apiLoaded = googleMapsService.loadApi()
  }

  ngAfterViewInit() {
    this.autocompleteInput = this.initialAddress;
    this.apiLoaded.subscribe((res) => {
      if (res) this.getPlaceAutocomplete();
    })
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: [this.addressType]
      });
    this.geocoder = new google.maps.Geocoder();
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

}