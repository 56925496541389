import { Injectable } from '@angular/core';
import { AppModule } from 'src/app/app.module';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class LoginService {
    constructor(private api: ApiService, private memoryStorageService: MemoryStorageService) { }

    async login(pw: string) {
        const res = await this.api.rawRequest<null, { token: string }>(
            {
                route: '/createSuperAdminToken',
                body: {
                    password: pw,
                },
                noLogoutOn401: false
            })

        this.memoryStorageService.superToken = res.token
    }
}
