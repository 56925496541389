import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'admin-admin',
  templateUrl: './admin.component.html',
  providers: [MessageService],
})
export class AdminComponent implements OnInit {
  constructor(private primengConfig: PrimeNGConfig) { }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
