<div class="padding">
    <div style="color:#919191;margin-bottom: 16px;margin-left: 5px;">
        <a style="color:#919191;" routerLink="/organizationAdmin/companies">{{'CompaniesManagement'|translate}}</a>
        >
        <span style="font-weight: bold;">{{'CustomerManagement'|translate}}{{' ('}}{{companyId}})</span>
    </div>
    <div class="title">
        {{'Comapny'|translate}} ({{companyId}}) -
        {{'RegisteredCustomers'|translate}}
    </div>
    <customer-list></customer-list>
</div>