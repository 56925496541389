import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dal } from 'src/app/dal/dal';
import { SearchEquipmentsRequestFilters } from 'src/app/shared/api-structures/misc/equipment';

@Component({
  selector: 'equipments-filter-options',
  template: `
  <h2>{{'FilterOptions'|translate}}</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- <mat-form-field appearance="fill">
      <mat-label>{{'Status'|translate}}</mat-label>
      <mat-select formControlName="status">
        <mat-option value="">{{'All'|translate}}</mat-option>
        <mat-option value="active">{{'Active'|translate}}</mat-option>
        <mat-option value="inactive">{{'Inactive'|translate}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field appearance="fill">
      <input matInput type="number" [placeholder]="'MinimumOfShelves' | translate" formControlName="minimumOfShelves">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input matInput type="number" [placeholder]="'MaximumOfShelves' | translate" formControlName="maximumOfShelves">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{'SortBy'|translate}}</mat-label>
      <mat-select formControlName="sortBy">
        <mat-option value="date">{{'LastUpdate'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{'SortDirection'|translate}}</mat-label>
      <mat-select formControlName="sortDirection">
        <mat-option value="asc">{{'Ascending'|translate}}</mat-option>
        <mat-option value="desc">{{'Descending'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button type="reset">{{'ClearFilters'|translate}}</button>
    <button mat-button type="submit" color="primary" [disabled]="!form.valid" style="float: right;">{{'Apply'|translate}}</button>
  </form>
  `,
  styles: [`
    .slider-div {
      width: 100%;
    }
  `]
})
export class EquipmentsFilterOptions {
  constructor(public dialogRef: MatDialogRef<EquipmentsFilterOptions>,
    private dal: Dal,
    @Inject(MAT_DIALOG_DATA) private data: SearchEquipmentsRequestFilters
  ) { }
  
  form = new FormGroup({
    status: new FormControl(this.data.status),
    minimumOfShelves: new FormControl(this.data.minimumOfShelves),
    maximumOfShelves: new FormControl(this.data.maximumOfShelves),
    sortBy: new FormControl(this.data.sortBy),
    sortDirection: new FormControl(this.data.sortDirection)
  });

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }
}