import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'expire-session-dialog',
  templateUrl: './expire-session-dialog.component.html',
  // styleUrls: ['./expire-session-dialog.component.scss'],
})
export class ExpireSessionDialogComponent {
  sessionExpireMinutes: number

  constructor(private dialogRef: MatDialogRef<ExpireSessionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.sessionExpireMinutes = this.data.sessionExpireMinutes
  }

  keepSessionActive() {
    this.dialogRef.close({ isKeepSessionActive: true })
  }
}
