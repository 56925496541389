import { Injectable } from "@angular/core";
import { MemoryStorageService } from "src/app/services/memory-storage.service";
import { HelpersService } from "./helpers.service";
import { LanguageService } from "./language.service";
import jwt_decode from 'jwt-decode';
import * as _ from "underscore";
import { AuthService } from "./auth.service";
import { AppComponent } from "src/app/views/app.component";

interface SessionsWTM {
    cIDPackage?: string
    mc1PlanogramId?: string
    menuIRE?: string
    varDend?: string
    varDstart?: string
    varNamePlanogram?: string
    varPersistValidation?: string
    varShelfCaracteristic?: string
    varcIDPlanogram?: string
    varcIDPlanogramShopper?: string
    varxCustomerTypeID?: string
    varxCustomertype?: string
    varxLevel?: string
    varxRegion?: string
    varxStore?: string
}
interface MessageEventData {
    type: 'clientSideAppInitResponse'
    token: string
    mc1PlanogramId: string
    getProductsAndCategoriesRawRequest?: string
    getPlanogramsRawRequest?: string
    sessions?: SessionsWTM
}

interface MessageEventEx extends MessageEvent<MessageEventData> { }

@Injectable({ providedIn: 'root' })
export class CommunicatorService {
    constructor(private memoryStorageService: MemoryStorageService,
        private helpers: HelpersService,
        private languageService: LanguageService,
        private authService: AuthService,
        private appComponent: AppComponent) { }

    async loadDataAndInit() {
        const p = new Promise<MessageEventData>((resolve, reject) => {
            let isResponded = false
            window.addEventListener('message', async (e: MessageEventEx) => {
                try {
                    if (e.data.type == 'clientSideAppInitResponse') {
                        // If not already responded and there is a token, its because of testing in the dev_iframe.html
                        // So, really, the 'isResponded' is for easy debugging
                        if (isResponded === false || this.memoryStorageService.token !== e.data.token) {
                            const { cIDLanguage, exp } = jwt_decode<{ cIDLanguage: string, exp: number }>(e.data.token)
                            this.languageService.setLanguage(cIDLanguage)
                            this.memoryStorageService.token = e.data.token
                            this.appComponent.initializeApp()
                            this.authService.$logged.next(true)
                            this.planNextRefresh(exp)
                            isResponded = true

                            const result: MessageEventData = {
                                mc1PlanogramId: e.data.mc1PlanogramId,
                                type: e.data.type,
                                token: e.data.token,
                                sessions: e.data.sessions
                            }

                            if (_.isString(e.data.getPlanogramsRawRequest)) {
                                result.getPlanogramsRawRequest = e.data.getPlanogramsRawRequest
                            }

                            if (_.isString(e.data.getProductsAndCategoriesRawRequest)) {
                                result.getProductsAndCategoriesRawRequest = e.data.getProductsAndCategoriesRawRequest
                            }

                            resolve(e.data)
                        }
                    }
                } catch (err) {
                    isResponded = true
                    reject(err)
                }
            })
        })

        this.requestData()

        const result = await p
        return result
    }

    private requestData() {
        top.postMessage({ type: 'clientSideAppInit' }, '*')
    }

    private planNextRefresh(exp: number) {
        const cms = new Date().getTime()
        const ems = exp * 1000
        const diff = ems - cms
        const reqIn = diff - (1000 * 60)

        this.helpers.delay(reqIn).then(() => this.requestData())
    }
}
