<div (click)="emitToggleSelect()" class="card" [ngClass]="{'selected': selected}">
    <div class="small-circle">
        <mat-icon>check</mat-icon>
    </div>
    <div class="title-field">{{data.name || '- Empty -'}}</div>

    <div class="field">
        <span class="field-value" [title]="data.email">{{data.email || '- Empty -'}}</span>
    </div>
    <div class="field">
        <span class="field-value" [title]="data.phone">{{data.phone || '- Empty -'}}</span>
    </div>
</div>