import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import _ from 'underscore';

interface TreeNodeData {
  isEditMode: boolean
  newVal?: any
  val: any
  path: string[]
}

interface TreeNodeEx extends TreeNode<TreeNodeData> { }

@Component({
  selector: 'admin-super-settings',
  templateUrl: './super-settings.component.html',
  styleUrls: ['./super-settings.component.scss'],
})
export class SuperSettingsComponent implements OnInit {
  _ = _
  nodes: TreeNodeEx[]

  constructor() { }

  async ngOnInit() {
    await this.refreshSettings()
    console.log(this.nodes)
  }

  edit(node: TreeNodeEx) {
    node.data.isEditMode = true
    node.data.newVal = node.data.val
  }

  async saveEdit(node: TreeNodeEx) {
    const settings = this.buildObject(node)

    // await this.settingsService.updateSettings(settings)
    await this.refreshSettings()

    node.data.val = node.data.newVal
    node.data.isEditMode = false
  }

  buildObject(node: TreeNodeEx) {
    const path = node.data.path
    const rootObj = {}
    let obj = rootObj

    for (let i = 0; i < path.length; i++) {
      const p = path[i];

      if (i < path.length - 1) {
        obj[p] = {}
        obj = obj[p]
      } else {
        obj[p] = node.data.newVal
      }
    }

    return rootObj
  }

  cancelEdit(node: TreeNodeEx) {
    delete node.data.newVal
    node.data.isEditMode = false
  }

  createNodesR(obj: any, basePath: string[]) {
    const result: TreeNodeEx[] = []

    if (_.isArray(obj)) {
      return [{
        label: 'No support for an array',
      }]
    }

    Object.keys(obj).forEach(key => {
      const val = obj[key]
      const curPath = _.clone(basePath)
      curPath.push(key)

      const tn: TreeNodeEx = {
        label: key,
      }

      if (_.isObject(val)) {
        tn.children = this.createNodesR(val, curPath)
      } else if (_.isNumber(val) || _.isString(val) || _.isBoolean(val)) {
        tn.type = 'editable'
        tn.data = {
          path: curPath,
          isEditMode: false,
          val,
        }
      }

      result.push(tn)
    })

    return result
  }

  async refreshSettings() {
    // const settings = await this.settingsService.getSettings()
    // this.nodes = this.createNodesR(settings, [])
  }
}
