import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { SortDirection } from 'src/app/shared/api-structures/common';
import { ListStaticModels } from 'src/app/shared/api-structures/misc/misc';
import { GetPlanogramResponse, PlanogramWithId } from 'src/app/shared/api-structures/misc/planogram';
import { PlanogramSettingsGettingResponse } from 'src/app/shared/api-structures/misc/planogramSettings';
import { ClusterService } from 'src/app/shared/services/cluster.service';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { UserManagementService } from 'src/app/shared/services/user-management.service';
import * as _ from 'underscore';
import { Dal } from '../../../dal/dal';
import { DataService } from '../../../services/data.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { PLANOGRAM_STATUS } from '../../../shared/const';
import { ConfirmDialogComponent } from '../../general/confirm-dialog/confirm-dialog.component';
import { CopyPlanogramDialogComponent } from '../copy-planogram-dialog/copy-planogram-dialog.component';
import { PlanogramAssociationsComponent } from '../planogram-associations/planogram-associations.component';
import { PlanogramNewDialogComponent } from '../planogram-new-dialog/planogram-new-dialog.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'planograms',
  templateUrl: './planograms.component.html',
  styleUrls: ['./planograms.component.css','../../../shared/ui-kit/table/table.scss']
})

export class PlanogramsComponent implements OnInit {
  PLANOGRAM_STATUS = PLANOGRAM_STATUS
  moment = moment
  displayedColumns: string[] = [ 'planogramImage','code', 'name', 'type', 'validity', 'status', 'lastUpdate', 'copyIcon',  'inactiveIcon', 'configIcon', 'infoIcon', 'editIcon','deleteIcon',];
  dataSource: MatTableDataSource<PlanogramWithId>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  searchPlanogramsValue: string;
  filterByState: PLANOGRAM_STATUS
  autocompleteShowSpinner: boolean = false;
  planograms: PlanogramWithId[] = [];
  staticModels: ListStaticModels.Item[] = [];
  totalPlanograms: number;
  sortBy: string;
  sortDirection: SortDirection;
  filterPlanograms: string[] = [];
  commonData: PlanogramSettingsGettingResponse;
  companyType = getCompanyType()
  openAssociation = ''
  hideConfig: boolean = true;
  uploadingFiles: {
    name: string,
    progress: number
  }[] = []
  constructor(
    private languageService: LanguageService,
    private memoryStorageService: MemoryStorageService,
    public dialog: MatDialog,
    private router: Router,
    public dataService: DataService,
    private utilitiesService: UtilitiesService,
    private dal: Dal,
    private snackbarService: SnackbarService,
    private clusterService: ClusterService,
    private CustomerService: CustomerService,
    private UsersService: UserManagementService,
    private datePipe: DatePipe,
    private appService: AppService,
  ) {
    if (getCompanyType() !== 'MC1') {
      this.displayedColumns.splice(7, 0, 'associated-users')
      this.hideConfig = false
    }
  }

  ngOnInit() {
    this.filterPlanograms = []
    this.paginator.pageSize = 25;
    let urlParams = this.utilitiesService.getUrlParams(window.location.href);
    let currentPage = this.paginator.pageIndex;
    let pageSize = this.paginator.pageSize;
    let query = urlParams['query']
    this.openAssociation = urlParams['openassociation']
    if (this.openAssociation) {
      this.router.navigate([], {
        queryParams: {
          'openassociation': null,
        },
        queryParamsHandling: 'merge'
      })
    }
    if (!query) {
      query = ""
    }
    else {
      query = this.utilitiesService.replaceAll(query, "%20", " ");
    }
    Promise.resolve().then(() => {
      this.searchPlanogramsValue = query
    })
    let filterByState = urlParams['filterByState']
    if (!filterByState) {
      this.filterByState = null
    }
    this.getPlanograms(currentPage, pageSize, query, this.filterByState);

    this.loadPlanogramSettings();
  }

  private async loadPlanogramSettings() {
    this.commonData = await this.dal.getPlanogramSettings();
  }

  async getPlanograms(currentPage: number, pageSize: number, query: string, filterByState: PLANOGRAM_STATUS, sortBy?: string, sortDirection?: SortDirection) {
    sortDirection = _.isEmpty(sortDirection) ? undefined : sortDirection
    sortBy = sortBy === '' ? undefined : sortBy
    const res = await this.dal.searchPlanograms(this.memoryStorageService.mc1PlanogramId, currentPage, pageSize, query, filterByState, sortBy, sortDirection)
    const res2 = await this.dal.listStaticModels()
    const m = res2
    const def = m.find(v => v.id === 'default')
    if (def !== undefined) {
      def.name = this.languageService.translateSync('Default')
    }
    this.staticModels = m
    this.totalPlanograms = res.totalPlanograms
    this.planograms = res.planograms;
    this.dataSource = new MatTableDataSource(this.planograms);
    this.paginator.length = this.totalPlanograms
    if (this.openAssociation) {
      this.popupAssociatedUsers(this.openAssociation)
      this.openAssociation = ''
    }
  }

  applyFilter() {
    this.filterPlanograms = []
    let query = "";
    if (this.searchPlanogramsValue) {
      query = this.searchPlanogramsValue
    }
    this.getPlanograms(this.paginator.pageIndex, this.paginator.pageSize, query, this.filterByState, this.sortBy, this.sortDirection)
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
    let queryParams = { query: query, filterByState: this.filterByState }
    this.utilitiesService.addFiltersToUrl(queryParams)
    this.filterPlanograms = []
  }

  filterByStatus(planogramStatus: any) {
    this.filterByState = planogramStatus
    this.applyFilter()
  }

  async updatePlanogramDetails(id: string) {
    if (!this.commonData)
      return;

    const planogram = await this.dal.getPlanogram(id);

    const modal = this.dialog.open(PlanogramNewDialogComponent, {
      width: '80vw',
      data: {
        planograms: this.planograms,
        staticModels: this.staticModels,
        commonData: this.commonData,
        planogram
      }
    });

    modal.afterClosed().subscribe(async (data) => {
      if (!data?.removeImage && data?.imageFile && data?.planogram) {
        const res = await this.uploadPrimaryImage(data.imageFile, data.planogram)
        data.planogram.imageUrlExternal = res.imageUrlExternal
      } else if (data?.removeImage && data?.imageUrlExternal && data?.planogram) {
        await this.removeImage(data.planogram, data.imageUrlExternal)
        data.planogram.imageUrlExternal = undefined
      }
      if (data?.planogram) {
        await this.dal.updatePlanogram(data.planogram)
        this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PlanogramSavedSuccessfully'))
      }
      this.applyFilter()
    });
  }

  private async uploadPrimaryImage(file: File, planogram: PlanogramWithId): Promise<GetPlanogramResponse> {
    const uploadProgress = { name: file.name, progress: 0 }
    this.uploadingFiles = [uploadProgress]
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadingFiles[0].progress = progress)
    const resultFileCreation = await this.utilitiesService.isFileUrlExist(fileUrl)
    if (resultFileCreation === false) {
      this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
      return
    }
    return await this.dal.updatePlanogramExternalImage({ id: planogram.id, imageUrlExternal: fileUrl })
  }

  private async removeImage(planogram: PlanogramWithId, imageUrlExternal: string): Promise<void> {
    if (!planogram?.imageUrlExternal || !imageUrlExternal || imageUrlExternal === planogram.imageUrl) {
      return
    }
    await this.dal.removePlanogramExternalImage({ id: planogram?.id, imageUrlExternal: planogram?.imageUrlExternal })
      .then((res) => imageUrlExternal = planogram?.imageUrl)
  }

  addNewPlanogram() {
    if (!this.commonData)
      return;

    const dialogRef = this.dialog.open(PlanogramNewDialogComponent, {
      width: '80vw',
      data: {
        planograms: this.planograms,
        staticModels: this.staticModels,
        commonData: this.commonData,
        planogram: null
      }
    })

    dialogRef.afterClosed().subscribe(async data => {
      if (_.isUndefined(data)) {
        return
      }
      const res = await this.dal.addPlanogram(data.planogram)
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PlanogramSavedSuccessfully'))
      this.applyFilter()
      this.router.navigate(['/planogram-planning'], { queryParams: { 'planogram_id': res.id } })
    })
  }

  showPlanogramImage(planogram) {
    if (!planogram?.imageUrlExternal && !planogram?.imageUrl) {
      return
    }
    this.dialog.open(PalanogrmImageDialog, {
      data: {
        planogram: planogram
      }
    })
  }
  copyPlanogram(planogram) {
    const dialogRef = this.dialog.open(CopyPlanogramDialogComponent, {
      data: { name: planogram.name + " - Copy", planograms: this.planograms }
    })
    dialogRef.afterClosed().subscribe(async newPlanogramName => {
      if (!newPlanogramName) {
        return
      }
      const planogramRes = await this.dal.duplicatePlanogram(planogram.id, newPlanogramName)
      this.planograms.push(planogramRes)
      this.totalPlanograms = this.planograms.length;
      this.applyFilter()
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PlanogramCopiedSuccessfully'))
    })
  }
  popupAssociatedUsers(planogramId: string) {
    const dialogRef = this.dialog.open(PlanogramAssociationsComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      height: '95vh',
      data: {
        id: planogramId,
        clusterService: this.clusterService,
        customersService: this.CustomerService,
        usersService: this.UsersService,
        dal: this.dal
      }
    })

  }
  deletePlanogram(planogram: PlanogramWithId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.languageService.translateSync('AreYouSureDeletePlanogram') }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }

      await this.dal.deletePlanogram(planogram.id)
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PlanogramDeletedSuccessfully'))
      this.applyFilter()
    })
  }
  inactivePlanogram(planogram: PlanogramWithId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.languageService.translateSync('AreYouSureInactivatePlanogram') }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }

      await this.dal.inactivatePlanogram(planogram.id)
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PlanogramInactivateSuccessfully'))
      this.applyFilter()
    })
  }
  sortData(sort: Sort) {
    if (sort.active && sort.direction) {
      this.sortBy = sort.active
      this.sortDirection = (sort.direction as SortDirection)
    }

    this.applyFilter()
  }
  async listPlanogramsForAutocomplete(event: KeyboardEvent, value) {
    if (value.length >= 3 && event.key !== "Enter" && event.key !== "Home" && event.key !== "End" && event.key !== "Shift" && event.key !== "ArrowLeft" && event.key !== "ArrowRight") {

      this.autocompleteShowSpinner = true
      this.filterPlanograms = []
      try {
        const resPlanograms = await this.dal.listPlanogramsForAutocomplete(value, this.memoryStorageService.mc1PlanogramId)
        this.autocompleteShowSpinner = false
        this.filterPlanograms = resPlanograms
      } catch (err) {
        this.autocompleteShowSpinner = false
      }
    }
    /*if (value == "") {
      this.applyFilter()
    }*/
  }

  formatDate(dateString: string) {
    const formattedDate = this.datePipe.transform(dateString, 'dd/MM/yyyy HH:mm');
    return formattedDate;
  }

  displayPlanogramType(planogramType: string) {
    if (planogramType.toLowerCase() === 'planogram') {
      return 'Fixed'
    }
    if (planogramType.toLowerCase() === 'free') {
      return 'Free'
    }
    return ''
  }

}

@Component({
  selector: 'palanogrm-image',
  templateUrl: 'planogram-image-dialog.html',
  styleUrls: ['./planogram-image-dialog.css']

})
export class PalanogrmImageDialog {
  planogram: PlanogramWithId
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    data.planogram.shareOfShelfType = data.planogram.shareOfShelfType === undefined ? 'linear' : data.planogram.shareOfShelfType
    this.planogram = data.planogram
  }
}
