import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';
import { CustomFieldValue } from 'src/app/shared/api-structures/common';

@Component({
  selector: 'add-advanced-rule-form',
  templateUrl: './add-advanced-rule-form.component.html',
  styleUrls: ['./add-advanced-rule-form.component.scss']
})
export class AddAdvancedRuleFormComponent implements OnInit {

  @Input() index: number
  @Output() removeRuleEvent = new EventEmitter<number>()
  @Output() ruleChangedEvent = new EventEmitter<any>()
  @Input() customFieldSettings: CustomFieldSettingsWithId[]
  @Input() form: FormGroup
  @Input() initialValue: CustomFieldValue
  isFirst = true

  fieldSettings: CustomFieldSettingsWithId

  constructor() { }

  ngOnInit(): void {
    this.form.get('fieldName').valueChanges.subscribe((value) => {
      this.fieldSettings = this.customFieldSettings.find((field) => field.name === value)
    })

    if(this.form.get('fieldName').value){
      this.fieldSettings = this.customFieldSettings.find((field) => field.name === this.form.get('fieldName').value)
    }

    this.form.valueChanges.subscribe((formValue) => {
      this.onRuleChange(formValue)
    })
  }

  onRuleChange(formValue) {
    this.ruleChangedEvent.emit({
      index: this.index,
      fieldName: formValue.fieldName,
      value: formValue.value,
    })
  }

  removeRule() {
    this.removeRuleEvent.emit(this.index)
  }

}
