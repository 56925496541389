import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiKey, CreateApiKeyResponse } from 'src/app/shared/api-structures/superAdmin';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ApiKeysService } from '../../services/api-keys.service';
import { AddEditApiKeyFormComponent } from './add-edit-api-key-form/add-edit-api-key-form.component';

@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit {

  dataSource = new MatTableDataSource<ApiKey>()
  columnsToDisplay = [
    'name',
    'ctx',
    'deleteIcon'
  ]
  filter:any

  constructor(private apiKeysService: ApiKeysService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.listApiKeys()
  }

  async listApiKeys() {
    const res = await this.apiKeysService.listApiKeys()
    this.dataSource.data = res.items
  }

  ondeleteClick(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: 'Are you sure you want to delete this API key?', key:false } })
    dialogRef.afterClosed().subscribe(v => {
      if (v) {
        this.deleteApiKey(id)
      }
    })
  }

  showKeyDialog(key: string) {
    this.dialog.open(ConfirmDialogComponent, { data: { title: 'Api Key', message: key, key: true } })
  }

  async deleteApiKey(id: string) {
    await this.apiKeysService.deleteApiKey(id)
    this.listApiKeys()
  }

  onCreateClick() {
    const dialogRef = this.dialog.open(AddEditApiKeyFormComponent, {width:'543px'})
    dialogRef.afterClosed().subscribe((v: CreateApiKeyResponse) => {
      if (v) {
        this.showKeyDialog(v.fullKey)
        this.listApiKeys()
      }
    })
  }


  export() {
    const { data } = this.dataSource;
    if (!data || data.length === 0) {
      return;
    }
    const replacer = (key: string, value: any) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'apikeys_list.csv';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  applyFilter() {
    const filterValue = this.filter.toLowerCase();
  
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const filterArray = filter.split('');
  
      return Object.values(data).some(value => {
        if (typeof value === 'string') {
          const valueLower = value.toLowerCase();
          return filterArray.every(character => valueLower.includes(character));
        }
        return false;
      });
    };
  
    this.dataSource.filter = filterValue;
  }

}
 