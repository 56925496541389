<div class="generic-table-main block">
  <div #customHeader>
    <ng-content select="[header]"></ng-content>
  </div>
  <div *ngIf="!customHeader.children.length && withHeader" class="row main-header">
    <div>
      <div class="title">{{title}}</div>
      <div class="sub-title">{{subTitle}}</div>
    </div>

    <div class="action-buttons">
      <div>
        <button [hidden]="!showRefresh" mat-flat-button color="primary" (click)="onRefresh()"
          class="button-desc"><mat-icon> refresh</mat-icon> {{'Refresh'|translate }}</button>
      </div>
      <div>
        <generic-import (onImport)="onImport($event)" [hidden]="!showImport" [data]="importDialogData"></generic-import>
      </div>
      <div>
        <button mat-flat-button color="primary" (click)="onAdd()" class="button-desc"><mat-icon>add</mat-icon>
          {{'AddNEW'|translate }}</button>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8 bordered">
    <div *ngIf="freeTextFilter" class="pre-table-header">
      <mat-form-field class="aligned">
        <input matInput placeholder="{{'Search'|translate}}" (keyup.enter)="onFilter($event.target.value)">
      </mat-form-field>

      <mat-icon *ngIf="showModalFilters" (click)="onModalFilters($event)" class="show-filters-btn">tune</mat-icon>
    </div>
    <div class="table-wrap" #tablewrap>
      <table *ngIf="columns" mat-table [dataSource]="dataSource" matSort class="width-100"
        (matSortChange)="onSort($event)">

        <ng-container *ngFor="let c of columns" [matColumnDef]="c.column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="nowrap">
            {{c.label | capitalize | translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="c.type === 'text' " [title]="row[c.column]" class="nowrap">
              {{row[c.column]}}
            </span>

            <a *ngIf="c.type === 'link' " [routerLink]="c.link" [title]="row[c.column]" class="nowrap">
              {{row[c.column]}}
            </a>

            <!-- <a *ngIf="c.type === 'iconLink' " [routerLink]="row[c.column]" [target]="'_blank'" [title]="row[c.column]">
              <mat-icon [fontIcon]="c.icon" [style]="{color:'#919191'}"></mat-icon>
            </a> -->

            <span *ngIf="c.type === 'date' " class="nowrap">
              {{row[c.column] ? moment(row[c.column]).format('L LT') : ''}}
            </span>

            <a *ngIf="c.type === 'users' " matTooltip="{{'Users'|translate}}" mat-button (click)="onUsers(row)">
              <mat-icon style="color:#919191;transform: scale(0.8);">supervisor_account</mat-icon>
            </a>

            <a *ngIf="c.type === 'edit' " matTooltip="{{'Edit'|translate}}" mat-button (click)="onEdit(row)">
              <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
            </a>

            <a *ngIf="c.type === 'delete' " matTooltip="{{'Delete'|translate}}" mat-button (click)="onDelete(row)">
              <mat-icon fontSet="fa" fontIcon="fa-trash" style="color:#919191"></mat-icon>
            </a>

            <ng-template select="[customCol]" *ngIf="c.type === 'custom' " [ngTemplateOutlet]="customCol"
              [ngTemplateOutletContext]="{$implicit: 'data' ,row: row, col: c}"></ng-template>
          </td>
        </ng-container>

        <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="body-row" mat-row *matRowDef="let row; columns: displayedColumns;">
      </table>
    </div>
    <div class="table_footer">
      <div>
        <button [hidden]="!showExport" mat-stroked-button class="button-export" (click)="onExport()">{{'Export' |
          translate}}
          <mat-icon>arrow_downward</mat-icon>
        </button>
      </div>
      <mat-paginator *ngIf="paginateEvent.observers.length" [pageSize]="25" [pageIndex]="currentPage"
        [pageSizeOptions]="[5, 10, 25, 100]" [length]="totalItens" (page)="onPaginate($event)">
      </mat-paginator>
      <simple-paginator *ngIf="simplePaginateEvent.observers.length" (page)="onSimplePaginate($event)"
        [currentPage]="currentPage" [hasNext]="hasNext"></simple-paginator>
    </div>
  </div>
</div>