import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment from 'moment';
import { ClusterWithId } from 'src/app/shared/api-structures/admin/cluster';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { ClusterService } from '../../../shared/services/cluster.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

type SortObj = {
  active: string,
  direction: 'asc' | 'desc'
}

interface TableData extends ClusterWithId {
  rules?: string
}

@Component({
  selector: 'app-clusters',
  templateUrl: './clusters.component.html',
  styleUrls: ['./clusters.component.scss']
})
export class ClustersComponent extends AutoDestroy implements OnInit {

  currentPage = 0;
  pageSize = 25;
  query = '';
  sortObj: SortObj = {
    active: 'name',
    direction: 'desc'
  }
  hasNext = false;
  displayedColumns = ['id', 'name', 'rules', 'customersCount', 'createdAt', 'lastUpdate', 'edit', 'delete']
  moment = moment;
  data: TableData[] = [];
  customerCustomFields = null
  constructor(
    private clusterService: ClusterService,
    private router: Router,
    private languageService: LanguageService,
    private helpers: HelpersService) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(this.clusterService.clustersList$.subscribe((data) => {
      if(!data) return
      this.data = data.items.map((cluster) => {
        const rules = []
        Object.entries(cluster).forEach(([key, value]) => {
          if (['customerTypes', 'customerCategories', 'customerChains', 'regions'].includes(key) && value.length) {
            rules.push(this.languageService.translateSync(this.helpers.capitalize(key)))
          } else if (['customFields'].includes(key) && Object.keys(value).length) {
            rules.push(this.languageService.translateSync(this.helpers.capitalize(key)))
          }
        })
        return { ...cluster, rules: rules.join(', ') }
      })
      this.hasNext = data.hasNext
    }))
    this.listClusters()
  }

  addCluster() {
    this.router.navigateByUrl('/admin/clusters/add')
  }

  onEdit(row: TableData) {
    this.router.navigateByUrl(`/admin/clusters/${row.id}`)
  }

  async onDelete(row: TableData) {
    try {
      await this.clusterService.deleteCluster(row.id)
      this.listClusters()
    } catch (error) {
      throw error
    }
  }

  listClusters() {
    this.clusterService.fetchClusters({
      currentPage: this.currentPage,
      pageSize: this.pageSize,
      query: this.query,
      sortBy: this.sortObj.active,
      sortDirection: this.sortObj.direction
    })
  }

  onFilter(query: string) {
    this.query = query
    this.listClusters()
  }

  onSort(sortObj: SortObj) {
    this.sortObj = sortObj
    this.listClusters()
  }

  onPaginate(page: number) {
    this.currentPage = page
    this.listClusters()
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
