import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ConfirmDialogComponent implements OnInit {

  showTooltip = false;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close(confirmResult) {
    this.dialogRef.close(confirmResult);
  }

  copyMessage(value: string): void {
    navigator.clipboard.writeText(value).then(
      () => {
        console.log('Text copied to clipboard');
        this.showTooltip = true;
        setTimeout(() => this.showTooltip = false, 1500);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  }
}