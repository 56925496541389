// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsIn, IsNumber, IsOptional, IsString } from 'class-validator'
import { IsCtx } from '../customDecorators'

export class CreateSignedUrlRequest {
    @Expose() @IsCtx() @IsOptional() ctx?: string
    @Expose() @IsString() @IsOptional() planogramId?: string
    @Expose() @IsString() @IsOptional() organizationId?: string
    @Expose() @IsString() ext!: string
    @Expose() @IsString() contentType!: string
    @Expose() @IsBoolean() isMobileReport!: boolean
    @Expose() @IsString() @IsOptional() mobileReportUuid?: string
    @Expose() @IsBoolean() @IsOptional() isPricetag?: boolean
    @Expose() @IsNumber() @IsOptional() version?: number
    @Expose() @IsIn(['user_feedback', 'machine_learning', 'import_planogram']) @IsOptional() source?: 'user_feedback' | 'machine_learning' | 'import_planogram'
    @Expose() @IsString() @IsOptional() id?: string // productId_catalogId
    @Expose() @IsBoolean() @IsOptional() isPlanogramUpload?: boolean
}

export class CreateSignedUrlRsponse {
    @Expose() @IsString() writeUrl!: string
    @Expose() @IsString() readUrl!: string
}

export class SignGcpPathsRequest {
    @Expose() @Type(() => String) @IsArray() gcpPaths!: string[]
}

export class SignGcpPathsResponse {
    @Expose() @Type(() => String) @IsArray() urls!: string[]
}

export namespace ListStaticModels {
    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
        @Expose() @IsNumber() @IsOptional() isActive?: boolean
        @Expose() @IsString() modelUrl!: string
        @Expose() @IsString() labelsUrl!: string
    }

    export class Response {
        @Expose() @Type(() => Item) @IsArray() arr!: Item[]
    }
}

export class InitSessionRequest {
    @Expose() @IsString() token!: string
    @Expose() @IsString() mc1PlanogramId!: string
}

export class InitSessionResponse {
    @Expose() @IsString() mc1PlanogramId!: string
    @Expose() @IsString() mc1PlanogramName!: string
    @Expose() @IsString() syncOnlyEditedImages!: string
    @Expose() @IsString() isNoiseProductEnabled!: boolean
}

export class ContractTrainingResponse {
    @Expose() @IsNumber() @IsOptional() currentMonth?: number
    @Expose() @IsNumber() @IsOptional() currentYear?: number
    @Expose() @IsNumber() @IsOptional() trainingCount?: number
    @Expose() @IsNumber() @IsOptional() trainingQuota?: number
    @Expose() @IsNumber() @IsOptional() singleTrainingSold?: number
}

export class GetStartInfoResponse {
    @Expose() @IsString() mapsKey?: string
    @Expose() @IsString() mapsDashboardsKey?: string
    @Expose() @IsString() dashboardApiEndpoint?: string
    @Expose() @IsString() language?: string
    @Expose() @IsString() chatCpgUrl?: string
    @Expose() @IsBoolean() @IsOptional() sendReportEditsToCatalog?: boolean

}

export class CompanySettingsResponse {
    @Expose() @IsString() @IsOptional() isNoiseEnabled?: boolean
    @Expose() @IsString() @IsOptional() singlePicturePriceOcr?: boolean
    @Expose() @IsString() @IsOptional() multiPicturePriceOcr?: boolean
    @Expose() @IsString() @IsOptional() galeryPhotoEnabled?: boolean
    @Expose() @IsBoolean() @IsOptional() showCatalogDimensions?: boolean
    @Expose() @IsString() @IsOptional() currentModelId?: string
    @Expose() @IsString() @IsOptional() syncOnlyEditedImages?: string
    @Expose() @IsString() @IsOptional() language?: string
    @Expose() @IsString() @IsOptional() country?: string
    @Expose() @IsString() @IsOptional() currency?: string
    @Expose() @IsString() @IsOptional() logoUrl?: string
    @Expose() @IsString() @IsOptional() ocrReadMethod?: string
    @Expose() @IsNumber() @IsOptional() blurDetectionThreshold?: number
    @Expose() @IsNumber() @IsOptional() maxNumberImagesInCatalog?: number
    @Expose() @IsNumber() @IsOptional() maxNumberOfCatalogs?: number
    @Expose() @IsNumber() @IsOptional() blurDetectionCaptureTries?: number
    @Expose() @IsNumber() @IsOptional() imagesQualityAndroid?: number
    @Expose() @IsNumber() @IsOptional() imagesQualityIOS?: number
    @Expose() @IsNumber() @IsOptional() overlayBounds?: number
    @Expose() @IsNumber() @IsOptional() threshold?: number
}