<div class="terr-list" *ngIf="!isEditing">
  <button mat-flat-button type="button" color="primary" style="float: right;" (click)="addArea()">
    <mat-icon>add</mat-icon> {{'AddNewArea' | translate}}
  </button>
  <mat-form-field class="aligned">
    <input matInput placeholder="{{'Search'|translate}}" (keyup.enter)="addFilter($event)">
  </mat-form-field>
  <div *ngFor="let area of areas" class="terr-list-item"
    [ngClass]="{'selected': selectedArea?.id === area.id}">
    <div style="width: 100%;">
      <div class="terr-header">{{area.name}}</div>
      <div>
        <!--<span class="terr-field">{{'Country' | translate}}:</span>{{area.country}} TODO:Finish This-->
        <div class="area_id_field">{{area.id}}</div>
        <span class="terr-field">{{'Tags' | translate}}:</span>
        <span *ngFor="let tag of area.tags"
        style=" background-color: #3364a2;
                padding: 0px 8px 0px 8px;
                margin: 0px 2px 0px 2px;
                border-radius: 14px;
                color: white;"
        class="terr-tag">{{tag}}</span>
      </div>
    </div>
    <button mat-button color="primary" (click)="onSelect(area)"><mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
  <div class="listEmpty" *ngIf="!areas.length">
    <h3>{{'NoAreas' | translate}}</h3>
  </div>
</div>