import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CompanySettings } from 'src/app/shared/api-structures/admin/companySettings';
import { Context } from 'src/app/shared/api-structures/admin/user';
import { ListCompaniesRequest, ListCompaniesResponse } from 'src/app/shared/api-structures/super-admin/company';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private listCompanies = new Subject<ListCompaniesResponse>()

  listCompanies$ = this.listCompanies.asObservable()

  constructor(private api: ApiService) { }

  async loadCompanies(body: ListCompaniesRequest) {
    const data = await this.api.organizationAdminRequest({
      endpoint: '/listCompanies',
      body: body,
      responseType: ListCompaniesResponse
    })
    this.listCompanies.next(data)
    return data
  }

  async getCompanySettings(context: Context) {
    const companySettings = await this.api.organizationAdminRequest({
      endpoint: '/getCompanySettings',
      context,
      responseType: CompanySettings.Item
    })
    return companySettings
  }

  async saveCompanySettings(context: Context, body: CompanySettings.Item) {
    const response = await this.api.organizationAdminRequest<CompanySettings.Item, CompanySettings.Item>({
      endpoint: '/saveCompanySettings',
      body,
      context,
      responseType: CompanySettings.Item
    })
    return response
  }
}