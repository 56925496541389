import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StandaloneNavbarService {
  private _showNavbar = new Subject<boolean>()

  constructor(private router: Router) { }

  get showNavbar() {
    return this._showNavbar.asObservable()
  }

  setShowNavbar(value: boolean) {
    this._showNavbar.next(value)
  }

  validateNavbar(){
    const url = window.location.href
    if (url.includes('admin') || url.includes('login')) {
      this.setShowNavbar(false)
    } else {
      this.setShowNavbar(true)
    }
  }
}
