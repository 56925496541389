import { Component, EventEmitter, Inject, Input } from '@angular/core';
import { TrainingsService } from 'src/app/admin/services/trainings.service';
import { PrimeNGConfig } from "primeng/api";
import { ContractTrainingResponse } from 'src/app/shared/api-structures/admin/training';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';


@Component({
  selector: 'new-training-dialog',
  templateUrl: 'new-training-dialog.component.html',
  styleUrls: ['./new-training.component.css']
})
export class NewTrainingDialog {
  public remainingTrainig: number
  public isMinimumImagesAvailable: boolean
  constructor(public dialogRef: MatDialogRef<NewTrainingDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.remainingTrainig = data.remainingTrainig
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'admin-new-training',
  templateUrl: './new-training.component.html',
  styleUrls: ['./new-training.component.css']
})
export class NewTrainingComponent {
  isShowDialog: boolean
  isShowDialogHaveTrainingInProcess: boolean
  contractTraining: ContractTrainingResponse
  remainingTrainig: number

  constructor(private trainingsService: TrainingsService, private primengConfig: PrimeNGConfig, public dialog: MatDialog,
    private memoryStorageService: MemoryStorageService) {
      this.memoryStorageService.contractTrainingObs.subscribe(v => {
        if (v){
          this.contractTraining = v
          this.remainingTrainig = Math.abs(v.trainingCount-v.trainingQuota+v.singleTrainingSold)
        }
      }).unsubscribe()
  }

  ngOnInit() {
    this.primengConfig.ripple = true
  }

  verifiContractTraining() {
    if (this.remainingTrainig > 0) {
      this.isShowDialog=true
    } else {
      this.dialog.open(NewTrainingDialog,
        {
          data: {
            contractTraining: this.contractTraining,
            remainingTrainig: this.remainingTrainig
          }
        });
    }
  }

  async startTraining() {
    this.isShowDialog = false
    await this.trainingsService.startNewTraining()
    this.memoryStorageService.setReloadTrainingPage(true)
  }

  async isThereTrainingRunning() {
    const isProduction = await this.trainingsService.isProduction()
    const isThereTrainingRunning = await this.trainingsService.isThereTrainingRunning()
    if (isThereTrainingRunning) {
      this.isShowDialogHaveTrainingInProcess = true
      return
    }
    if (isProduction) {
      if (this.remainingTrainig == 0) {
        this.dialog.open(NewTrainingDialog,
          {
            data: {
              contractTraining: this.contractTraining,
              remainingTrainig: this.remainingTrainig
            }
          });
        return
      }
    }
    this.isShowDialog = true
    return
  }
}
