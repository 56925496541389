<h2 mat-dialog-title>{{'CreateCustomer'|translate}}</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'ExternalId'|translate}}</mat-label>
        <input matInput [(ngModel)]="externalId">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'Name'|translate}}</mat-label>
        <input matInput [(ngModel)]="name" required>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Category</mat-label>
        <input type="text" placeholder="Select category" aria-label="Select category" matInput
            [formControl]="categoryCtrl" [matAutocomplete]="autoCategory" [(ngModel)]="category"
            (click)="dropDownCategoryOptions()" required>
        <mat-autocomplete autoActiveFirstOption #autoCategory="matAutocomplete">
            <mat-option *ngFor="let option of filteredCategories  | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon matSuffix *ngIf="isLoadingCategories">
            <i class="fa fa-spinner fa-spin"></i>
        </mat-icon>
    </mat-form-field>
    
    <mat-form-field>
        <mat-label>{{'TaxId'|translate}}</mat-label>
        <input matInput [(ngModel)]="taxId" required>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'CustomerCode'|translate}}</mat-label>
        <input matInput [(ngModel)]="customerCode" required>
    </mat-form-field>

    <address-input (setAddress)="onPlaceChanged($event)" required></address-input>

    <mat-form-field class="example-full-width">
        <mat-label>{{'Territory' | translate }}</mat-label>
        <input type="text" placeholder="Select Territory" aria-label="Select Territory" matInput
            [matAutocomplete]="autoTerritory" [formControl]="terrCtrl" [(ngModel)]="territory">
        <mat-autocomplete autoActiveFirstOption #autoTerritory="matAutocomplete">
            <mat-option *ngFor="let option of filteredTerritories | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon matSuffix *ngIf="isLoadingAttributes">
            <i class="fa fa-spinner fa-spin"></i>
        </mat-icon>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Chain</mat-label>
        <input type="text" placeholder="Select Chain" aria-label="Select Chain" matInput [formControl]="chainsCtrl"
            [matAutocomplete]="auto" [(ngModel)]="chain">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredChains  | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon matSuffix *ngIf="isLoadingAttributes">
            <i class="fa fa-spinner fa-spin"></i>
        </mat-icon>
    </mat-form-field>

    <custom-fields (formValue)="customFieldsChanged($event)" [fieldsSettings]="this.data.customFields" [setDefaultValue]="true"></custom-fields>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close matTooltip="{{'CANCEL'|translate}}">
        <mat-icon color="primary">cancel</mat-icon>
    </button>
    <button mat-button (click)="save()" matTooltip="{{'SaveChanges'|translate}}">
        <mat-icon color="primary">save</mat-icon>
    </button>
</mat-dialog-actions>