<mat-form-field>
    <input matInput placeholder="Search User" [matAutocomplete]="auto"
        (keyup)="listUsersForAutocomplete($event.target.value)" (keyup.enter)="emitFilter($event.target.value)"
        [(value)]="filter" #MatAutocompleteTrigger>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)"
        [displayWith]="displayFn">

        <mat-option *ngFor="let option of users" [value]="option">
            {{option.email ?? option.name ?? option.phone}}
        </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix *ngIf="isLoadingUsers">
        <i class="fa fa-spinner fa-spin"></i>
    </mat-icon>
</mat-form-field>

<div class="dialog-button-container">
    <span style="padding-right: 15px;">
        <button mat-flat-button color="primary" cdkFocusInitial
            (click)="addAssociatedUsersToCustomer()">{{'ASSOCIATE'|translate}}</button>
    </span>
</div>