<div (click)="emitToggleSelect()" class="card" [ngClass]="{'selected': selected}">
    <div class="small-circle">
        <mat-icon>check</mat-icon>
    </div>
    <h6>{{data.name}}</h6>
    <div class="field">
        <span class="field-name">{{'Categories' | translate}}: </span>
        <span class="field-value" [title]="data.customerCategories.join(', ')">
            {{data.customerCategories.join(',') || '- None -'}}
        </span>
    </div>
    <div class="field">
        <span class="field-name">{{'Chains' | translate}}: </span>
        <span class="field-value" [title]="data.customerChains.join(', ')">
            {{data.customerChains.join(', ') || '- None-'}}</span>
    </div>
    <div class="field">
        <span class="field-name">{{'Regions' | translate}}: </span>
        <span class="field-value" [title]="data.regions.join(', ')">
            {{areaNames.join(',') || '- None -'}}
        </span>
    </div>
    <div class="field">
        <span class="field-name">{{'CustomerCount' | translate}}: </span>
        <span class="field-value">{{data.customersCount}}</span>
    </div>
</div>