<mat-form-field>
    <input matInput placeholder="Search Customer" [matAutocomplete]="auto" (keyup.enter)="emitFilter($event.target.value)"
    (keyup)="listCustomersForAutocomplete($event.target.value)" [(value)]="filter" #MatAutocompleteTrigger>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)"
        [displayWith]="displayFn">
        
        <mat-option *ngFor="let option of customers" [value]="option">
            {{option.name}}{{'  '}}{{option.category}}{{'    '}}{{option.externalId}}
        </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix *ngIf="isLoadingCustomers">
        <i class="fa fa-spinner fa-spin"></i>
    </mat-icon>
</mat-form-field>

<div class="dialog-button-container">
    <span style="padding-right: 15px;">
        <button mat-flat-button color="primary" cdkFocusInitial (click)="addAssociatedUsersToCustomer()">{{'ASSOCIATE'|translate}}</button>
    </span>
</div>