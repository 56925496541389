import { Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ListInputComponent)
  }]
})
export class ListInputComponent implements ControlValueAccessor {
  @ViewChild('listInputMain') listInputMain: ElementRef;

  @Input() required: boolean
  @Input() placeholder: string = 'Add Items'

  onChange = (value: string[]) => { }
  onTouched: any = () => { };
  disabled = false;
  ctrl = new FormControl()
  values: string[] = []
  listStyle
  showListFlag = false

  constructor() { }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = (value) => {
      fn(value)
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(values: string[]): void {
    if (values) this.values = values
  }

  addValue() {
    const value = this.ctrl.value
    this.ctrl.setValue('')

    if (!value) return
    if (this.values.includes(value)) return

    this.values.unshift(value)
    this.onChange(this.values)
  }

  removeValue(event, index: number) {
    event.stopPropagation()
    this.values.splice(index, 1)
    this.onChange(this.values)
  }

  showList() {
    const width = this.listInputMain.nativeElement.offsetWidth
    this.listStyle = { 'max-height': '200px', 'width': `${width}px` }
    this.showListFlag = true
  }

  hideList() {
    this.showListFlag = false
  }

}
