import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SortDirection } from 'src/app/shared/api-structures/common';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OptionForSelect } from './add-associate/add-associate.component';

@Component({
  selector: 'associated-generic',
  templateUrl: './associated-generic.component.html',
  styleUrls: ['./associated-generic.component.scss']
})
export class AssociatedGenericComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @Input() id: string
  @Input() fetch: (currentPage: number, pageSize: number, filter: string) => Promise<any>
  @Input() add: (id: string) => Promise<void>
  @Input() delete: (id: string) => Promise<void>
  @Input() fetchAddOptions: (currentPage: number, pageSize: number, query: string) => Promise<OptionForSelect>
  @Input() displayedColumns: string[]
  @Input() headerText: string

  dataSource: MatTableDataSource<Record<string, unknown>>

  sortBy: string;
  sortDirection: SortDirection
  filter: string = ''
  currentPage: number = 0
  pageSize: number = 25
  moment = moment

  customerName: string
  selectedId: string
  isShowAddUserField: boolean = false
  isShowConfirmDeleteDialog = false

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helpersService: HelpersService,
    private snackbarService: SnackbarService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit() {
    this.getAssociated()
  }

  sortData(sort: Sort) {
    this.sortBy = sort.active;
    this.sortDirection = (sort.direction as SortDirection)
    this.applyFilter()
    this.sortBy = ""
    this.sortDirection = null
  }

  onOptionSelected(filter: string) {
    this.filter = filter
    this.applyFilter()
  }

  applyFilter() {
    this.currentPage = 0
    this.getAssociated()
  }

  async getAssociated() {
    const result = await this.fetch(this.currentPage, this.pageSize, this.filter)
    this.dataSource = new MatTableDataSource(result.items)
  }

  async addItem(id: string) {
    if (this.dataSource.data.find(x => x.id === id)) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync(`AssociationAlreadyExists`))
    } else {
      this.closeAdd()
      await this.add(id)
      this.getAssociated()
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync(`AssociationAdded`))
    }
  }

  closeAdd() {
    this.filter = ''
    this.getAssociated()
  }

  showDeleteDialog(selectedId: string) {
    this.selectedId = selectedId
    this.isShowConfirmDeleteDialog = true
  }

  async onDelete() {
    this.isShowConfirmDeleteDialog = false

    try {
      await this.delete(this.selectedId)
    } catch (error) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync(`AssociationDeleteError`))
      return
    }
    this.getAssociated()
    this.snackbarService.openSnackBar(2000, this.languageService.translateSync(`AssociationDeleted`))
  }
}
