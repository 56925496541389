// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsString, IsUrl, ValidateNested } from 'class-validator'
import { UrlOptions } from '../common'

export class AddStaticModelRequest {
    @Expose() @IsString() name!: string
    @Expose() @IsUrl(UrlOptions) modelUrl!: string
    @Expose() @IsUrl(UrlOptions) labelsUrl!: string
    @Expose() @IsUrl(UrlOptions) priceLabelUrl!: string
    @Expose() @IsUrl(UrlOptions) priceModelUrl!: string
    @Expose() @IsUrl(UrlOptions) shelfLabelUrl!: string
    @Expose() @IsUrl(UrlOptions) shelfModelUrl!: string
    @Expose() @IsUrl(UrlOptions) ocrModelUrl!: string
    @Expose() @IsUrl(UrlOptions) popLabelUrl!: string
    @Expose() @IsUrl(UrlOptions) popModelUrl!: string
}

export namespace ListStaticModel {
    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
        @Expose() @IsBoolean() isActive?: boolean
        @Expose() @IsString() modelUrl!: string
        @Expose() @IsString() labelsUrl!: string
        @Expose() @IsString() priceLabelUrl!: string
        @Expose() @IsString() priceModelUrl!: string
        @Expose() @IsString() shelfLabelUrl!: string
        @Expose() @IsString() shelfModelUrl!: string
        @Expose() @IsString() ocrModelUrl!: string
        @Expose() @IsString() popLabelUrl!: string
        @Expose() @IsString() popModelUrl!: string
    }

    export class Response {
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() arr!: Item[]
    }
}

