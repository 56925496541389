import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fromEvent } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RectPosition, RectSize } from 'src/app/shared/interfaces';

@Component({
  selector: 'draw-annotations',
  standalone: true,
  imports: [CommonModule],
  template: `
    <canvas class="main" #canvas></canvas>
  `,
  styles: [`
    .main {
      background-color: rgb(255,255,255,.2);;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: crosshair;
    }
  `]
})
export class DrawAnnotationsComponent {

  @Input() name: string;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @Output() onDrawEnded = new EventEmitter<{ pos: RectPosition, canvasSize: RectSize }>();
  ctx: CanvasRenderingContext2D;
  sub
  sizeSub

  ngAfterViewInit() {

    this.onResize()
    this.onDraw()
  }

  ngOnDestroy() {
    if (this.sub) { this.sub.unsubscribe() }
    if (this.sizeSub) { this.sizeSub.unobserve() }
  }

  onResize() {
    const ro = new ResizeObserver(entries => {
      const ctx = this.ctx;
      const canvas = this.canvas.nativeElement;
      let dataURL = canvas.toDataURL('image/png');
      var img = document.createElement('img');
      img.src = dataURL;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      var img = document.createElement('img');
      img.src = dataURL;
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
      }
    });
    this.sizeSub = ro.observe(this.canvas.nativeElement);
  }

  drawEnded(e: { pos: RectPosition, canvasSize: RectSize }) {
    this.onDrawEnded.emit(e)
  }

  onDraw() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    const mouseDownStream$ = fromEvent(this.canvas.nativeElement, 'mousedown');
    const mouseMoveStream$ = fromEvent(this.canvas.nativeElement, 'mousemove');
    const mouseUpStream$ = fromEvent(window, 'mouseup');
    let lastMouseX = 0
    let lastMouseY = 0
    let height = 0
    let width = 0
    this.sub = mouseDownStream$.pipe(
      tap((event: MouseEvent) => {
        lastMouseX = event.offsetX
        lastMouseY = event.offsetY
      }),
      switchMap(() => mouseMoveStream$.pipe(
        tap((event: MouseEvent) => {
          const mousex = event.offsetX
          const mousey = event.offsetY
          width = mousex - lastMouseX
          height = mousey - lastMouseY
          // this.ctx.lineTo(event.offsetX, event.offsetY);
          this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height); //clear canvas
          this.ctx.beginPath();
          this.ctx.rect(lastMouseX, lastMouseY, width, height)
          this.ctx.strokeStyle = 'red';
          this.ctx.lineWidth = 2;
          this.ctx.stroke();
        }),
        takeUntil(mouseUpStream$),
        finalize(() => {
          if (width < -10) {
            lastMouseX = lastMouseX + width
            width = -width
          }
          if (height < -10) {
            lastMouseY = lastMouseY + height
            height = -height
          }
          if (width > 10 && height > 10) {
            this.drawEnded({
              pos: { top: lastMouseY, left: lastMouseX, width, height },
              canvasSize: { height: this.canvas.nativeElement.height, width: this.canvas.nativeElement.width }
            })
          }
          // else too small
          this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        })
      ))
    ).subscribe();
  }

}
