
import { Expose, Type } from 'class-transformer'
import { IsArray, IsDate, IsIn, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ByPageRequest, LatLng, SortDirection, SortDirectionArray } from '../common'
import { DateType } from '../customDecorators'



export class BaseArea {
  @Expose() @IsString() name!: string
  @Expose() @ValidateNested() @Type(() => LatLng) @IsArray() polygon!: LatLng[]
  @Expose() @IsString({ each: true }) @IsArray() @IsOptional() tags?: string[]
  @Expose() @IsString() @IsOptional() category?: string
  @Expose() @IsString({ each: true }) @IsArray() @IsOptional() regions?: string[]
  @Expose() @IsString() @IsOptional() type?: string
  @Expose() @IsString() @IsOptional() color?: string
}

export class AddAreaRequest extends BaseArea { }

export class UpdateAreaData {
  @Expose() @IsString() @IsOptional() name?: string
  @Expose() @ValidateNested() @Type(() => LatLng) @IsArray() polygon?: LatLng[]
  @Expose() @IsString({ each: true }) @IsArray() @IsOptional() tags?: string[]
  @Expose() @IsString() @IsOptional() category?: string
  @Expose() @IsString({ each: true }) @IsArray() @IsOptional() regions?: string[]
  @Expose() @IsString() @IsOptional() type?: string
  @Expose() @IsString() @IsOptional() color?: string
}

export class UpdateAreaRequest {
  @Expose() @IsString() id!: string
  @Expose() @Type(() => UpdateAreaData) @ValidateNested() data!: UpdateAreaData
}

export class Area extends BaseArea {
  @Expose() @IsString() id!: string
  @Expose() @DateType() @IsDate() @IsOptional() lastUpdate?: Date
}

export class ListAreasRequest extends ByPageRequest {
  @Expose() @IsIn(SortDirectionArray) @IsOptional() sort?: SortDirection
  @Expose() @IsString() @IsOptional() sortBy?: keyof Area
  @Expose() @IsString() @IsOptional() textFilter?: string
}

export class ListAreasResponse {
  @Expose() @Type(() => Area) @IsArray() items!: Area[]
}

// export class EditAreaCustomersFields {
//   @Expose() @IsString() @IsOptional() territory?: string
//   @Expose() @IsString() @IsOptional() category?: string
//   @Expose() @IsString() @IsOptional() group?: string
// }

// export class EditAreaCustomersRequest {
//   @Expose() @IsString() id!: string
//   @Expose() @Type(() => EditAreaCustomersFields) @ValidateNested() data!: EditAreaCustomersFields
// }