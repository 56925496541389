import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AssociatePlanogramRequest, BaseCluster, ClusterWithId, CountClusterCustomersRequest, DissociatePlanogramRequest, ListClustersRequest, ListClustersResponse } from "src/app/shared/api-structures/admin/cluster";
import { ApiService } from "src/app/shared/services/api.service";
import { LanguageService } from "src/app/shared/services/language.service";
import { CountResponse } from "../api-structures/common";

@Injectable({ providedIn: 'root' })
export class ClusterService {
  private clustersList = new Subject<ListClustersResponse>()

  clustersList$ = this.clustersList.asObservable()
  constructor(private api: ApiService, private languageService: LanguageService) { }

  async getCluster(id: string) {
    const data = await this.api.adminRequest({
      endpoint: '/getCluster',
      body: { id },
      responseType: ClusterWithId,
    })
    return data
  }

  async listClusters(request: ListClustersRequest) {
    const data = await this.api.adminRequest({
      endpoint: '/listClusters',
      body: request,
      responseType: ListClustersResponse,
      errorMessage: this.languageService.translateSync('ErrorListingClusters')
    })
    return data
  }

  async fetchClusters(request: ListClustersRequest) {
    const data = await this.listClusters(request)
    this.clustersList.next(data)
    return data
  }

  async addCluster(request: BaseCluster) {
    const data = await this.api.adminRequest({
      endpoint: '/addCluster',
      body: request,
      responseType: ClusterWithId,
    })
    return data
  }

  async updateCluster(request: ClusterWithId) {
    const data = await this.api.adminRequest({
      endpoint: '/updateCluster',
      body: request,
      responseType: ClusterWithId,
    })
    return data
  }

  async deleteCluster(id: string) {
    const data = await this.api.adminRequest({
      endpoint: '/deleteCluster',
      body: { id },
    })
    return data
  }

  async countCustomersByCluster(data: CountClusterCustomersRequest, isHideProgress: boolean = true) {
    const result = await this.api.adminRequest<CountClusterCustomersRequest, CountResponse>({
      endpoint: '/countClusterCustomers',
      body: data
    }, { isHideProgress })

    return result
  }

  async associatePlanogram(data: AssociatePlanogramRequest) {
    const result = await this.api.miscRequest({
      endpoint: '/associateClusterToPlanogram',
      body: data
    })

    return result
  }

  async dissociatePlanogram(data: DissociatePlanogramRequest) {
    const result = await this.api.miscRequest({
      endpoint: '/dissociatePlanogramClusterToPlanogram',
      body: data
    })

    return result
  }

}