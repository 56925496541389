<div style="padding: 16px; background-color: white;" class="mat-elevation-z8">
  <div style="border: 2px solid #F0F0F1; border-radius: 8px;">
    <div class="pre-table-header">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup.enter)="applyFilter()" [(ngModel)]="filter" #input>
      </mat-form-field>
    </div>
    <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="organizationId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Organization Id'|translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="companies">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Companies'|translate}}</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.companies">{{row.companies}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'UpdatedAt'|translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.lastUpdate | date : 'dd/MM/yyyy HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="icons">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td style="padding-right: 0;" mat-cell *matCellDef="let row" class="delete-cell">
          <button matTooltip="{{'Companies'|translate}}" mat-button [routerLink]="['companies']"
          [queryParams]="{ organization_id : row.id }">
          <mat-icon style="color:#919191;transform: scale(0.8);">business</mat-icon>
        </button>
        <button matTooltip="{{'Edit'|translate}}" mat-button (click)="updateOrganization(row)">
          <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
        </button>
          <button matTooltip="{{'Delete'|translate}}" mat-button (click)="ondeleteClick(row.id)">
            <mat-icon style="transform: scale(0.9);">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
    </table>

    <mat-paginator [length]="totalOrganizations" [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
    </mat-paginator>
  </div>
</div>