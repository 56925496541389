import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';

export const configFactory = (configService: ConfigService) => {
    return () => configService.loadConfig();
};

type FirebaseConf = {
    apiKey: string,
    authDomain: string
}

export interface IConfig {
    isProduction: boolean
    api_endpoint: string
    login_url?: string
    firebase: FirebaseConf
}

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    public config!: IConfig;

    constructor(private http: HttpClient) { }

    public loadConfig() {
        return this.http
            .get<IConfig>('./configs/config.json')
            .toPromise()
            .then((config) => {
                this.config = config as IConfig;
                return Promise.resolve(config);
            })
            .catch((err) => {
                console.log(err);
            });
    }
}
