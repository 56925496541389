import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Dal } from 'src/app/dal/dal';
import { EquipmentWithId, SearchEquipmentsRequestFilters } from 'src/app/shared/api-structures/misc/equipment';
import { EquipmentService } from 'src/app/super-admin/services/equipment.service';
import { EquipmentsFilterOptions } from './equipments-filter-options.component';
import { MatDialog } from '@angular/material/dialog';
import _, { isEqual } from 'underscore';
import { CreateEquipmentDialogComponent } from '../planograms/create-equipment-dialog/create-equipment-dialog.component';

@Component({
  selector: 'equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent implements OnInit {
  equipments: EquipmentWithId[] = []

  readonly filterSeparatorKeysCodes: number[] = [ENTER, COMMA]
  filters: string[] = []
  filterShowSpinner: false
  isInfiniteScrollDisabled: boolean = false
  equipmentEdit
  modalFilters: SearchEquipmentsRequestFilters = {
    status: null,
    minimumOfShelves: null,
    maximumOfShelves: null,
    sortBy: null,
    sortDirection: null
  }
  mode: 'view' | 'add' = 'view'

  constructor(private dal: Dal, private equipmentService: EquipmentService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getEquipments()
  }

  async getEquipments() {
    this.equipments = await this.dal.getEquipments(this.filters, this.modalFilters)
  }

  getEquipmentHeight(equipment: EquipmentWithId) {
    return this.equipmentService.getEquipmentShelvesLength(equipment);
  }

  addFilter(event: MatChipInputEvent) {
    const input = event.input
    const value = event.value

    if ((value || '').trim()) {
      this.filters.push(value.trim())
      this.getEquipments()
    }

    // Reset the input value
    if (input) {
      input.value = ''
    }
  }

  removeFilter(filter: string) {
    const index = this.filters.indexOf(filter)

    if (index >= 0) {
      this.filters.splice(index, 1)
      this.getEquipments()
    }
  }

  isMatTooltipDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }

  openCreateForm() {
    let dialogRef = this.dialog.open(CreateEquipmentDialogComponent, { height: '700px' })
    dialogRef.afterClosed().subscribe(async ({equipment, mode}) => {
      if (mode === 'close') {
        return
      }
      if (_.isUndefined(equipment)) {
        return
      }
      await this.dal.addEquipment(equipment)
      this.equipments = []
      this.getEquipments()
    })
  }

  openFilterOptions(event: MouseEvent) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(EquipmentsFilterOptions, { width: '500px', data: this.modalFilters });
    dialogRef.afterClosed().subscribe(data => {
      if (data && !isEqual(this.modalFilters, data)) {
        this.modalFilters = data
        this.getEquipments()
      }
    });
  }

  async saveNewEquipment(equipment?: EquipmentWithId) {
    this.mode = 'view'
    if (equipment) {
      await this.dal.addEquipment(equipment)
    }
    this.equipments = []
    this.getEquipments()
  }

  editEquipment(equipment) {
    let dialogRef = this.dialog.open(CreateEquipmentDialogComponent, { height: '700px', data: {equipment} })
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.mode === 'close') {        
        this.equipments = []
        this.getEquipments()
        return
      }
      
      await this.dal.addEquipment(result.equipment)
      this.equipments = []
      this.getEquipments()
    })
  }

}
