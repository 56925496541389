import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { ContractTrainingResponse } from 'src/app/shared/api-structures/admin/training';
import { SortDirection } from 'src/app/shared/api-structures/common';
import { CountImagesToApproveResponse, ListProductsWithImagesToApprove, ListProductsWithImagesToApproveFilters, SortBy, SourceType } from 'src/app/shared/api-structures/misc/toApprove';
import { ApiService } from 'src/app/shared/services/api.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { isEqual } from 'underscore';
import { Dal } from '../../../dal/dal';
import { RecognitionMode, RecognitionReviewImages } from '../unapproved-images/unapproved-images.component';
import { UnapprovedListFiltersComponent } from './unapproved-list-filters.component';

export type productType = 'Own' | 'Invader'
export class productWithImageToApprove extends ListProductsWithImagesToApprove.ResponseStructre { }


@Component({
  selector: 'unapproved',
  templateUrl: './unapproved.component.html',
  styleUrls: ['./unapproved.component.scss']
})

export class UnapprovedComponent implements OnInit {
  @Output() onImageCountChange = new EventEmitter<CountImagesToApproveResponse>()

  products: productWithImageToApprove[] = []
  totalimages: number
  filterBy: SourceType
  paginator: { pageIndex: number, pageSize: number, length: number } = { pageIndex: 0, pageSize: 50, length: undefined }
  readonly filterSeparatorKeysCodes: number[] = [ENTER, COMMA]
  filters: string[] = []
  filterShowSpinner: false
  newFilters: ListProductsWithImagesToApproveFilters = {
    minimumImages: null,
    maximumImages: null,
    sortBy: null,
    sortDirection: null,
  }
  isOnInit: boolean = true
  isTheEnd: boolean = false
  isLoadingImages: boolean = true
  percentGridFormat: string
  textFilterOrderImages: string
  sortDirection: SortDirection = 'desc'
  sortBy: SortBy = null
  isInfiniteScrollDisabled: boolean = false
  remainingTrainings: number;
  maxNumberImagesInCatalog: number

  constructor(public dialog: MatDialog, private router: Router,
    private dal: Dal,
    private languageService: LanguageService,
    private memoryStorageService: MemoryStorageService
  ) { }

  ngOnInit() {
    const shouldFilterOnlyEditedImages = this.memoryStorageService.syncOnlyEditedImages || RecognitionReviewImages.ALL_IMAGES

    if (shouldFilterOnlyEditedImages == RecognitionReviewImages.JUST_EDITED_IMAGES) {
      this.filterBy = RecognitionMode.USER_FEEDBACK
    }
    this.countImagesToApprove()
    this.getUnapprovedImages(0, this.paginator.pageSize, this.filterBy, this.filters, this.newFilters, false)
    this.getContractTraining()
    
  }

  async getContractTraining() {
    this.memoryStorageService.contractTrainingObs.subscribe(v => {
      if (v){
        this.remainingTrainings = Math.abs(v.trainingCount-v.trainingQuota+v.singleTrainingSold)
      }
    }).unsubscribe()
  }

  async countImagesToApprove() {
    const res = await this.dal.countImagesToApprove(this.filterBy)
    this.onImageCountChange.emit(res)
    this.isOnInit = false
  }

  async nextPage() {
    if (this.isLoadingImages === true) {
      return
    }

    this.isLoadingImages = true
    this.paginator.pageIndex++
    await this.getUnapprovedImages(this.paginator.pageIndex, this.paginator.pageSize, this.filterBy, this.filters, this.newFilters, true)
  }

  async restartImagesList() {
    delete this.products
    this.products = []
    this.paginator.pageIndex = 0
    this.getUnapprovedImages(0, this.paginator.pageSize, this.filterBy, this.filters, this.newFilters, false)
  }

  async showLatestImages() {
    this.sortDirection = 'desc'
    this.textFilterOrderImages = this.languageService.translateSync('MoreRecent')
    this.restartImagesList()
  }

  async showOlderImages() {
    this.sortDirection = 'asc'
    this.textFilterOrderImages = this.languageService.translateSync('MoreOlder')
    this.restartImagesList()
  }

  definePercentGridFormat(totalPaged: number) {
    return this.percentGridFormat = '250px'
  }

  async getUnapprovedImages(currentPage: number, pageSize: number, filterByImagesSource: SourceType, filters: string[], newFilters, isScrolling: boolean) {
    const regex = '^[ \\w\\.\\-@%,()/À-ÿ]*$'
    
    const res = await this.dal.searchUnapprovedImages(currentPage, pageSize, filterByImagesSource, filters, newFilters)
    this.totalimages = res.totalItemsCount
    isScrolling ? this.products.push(...res.items) : this.products = res.items
    this.paginator.length = this.totalimages
    let productsName: string[] = this.products.map(p => {return p.productName})
    productsName = productsName.filter(p => { return p.match(regex)})
    this.isInfiniteScrollDisabled = this.totalimages === this.products.length;
    this.isLoadingImages = false
  }

  addFilter(event: MatChipInputEvent) {
    const input = event.input
    const value = event.value

    if ((value || '').trim()) {
      this.filters.push(value.trim())
      this.restartImagesList()
    }

    // Reset the input value
    if (input) {
      input.value = ''
    }
  }

  removeFilter(filter: string) {
    const index = this.filters.indexOf(filter)

    if (index >= 0) {
      this.filters.splice(index, 1)
      this.restartImagesList()
    }
  }

  isMatTooltipDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }

  async selectImageCatalog(productId: string, productName: string) {
    const imageCatalogs = await this.getImageCatalogsWithsUnapprovedImages(productId)
    if (imageCatalogs.length === 1) {
      this.router.navigate(['/image-review'],
        {
          queryParams: {
            product_id: productId, product_name: productName, imageCatalog_id: imageCatalogs[0].imageCatalogId,
            product_thumbnail: imageCatalogs[0].thumbnail,
            is_have_more_image_catalogs: false,
          }
        }
      )
      return
    }
    this.router.navigate(['/unapproved-catalogs'],
    {
      queryParams: {
        product_id: productId,
        product_name: productName,
      }
    })
  }

  private async getImageCatalogsWithsUnapprovedImages(productId: string) {
    const response = await this.dal.listImageCatalogsWithImagesToApprove({ productId: productId })
    return response.items
  }

  openFilterOptions(event: MouseEvent) {
    event.stopPropagation();
    const data = { filters: this.newFilters, sortBy: this.sortBy, sortDirection: this.sortDirection }
    const dialogRef = this.dialog.open(UnapprovedListFiltersComponent, { data });
    this.paginator.pageIndex = 0
    dialogRef.afterClosed().subscribe(newData => {
      if (newData && !isEqual(newData, data)) {
        this.newFilters.sortBy = newData.sortBy
        this.newFilters.sortDirection = newData.sortDirection
        this.getUnapprovedImages(this.paginator.pageIndex, this.paginator.pageSize, this.filterBy, this.filters, this.newFilters, false)
      }
    });
  }

  updateColor(progress) {
    if (progress<=80){
       return 'accent';
    } else if (progress>80 && progress <=90){
       return 'warn';
    } else {
      return 'primary';
    }
 }
}